import { BackendPartialBusinessForUpdate } from 'utils/backendTypes';
import { Company } from 'context/customer-context/types';
import { getLocaleAllocation, getRoleArrayFromPersonCompany } from '../utils';
import { returnBooleanForBackend } from './index';

export const serializeCompanyToBackend = (company: Company): BackendPartialBusinessForUpdate => {
  const serializedCompany: BackendPartialBusinessForUpdate = {
    id: company.id,
    business: company.companyName,
    business_address_street: company.businessAddressStreet,
    business_address_house_n: company.businessAddressHouseNumber,
    business_address_extra: company.businessAddressAdditional,
    business_address_postcode: company.businessAddressPostalCode,
    business_address_city: company.businessAddressCity,
    business_address_country: company.businessAddressCountry?.code || '',
    legal_form: company.legalForm,
    hgb_accordance: !!returnBooleanForBackend(company.hgb),
    customer_profile_relation: {
      roles: getRoleArrayFromPersonCompany(company),
    },
  };

  if (company.shares.length) {
    serializedCompany.shares = company.shares.map((share) => ({
      allocation: String(getLocaleAllocation(share.allocation, 'de')),
      parent: share.parent as number,
      side_deal: share.sideDeal ? share.sideDeal === 'yes' : null,
    }));
  }

  return serializedCompany;
};
