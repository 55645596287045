import { useCallback, useEffect, EffectCallback, DependencyList, useRef } from 'react';
import _debounce from 'lodash.debounce';

export const useLazyEffect = (effect: EffectCallback, deps: DependencyList = [], wait = 300) => {
  const cleanUp = useRef<void | (() => void)>();
  const effectRef = useRef<EffectCallback>();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updatedEffect = useCallback(effect, deps);
  effectRef.current = updatedEffect;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const lazyEffect = useCallback(
    _debounce(
      () => {
        cleanUp.current = effectRef.current?.();
      },
      wait,
      { maxWait: 1000 },
    ),
    [],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(lazyEffect, deps);
  useEffect(
    () => () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      cleanUp.current instanceof Function ? cleanUp.current() : undefined;
    },
    [],
  );
};
