import React, { ReactNode, FC } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { CheckboxProps } from '@mui/material/Checkbox';

import { AuthProvider } from './auth-context';
import DataContextProvider from './data-context';
import CustomerDataProvider from './customer-context';
import EditorDataProvider from './editor-context';
import ErrorsDataProvider from './errors-context';
import BlockDataProvider from './block-context';
import ChangeLFCProvider from './changelf-context';
import ButtonStatusProvider from './iban-context';
import PersistentProvider from './persistent-context';
import DynamicTextsProvider from './dynamic-texts-context';

declare module '@mui/material' {
  interface CustomCheckboxProps {
    animate?: boolean;
  }
  export class Checkbox extends React.Component<CustomCheckboxProps & CheckboxProps> {}
}

const queryClient = new QueryClient();

type Provider = FC<{ children: ReactNode }>;

const combineProviders = (providers: Provider[]): Provider =>
  providers.reduce((Combined, Provider) => ({ children }) => (
    <Combined>
      <Provider>{children}</Provider>
    </Combined>
  ));

interface AppProvidersProps {
  children: ReactNode;
}

const AppProviders: FC<AppProvidersProps> = ({ children }) => {
  const Providers = combineProviders([
    AuthProvider,
    PersistentProvider as Provider,
    DataContextProvider,
    DynamicTextsProvider,
    CustomerDataProvider,
    EditorDataProvider,
    ErrorsDataProvider,
    BlockDataProvider,
    ChangeLFCProvider,
    ButtonStatusProvider,
  ]);

  return (
    <QueryClientProvider client={queryClient}>
      <Providers>{children}</Providers>
    </QueryClientProvider>
  );
};

export default AppProviders;
