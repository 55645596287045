import { FormikErrors } from 'formik';

import { FormHelperText } from '@mui/material';

import { FormValues } from 'containers/CustomerProfile/validationSchema';
import { Person, RefLegalRepresentative } from 'context/customer-context/types';
import { LegalFormsType } from 'utils/commonTypes/types';
import { getExceptionsLegalForms } from 'utils/utils';

export const showLAPError = ({
  errors,
  refLegalRepresentative,
  currentLegalForm,
  values,
}: {
  errors: FormikErrors<FormValues>;
  refLegalRepresentative: RefLegalRepresentative;
  currentLegalForm: LegalFormsType | null;
  values: FormValues;
}) => {
  const errorPerson =
    errors.people &&
    errors.people[refLegalRepresentative.index] &&
    (errors.people[refLegalRepresentative.index] as FormikErrors<Person>);
  return (
    currentLegalForm &&
    getExceptionsLegalForms(currentLegalForm.label) &&
    errorPerson &&
    errorPerson.isLoanApplicant && (
      <FormHelperText error>
        Aufgrund der ausgewählten Rechtsform {values.company.data.legalForm} muss die angegebene
        Person Darlehensantragssteller sein.
      </FormHelperText>
    )
  );
};
