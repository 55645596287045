import { createContext, useCallback, useContext, useState, ChangeEvent } from 'react';

import { initialState } from './constants';
import { ChangeLFCProviderProps, InitialStateType } from './types';

const ChangeLFC = createContext(initialState);

const ChangeLFCProvider = ({ children }: ChangeLFCProviderProps) => {
  const [wantedLF, setWantedLF] = useState('');
  const [manualSubmit, setManualSubmit] = useState(false);
  const [openChangeLF, setOpenChangeLF] = useState(false);
  const [handleAccept, setHandleAccept] = useState<
    | ((
        e: ChangeEvent<{
          name?: string | undefined;
          value: unknown;
        }>,
      ) => void)
    | (() => void)
  >(() => () => {});
  const [wantedLFEvent, setWantedLFEvent] = useState<ChangeEvent<{
    name?: string | undefined;
    value: unknown;
  }> | null>(null);

  const handleClose = useCallback(() => {
    setWantedLF('');
    setOpenChangeLF(false);
    setHandleAccept(() => {});
  }, []);

  const handleFinalAccept = useCallback(() => {
    if (wantedLFEvent && handleAccept) handleAccept(wantedLFEvent);
  }, [handleAccept, wantedLFEvent]);

  const value: InitialStateType = {
    wantedLF,
    openChangeLF,
    wantedLFEvent,
    manualSubmit,
    setManualSubmit,
    handleAccept,
    handleClose,
    setWantedLF,
    setOpenChangeLF,
    setHandleAccept,
    setWantedLFEvent,
    handleFinalAccept,
  };

  return <ChangeLFC.Provider value={value}>{children}</ChangeLFC.Provider>;
};

export const useChangeLFC = () => {
  const context = useContext(ChangeLFC);
  if (context === undefined) {
    throw new Error('useChangeLFC must be used within a ChangeLFCProvider');
  }
  return context;
};

export default ChangeLFCProvider;
