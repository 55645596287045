import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  closeButtonDialogChange: {
    position: 'absolute',
    right: 5,
    top: 5,
    padding: 0,
    cursor: 'pointer',
  },
  closeIconButtonDialogChange: {
    fontSize: 30,
  },
  infoIcon: {
    marginRight: 10,
    color: theme.palette.primary.main,
  },
  changesTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  contentDialogChanges: {
    color: '#4e4f52',
  },
  dialogActionsChanges: {
    justifyContent: 'center',
    paddingBottom: 20,
  },
  closeButton: {
    background: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      borderColor: theme.palette.error.dark,
      boxShadow: 'none',
    },
  },
  deleteLoadingContainer: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0, 0.2)',
  },
}));

export default useStyles;
