// TODO: Check if this logic should be on the backend
export const DEFAULT_TYPE = 'LAF';
export const LOAN_APPLICATION_REQUEST_FILE = 'LRF';
export const DEFAULT_CATEGORY = 'Other';
export const AT_LEAST_ONE_MANDATORY = 'AT_LEAST_ONE';
export const LEGAL_FORM_SLUG = 'rechtsform_unternehmens';
export const HGB_ACCORDANCE = 'hgb_bilanziert_grunddaten';
export const CAPTIQ_TIPSTER = 'captiq-default-tp-01';
export const CAPTIQ_POOL = 'captiq-default-pool';
export const CAPTIQ_TIPSTER_TOKEN = '00001';
export const B2C_ROLE = 'C';
export const NO_MAIN_COMPANY = 'noMainCompany';
