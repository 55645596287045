import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  textArea: {
    width: '100%',
    padding: '10px',
    minHeight: '30px',
    fontSize: '15px',
    fontFamily: theme.typography.fontFamily,
    border: '1px solid #CCC',
    borderRadius: '5px',
    marginBottom: '15px',
  },
}));

export default useStyles;
