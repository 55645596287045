import classNames from 'classnames';

import { Button } from '@mui/material';
import IconExternal from '@mui/icons-material/Launch';

import { NO_FILE_UPLOADED } from 'constants/messages';
import { IFileButtonProps } from './types';
import useStyles from './styles';

const FileButton = ({ field }: IFileButtonProps) => {
  const styles = useStyles();
  const filename = field.value
    ? field.value_display || (field.value as string).split('/').pop()
    : null;

  return field.value ? (
    <Button
      className={styles.buttonContent}
      target="_blank"
      color="primary"
      href={field.value as string}
      variant="outlined"
      size="small"
    >
      <IconExternal style={{ marginRight: 5 }} />
      Siehe Datei: {filename}
    </Button>
  ) : (
    <Button
      className={classNames(styles.buttonContent, styles.buttonContentColor)}
      variant="outlined"
      size="small"
      disabled
    >
      <IconExternal style={{ marginRight: 5 }} />
      {NO_FILE_UPLOADED}
    </Button>
  );
};

export default FileButton;
