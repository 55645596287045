import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  fieldContainer: {
    display: 'flex',
  },
  fieldContainerRichText: {
    flexDirection: 'column',
  },
}));

export default useStyles;
