export default () => ({
  root: {
    '&:hover': {
      color: 'black',
      background: 'yellow',
    },
    '&:focus': {
      color: 'black',
      background: 'yellow',
    },
    focused: {
      backgroundColor: 'yellow',
    },
  },
  container: {
    animation: 'slideUp .3s',
    animationTimingFunction: 'cubic-bezier(.01,.07,.21,1)',
  },
  stepHelpText: {
    marginBottom: '15px',
    fontSize: '14px',
  },
  formSelect: {
    marginBottom: '10px',
    backgroundColor: '#fff',
    '&:hover': {
      outline: 'none',
      borderColor: '#ff0000',
    },
  },
  titleText: {
    marginBottom: 10,
    fontSize: '17px',
    fontWeight: '500',
    color: '#000',
  },
  textAmount: {
    fontSize: '30px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  resultContainer: {
    backgroundColor: '#f9f9f9',
    padding: '25px',
    borderRadius: '10px',
  },
  slider: {
    padding: '30px 0',
  },
  adornment: {
    display: 'inline-flex',
  },
  textError: {
    borderRadius: '5px',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: '500',
    marginTop: '15px',
    backgroundImage: 'linear-gradient(#FFEECC,#FFE37F)',
    padding: '8px 10px',
    animation: 'slideUp .5s',
    textTransform: 'uppercase',
    animationTimingFunction: 'cubic-bezier(.01,.07,.21,1)',
    color: '#694D0A',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tooltip: {
    maxWidth: 'none',
    marginLeft: '17px !important',
    marginTop: '-5px !important',
    marginBottom: '-5px !important',
  },
  calculationBox: {
    textAlign: 'center',
    justifyContent: 'center',
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  divider: {
    margin: '24px 0',
  },
  caption: {
    fontSize: '0.77rem',
  },
  paragraph: {
    marginBottom: 5,
  },
  loanAmountLabel: {
    transform: 'translateY(-20px)',
    textWrap: 'wrap',
    lineHeight: '20px',
  },
  numberInput: {
    '& .MuiInputLabel-root': {
      overflow: 'visible',
    },
  },
  displayNone: {
    display: 'none',
  },
});
