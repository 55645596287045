export const QUESTION_HOCHLADEN_BUSINESSPLAN = 'q_hochladen_businessplan';
export const QUESTION_FB_BESTEHT_PRAXIS = 'q_fb_besteht_praxis';

export const QUESTION_SONSTINGE_DOKUMENTS = [
  'q_qualifikation_sonstige_dokumente',
  'q_qualifikation_sonstige_dokumente_s',
];

export const HOCHLADEN_BUSINESSPLAN = 'hochladen_businessplan';
export const FB_BESTEHT_PRAXIS = 'fb_besteht_praxis';
