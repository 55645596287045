import { useState } from 'react';

import { Button } from '@mui/material';

import { request } from 'service/ApiCaptiq';
import * as ApiCaptiq from 'service/ApiCaptiq';
import { IComment } from 'utils/commonTypes/types';
import { NO_PREDEFINED_COMMENTS_MSG } from 'constants/comments';
import PredefinedComments from '../PredefinedComments';
import { ISendCommentProps } from './types';
import useStyles from './styles';

const SendComment = ({ loanApplication, onCommentSaved, section }: ISendCommentProps) => {
  const [predefinedComments, setPredefinedComments] = useState([NO_PREDEFINED_COMMENTS_MSG]);
  const [openPredefinedComments, setOpenPredefinedComments] = useState(false);
  const [elementPredefined, setElementPredefined] = useState<HTMLElement | null>(null);
  const [newComment, setNewComment] = useState<IComment>({
    comment: '',
    type: 'IC',
    id: 0,
    title: '',
  });
  const styles = useStyles();

  const getComments = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenPredefinedComments(true);
    setElementPredefined(event.currentTarget);

    try {
      const response = await request.get(ApiCaptiq.PREDEFINED_COMMENTS_URL);
      if (response.data.length > 0) {
        setPredefinedComments(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const saveComment = async () => {
    if (!newComment || newComment.comment.length <= 0) return;
    try {
      const response = await request.post(`${ApiCaptiq.LOAN_APPLICATION_COMMENT_URL}`, {
        loan_application: loanApplication.id,
        path: section,
        comment: newComment.comment,
        type: newComment.type,
      });
      onCommentSaved(response.data);
      setNewComment({ comment: '', type: 'IC', id: 0, title: '' });
    } catch (e) {
      console.error(e);
    }
  };

  const chooseComment = (comment: IComment) => {
    setNewComment(comment);
    setOpenPredefinedComments(false);
  };

  const updateCommentComment = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const comment = event.target.value;
    setNewComment((prevValue) => ({ ...prevValue, comment }));
  };

  return (
    <div>
      <textarea
        className={styles.textArea}
        placeholder="Geben Sie Ihren Kommentar ein"
        onChange={updateCommentComment}
        value={newComment?.comment}
      />
      <Button size="small" color="primary" variant="contained" onClick={saveComment}>
        Kommentar hinterlassen
      </Button>
      <Button style={{ float: 'right' }} onClick={getComments}>
        Wähle einen Kommentar
      </Button>
      <PredefinedComments
        open={openPredefinedComments}
        element={elementPredefined}
        onClose={() => setOpenPredefinedComments(false)}
        onChoose={(comment) => chooseComment(comment)}
        comments={predefinedComments}
      />
    </div>
  );
};

export default SendComment;
