import React from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  IconButton,
  CircularProgress,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';

import { useDynamicTexts } from 'context/dynamic-texts-context';
import { IWarningDeleteOwnerProps } from './types';
import useStyles from './styles';

const WarningDeleteOwner = ({
  open,
  handleCloseDeleteOwnerModal,
  handleCloseModal,
  loadingDelete,
  isLegaRepresentative,
  isLoanApplicant,
}: IWarningDeleteOwnerProps) => {
  const styles = useStyles();
  const { contractSignatoryOrLoanApplicant } = useDynamicTexts();

  const getModalText = (body = false) => {
    if (isLegaRepresentative) {
      if (body) return 'gesetzlichen Vertreter';
      return 'Gesetzlichen Vertreter';
    }
    if (isLoanApplicant) {
      return contractSignatoryOrLoanApplicant;
    }
    return 'Inhaber';
  };

  return (
    <Dialog open={open} disableEscapeKeyDown>
      <IconButton
        className={styles.closeButtonDialogChange}
        component="span"
        onClick={handleCloseModal}
        size="large"
      >
        <CloseIcon className={styles.closeIconButtonDialogChange} />
      </IconButton>
      <DialogTitle id="alert-dialog-title">
        <div className={styles.changesTitle}>
          <InfoIcon className={styles.infoIcon} />
          <div>{getModalText()} löschen</div>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={styles.contentDialogChanges}>
          Möchten Sie diesen {getModalText(true)} löschen?
        </DialogContentText>
      </DialogContent>
      <DialogActions className={styles.dialogActionsChanges}>
        <Button color="primary" variant="contained" onClick={handleCloseDeleteOwnerModal}>
          LÖSCHEN
        </Button>
        <Button className={styles.closeButton} variant="contained" onClick={handleCloseModal}>
          NICHT LÖSCHEN
        </Button>
      </DialogActions>
      {loadingDelete && (
        <div className={styles.deleteLoadingContainer}>
          <CircularProgress />
        </div>
      )}
    </Dialog>
  );
};

export default WarningDeleteOwner;
