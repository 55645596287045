import React, { useMemo, useState } from 'react';
import resolvePath from 'object-resolve-path';
import { Field, FormikValues, useFormikContext } from 'formik';

import { IconButton, InputAdornment } from '@mui/material';
import { Edit } from '@mui/icons-material';

import { validationValueNumber } from 'utils/utils';
import PercentageField from 'components/PercentageField';
import useStyles from './styles';

interface Props {
  name: string;
  readOnly?: boolean;
  moreDecimals?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  moreInputProps?: any;
  showEditButton?: boolean;
  shouldBeLocallyDisabled?: boolean;
}

const DecimalField = ({
  name,
  readOnly,
  moreDecimals,
  onChange,
  onBlur,
  showEditButton,
  disabled = false,
  shouldBeLocallyDisabled = false,
  moreInputProps = {},
}: Props) => {
  const { values } = useFormikContext<FormikValues>();
  const [locallyDisabled, setLocallyDisabled] = useState(shouldBeLocallyDisabled);
  const value = useMemo(() => resolvePath(values, name), [values, name]);
  const styles = useStyles();

  const handleChangeLocallyDisabled = () => {
    setLocallyDisabled((prevState: boolean) => !prevState);
  };

  const inputProps = {
    readOnly,
    endAdornment: (
      <>
        {showEditButton && (
          <InputAdornment position="end" component="span">
            <IconButton
              classes={{ root: styles.pen }}
              disabled={readOnly}
              aria-label="delete"
              color="primary"
              onClick={handleChangeLocallyDisabled}
              size="large"
            >
              <Edit />
            </IconButton>
            %
          </InputAdornment>
        )}
      </>
    ),
    ...moreInputProps,
  };

  return (
    <Field
      className={
        readOnly ? styles.readOnlyField : validationValueNumber(value) && styles.completeField
      }
      component={PercentageField}
      name={name}
      variant="outlined"
      size="small"
      fullWidth
      InputProps={inputProps}
      onChange={onChange}
      onBlur={onBlur}
      moreDecimals={moreDecimals}
      disabled={disabled || locallyDisabled}
      data-testid="decimal-field"
    />
  );
};

export default DecimalField;
