import { YesOrNo } from 'utils/commonTypes/types';

export const returnYesNoForFormik = (value: boolean | null): YesOrNo => {
  switch (value) {
    case true:
      return 'yes';
    case false:
      return 'no';
    case null:
    default:
      return '';
  }
};
