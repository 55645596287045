import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  wrapperComments: {
    maxWidth: '500px',
    fontFamily: theme.typography.fontFamily,
  },
  textArea: {
    width: '100%',
    padding: '10px',
    minHeight: '30px',
    fontSize: '15px',
    fontFamily: theme.typography.fontFamily,
    border: '1px solid #CCC',
    borderRadius: '5px',
    marginBottom: '15px',
  },
  contentComment: {
    padding: 15,
  },
  predefinedComments: {
    maxWidth: '350px',
    overflow: 'auto',
    maxHeight: '400px',
    fontFamily: theme.typography.fontFamily,
  },
  listComments: {
    padding: '10px 15px 0 15px',
    backgroundColor: '#f5f5f5',
    maxHeight: 300,
    minHeight: 50,
    overflow: 'auto',
  },
  commentBlock: {
    marginBottom: '10px',
    borderRadius: 7,
    padding: '7px 10px',
    fontSize: '15px',
    border: '1px solid #e8dfb7',

    '& small': {
      display: 'block',
      textAlign: 'right',
      fontSize: '13px',
      color: '#999',
      textTransform: 'uppercase',
      marginTop: 5,
    },
  },
  commentBlockSmall: {
    fontSize: '14px',
    overflow: 'hidden',
    lineClamp: 2,
    display: '-webkit-box',
    boxOrient: 'vertical',
    maxHeight: '34px',
  },
  commentBlockPrivate: {
    backgroundColor: '#ffffd8',
  },
}));

export default useStyles;
