import React, { memo, Children, useCallback, useMemo } from 'react';
import { FastField, useFormikContext } from 'formik';
import _get from 'lodash.get';
import moment from 'moment';
import { differenceInYears } from 'date-fns';

import { RadioGroup, TextField, TextFieldProps as TextFieldPropsFormik } from 'formik-mui';
import { MenuItem, Radio, FormControlLabel, TextFieldProps, Box } from '@mui/material';

import { useDataContext } from 'context/data-context';
import { FastFieldProps, LegalFormsType, YesOrNo } from 'utils/commonTypes/types';
import { getSalutationLabel, Salutation } from 'constants/salutations';
import { Title } from 'constants/titles';
import { OccupationalGroup } from 'constants/occupationalGroups';
import { FamilyStatus } from 'constants/familyStatuses';
import { InitialValues } from 'context/customer-context/types';
import CopyButton from 'components/CopyButton';
import OnlyNumbers from 'components/OnlyNumbersField';
import LabeledGrid from 'components/LabeledGrid';
import PercentageField from 'components/PercentageField';
import InputRender from 'components/CaptiqField/InputRender';
import CountrySelector, { TypeOptions as PlacesTypeOptions } from 'components/CountrySelector';
import Adornment from 'components/Adornment';
import { shouldFastFieldUpdate } from 'utils/utils';
import useStyles from '../Owner/styles';

type TypeOptions = 'percent' | 'select' | 'text' | 'radiogroup' | 'date' | 'place' | 'onlyNumbers';

interface InputProps {
  name?: string;
  type?: TypeOptions;
  data?:
    | readonly (Salutation | Title | OccupationalGroup | FamilyStatus)[]
    | LegalFormsType[]
    | YesOrNo[]
    | [];
  getLabel?: (key: any) => string;
  children?: any;
  onMap?: (key: any) => any;
  label?: string;
  validate?: (key: any) => any;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  placetype?: PlacesTypeOptions;
  textFieldProps?: TextFieldProps;
  radioShowNo?: boolean;
  radioShowYes?: boolean;
  maxDate?: Date;
  customUpdateFunction?: (nextProps: FastFieldProps, currentProps: FastFieldProps) => boolean;
}

interface FieldProps extends InputProps {
  title: string;
  required?: boolean;
  tooltip?: string;
  customError?: React.ReactNode;
  field?: any;
}

interface CustomTextFieldProps extends TextFieldPropsFormik {
  select: boolean;
}

const CustomTextField = memo((props: CustomTextFieldProps) => {
  const classes = useStyles();

  const { touched, errors } = useFormikContext();

  const isTouched: boolean = useMemo(
    () => Boolean(_get(touched, props.field.name)),
    [props.field.name, touched],
  );
  const nameHasError: boolean = useMemo(
    () => Boolean(_get(errors, props.field.name)),
    [props.field.name, errors],
  );

  return (
    <TextField
      {...(props as TextFieldPropsFormik)}
      className={props.field.value ? classes.completeField : undefined}
      InputProps={{
        error: isTouched && nameHasError,
        endAdornment: props.field.value && !nameHasError && (
          <Adornment classes={classes} isSelect={props.select} />
        ),
      }}
    />
  );
});

export const Input = memo(
  ({
    type,
    name = '',
    data = [],
    getLabel,
    children,
    onMap,
    radioShowNo = true,
    radioShowYes = true,
    maxDate,
    customUpdateFunction = undefined,
    ...props
  }: InputProps) => {
    const { values, errors, setFieldValue } = useFormikContext<InitialValues>();
    const classes = useStyles();
    const defaultFunctionMap = useCallback(
      (key: any) => <MenuItem value={key}>{getLabel ? getLabel(key) : key}</MenuItem>,
      [getLabel],
    );
    const pickerValue = useMemo(() => _get(values, name), [values, name]);
    const pickerError = useMemo(() => _get(errors, name), [errors, name]);
    const pickerData = useMemo(() => ({ slug: name }), [name]);

    const handlerOnChange = useCallback(
      (value: any) => {
        setFieldValue(name, value);
      },
      [setFieldValue, name],
    );
    const handlerMap = onMap || defaultFunctionMap;
    let dateVal = pickerValue;
    if (pickerValue && type === 'date') {
      dateVal = moment(pickerValue).format('DD.MM.YYYY');
    }

    switch (type) {
      case 'percent':
        return (
          <FastField
            component={PercentageField}
            shouldUpdate={shouldFastFieldUpdate(customUpdateFunction)}
            name={name}
            variant="outlined"
            size="small"
            fullWidth
            InputProps={{
              endAdornment: pickerValue ? <Adornment /> : <Adornment type={type} />,
            }}
            {...props}
          />
        );
      case 'onlyNumbers':
        return (
          <FastField
            component={OnlyNumbers}
            name={name}
            variant="outlined"
            size="small"
            fullWidth
            shouldUpdate={shouldFastFieldUpdate(customUpdateFunction)}
            InputProps={{
              endAdornment: pickerValue && !pickerError && <Adornment />,
            }}
            {...props}
          />
        );
      case 'select':
        return (
          <FastField
            component={CustomTextField}
            fullWidth
            name={name}
            select
            size="small"
            variant="outlined"
            shouldUpdate={shouldFastFieldUpdate(customUpdateFunction)}
            {...props}
          >
            {Children.toArray(data.map(handlerMap))}
          </FastField>
        );
      case 'text':
        return (
          <FastField
            component={CustomTextField}
            fullWidth
            name={name}
            size="small"
            variant="outlined"
            shouldUpdate={shouldFastFieldUpdate(customUpdateFunction)}
            {...props}
          />
        );
      case 'radiogroup':
        return (
          <FastField
            component={RadioGroup}
            name={name}
            size="small"
            variant="outlined"
            row
            disabled={props.disabled}
            shouldUpdate={shouldFastFieldUpdate(customUpdateFunction)}
            {...props}
          >
            {radioShowYes && (
              <FormControlLabel
                value="yes"
                data-testid={`radio-button-yes-${name}`}
                control={<Radio color="primary" />}
                label="Ja"
                disabled={props.disabled}
              />
            )}
            {radioShowNo && (
              <FormControlLabel
                value="no"
                control={<Radio color="primary" />}
                label="Nein"
                disabled={props.disabled}
              />
            )}
          </FastField>
        );
      case 'place':
        return (
          <CountrySelector
            name={name}
            textFieldProps={{ ...props.textFieldProps }}
            type={props.placetype as PlacesTypeOptions}
            disabled={props.disabled}
            {...props}
          />
        );
      case 'date':
        return (
          <>
            {InputRender.getDatePicker(
              pickerData,
              dateVal,
              props.disabled,
              handlerOnChange,
              classes,
              maxDate,
            )}
            {dateVal && (
              <Box color="text.primary" fontSize="13px !important">
                Alter: {differenceInYears(new Date(), moment(dateVal, 'DD.MM.YYYY').toDate())} Jahre
              </Box>
            )}
          </>
        );
      default:
        return children;
    }
  },
);

Input.displayName = 'Input';

const CustomField = memo(
  ({ title, tooltip = undefined, required = false, customError = null, ...props }: FieldProps) => {
    const { loanApplication } = useDataContext();
    const loanStatus = loanApplication ? loanApplication.status : '';

    const { values } = useFormikContext<InitialValues>();
    let pickerValue: any = useMemo(() => _get(values, props.name || '', ''), [values, props?.name]);

    const showCopyButton = loanStatus === 'EDIT' || loanStatus === 'SUBMITTED';

    let extraComponent;
    if (
      showCopyButton &&
      pickerValue &&
      ((props.type !== 'select' && props.type !== 'radiogroup' && props.type !== 'place') ||
        props.name?.includes('salutation') ||
        props.name?.includes('countryOfBirth') ||
        props.name?.includes('personalAddressCountry') ||
        props.name?.includes('prefixMobilePhone'))
    ) {
      if (props.name?.includes('salutation')) {
        pickerValue = getSalutationLabel(pickerValue);
      }
      if (props.name?.includes('prefixMobilePhone')) {
        pickerValue = `${pickerValue.country} (+${pickerValue.prefix})`;
      }
      if (
        props.name?.includes('countryOfBirth') ||
        props.name?.includes('personalAddressCountry')
      ) {
        pickerValue = pickerValue.country;
      }
      extraComponent = <CopyButton value={pickerValue} type={props.type} />;
    }

    return (
      <LabeledGrid
        label={title}
        tooltip={tooltip}
        required={required}
        extraComponent={extraComponent}
      >
        <Input {...props} />
        {customError}
      </LabeledGrid>
    );
  },
);

CustomField.displayName = 'CustomField';

export default CustomField;
