import { RefBeneficialOwner } from 'context/customer-context/types';
import { Block } from 'utils/commonTypes/types';
import { Errors } from '.';

interface Props {
  errorsYUP: Errors[];
  block: Block;
  refsBeneficialOwners: RefBeneficialOwner[];
  isEqual: boolean;
}

export const getErrorsBO = ({ block, errorsYUP, refsBeneficialOwners, isEqual }: Props) => ({
  title: block.title,
  type: 'withInnerBlocks',
  section_path: block.section_path,
  blocks: errorsYUP.map((item) => ({
    title: `Level: ${item.level} - ${block.title}${
      refsBeneficialOwners.length !== 1 ? ` ${item.position + 1}` : ''
    }`,
    type: 'normalBlock',
    section_path: block.section_path,
    warnings: item.errors
      .filter((error) => (isEqual ? error.type === 'required' : error.type !== 'required'))
      .map((error) => ({
        label: isEqual ? error.label : `${error.label}: ${error.message}`,
        slug: `${error.label}bo${item.position}${item.level}`,
      })),
  })),
});
