import * as Yup from 'yup';

import { CompanyDocumentsLegalFormValues } from 'context/customer-context/types';

export const companyDocumentsLegalFormSchema = Yup.object().shape({
  commercialRegister: Yup.object().nullable().shape({
    code: Yup.string(),
    name: Yup.string(),
  }),
  commercialRegisterNumber: Yup.string(),
});

export const companyDocumentsLegalFormInitialValues: CompanyDocumentsLegalFormValues = {
  commercialRegister: null,
  commercialRegisterNumber: '',
};
