import { Company, Person, RefBeneficialOwner } from 'context/customer-context/types';

export const serializeBeneficialOwnersWithLevels = (
  companies: Company[],
  people: Person[],
  refsBeneficialOwners: RefBeneficialOwner[],
) => {
  const finalCompanies = [...companies];
  const finalPeople = [...people];
  refsBeneficialOwners.forEach((refLevelOne: RefBeneficialOwner) => {
    if (refLevelOne._type === 'person')
      finalPeople[refLevelOne.index].isBeneficialOwnerLevelOne = 'yes';
    else finalCompanies[refLevelOne.index].isBeneficialOwnerLevelOne = 'yes';
    const levelOneChildren = refLevelOne.children;
    if (levelOneChildren) {
      levelOneChildren.forEach((refLevelTwo: RefBeneficialOwner) => {
        if (refLevelTwo._type === 'person')
          finalPeople[refLevelTwo.index].isBeneficialOwnerLevelTwo = 'yes';
        else finalCompanies[refLevelTwo.index].isBeneficialOwnerLevelTwo = 'yes';

        const levelTwoChildren = refLevelTwo.children;
        if (levelTwoChildren) {
          levelTwoChildren.forEach((refLevelThree: RefBeneficialOwner) => {
            if (refLevelThree._type === 'person')
              finalPeople[refLevelThree.index].isBeneficialOwnerLevelThree = 'yes';
            else finalCompanies[refLevelThree.index].isBeneficialOwnerLevelThree = 'yes';
          });
        }
      });
    }
  });
  return { finalCompanies, finalPeople };
};
