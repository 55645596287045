import React, { createContext, useCallback, useContext, useState } from 'react';
import validator from 'validator';
import _get from 'lodash/get';

import { isValueValid } from 'utils/utils';
import { BlockStatus } from 'components/CaptiqTabs/components/ReviewStatusIcon/types';
import { LoanApplication, Question, Step } from 'utils/commonTypes/types';
import { isFatca } from 'utils/validateStaticBlocksValues/isFatca';
import { MIN_AGE } from 'constants/validations';
import { usePersistentData } from 'context/persistent-context';
import validatePhoneNumber from 'utils/dataHandlers/validatePhoneNumber';
import getCountryCode from 'utils/getCountryPrefix';

import {
  BalanceSchufaValuesType,
  BalanceSheetValuesType,
  Block,
  Documents,
  DocumentsBackend,
  InstanceField,
  RenderFieldBackend,
  RequestUserType,
  StepData,
  staticComponentsStatusType,
} from './types';
import { initialState } from './constants';

export const validateDates = [
  'FOUNDATION_DATE_STARTUP',
  'FOUNDATION_DATE_ONGOING',
  'FOUNDATION_DATE_TAKEOVER_PERSON',
  'FOUNDATION_DATE_PROCESS',
  'BIRTHDATE',
];

export const checkValidateDates = (
  value: string | null,
  fieldValidator: string | null | undefined,
) => {
  if (value === null) return false;
  switch (fieldValidator) {
    case 'FOUNDATION_DATE_STARTUP':
      if (value && new Date(value) <= new Date()) {
        return false;
      }
      return true;
    case 'FOUNDATION_DATE_ONGOING':
      if (value && new Date(value) >= new Date()) {
        return false;
      }
      return true;
    case 'FOUNDATION_DATE_TAKEOVER_PERSON':
      if (value && new Date(value) >= new Date()) return false;
      return true;
    case 'FOUNDATION_DATE_PROCESS': {
      const valueDate = new Date(value);
      const newDate = new Date();
      if (value && valueDate >= newDate) {
        return false;
      }
      newDate.setFullYear(new Date().getFullYear() - 2);
      if (value && valueDate <= newDate) {
        return false;
      }
      return true;
    }
    case 'BIRTHDATE': {
      const maxDateYear = new Date().getFullYear();
      const valueYear = new Date(value).getFullYear();
      if (value && maxDateYear - valueYear < MIN_AGE) return false;
      return true;
    }
    default:
      return true;
  }
};

export const DataContext = createContext(initialState);

const DataContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [blocks, setBlocks] = useState<Block>({});
  const [fbRequestUser, setFbRequestUserContext] = useState<RequestUserType>({
    Captiq: false,
    CP: false,
  });
  const [loanApplication, setLoanApplicationContext] = useState<LoanApplication | null>(null);
  const [stepDataState, setStepDataStateContext] = useState<Step[] | null>(null);
  const [stepDataStateReview, setStepDataStateReviewContext] = useState<StepData | null>(null);
  const [saveStepData, setSaveStepDataContext] = useState(null);
  const [loadingReview, setLoadingReview] = useState<boolean>(false);
  const [loadingStep, setLoadingStep] = useState<boolean>(false);
  const [customerDocuments, setCustomerDocuments] = useState<Documents[]>([]);
  const [brokerDocuments, setBrokerDocuments] = useState<Documents[]>([]);
  const [frontingbankDocuments, setFrontingbankDocuments] = useState<Documents[]>([]);
  const [balanceSheetValues, setBalanceSheetValues] = useState<BalanceSheetValuesType | undefined>(
    {},
  );
  const [staticComponentsStatus, setStaticComponentsStatus] = useState<
    staticComponentsStatusType[]
  >([]);
  const { countryList } = usePersistentData();
  const [loadingPage, setLoadingPage] = useState(false);
  const [modalDeclineAgreementContractSigningPage, setModalDeclineAgreementContractSigningPage] =
    useState<boolean>(false);
  const [modalDeclineLoanContractSigningPage, setModalDeclineLoanContractSigningPage] =
    useState<boolean>(false);
  const [isDeclining, setIsDeclining] = useState<boolean>(false);

  const updateStaticComponentsStatus = useCallback(
    (slug: string, newStatus: BlockStatus) => {
      const blockStatus = [...staticComponentsStatus];
      const itemIdx = blockStatus.findIndex((block) => block.path === slug);
      if (itemIdx !== -1) {
        blockStatus[itemIdx].status = newStatus;
      } else {
        blockStatus.push({
          path: slug,
          status: newStatus,
        });
      }
      setStaticComponentsStatus(blockStatus);
    },
    [staticComponentsStatus],
  );
  const [balanceSchufaValues, setBalanceSchufaValues] = useState<
    BalanceSchufaValuesType | undefined
  >({});
  const [loanApplicationUsage, setLoanApplicationUsage] = useState('');
  const [loanApplicationDuration, setLoanApplicationDuration] = useState(0);

  const setListFrontingbankDocuments = useCallback(
    (listFrontingbankDocuments: DocumentsBackend | RenderFieldBackend): void => {
      const arrDocuments = [...frontingbankDocuments];
      arrDocuments.push(listFrontingbankDocuments);
      setFrontingbankDocuments(arrDocuments);
    },
    [frontingbankDocuments],
  );

  const setListBrokerDocuments = useCallback(
    (listBrokerDocuments: DocumentsBackend | RenderFieldBackend): void => {
      const arrDocuments = [...brokerDocuments];
      arrDocuments.push(listBrokerDocuments);
      setBrokerDocuments(arrDocuments);
    },
    [brokerDocuments],
  );

  const setListCustomerDocuments = useCallback(
    (listCustomerDocuments: DocumentsBackend | RenderFieldBackend): void => {
      const arrDocuments = [...customerDocuments];
      arrDocuments.push(listCustomerDocuments);
      setCustomerDocuments(arrDocuments);
    },
    [customerDocuments],
  );

  const removeCustomerDocument = useCallback(
    (id: number): void => {
      const arrDocuments = [...customerDocuments].filter((document) => document.id !== id);
      setCustomerDocuments(arrDocuments);
    },
    [customerDocuments],
  );

  const removeBrokerDocument = useCallback(
    (id: number): void => {
      const arrDocuments = [...brokerDocuments].filter((document) => document.id !== id);
      setBrokerDocuments(arrDocuments);
    },
    [brokerDocuments],
  );

  const removeFrontingBankDocument = useCallback(
    (id: number): void => {
      const arrDocuments = [...frontingbankDocuments].filter((document) => document.id !== id);
      setFrontingbankDocuments(arrDocuments);
    },
    [frontingbankDocuments],
  );

  const setLoadingReviewPage = useCallback((loadingReviewPage: boolean): void => {
    setLoadingReview(loadingReviewPage);
  }, []);

  const setLoadingStepData = useCallback((loadingStepData: boolean): void => {
    setLoadingStep(loadingStepData);
  }, []);

  const setSaveStepData = useCallback((saveStepDataContext: any): void => {
    setSaveStepDataContext(saveStepDataContext);
  }, []);

  const setLoanApplication = useCallback((loanApplicationContext: LoanApplication): void => {
    setLoanApplicationContext(loanApplicationContext);
  }, []);

  const setFbRequestUser = useCallback((fbRequestUserContext: RequestUserType): void => {
    setFbRequestUserContext(fbRequestUserContext);
  }, []);

  const setStepDataState = useCallback((stepDataStateContext: Step[] | null): void => {
    setStepDataStateContext(stepDataStateContext);
  }, []);

  const setStepDataStateReview = useCallback(
    (stepDataStateReviewContext: StepData | null): void => {
      setStepDataStateReviewContext(stepDataStateReviewContext);
    },
    [],
  );

  const clearBlocks = useCallback((): void => {
    setBlocks({});
  }, []);

  const isBlockComplete = useCallback(
    (blockSlug: string): boolean => {
      const block = blocks[blockSlug];
      const questions = Object.values(block.questions);
      const requiredQuestions = questions.filter((q) => q.isRequired);
      const startUpDateQuestion = questions.findIndex(
        (q) => q.sectionPath === 'geplantes_unternehmen__grunddaten__q_geplante_gründung',
      );
      const completedStartUp =
        startUpDateQuestion === -1 ? true : questions[startUpDateQuestion].isCompleted;
      if (requiredQuestions.length) {
        return (
          requiredQuestions.every((q) => (Object.values(q.fields).length ? q.isCompleted : true)) &&
          completedStartUp
        );
      }
      return questions.every((q) => q.isCompleted) && completedStartUp;
    },
    [blocks],
  );

  const isQuestionComplete = useCallback(
    (blockSlug: string, questionSlug: string): boolean => {
      const block = blocks[blockSlug];
      const question = block.questions[questionSlug];
      const fields = Object.values(question.fields);

      if (
        blockSlug === 'geplantes_unternehmen__geplante_anschrift' &&
        questionSlug === 'q_geplante_adresszusatz'
      ) {
        return true;
      }

      if (isFatca(questionSlug) && fields.every((field) => field.value === 'True')) {
        return false;
      }

      if (question.mandatoryType === 'AT_LEAST_ONE') {
        if (fields.every((field) => field.type === 'BOOLEAN')) {
          return fields.some((field) => field.value);
        }
        return fields.some((field) => field.isCompleted);
      }
      if (question.mandatoryType === 'ALL') {
        return fields.every((field) => field.isCompleted);
      }
      for (const field of fields) {
        if (field.fieldValidator === 'FOUNDATION_DATE_STARTUP' && field.value !== null) {
          if (!checkValidateDates(field.value, field.fieldValidator)) {
            return false;
          }
        }
      }
      if (question.isRequired) {
        if (fields.every((field) => field.type === 'BOOLEAN')) {
          return fields.some((field) => field.value);
        }
        const existValidateDates = fields.filter((e) => {
          if (e.fieldValidator) {
            return validateDates.includes(e.fieldValidator);
          }
          return false;
        });
        if (existValidateDates.length > 0) {
          if (
            !existValidateDates.every((date) => checkValidateDates(date.value, date.fieldValidator))
          )
            return false;
        }
        for (const field of fields) {
          if (field.isRequired) {
            if (field.fieldValidator === 'EMAIL' && field.value && !validator.isEmail(field.value))
              return false;
            if (field.fieldValidator === 'PHONE') {
              const internationalPrefixCode: string = _get(
                blocks,
                `unternehmensdaten__kontakt.questions['q_internationales_prafix'].fields['internationales_prafix'].value`,
              ) as unknown as string;
              const internationalPrefix = getCountryCode(countryList, internationalPrefixCode);
              if (field.value && validatePhoneNumber(field.value, String(internationalPrefix))[0])
                return false;
            }
            if (!field.isCompleted) return false;
          }
        }
        return true;
      }
      let completed = true;
      for (const field of fields) {
        completed = completed && !!field.isCompleted;
      }
      return completed;
    },
    [blocks, countryList],
  );

  const registerBlock = useCallback(
    (name: string): void => {
      blocks[name] = {
        questions: {},
        isRequired: false,
        isCompleted: false,
      };
      setBlocks(blocks);
    },
    [blocks],
  );

  // TODO it is necessary to migrate CaptiqField to typescript to obtain the "field" parameter type
  const registerField = useCallback(
    (blockSlug: string, questionSlug: string, field: any): void => {
      const {
        slug: fieldSlug,
        value,
        is_required: isRequired,
        field_type,
        valueDisplay = null,
        field_validator,
      } = field;

      const block = blocks[blockSlug];
      if (block) {
        const question = block.questions[questionSlug];
        if (question) {
          question.fields[fieldSlug] = {
            value,
            isRequired,
            type: field_type,
            valueDisplay,
            isValid: isValueValid(value),
            isCompleted: field_validator === 'FOUNDATION_DATE_STARTUP' ? true : isValueValid(value),
            fieldValidator: field_validator,
          };
          if (!question.isRequired) {
            question.isRequired = isRequired;
          }
          if (!block.isRequired) {
            block.isRequired = isRequired;
          }
          question.isCompleted = isQuestionComplete(blockSlug, questionSlug);
          block.isCompleted = isBlockComplete(blockSlug);
          setBlocks(blocks);
        }
      }
    },
    [blocks, isBlockComplete, isQuestionComplete],
  );

  // TODO it is necessary to migrate CaptiqQuestions to typescript to obtain the "question" parameter type
  const registerQuestion = useCallback(
    (blockSlug: string, question: Question) => {
      const { slug, section_path, mandatory_type } = question;

      blocks[blockSlug].questions[slug] = {
        mandatoryType: mandatory_type,
        sectionPath: section_path,
        isCompleted: false,
        isRequired: false,
        fields: {},
      };
      setBlocks(blocks);
    },
    [blocks],
  );

  const removeField = useCallback(
    (blockSlug: string, questionSlug: string, field: string): void => {
      if (blocks[blockSlug]) {
        const question = blocks[blockSlug].questions[questionSlug];
        if (question) {
          delete question.fields[field];
          question.isCompleted = isQuestionComplete(blockSlug, questionSlug);
          blocks[blockSlug].isCompleted = isBlockComplete(blockSlug);
          setBlocks({ ...blocks });
        }
      }
    },
    [blocks, isBlockComplete, isQuestionComplete],
  );

  // TODO it is necessary to migrate CaptiqQuestions to typescript to obtain the "question" parameter type
  const updateQuestionFields = useCallback((question: any, fieldsList: any): any => {
    const updatedFields: any = {};

    fieldsList?.forEach((list: InstanceField[]) => {
      list.forEach((field) => {
        const blockField = updatedFields[field.slug];
        let isValid = false;
        if (blockField) {
          isValid = blockField.isValid;
        }
        updatedFields[field.slug] = {
          isValid,
          isRequired: field.is_required,
          value: field.value,
          valueDisplay:
            field.field_type === 'FILE' ? field.value_display || '' : field.visibility_value,
        };
      });
    });

    return updatedFields;
  }, []);

  const updateBlockAfterDeletion = useCallback(
    (blockSlug: string, questionSlug: string, fieldsList: any): void => {
      const block = blocks[blockSlug];
      const question = block?.questions[questionSlug];

      if (block && question) {
        const updatedFields = updateQuestionFields(question, fieldsList);
        const updatedQuestions = {
          ...block.questions,
          [questionSlug]: { ...question, fields: updatedFields },
        };
        const updatedBlocks = { ...blocks, [blockSlug]: { ...block, questions: updatedQuestions } };
        setBlocks(updatedBlocks);
      }
    },
    [blocks, updateQuestionFields],
  );

  // TODO it is necessary to migrate CaptiqBlock to typescript to obtain the "value, valueDisplay" parameter type
  const updateField = useCallback(
    (
      blockSlug: string,
      questionSlug: string,
      fieldSlug: string,
      value: any,
      valueDisplay: string | null = null,
      fieldValidator: string | null = null,
    ): void => {
      const block = blocks[blockSlug];
      const question = block.questions[questionSlug];
      const field = question.fields[fieldSlug];
      if (question) {
        if (field) {
          field.value = value;
          field.valueDisplay = valueDisplay;
          field.isValid = isValueValid(value);
          field.isCompleted =
            fieldValidator && fieldValidator === 'FOUNDATION_DATE_STARTUP'
              ? true
              : isValueValid(value);
          question.isCompleted = isQuestionComplete(blockSlug, questionSlug);
          block.isCompleted = isBlockComplete(blockSlug);
          if (questionSlug === 'q_internationales_prafix') {
            const questionPhone = block.questions.q_telefonnummer;
            questionPhone.isCompleted = isQuestionComplete(blockSlug, 'q_telefonnummer');
            block.isCompleted = isBlockComplete(blockSlug);
          }
          setBlocks({ ...blocks });
        }
      }
    },
    [blocks, isBlockComplete, isQuestionComplete],
  );

  return (
    <DataContext.Provider
      value={{
        blocks,
        registerBlock,
        registerQuestion,
        clearBlocks,
        updateBlockAfterDeletion,
        registerField,
        updateField,
        removeField,
        saveStepData,
        setSaveStepData,
        loadingReview,
        setLoadingReviewPage,
        loadingStep,
        setLoadingStepData,
        loanApplication,
        setLoanApplication,
        fbRequestUser,
        setFbRequestUser,
        stepDataState,
        setStepDataState,
        stepDataStateReview,
        setStepDataStateReview,
        customerDocuments,
        setListCustomerDocuments,
        brokerDocuments,
        setListBrokerDocuments,
        frontingbankDocuments,
        setListFrontingbankDocuments,
        removeCustomerDocument,
        removeBrokerDocument,
        removeFrontingBankDocument,
        balanceSheetValues,
        setBalanceSheetValues,
        staticComponentsStatus,
        setStaticComponentsStatus,
        updateStaticComponentsStatus,
        balanceSchufaValues,
        setBalanceSchufaValues,
        loanApplicationUsage,
        setLoanApplicationUsage,
        loanApplicationDuration,
        setLoanApplicationDuration,
        setBrokerDocuments,
        setCustomerDocuments,
        setFrontingbankDocuments,
        loadingPage,
        setLoadingPage,
        modalDeclineAgreementContractSigningPage,
        setModalDeclineAgreementContractSigningPage,
        modalDeclineLoanContractSigningPage,
        setModalDeclineLoanContractSigningPage,
        isDeclining,
        setIsDeclining,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => {
  const context = useContext(DataContext);
  if (context === undefined) {
    throw new Error('useDataContext must be used within a CountProvider');
  }
  return context;
};

export default DataContextProvider;
