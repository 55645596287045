import { ONGOING_TEMPLATE_TAG, PROCESS_COMPANY_TEMPLATE_TAG } from 'constants/templates';

export const isOngoingOrProcessCompanyTemplate = (loanUsage: string) =>
  ONGOING_TEMPLATE_TAG === loanUsage || PROCESS_COMPANY_TEMPLATE_TAG === loanUsage;

export const getContractSignatoryOrLoanApplicant = (loanUsage: string) => {
  const isOngoingOrProcessCompany = isOngoingOrProcessCompanyTemplate(loanUsage);
  return isOngoingOrProcessCompany ? 'Vertragsunterzeichner' : 'Darlehensantragsteller';
};

export const getCompanyOrApplicantCompany = (loanUsage: string) => {
  const isOngoingOrProcessCompany = isOngoingOrProcessCompanyTemplate(loanUsage);
  return isOngoingOrProcessCompany ? 'Antragstellendes Unternehmen' : 'Unternehmen';
};

export const getApplicantOrSignatory = (loanUsage: string) => {
  const isOngoingOrProcessCompany = isOngoingOrProcessCompanyTemplate(loanUsage);
  return isOngoingOrProcessCompany ? 'Unterzeichner/in' : 'Antragsteller/in';
};
