import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  stickyHeader: {
    position: 'sticky',
    top: 0,
    background: 'white',
    zIndex: 2,
  },
  title: {
    fontWeight: 500,
    color: '#000',
    textTransform: 'uppercase',
  },
  helpText: {
    flexGrow: 1,
  },
  blockStatus: {
    fontSize: '12px',
    textTransform: 'uppercase',
    fontWeight: 500,
    height: '22px',
  },
  statusSuccess: {
    backgroundColor: '#85DD83',
    color: '#114310',
  },
  statusDeclined: {
    backgroundColor: '#FFABAB',
    color: '#730000',
  },
  statusPending: {
    backgroundColor: '#E4A2FF',
    color: '#59083D',
  },
  statusNotReviewer: {
    backgroundColor: '#FFD571',
    color: '#363636',
  },
  statusEdit: {
    backgroundColor: '#FCAD6E',
    color: '#321A00',
  },
  blockCommentsIcon: {
    padding: '0 10px !important',
    margin: '0 30px 0 10px',
    borderRadius: 100,
    height: '21px',
    minHeight: '21px',
    minWidth: 0,
  },
  blockCommentsLabel: {
    height: 22,
  },
}));
