import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';

const checkIsNumber = (value: string | undefined | null, context: Yup.TestContext<AnyObject>) => {
  if (!context.schema.exclusiveTests.required && !value) return true;
  if (value && /^\d+$/.test(value)) return true;
  return false;
};

export default checkIsNumber;
