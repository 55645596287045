import { UseQueryResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { BOData } from 'containers/CustomerProfile/types';
import {
  LegalFormsTypes,
  LegalFormsType,
  CountryType,
  Block,
  LegalForm,
} from 'utils/commonTypes/types';
import {
  BalancingFormType,
  Company,
  MainCompany,
  Person,
  RefBeneficialOwner,
  RefLegalRepresentative,
  RefLoanApplicant,
  RefOwner,
} from './types';

export type DeleteOptionsType =
  | 'OWNER_PERSON'
  | 'OWNER_COMPANY'
  | 'LOAN_APPLICANT'
  | 'BENEFICIAL_OWNER_PERSON'
  | 'LEGAL_REPRESENTATIVE';

export const BUSINESS_STATUS = ['INITIAL', 'IN_PLANNING', 'EXISTING'];
export const INITIAL = 'INITIAL';
export const IN_PLANNING = 'IN_PLANNING';
export const EXISTING = 'EXISTING';
export const BASE_TEMPLATE_FOUNDATION_AVAILABLE =
  'Gründung (Unternehmen gegründet, kein JA vorhanden)';

export const BASE_TEMPLATE_TAKEOVER_PERSON_TEMPLATE_TAG =
  'Übernahme (Person übernimmt Anteile von Unternehmen)';

export type DeleteDataType = Person | Company | MainCompany | null;
export type BusinessStatus = (typeof BUSINESS_STATUS)[number];

type CurrentDeleteModalType = {
  type: DeleteOptionsType | null;
  deleteData: DeleteDataType;
  afterDelete?: () => void;
  parentID?: number;
};

export type CustomerProfileIdType = string | number | null;

export type IProfileVersion = 'VERSION_1' | 'VERSION_2' | 'VERSION_3';

export interface CustomerProfile {
  id: number;
  customerId: string;
  jurisdiction: number | '';
  customerProfileStatus?: boolean;
  customerProfileType: string;
  profileVersion: IProfileVersion;
}

type FetchCustomerProfileType = (
  customerID: CustomerProfileIdType,
  refresh?: boolean,
) => Promise<void>;

export type InitialStateType = {
  companies: Company[];
  countryList: CountryType[];
  currentDeleteModal: CurrentDeleteModalType | null;
  boData: BOData | null;
  boDeleteModalStatus: boolean;
  deleteData: Company | null;
  customerProfileId: CustomerProfileIdType;
  customerProfileData: CustomerProfile | null;
  balancingForms: BalancingFormType;
  legalForms: LegalFormsTypes | null;
  currentLegalForm: LegalFormsType | null;
  mainCompany: MainCompany | null;
  people: Person[];
  refsBeneficialOwners: RefBeneficialOwner[];
  refsLegalRepresentatives: RefLegalRepresentative[];
  refsLoanApplicants: RefLoanApplicant[];
  refsOwners: RefOwner[];
  legalForm: LegalForm | null;
  hgbAccordance: boolean;
  setHgbAccordance: (hgbAccordance: boolean) => void;
  setLegalForm: (legalForm: LegalForm) => void;
  setCurrentLegalForm: (legalForm: LegalFormsType) => void;
  fetchCustomerProfile: FetchCustomerProfileType;
  bulkUpdateStatus: boolean;
  sonstigeDisplay: boolean;
  hiddenFields: any;
  setCompanies: any;
  setCurrentDeleteModal: any;
  setBODeleteModalStatus: any;
  setPeople: any;
  setRefsBeneficialOwners: any;
  setRefsLegalRepresentatives: any;
  setRefsLoanApplicants: any;
  setRefsOwners: any;
  firstDataLoad: boolean;
  initialHasBeenCalled: () => void;
  setBulkUpdateStatus: any;
  setSonstigeDisplay: any;
  initialValues: InitialValues | {};
  validationSchema: any;
  completedStaticFieldsOW: boolean[];
  completedStaticFieldsBO: boolean[];
  completedStaticFieldsLR: boolean[];
  completedStaticFieldsLA: boolean[];
  missingErrorOW: Block | {};
  missingErrorLR: Block | {};
  missingErrorBO: Block | {};
  missingErrorLA: Block | {};
  wrongErrorLR: Block | {};
  wrongErrorLA: Block | {};
  wrongErrorBO: Block | {};
  setCompleteStatus: any;
  setInitialValues: any;
  setValidationSchema: any;
  setValidationSchemaOwner: any;
  setValidationSchemaBeneficialOwner: any;
  setValidationSchemaLegalRepresentative: any;
  setValidationSchemaLoanApplicant: any;
  setMissingErrorOW: React.Dispatch<React.SetStateAction<{} | Block>>;
  setMissingErrorLR: React.Dispatch<React.SetStateAction<{} | Block>>;
  setMissingErrorBO: React.Dispatch<React.SetStateAction<{} | Block>>;
  setMissingErrorLA: React.Dispatch<React.SetStateAction<{} | Block>>;
  setWrongErrorLR: React.Dispatch<React.SetStateAction<{} | Block>>;
  setWrongErrorLA: React.Dispatch<React.SetStateAction<{} | Block>>;
  setWrongErrorBO: React.Dispatch<React.SetStateAction<{} | Block>>;
  setInitialValuesMain: React.Dispatch<React.SetStateAction<{} | Block>>;
  hiddenFieldsQuery: UseQueryResult<AxiosResponse<any, any>, unknown> | null;
  runValidations?: () => Promise<void>;
};

export const initialState: InitialStateType = {
  boData: null,
  boDeleteModalStatus: false,
  companies: [],
  countryList: [],
  currentDeleteModal: null,
  currentLegalForm: null,
  customerProfileId: null,
  customerProfileData: null,
  deleteData: null,
  balancingForms: {},
  legalForms: null,
  mainCompany: null,
  people: [],
  refsBeneficialOwners: [],
  refsLegalRepresentatives: [],
  refsLoanApplicants: [],
  refsOwners: [],
  firstDataLoad: false,
  bulkUpdateStatus: false,
  sonstigeDisplay: true,
  hiddenFields: null,
  legalForm: null,
  hgbAccordance: false,
  setHgbAccordance: () => {},
  setLegalForm: () => {},
  setCurrentLegalForm: () => {},
  fetchCustomerProfile: async () => {},
  setCompanies: () => {},
  setCurrentDeleteModal: () => {},
  setBODeleteModalStatus: () => {},
  setPeople: () => {},
  setRefsBeneficialOwners: () => {},
  setRefsLegalRepresentatives: () => {},
  setRefsLoanApplicants: () => {},
  setRefsOwners: () => {},
  initialHasBeenCalled: () => {},
  setBulkUpdateStatus: () => {},
  setSonstigeDisplay: () => {},
  initialValues: {},
  validationSchema: null,
  completedStaticFieldsOW: [],
  completedStaticFieldsBO: [],
  completedStaticFieldsLR: [],
  completedStaticFieldsLA: [],
  missingErrorOW: {},
  missingErrorLR: {},
  missingErrorBO: {},
  missingErrorLA: {},
  wrongErrorLR: {},
  wrongErrorLA: {},
  wrongErrorBO: {},
  setCompleteStatus: () => {},
  setInitialValues: () => {},
  setValidationSchema: () => {},
  setValidationSchemaOwner: () => {},
  setValidationSchemaBeneficialOwner: () => {},
  setValidationSchemaLegalRepresentative: () => {},
  setValidationSchemaLoanApplicant: () => {},
  setMissingErrorOW: () => {},
  setMissingErrorLR: () => {},
  setMissingErrorBO: () => {},
  setMissingErrorLA: () => {},
  setWrongErrorLR: () => {},
  setWrongErrorLA: () => {},
  setWrongErrorBO: () => {},
  setInitialValuesMain: () => {},
  hiddenFieldsQuery: null,
};

export interface InitialValues {
  mainCompany: MainCompany | null;
  company: MainCompany | null;
  people: Person[];
  companies: Company[];
  currentLegalForm: LegalFormsType | null;
  refsOwners: RefOwner[];
  refsBeneficialOwners: RefBeneficialOwner[];
  refsLegalRepresentatives: RefLegalRepresentative[];
  refsLoanApplicants: RefLoanApplicant[];
  customerProfileId: CustomerProfileIdType;
  customerProfileData: CustomerProfile | null;
  loanApplicationUsage: string;
  loanApplicationDuration: number;
}

export const initialValues: InitialValues = {
  mainCompany: null,
  company: null,
  people: [],
  companies: [],
  currentLegalForm: null,
  refsOwners: [],
  refsBeneficialOwners: [],
  refsLegalRepresentatives: [],
  refsLoanApplicants: [],
  customerProfileId: null,
  customerProfileData: null,
  loanApplicationUsage: '',
  loanApplicationDuration: 0,
};

export interface UpdateInitialValues {
  mainCompany?: MainCompany | null;
  people?: Person[];
  companies?: Company[];
  currentLegalForm?: LegalFormsType;
  refsOwners?: RefOwner[];
  refsBeneficialOwners?: RefBeneficialOwner[];
  refsLegalRepresentatives?: RefLegalRepresentative[];
  refsLoanApplicants?: RefLoanApplicant[];
  customerProfileId?: CustomerProfileIdType;
  customerProfileData?: CustomerProfile;
}

export const SET_COMPANIES = 'SET_COMPANIES';
export const SET_PEOPLE = 'SET_PEOPLE';
export const SET_MAIN_COMPANY = 'SET_MAIN_COMPANY';
export const SET_CURRENT_LEGAL_FORM = 'SET_CURRENT_LEGAL_FORM';
export const SET_REFS_OWNERS = 'SET_REFS_OWNERS';
export const SET_REFS_BENEFICIAL_OWNER = 'SET_REFS_BENEFICIAL_OWNER';
export const SET_REFS_LEGAL_REPRESENTATIVES = 'SET_REFS_LEGAL_REPRESENTATIVES';
export const SET_REFS_LOAN_APPLICANTS = 'SET_REFS_LOAN_APPLICANTS';
export const SET_CUSTOMER_PROFILE_ID = 'SET_CUSTOMER_PROFILE_ID';
export const SET_CUSTOMER_PROFILE_DATA = 'SET_CUSTOMER_PROFILE_DATA';
export const SET_STATE = 'SET_STATE';

export type Action =
  | SetCompanies
  | SetPeople
  | SetMainCompany
  | SetCurrentLegalForm
  | SetRefsOwners
  | SetRefsBeneficialOwner
  | SetRefsLegalRepresentatives
  | SetRefsLoanApplicants
  | SetCustomerProfileId
  | SetCustomerProfileData
  | SetState;

interface SetMainCompany {
  type: typeof SET_MAIN_COMPANY;
  mainCompany: MainCompany;
}

interface SetCompanies {
  type: typeof SET_COMPANIES;
  companies: Company[];
}

interface SetPeople {
  type: typeof SET_PEOPLE;
  people: Person[];
}

interface SetCurrentLegalForm {
  type: typeof SET_CURRENT_LEGAL_FORM;
  currentLegalForm: LegalFormsType;
}

interface SetRefsOwners {
  type: typeof SET_REFS_OWNERS;
  refsOwners: RefOwner[];
}

interface SetRefsBeneficialOwner {
  type: typeof SET_REFS_BENEFICIAL_OWNER;
  refsBeneficialOwners: RefBeneficialOwner[];
}

interface SetRefsLegalRepresentatives {
  type: typeof SET_REFS_LEGAL_REPRESENTATIVES;
  refsLegalRepresentatives: RefLegalRepresentative[];
}

interface SetRefsLoanApplicants {
  type: typeof SET_REFS_LOAN_APPLICANTS;
  refsLoanApplicants: RefLoanApplicant[];
}

interface SetCustomerProfileId {
  type: typeof SET_CUSTOMER_PROFILE_ID;
  customerProfileId: CustomerProfileIdType;
}

interface SetCustomerProfileData {
  type: typeof SET_CUSTOMER_PROFILE_DATA;
  customerProfileData: CustomerProfile;
}

interface SetState {
  type: typeof SET_STATE;
  state: UpdateInitialValues;
}
