import { useEffect, useCallback } from 'react';

import { Typography } from '@mui/material';
import Popover from '@mui/material/Popover';

import { IComment } from 'utils/commonTypes/types';
import useStyles from './styles';
import SendComment from './components/SendComment';

interface ICaptiqCommentsProps {
  element: HTMLElement;
  open: boolean;
  comments: Array<any>;
  loanApplication: any;
  sectionPath: string;
  onCommentSaved: (comment: IComment) => void;
  hideInputComment: boolean;
  onClose: () => void;
}

const CaptiqComments = ({
  element,
  open,
  comments,
  loanApplication,
  sectionPath,
  onCommentSaved,
  hideInputComment,
  onClose,
}: ICaptiqCommentsProps) => {
  const classes = useStyles();
  let listCommentsRef: HTMLDivElement | null = null;

  const setListElement = (el: HTMLDivElement) => {
    listCommentsRef = el;
    scrollToBottom();
  };

  const scrollToBottom = useCallback(() => {
    if (listCommentsRef) {
      listCommentsRef.scrollTop = listCommentsRef.scrollHeight;
    }
  }, [listCommentsRef]);

  useEffect(() => {
    scrollToBottom();
  }, [comments, scrollToBottom]);

  return (
    <Popover
      id="pop"
      open={open}
      anchorEl={element}
      onClose={onClose}
      anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
      transformOrigin={{ vertical: 'center', horizontal: 'right' }}
    >
      <div className={classes.wrapperComments}>
        <div className={classes.contentComment}>
          <Typography>Interner Kommentar</Typography>
        </div>
        <div className={classes.listComments} ref={setListElement}>
          {comments.map((comment, index) => (
            <div
              key={index}
              className={[
                classes.commentBlock,
                comment.type === 'IC' ? classes.commentBlockPrivate : '',
              ].join(' ')}
            >
              {comment.comment}
              <small>
                {comment.created} by {comment.author}
              </small>
            </div>
          ))}
          {comments.length === 0 && (
            <div className={classes.commentBlock}>No comments for this block.</div>
          )}
        </div>

        {hideInputComment ? null : (
          <div className={classes.contentComment}>
            <SendComment
              loanApplication={loanApplication}
              section={sectionPath}
              onCommentSaved={onCommentSaved}
            />
          </div>
        )}
      </div>
    </Popover>
  );
};

export default CaptiqComments;
