import React, { Component } from 'react';

import CaptiqComments from '../CaptiqComments';
import { request } from '../../service/ApiCaptiq';
import * as ApiCaptiq from '../../service/ApiCaptiq';
import { DataContext } from '../../context/data-context';
import { getFilteredComments } from '../BaseOverview/utils';
import {
  CaptiqReviewBlockPropTypes,
  getBlockStatus,
  staticBaseComponents,
  updateExtraData,
  handleError,
  saveData,
  saveFinancingDocuments,
} from './utils';
import withWrapper from './wrapper';
import RenderFinancingDocuments from './components/RenderFinancingDocuments';
import Block from './components/Block';

class CaptiqReviewBlock extends Component {
  state = {
    form: {},
    disabled: true,
    commentsEl: null,
    openComments: false,
    comments: [],
    extraData: {},
    blockKey: 1,
    waitingAction: '',
    blockData: null,
    personIndexTab: 0,
    extraValidationInfo: null,
  };

  constructor(props) {
    super(props);

    this.firstStepRef = React.createRef();
    this.financingDocumentsRef = React.createRef();
    this.actionsRef = React.createRef();
  }

  async UNSAFE_componentWillMount() {
    const { scaffold } = this.props;
    const { blocksPaths, blocksStatus } = getBlockStatus(scaffold, this.context.registerBlock);
    this.setState({
      form: blocksPaths,
      extraData: blocksStatus,
      blockData: scaffold,
      personIndexTab: scaffold.personIndex,
    });
  }

  updateBlockStatus = () => {
    if (this.props.scaffold.based_component) {
      const { blocksPaths, blocksStatus } = getBlockStatus(
        this.props.scaffold,
        this.context.registerBlock,
      );
      this.setState({
        form: blocksPaths,
        extraData: blocksStatus,
        blockData: this.props.scaffold,
      });
    }
  };

  componentDidUpdate = async (prevProps) => {
    const hasPersonIndexChanged =
      prevProps.scaffold.personIndex !== this.props.scaffold.personIndex;
    const hasStatusChanged = prevProps.scaffold.status !== this.props.scaffold.status;
    const hasInstanceStatusChanged =
      prevProps.scaffold.instances[0]?.status !== this.props.scaffold.instances[0]?.status;
    const hasNewCommentsLengthChanged =
      prevProps.newComments.length !== this.props.newComments.length;
    if (hasPersonIndexChanged) {
      this.setState({
        personIndexTab: this.props.scaffold.personIndex,
      });
      this.updateBlockStatus();
      await this.loadComments();
    }
    if (hasStatusChanged) {
      this.updateBlockStatus();
    }
    if (hasInstanceStatusChanged) {
      if (this.props.scaffold.based_component) {
        this.updateBlockStatus();
      } else {
        const { blocksStatus } = getBlockStatus(this.props.scaffold, () => {});
        this.setState({
          extraData: blocksStatus,
        });
      }
    }
    if (hasNewCommentsLengthChanged) {
      await this.loadComments();
    }
  };

  componentDidMount = async () => {
    const { loanApplication } = this.props;
    this.setState({ loanApplication });
    await this.loadComments();
  };

  setActivePanel = (index) => (event, expanded) => {
    this.props.setActivePanel(index, expanded);
    const item = event.currentTarget;
    setTimeout(() => {
      if (typeof item.scrollIntoView === 'function') item.scrollIntoView();
    }, 600);
  };

  addCommentToState = (comment) => {
    this.state.blockData.addComments?.(1);
    this.setState({ comments: [...this.state.comments, comment] });
  };

  cancelEdit = () => {
    const { blockData } = this.state;
    const { resetForm } = this.props;
    if (blockData.based_component) resetForm();
    this.setState({ disabled: true, blockKey: this.state.blockKey + 1 });
  };

  updateStateAndCallOnSave = (response, status, customExtraData, path) => {
    const updatedExtraData = updateExtraData({
      extraData: this.state.extraData,
      response,
      customExtraData,
      path,
      updateStaticComponentsStatus: this.context.updateStaticComponentsStatus,
    });
    this.setState({ extraData: updatedExtraData });
    if (this.props.onSave) this.props.onSave(response.data.loan_application_status, status);
  };

  changeStatus = (path, status, customExtraData) => {
    const { setLoadingReviewPage } = this.context;
    const { loanApplication, blockData } = this.state;
    const loanId = loanApplication.id;

    setLoadingReviewPage(true);
    this.setState({ waitingAction: status });

    request
      .post(`${ApiCaptiq.LOAN_APPLICATION_URL}${loanId}/${path}/change_block_status/`, {
        status,
        is_component: Boolean(blockData.based_component),
      })
      .then((response) => this.updateStateAndCallOnSave(response, status, customExtraData, path))
      .catch(
        handleError({
          showErrorMessage: this.props.showErrorMessage,
          setLoadingReviewPage,
        }),
      );
  };

  closeComments = () => {
    this.setState({ openComments: false });
  };

  deleteField = (blockSlug, field) => {
    const { form } = this.state;
    delete form[blockSlug][field];
    this.setState({ form });
    if (this.props.deleteField) this.props.deleteField(field);
  };

  updateStateOfBankData = (blockSlug, form) => {
    const { blockData } = this.state;
    blockData.instances.forEach((instance) =>
      instance.questions.forEach((question) =>
        question.instances.forEach((inst) =>
          inst.fields.forEach((field) => {
            if (form[blockSlug][field.slug] || form[blockSlug][field.slug] === '')
              field.value = form[blockSlug][field.slug];
          }),
        ),
      ),
    );
    this.setState({ blockData: { ...blockData } }, () => this.forceUpdate());
  };

  handleChange = (
    blockSlug,
    _questionSlug,
    name,
    value,
    valueDisplay = null,
    fieldValidator = null,
  ) => {
    this.setState(
      (prevState) => {
        const updatedForm = {
          ...prevState.form,
          [blockSlug]: {
            ...prevState.form[blockSlug],
            [name]: value,
          },
        };
        return { form: updatedForm };
      },
      () => {
        this.context.updateField(
          blockSlug,
          _questionSlug,
          name,
          value,
          valueDisplay,
          fieldValidator,
        );

        if (
          fieldValidator === 'IBAN' ||
          fieldValidator === 'BIC' ||
          fieldValidator === 'BANK_NAME'
        ) {
          this.updateStateOfBankData(blockSlug, this.state.form);
        }
      },
    );

    if (this.props.onChange) {
      this.props.onChange(name, value);
    }
  };

  loadComments = async () => {
    try {
      const { sectionPath, newComments } = this.props;
      this.state.blockData.addComments?.(0);
      this.setState({ comments: getFilteredComments(newComments, sectionPath) });
    } catch (error) {
      console.error(error);
    }
  };

  openComments = (event) => {
    this.setState({
      commentsEl: event.currentTarget,
      openComments: true,
    });
    event.stopPropagation();
  };

  updateStateAndStatus = (block_name, status, extraData) => {
    this.changeStatus(block_name, status, extraData);
    this.setState({ disabled: true });
  };

  handleUnternehmensdaten = (block_name) => {
    if (
      block_name === 'unternehmensdaten__grunddaten_ongoing' &&
      this.props.changeLFContext.wantedLF !== ''
    ) {
      this.props.changeLFContext.setManualSubmit(true);
    }
  };

  startEdition = () => {
    this.setState({ disabled: false });
  };

  renderExtraValidationInfo = (extraValidationInfo) => this.setState({ extraValidationInfo });

  renderBlock = (value, index) => {
    const {
      expanded,
      hiddenFields,
      upperFormState,
      loanId,
      loanApplication,
      customColors,
      isValid,
      stepOrder,
      step_id,
      personID,
      metaData,
      currentTab,
      componentProps,
    } = this.props;

    const {
      comments,
      extraData,
      blockData,
      blockKey,
      waitingAction,
      extraValidationInfo,
      form,
      disabled,
      personIndexTab,
    } = this.state;

    const blockProps = {
      value,
      index,
      expanded,
      hiddenFields,
      upperFormState,
      loanId,
      loanApplication,
      customColors,
      isValid,
      stepOrder,
      step_id,
      personID,
      metaData,
      currentTab,
      componentProps,
      comments,
      extraData,
      blockData,
      blockKey,
      waitingAction,
      extraValidationInfo,
      form,
      disabled,
      personIndexTab,
    };

    const blockHandlers = {
      setActivePanel: this.setActivePanel,
      openComments: this.openComments,
      startEdition: this.startEdition,
      cancelEdit: this.cancelEdit,
      saveData: saveData({
        state: this.state,
        firstStepRef: this.firstStepRef,
        updateStateAndStatus: this.updateStateAndStatus,
        handleUnternehmensdaten: this.handleUnternehmensdaten,
        submitForm: this.props.submitForm,
      }),
      changeStatus: this.changeStatus,
      renderExtraValidationInfo: this.renderExtraValidationInfo,
      handleChange: this.handleChange,
      deleteField: this.deleteField,
    };

    return (
      <Block
        key={index}
        {...blockProps}
        {...blockHandlers}
        firstStepRef={this.firstStepRef}
        actionsRef={this.actionsRef}
      />
    );
  };

  render() {
    const {
      expanded,
      upperFormState,
      loanId,
      loanApplication,
      sectionPath,
      customColors,
      isValid,
    } = this.props;
    const { commentsEl, openComments, comments, extraData, blockData, waitingAction } = this.state;
    const isStaticComponent = staticBaseComponents.includes(blockData.based_component);

    return (
      <div style={{ marginBottom: isStaticComponent ? 0 : 10 }}>
        <CaptiqComments
          element={commentsEl}
          open={openComments}
          onClose={this.closeComments}
          loanApplication={loanApplication}
          sectionPath={sectionPath}
          comments={comments}
          onCommentSaved={this.addCommentToState}
        />
        <RenderFinancingDocuments
          blockData={blockData}
          extraData={extraData}
          comments={comments}
          waitingAction={waitingAction}
          upperFormState={upperFormState}
          expanded={expanded}
          loanId={loanId}
          customColors={customColors}
          isValid={isValid}
          setActivePanel={this.setActivePanel}
          openComments={this.openComments}
          startEdition={this.startEdition}
          cancelEdit={this.cancelEdit}
          saveFinancingDocuments={saveFinancingDocuments({
            state: this.state,
            financingDocumentsRef: this.financingDocumentsRef,
            updateStateAndStatus: this.updateStateAndStatus,
          })}
          changeStatus={this.changeStatus}
          financingDocumentsRef={this.financingDocumentsRef}
        />
        {blockData && blockData.instances.map((value, index) => this.renderBlock(value, index))}
      </div>
    );
  }
}

CaptiqReviewBlock.propTypes = CaptiqReviewBlockPropTypes;

CaptiqReviewBlock.defaultProps = {
  submitForm: () => {},
};

CaptiqReviewBlock.contextType = DataContext;

export default withWrapper(CaptiqReviewBlock);
