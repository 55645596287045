export function getHGBDocumentsSlugs(index: number, hgb: boolean, legalForm: string) {
  const hgbValue = hgb ? 'true' : 'false';
  const newBlock: any = {};
  // main dropdown
  newBlock[`${index}__dropdown_by_file_${hgbValue}_${legalForm.toLowerCase()}`] = '';

  // case 1
  if (hgb) newBlock[`${index}__letzte_bilanz_hgb_${legalForm.toLowerCase()}_4278_0`] = '';
  else if (legalForm.toLowerCase() === 'partg')
    newBlock[`${index}__letzte_bilanz_hgb_f_${legalForm.toLowerCase()}_4278_0`] = '';
  else newBlock[`${index}__letzte_gew_ein_hgb_${legalForm.toLowerCase()}_4278_0`] = '';

  // // calculos dropdown
  newBlock[`${index}__dropdown_balancing_${legalForm}_1_3983_4278_0`] = '';

  // // calculos fields
  newBlock[`${index}__1__dropdown_balancing_${legalForm}_1_3983_fields_A`] = '';
  newBlock[`${index}__1__dropdown_balancing_${legalForm}_1_3983_fields_B`] = '';
  newBlock[`${index}__1__dropdown_balancing_${legalForm}_1_3983_fields_C`] = '';
  newBlock[`${index}__1__dropdown_balancing_${legalForm}_1_3983_fields_D`] = '';

  // case 2
  newBlock[`${index}__aktuelle_bwa_hgb_${hgbValue[0]}_${legalForm.toLowerCase()}_4278_0`] = '';
  newBlock[`${index}__aktuelle_bwa_hgb_${hgbValue[0]}_${legalForm.toLowerCase()}_4278_1`] = '';

  // case 3
  newBlock[`${index}__dazugehorige_summen_hgb_${hgbValue[0]}_${legalForm.toLowerCase()}_4278_0`] =
    '';
  newBlock[`${index}__dazugehorige_summen_hgb_${hgbValue[0]}_${legalForm.toLowerCase()}_4278_1`] =
    '';

  // // calculos fields extra
  newBlock[`${index}__2__dropdown_balancing_${legalForm}_1_3983_fields_A`] = '';
  newBlock[`${index}__2__dropdown_balancing_${legalForm}_1_3983_fields_B`] = '';
  newBlock[`${index}__2__dropdown_balancing_${legalForm}_1_3983_fields_C`] = '';
  newBlock[`${index}__2__dropdown_balancing_${legalForm}_1_3983_fields_D`] = '';
  return newBlock;
}

export function getBlockComplete(
  values: any,
  dataStructure: any,
  blockId: number,
  isLastYear: boolean,
  hgb: boolean,
) {
  let state = true;
  if (values[dataStructure[`b${blockId}_field_f1`]?.name] === 'nofi') {
    return true;
  }
  if (values[dataStructure[`b${blockId}_field_f1`]?.name] === 'ja') {
    state =
      state &&
      values[dataStructure[`b${blockId}_field_f2`]?.name] !== '' &&
      values[dataStructure[`b${blockId}_field_f2`]?.name] !== undefined;

    if (values[dataStructure[`b${blockId}_field_f3`]?.name] === undefined && hgb) {
      return state;
    }
    if (values[dataStructure[`b${blockId}_field_f3`]?.name] === '') {
      return false;
    }
    if (values[dataStructure[`b${blockId}_field_f3`]?.name] === 'True') {
      return state;
    }
    if (values[dataStructure[`b${blockId}_field_f3`]?.name] === 'False') {
      if (blockId === 4)
        state =
          state &&
          !(
            values[dataStructure[`b${blockId}_field_f4`]?.name] === '' ||
            values[dataStructure[`b${blockId}_field_f5`]?.name] === '' ||
            values[dataStructure[`b${blockId}_field_f6`]?.name] === '' ||
            values[dataStructure[`b${blockId}_field_f7`]?.name] === '' ||
            values[dataStructure[`b${blockId}_field_f12`]?.name] === '' ||
            values[dataStructure[`b${blockId}_field_f13`]?.name] === '' ||
            values[dataStructure[`b${blockId}_field_f14`]?.name] === '' ||
            values[dataStructure[`b${blockId}_field_f15`]?.name] === '' ||
            values[dataStructure[`b${blockId}_field_f4`]?.name] === undefined ||
            values[dataStructure[`b${blockId}_field_f5`]?.name] === undefined ||
            values[dataStructure[`b${blockId}_field_f6`]?.name] === undefined ||
            values[dataStructure[`b${blockId}_field_f7`]?.name] === undefined ||
            values[dataStructure[`b${blockId}_field_f12`]?.name] === undefined ||
            values[dataStructure[`b${blockId}_field_f13`]?.name] === undefined ||
            values[dataStructure[`b${blockId}_field_f14`]?.name] === undefined ||
            values[dataStructure[`b${blockId}_field_f15`]?.name] === undefined
          );
      else if (isLastYear) {
        if (
          values[dataStructure[`b${blockId}_field_f4`]?.name] === '' ||
          values[dataStructure[`b${blockId}_field_f5`]?.name] === '' ||
          values[dataStructure[`b${blockId}_field_f6`]?.name] === '' ||
          values[dataStructure[`b${blockId}_field_f7`]?.name] === '' ||
          values[dataStructure[`b${blockId}_field_f4`]?.name] === undefined ||
          values[dataStructure[`b${blockId}_field_f5`]?.name] === undefined ||
          values[dataStructure[`b${blockId}_field_f6`]?.name] === undefined ||
          values[dataStructure[`b${blockId}_field_f7`]?.name] === undefined
        ) {
          return false;
        }
      } else if (
        values[dataStructure[`b${blockId}_field_f4`]?.name] === '' ||
        values[dataStructure[`b${blockId}_field_f5`]?.name] === '' ||
        values[dataStructure[`b${blockId}_field_f6`]?.name] === '' ||
        values[dataStructure[`b${blockId}_field_f7`]?.name] === '' ||
        values[dataStructure[`b${blockId + 1}_field_f4`]?.name] === '' ||
        values[dataStructure[`b${blockId + 1}_field_f5`]?.name] === '' ||
        values[dataStructure[`b${blockId + 1}_field_f6`]?.name] === '' ||
        values[dataStructure[`b${blockId + 1}_field_f7`]?.name] === '' ||
        values[dataStructure[`b${blockId}_field_f4`]?.name] === undefined ||
        values[dataStructure[`b${blockId}_field_f5`]?.name] === undefined ||
        values[dataStructure[`b${blockId}_field_f6`]?.name] === undefined ||
        values[dataStructure[`b${blockId}_field_f7`]?.name] === undefined ||
        values[dataStructure[`b${blockId + 1}_field_f4`]?.name] === undefined ||
        values[dataStructure[`b${blockId + 1}_field_f5`]?.name] === undefined ||
        values[dataStructure[`b${blockId + 1}_field_f6`]?.name] === undefined ||
        values[dataStructure[`b${blockId + 1}_field_f7`]?.name] === undefined
      ) {
        return false;
      }
      return state;
    }
  }
  if (values[dataStructure[`b${blockId}_field_f1`]?.name] === 'bwa') {
    return (
      values[dataStructure[`b${blockId}_field_f8`]?.name] !== '' &&
      values[dataStructure[`b${blockId}_field_f9`]?.name] !== '' &&
      values[dataStructure[`b${blockId}_field_f8`]?.name] !== undefined &&
      values[dataStructure[`b${blockId}_field_f9`]?.name] !== undefined
    );
  }
  if (values[dataStructure[`b${blockId}_field_f1`]?.name] === 'susa') {
    return (
      values[dataStructure[`b${blockId}_field_f10`]?.name] !== '' &&
      values[dataStructure[`b${blockId}_field_f11`]?.name] !== '' &&
      values[dataStructure[`b${blockId}_field_f10`]?.name] !== undefined &&
      values[dataStructure[`b${blockId}_field_f11`]?.name] !== undefined
    );
  }

  return false;
}
