import React, { useCallback, memo } from 'react';
import _get from 'lodash/get';

import { TextField as MuiTextField } from '@mui/material';
import { fieldToTextField, TextFieldProps } from 'formik-mui';
import NumberFormatCustom from './NumberFormatCustom';

const CurrencyField = (props: TextFieldProps) => {
  const {
    form: { setFieldValue, touched, errors },
    field: { name },
    onChange: customOnChange,
  } = props;
  const textFieldProp = { ...props };

  const onChange = useCallback(
    (event: any) => {
      const { value } = event.target;
      if (/^(\d*\.?\d*?)$/.test(value)) {
        setFieldValue(name, value);
      }
      if (typeof customOnChange === 'function') {
        customOnChange(event);
      }
    },
    [customOnChange, setFieldValue, name],
  );

  return (
    <MuiTextField
      {...fieldToTextField(textFieldProp)}
      InputProps={{
        inputComponent: NumberFormatCustom,
        ...props.InputProps,
      }}
      onChange={onChange}
      error={_get(touched, name) && !!_get(errors, name)}
      helperText={(_get(touched, name) as any) && (_get(errors, name) as any)}
    />
  );
};

export default memo(CurrencyField);
