import React, { useState, useEffect, useCallback } from 'react';

import { Typography, Button, CircularProgress } from '@mui/material';
import { GetApp } from '@mui/icons-material';

import checkedIcon from 'Assets/images/checked.png';
import { request } from 'service/ApiCaptiq';
import * as ApiCaptiq from 'service/ApiCaptiq';
import { getFileNameFromUrl } from 'utils/getFileNameFromUrl';
import useStyles from './styles';

interface Props {
  loanUsageId: number;
  loanUsageName: string;
}

const TemplateFile = ({ loanUsageId, loanUsageName }: Props) => {
  const styles = useStyles();
  const [file, setFile] = useState({ url: '', name: '' });
  const [loading, setLoading] = useState(false);

  const fetchFile = useCallback(async () => {
    try {
      setLoading(true);
      const url = `${ApiCaptiq.GET_TEMPLATE_FILE}?loan_usage=${loanUsageId}`;
      const { data } = await request.get(url);

      setFile({ url: data.url, name: getFileNameFromUrl(data.url) });
    } catch (error) {
      console.error(error);
      setFile({ url: '', name: '' });
    } finally {
      setLoading(false);
    }
  }, [loanUsageId]);

  useEffect(() => {
    if (typeof loanUsageId === 'number') {
      fetchFile();
    }
  }, [fetchFile, loanUsageId]);

  return (
    <div>
      <Typography className={styles.titleText}>
        Hier finden Sie die zu Ihrem gewählten Verwendungszweck passende Checkliste mit allen
        benötigten Daten & Dokumenten:
      </Typography>
      <div className={styles.innerContainer}>
        <img src={checkedIcon} alt="" className={styles.icon} />
        <Typography className={styles.text}>Checkliste {loanUsageName}:</Typography>
      </div>
      <div className={styles.buttonsContainer}>
        {loading ? (
          <CircularProgress color="primary" size={24} className={styles.loading} />
        ) : (
          <>
            <Button
              disabled={!file.url}
              download={file.name}
              href={file.url}
              size="small"
              variant="contained"
              startIcon={<GetApp fontSize="inherit" />}
              color="primary"
            >
              <span>Download</span>
            </Button>
            <Button
              disabled={!file.url}
              href={file.url}
              target="_blank"
              size="small"
              variant="contained"
              color="white"
            >
              Ansehen
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default TemplateFile;
