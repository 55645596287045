export interface validationError {
  inner: any[];
}

export interface Response {
  message: string;
  type: 'required';
  label: string;
}

export const getValidationErrors = (err: validationError): Response[] =>
  err.inner.map((error: any) => ({
    message: error.message,
    type: error.type,
    label: error.params.label,
  }));
