import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
  completeField: {
    '& fieldset': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  readOnlyField: {
    '& fieldset': {
      borderColor: `rgba(0, 0, 0, 0.23) !important`,
    },
  },
  lottieSelect: {
    marginRight: '20px',
  },
  pen: {
    padding: 0,
    marginRight: '20px',
  },
  endAdornment: {
    marginLeft: 20,
    marginRight: 25,
  },
}));
