import { createContext, useContext, useState } from 'react';

import { BlockDataProviderProps } from './types';
import { initialState } from './constants';

const BlockData = createContext(initialState);

const BlockDataProvider = ({ children }: BlockDataProviderProps) => {
  const [expanded, setExpanded] = useState<string>('');
  const [currentFieldSlug, setCurrentFieldSlug] = useState<string>('');
  const [staticFormikValues, setStaticFormikValues] = useState({});

  const value = {
    expanded,
    currentFieldSlug,
    setExpanded,
    setCurrentFieldSlug,
    staticFormikValues,
    setStaticFormikValues,
  };

  return <BlockData.Provider value={value}>{children}</BlockData.Provider>;
};

export const useBlockData = () => {
  const context = useContext(BlockData);
  if (context === undefined) {
    throw new Error('useBlockData must be used within a CountProvider');
  }
  return context;
};

export default BlockDataProvider;
