import { Company, Person, RefOwner } from 'context/customer-context/types';
import { getRolId } from './index';

const filterRefs = (data: any[], type: 'person' | 'company') => {
  const filter = data
    .filter((v: Person | Company, index) => {
      // We need to keep the original index of the entity
      // eslint-disable-next-line no-param-reassign
      if (v.isOwner === 'yes') v.originalIndex = index;
      return v.isOwner === 'yes';
    })
    .map(
      (v): RefOwner => ({
        _type: type,
        id: v.id,
        ownerId: getRolId('OWP', v.correlativeId),
        index: v.originalIndex,
        modelId: type === 'person' ? v.personId : v.businessId,
      }),
    );
  return filter;
};

export const getOwnerRefs = (people: Person[], companies: Company[]): RefOwner[] => {
  const myPeople = filterRefs(people, 'person');
  const myCompanies = filterRefs(companies, 'company');
  const refs: RefOwner[] = [...myPeople, ...myCompanies];
  return refs;
};
