import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  description: {
    margin: '0 0 20px 0',
  },
  selectContainer: {
    margin: '8px 0',
  },
  optionItem: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      color: theme.palette.success.main,
    },
  },
  controlLabel: {
    '&  span': {
      fontSize: '1rem',
    },
  },
  divider: {
    marginBottom: 8,
  },
  containerTitle: {
    display: 'flex',
    marginBottom: '10px',
  },
  questionTitle: {
    fontWeight: 500,
    color: '#000',
  },
  completeField: {
    '& fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  endAdornment: {
    marginRight: 25,
    marginLeft: 20,
  },
  tabsContent: {
    padding: 20,
    background: '#efefef',
    borderRadius: 15,
    transition: 'background-color 0.4s',
  },
  activeBackground: {
    background: '#bfbfbf',
    transition: 'background-color 0.4s',
  },
  button: {
    justifyContent: 'flex-start',
    textTransform: 'none',
    fontWeight: 300,
    width: 'fit-content',
  },
  largeButtonIcon: {
    '& > :first-child': {
      fontSize: 35,
    },
  },
  dividerSecondLevel: {
    marginBottom: 8,
    marginTop: 8,
  },
  spacerGrid: {
    marginBottom: 2,
    marginTop: 2,
  },
}));

export default useStyles;
