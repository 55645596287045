import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { CaptiqComment } from 'constants/types';
import * as ApiCaptiq from 'service/ApiCaptiq';
import { request } from 'service/ApiCaptiq';

export const useComments = (applicationID: number, props?: UseQueryOptions<CaptiqComment[]>) =>
  useQuery<CaptiqComment[]>({
    queryKey: ['comments', applicationID],
    queryFn: () =>
      request
        .get(`${ApiCaptiq.LOAN_APPLICATION_COMMENT_URL}?loan_application=${applicationID}`)
        .then((res) => res.data),
    staleTime: Infinity,
    enabled: !!applicationID,
    ...props,
  });
