import { InitialStateType } from './types';

export const initialState: InitialStateType = {
  expanded: '',
  currentFieldSlug: '',
  staticFormikValues: {},
  setStaticFormikValues: () => {},
  setExpanded: () => {},
  setCurrentFieldSlug: () => {},
};
