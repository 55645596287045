import * as Yup from 'yup';
import { StringSchema } from 'yup';
import moment from 'moment';

import { CountryType, LegalForm } from 'utils/commonTypes/types';
import { MAX_LENGTH_PHONE_NUMBER, ONLY_NUMBERS } from 'constants/validations';
import { countryOrNationality } from 'utils/validateStaticBlocksValues/countryOrNationality';
import checkIsNumber from 'utils/dataHandlers/checkIsNumber';
import {
  ONGOING_TEMPLATE_TAG,
  PROCESS_COMPANY_TEMPLATE_TAG,
  STARTUP_TEMPLATE_TAG,
  TAKEOVER_PERSON_TEMPLATE_TAG,
} from 'constants/templates';
import { GbRLegalFormKey } from 'constants/legalForms';
import { getLocaleAllocation } from 'utils/utils';
import { LANGUAGE } from 'constants/language';
import { countryObject } from '../Owner/validationSchema';

export const whenInternationalPrefix = (
  prefixMobilePhone: CountryType | null | undefined,
  schema: StringSchema,
) => {
  const max = prefixMobilePhone?.prefix
    ? MAX_LENGTH_PHONE_NUMBER - prefixMobilePhone.prefix.replace(ONLY_NUMBERS, '').length
    : MAX_LENGTH_PHONE_NUMBER;
  return schema.max(max, `Die Telefonnummer darf maximal ${max} Ziffern haben.`);
};

export const whenPrefixMobilePhone = (prefixMobilePhone: CountryType, schema: StringSchema) => {
  const max = prefixMobilePhone?.prefix
    ? MAX_LENGTH_PHONE_NUMBER - prefixMobilePhone.prefix.replace(ONLY_NUMBERS, '').length
    : MAX_LENGTH_PHONE_NUMBER;
  return schema.max(max, `Die Telefonnummer darf maximal ${max} Ziffern haben.`);
};

export const checkMinValuePhone = (value: string | undefined | null) => {
  if (value !== null && value !== undefined && value !== '') {
    if (value.length < 7) return false;
  }
  return true;
};

export const checkMaxAge = ({
  ageCustomer,
  legalForm,
  loanApplicationDuration,
  loanApplicationUsage,
}: {
  ageCustomer: number;
  legalForm: '' | LegalForm | undefined;
  loanApplicationDuration: number;
  loanApplicationUsage: string;
}) => {
  const totalAge = ageCustomer + loanApplicationDuration / 12;

  if (loanApplicationUsage === STARTUP_TEMPLATE_TAG) {
    const maxAllowedAge = 60;
    if (ageCustomer >= maxAllowedAge + 1) {
      return true;
    }
  } else {
    const maxAllowedAge = 65;
    const legalFormsForOldAgeWarning = ['eK', 'Fb', 'GbR', 'PartG', 'PartGmbB'];

    if (legalFormsForOldAgeWarning.includes(legalForm as string)) {
      if (totalAge >= maxAllowedAge + 1) {
        return true;
      }
    }
  }

  return false;
};

export const checkMinAge = ({
  ageCustomer,
  legalForm,
  loanApplicationUsage,
}: {
  ageCustomer: number;
  legalForm: '' | LegalForm | undefined;
  loanApplicationUsage: string;
}) => {
  if (
    [STARTUP_TEMPLATE_TAG, TAKEOVER_PERSON_TEMPLATE_TAG, PROCESS_COMPANY_TEMPLATE_TAG].includes(
      loanApplicationUsage,
    ) ||
    (loanApplicationUsage === ONGOING_TEMPLATE_TAG && (legalForm === 'eK' || legalForm === 'Fb'))
  ) {
    const minAllowedAge = 20;
    if (ageCustomer < minAllowedAge) {
      return true;
    }
  }

  const minAllowedAge = 18;
  if (ageCustomer < minAllowedAge) {
    return true;
  }

  return false;
};

export const whenDateOfBirth = {
  is: () => true,
  then: Yup.string()
    .test('test_if_is_old_person', 'dateOfBirth error', (value, context) => {
      const loanApplicationUsage = context.options.context?.loanApplicationUsage;
      const legalForm = context.options.context?.company?.data?.legalForm;
      const loanApplicationDuration = context.options.context?.loanApplicationDuration || 0;
      const ageCustomer = moment().diff(new Date(value as string), 'years', true);

      const isInvalidMaxAge = checkMaxAge({
        ageCustomer,
        legalForm,
        loanApplicationDuration,
        loanApplicationUsage,
      });

      if (isInvalidMaxAge) return false;

      return true;
    })
    .test('test_if_is_minor_person', 'dateOfBirth min_age', (value, context) => {
      const loanApplicationUsage = context.options.context?.loanApplicationUsage;
      const legalForm = context.options.context?.company?.data?.legalForm;
      const ageCustomer = moment().diff(new Date(value as string), 'years', true);

      const isInvalidMinAge = checkMinAge({
        ageCustomer,
        legalForm,
        loanApplicationUsage,
      });

      if (isInvalidMinAge) return false;

      return true;
    }),
};

export const personalDataValidationSchema = Yup.object().shape({
  salutation: Yup.string().required('Bitte wählen Sie eine Anrede').label('Anrede'),
  title: Yup.string().nullable().label('Titel'),
  firstNames: Yup.string().required('Bitte geben Sie Ihren Vornamen ein').label('Vorname'),
  lastName: Yup.string().required('Bitte geben Sie Ihren Nachnamen ein').label('Nachname'),
  dateOfBirth: Yup.string().when([], whenDateOfBirth),
  countryOfBirth: countryOrNationality('Geburtsland'),
  cityOfBirth: Yup.string().required().label('Geburtsort'),
  nationality: countryOrNationality('Staatsangehörigkeit'),
  occupationalGroup: Yup.string().required().label('Berufsgruppe'),
  familySituation: Yup.string().required().label('Familienstatus'),
  /* CRP-2726 Undisplay not mandatory fields
  dependents: Yup.array()
    .of(
      Yup.object({
        age: Yup.number()
          .moreThan(0, 'Das Alter kann keine negative Zahl sein')
          .typeError('Bitte geben Sie eine gültige Zahl ein')
          .nullable()
          .transform((v, o) => (o === '' ? null : v)),
        monthlyUpkeep: Yup.number()
          .positive('Der monatliche Unterhalt kann kein negativer Betrag sein')
          .nullable()
          .transform((v, o) => (o === '' ? null : v))
          .typeError('Bitte geben Sie eine gültige Zahl ein')
          .test(
            'test_currency',
            'Stelle sicher, dass es insgesamt nicht mehr als 6 Ziffern lang ist',
            (value) => {
              if (value === undefined || value === null) return true;
              return regexForValidNumber.test(`${value}`);
            },
          ),
      }),
    )
    .label('Unterhaltsberechtigte Personen'),
  children: Yup.array()
    .of(
      Yup.object({
        age: Yup.number()
          .moreThan(0, 'Das Alter kann keine negative Zahl sein')
          .typeError('Bitte geben Sie eine gültige Zahl ein'),
      }),
    )
    .label('Kinder'),
  taxOffice: Yup.string().label('Finanzamt'),
  taxID: Yup.string().label('SteuerID TIN'),
  taxNumber: Yup.string().label('Steuer-Nr'),
  */
  taxableInUSA: Yup.string().oneOf(['no']).required().label('Steuerpflichtig in den USA? (FATCA)'),
  isSoleRepresentation: Yup.string()
    .nullable()
    .label('Ist die Person einzelvertretungsberechtigt?')
    .test(
      'test_sole_representation',
      'Aufgrund Ihrer Angabe zur Vertretungsbefugnis kann CAPTIQ Ihnen leider keine Finanzierung anbieten, da zurzeit keine Unternehmen mit Gesamtvertretungsbefugnis finanziert werden können. Das Anlegen eines Kundenprofils ist zum Verwaltungszweck jedoch weiterhin möglich.',
      (value, context) => {
        const { legalForm } = context.options.context?.company?.data;
        const loanApplicationUsage = context.options.context?.loanApplicationUsage;
        if (
          loanApplicationUsage === STARTUP_TEMPLATE_TAG ||
          loanApplicationUsage === TAKEOVER_PERSON_TEMPLATE_TAG
        ) {
          return true;
        }
        if (legalForm === GbRLegalFormKey) return value === 'no';
        return value === 'yes';
      },
    ),
  allocation: Yup.string().label('Anteile am Unternehmen'),
  isOwner: Yup.string().label('Inhaber(in)'),
  isBeneficialOwner: Yup.string().label('Wirtschaftlich Berechtigte'),
  shares: Yup.array()
    .of(
      Yup.object({
        allocation: Yup.string(),
        sideDeal: Yup.string(),
      }),
    )
    .nullable(),
  hasOtherCompanies: Yup.string()
    .label(
      'Hält die Person Anteile von mehr als 25% an mind. einer anderen Kapitalgesellschaft (z.B. GmbH) oder ist die Person an mind. einer weiteren Personengesellschaft (z.B. GbR) beteiligt?',
    )
    .test('required', 'Bitte wählen Sie eine Option', (val: any, context: any) => {
      const { profileVersion, loanApplicationUsage } = context.options.context;
      const { shares } = context.parent;

      if (
        Number(profileVersion.at(-1)) < 3 ||
        loanApplicationUsage !== ONGOING_TEMPLATE_TAG ||
        (shares &&
          shares.length > 0 &&
          !!shares[0].allocation &&
          getLocaleAllocation(shares[0].allocation || '0', LANGUAGE) < 50.01)
      ) {
        return true;
      }

      const condition = !!val;

      return condition;
    }),
});

export const contactValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Bitte geben Sie eine gültige E-Mail-Adresse ein')
    .required()
    .label('E-Mail'),
  repeatEmail: Yup.string()
    .when('email', {
      is: (val: string) => val && val.length > 0,
      then: Yup.string().oneOf(
        [Yup.ref('email')],
        'Die Mailadresse stimmt leider nicht mit der oben angegebenen überein. Bitte überprüfen Sie diese nochmals.',
      ),
    })
    .required()
    .label('E-Mail wiederholen'),
  prefixMobilePhone: countryOrNationality('Vorwahl (mobil)'),
  mobilePhone: Yup.string()
    .required()
    .test('test_is_number', 'Die Telefonnummer darf nur aus Ziffern bestehen', (value, context) =>
      checkIsNumber(value, context),
    )
    .min(7, 'Die Telefonnummer muss mindestens 7 Ziffern haben')
    .when('prefixMobilePhone', whenPrefixMobilePhone)
    .label('Telefonnummer (mobil)'),
  internationalPrefix: Yup.object(countryObject).nullable().label('Vorwahl'),
  phoneNumber: Yup.string()
    .test('test_is_number', 'Die Telefonnummer darf nur aus Ziffern bestehen', (value, context) =>
      checkIsNumber(value, context),
    )
    .test('test_if_correct_value', 'Die Telefonnummer muss mindestens 7 Ziffern haben', (value) =>
      checkMinValuePhone(value),
    )
    .when('internationalPrefix', whenInternationalPrefix)
    .label('Telefonnummer'),
});

export const addressValidationSchema = Yup.object().shape({
  personalAddressStreet: Yup.string().required().label('Straße Privatanschrift'),
  personalAddressHouseNumber: Yup.string().required().label('Hausnummer Privatanschrift'),
  personalAddressAdditional: Yup.string().label('Adresszusatz Privatanschrift'),
  personalAddressPostalCode: Yup.string()
    .min(4, 'Die PLZ in Deutschland sind 5-stellig und in Österreich und in der Schweiz 4-stellig.')
    .max(5, 'Die PLZ in Deutschland sind 5-stellig und in Österreich und in der Schweiz 4-stellig.')
    .required()
    .label('PLZ Privatanschrift'),
  personalAddressCity: Yup.string().required().label('Ort Privatanschrift'),
  personalAddressCountry: countryOrNationality('Wohnsitzland Privatanschrift'),
});

export const validationSchema = Yup.object().shape({
  loanApplicants: Yup.array().of(
    Yup.object().shape({
      personalData: personalDataValidationSchema,
      contact: contactValidationSchema,
      address: addressValidationSchema,
    }),
  ),
});
