import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  buttonContent: {
    wordBreak: 'break-all',
    textTransform: 'none',
  },
  buttonContentColor: {
    color: '#c4c4c4',
  },
}));

export default useStyles;
