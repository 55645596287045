import React, { memo } from 'react';

import { Grid } from '@mui/material';

import { RefBeneficialOwner } from 'context/customer-context/types';
import CustomField from '../CustomField';

export interface AddressFormProps {
  refBeneficialOwner: RefBeneficialOwner;
  isEditing?: boolean;
}

export const AddressForm = memo(({ refBeneficialOwner, isEditing = true }: AddressFormProps) => (
  <Grid container item spacing={2}>
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <CustomField
          name={`people[${refBeneficialOwner.index}].personalAddressStreet`}
          title="Straße Privatanschrift"
          type="text"
          disabled={!isEditing}
          required
        />
        <CustomField
          name={`people[${refBeneficialOwner.index}].personalAddressHouseNumber`}
          title="Hausnummer Privatanschrift"
          type="text"
          disabled={!isEditing}
          required
        />
        <CustomField
          name={`people[${refBeneficialOwner.index}].personalAddressAdditional`}
          title="Adresszusatz Privatanschrift"
          type="text"
          disabled={!isEditing}
        />
        <CustomField
          name={`people[${refBeneficialOwner.index}].personalAddressPostalCode`}
          title="PLZ Privatanschrift"
          type="onlyNumbers"
          disabled={!isEditing}
          required
        />
        <CustomField
          name={`people[${refBeneficialOwner.index}].personalAddressCity`}
          title="Ort Privatanschrift"
          type="text"
          disabled={!isEditing}
          required
        />
        <CustomField
          title="Wohnsitzland Privatanschrift"
          name={`people[${refBeneficialOwner.index}].personalAddressCountry`}
          textFieldProps={{ label: 'Bitte wählen' }}
          type="place"
          placetype="country"
          disabled={!isEditing}
          required
        />
      </Grid>
    </Grid>
  </Grid>
));

AddressForm.displayName = 'AddressForm';
