import { OptionsObject } from 'notistack';

export const notificationConfig: OptionsObject = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  variant: 'error',
  autoHideDuration: 3000,
};

export const handleBackendErrors = (
  error: any,
  enqueueSnackbar: (
    message: React.ReactNode,
    options?: OptionsObject | undefined,
  ) => React.ReactText,
  noSnackbar: boolean = false,
) => {
  if (error) {
    const errorCode = error.response?.status;
    if (errorCode && String(errorCode).startsWith('5')) {
      enqueueSnackbar(
        'Es liegt ein Problem auf unserem Server vor. Bitte versuchen Sie es in einer Minute erneut.',
        notificationConfig,
      );
      return true;
    }
    if (error.message === 'Network Error') {
      enqueueSnackbar(
        'Ein Fehler ist aufgetreten. Bitte überprüfen Sie Ihre Verbindung und versuchen Sie es erneut.',
        notificationConfig,
      );
      return true;
    }
    if (!noSnackbar) {
      enqueueSnackbar('Es liegt ein unbekannter Fehler vor.', notificationConfig);
      return true;
    }
  }
  return false;
};
