import { createContext, useContext, useState, useCallback, useEffect } from 'react';

import { ErrorsDataProviderProps, CustomError, Reminder, FieldViewed } from './types';
import { initialState } from './constants';

const ErrorsData = createContext(initialState);

const ErrorsDataProvider = ({ children }: ErrorsDataProviderProps) => {
  const [errors, setErrors] = useState<CustomError[]>([]);
  const [customErrors, setCustomErrors] = useState<CustomError[]>([]);
  const [reminders, setReminders] = useState<Reminder[]>([]);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [showReviewApprovalModal, setShowReviewApprovalModal] = useState<boolean>(false);
  const [showIcon, setShowIcon] = useState<boolean>(false);
  const [currentSection, setCurrentSection] = useState<number>(0);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [fieldViewed, setFieldViewed] = useState<FieldViewed>({});
  const [validateReminder, setValidateReminder] = useState<boolean>(true);
  const [redirectView, setRedirectView] = useState('view');
  const [extraNavigation, setExtraNavigation] = useState<((step: number) => void) | null>(null);

  const handleReset = useCallback(() => {
    setReminders([]);
    setErrors([]);
    setCurrentSection(0);
    setCurrentStep(0);
    setFieldViewed({});
    setRedirectView('view');
    setExtraNavigation(null);
  }, []);

  useEffect(() => {
    if (errors.length > 0) {
      setCurrentSection(0);
      setCurrentStep(0);
    }
  }, [errors]);

  const value = {
    errors,
    showErrorModal,
    showReviewApprovalModal,
    customErrors,
    showIcon,
    currentSection,
    currentStep,
    reminders,
    fieldViewed,
    validateReminder,
    redirectView,
    extraNavigation,
    setErrors,
    setShowErrorModal,
    setShowReviewApprovalModal,
    setCustomErrors,
    setShowIcon,
    setCurrentSection,
    setCurrentStep,
    setFieldViewed,
    handleReset,
    setReminders,
    setValidateReminder,
    setRedirectView,
    setExtraNavigation,
  };

  return <ErrorsData.Provider value={value}>{children}</ErrorsData.Provider>;
};

export const useErrorsData = () => {
  const context = useContext(ErrorsData);
  if (context === undefined) {
    throw new Error('useErrorsData must be used within a CountProvider');
  }
  return context;
};

export default ErrorsDataProvider;
