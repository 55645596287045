import { TITLE_OW } from 'components/StaticBlocks/Owner';
import { RefOwner } from 'context/customer-context/types';
import { Block } from 'utils/commonTypes/types';
import { Errors } from '.';

interface Props {
  errorsYUP: Errors[];
  block: Block;
  refsOwners: RefOwner[];
}

export const getErrorsOW = ({ block, errorsYUP, refsOwners }: Props) => ({
  title: block.title,
  type: 'withInnerBlocks',
  section_path: block.section_path,
  blocks: errorsYUP.map((item) => ({
    title: `${block.title}${refsOwners.length !== 1 ? ` ${item.position + 1}` : ''}`,
    type: 'withInnerBlocks',
    section_path: block.section_path,
    blocks: [
      {
        title: TITLE_OW,
        type: 'normalBlock',
        section_path: block.section_path,
        warnings: item.errors
          .filter((error) => error.type === 'required')
          .map((error) => ({ label: error.label, slug: `${error.label}ow${item.position}` })),
      },
    ],
  })),
});
