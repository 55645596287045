import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { Check, Close, Edit, Block } from '@mui/icons-material';
import {
  AccordionActions,
  Button,
  Menu,
  MenuItem,
  Tooltip,
  IconButton,
  CircularProgress,
} from '@mui/material';

import { useDataContext } from 'context/data-context';
import {
  BLOCK_STATUS_APPROVED,
  BLOCK_STATUS_DECLINED,
  BLOCK_STATUS_EDIT,
} from 'constants/reviewBlocks';
import useStyles from './styles';

interface Props {
  onSave: () => Promise<void>;
  onCancel: () => void;
  onDeclineBlock: () => void;
  onApproveBlock: () => void;
  onStartEdition: () => void;
  waitingAction: string;
  isValid: boolean;
  status: string;
  isStaticComponent: boolean;
}

interface Ref {
  isEditing: boolean;
}

const Actions = forwardRef<Ref, Props>(
  (
    {
      onSave,
      onCancel,
      onDeclineBlock,
      onApproveBlock,
      onStartEdition,
      waitingAction,
      isValid,
      status,
      isStaticComponent,
    },
    ref,
  ) => {
    const styles = useStyles();
    const { loadingReview } = useDataContext();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isEditing, setIsEditing] = useState(false);

    const handleShowEditOptions = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleCloseEditOptions = () => {
      setAnchorEl(null);
    };

    const handleStartEdition = () => {
      setIsEditing(true);
      setAnchorEl(null);
      setTimeout(() => {
        onStartEdition();
      }, 50);
    };

    const handleOnCancel = () => {
      setIsEditing(false);
      setTimeout(() => {
        onCancel();
      }, 50);
    };

    const handleOnSave = async () => {
      await onSave();
      setIsEditing(false);
    };

    useImperativeHandle(
      ref,
      () => ({
        isEditing,
      }),
      [isEditing],
    );

    return (
      <AccordionActions>
        {isEditing ? (
          <>
            <Button
              disabled={loadingReview}
              size="small"
              onClick={handleOnCancel}
              className={styles.buttonMargin}
              data-testid="cancel-block"
            >
              <Block className={styles.iconLeft} /> Bearbeiten abbrechen
            </Button>
            <div className={styles.buttonWrapper}>
              <Button
                disabled={loadingReview || !isValid}
                variant="contained"
                size="small"
                color="primary"
                onClick={handleOnSave}
                className={styles.button}
                data-testid="save-block"
              >
                <Check className={styles.iconLeft} /> Änderungen übernehmen
              </Button>
              {loadingReview && waitingAction === BLOCK_STATUS_EDIT && (
                <CircularProgress disableShrink size={24} className={styles.fabProgress} />
              )}
            </div>
          </>
        ) : (
          <>
            <Tooltip title="Bearbeite Block">
              <IconButton
                disabled={loadingReview}
                onClick={handleShowEditOptions}
                data-testid="edit-block"
                size="large"
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Menu
              id="edit-options"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseEditOptions}
            >
              <MenuItem onClick={handleStartEdition}>Block ändern</MenuItem>
              <MenuItem disabled>Block entsperren</MenuItem>
              <MenuItem disabled>Neue Datenfelder hinzufügen</MenuItem>
              <MenuItem disabled>Neuen Block hinzufügen</MenuItem>
            </Menu>
            <Tooltip title="Abgelehnter Block">
              <div className={styles.wrapper}>
                <IconButton
                  data-testid="decline-block"
                  disabled={loadingReview || status === BLOCK_STATUS_DECLINED}
                  onClick={onDeclineBlock}
                  size="large"
                >
                  <Close />
                </IconButton>
                {loadingReview && waitingAction === BLOCK_STATUS_DECLINED && (
                  <CircularProgress disableShrink size={60} className={styles.fabProgress} />
                )}
              </div>
            </Tooltip>
            <Tooltip title="Genehmigter Block">
              <div className={styles.wrapper}>
                <IconButton
                  data-testid="approve-block"
                  disabled={
                    (isStaticComponent ? false : !isValid) ||
                    loadingReview ||
                    status === BLOCK_STATUS_APPROVED
                  }
                  onClick={onApproveBlock}
                  size="large"
                >
                  <Check />
                </IconButton>
                {loadingReview && waitingAction === BLOCK_STATUS_APPROVED && (
                  <CircularProgress disableShrink size={60} className={styles.fabProgress} />
                )}
              </div>
            </Tooltip>
          </>
        )}
      </AccordionActions>
    );
  },
);

Actions.displayName = 'Actions';

export default Actions;
