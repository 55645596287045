import * as Yup from 'yup';

import { countryObject } from 'components/StaticBlocks/Owner/validationSchema';

export const countryOrNationality = (label: string) =>
  Yup.lazy((value) => {
    if (!value) {
      return Yup.mixed().required().label(label);
    }

    return Yup.object(countryObject).required().label(label);
  });
