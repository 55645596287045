import { Component, memo, forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography, InputAdornment, Snackbar, Divider } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Slider from '@mui/material/Slider';
import { InfoOutlined } from '@mui/icons-material';

import * as ApiCaptiq from '../../service/ApiCaptiq';
import { request } from '../../service/ApiCaptiq';
import SnackContent from '../SnackContent';
import InputNumber from './InputNumber';
import LoanResume from './LoanResume';
import LoanOptions from './LoanOptions';
import NumericField from '../NumericField';
import { AuthContext } from '../../context/auth-context';
import { useDataContext } from '../../context/data-context';
import styles from './styles';

class FirstStep extends Component {
  state = {
    form: {
      loan_type: '',
      profession: '',
      loan_usage: '',
      loan_product: '',
      duration: 0,
      interest_rate: 0.0,
      agio: 0,
      loan_options: null,
      loan_amount: 0.0,
      loan_total: 0.0,
      monthly_rate: 0.0,
      disableLoanOptions: false,
      loan_paid_out: 0.0,
      agio_amount: 0.0,
      rate_amount: 0.0,
      nominal_interest_rate: 0.0,
    },
    open: false,
    messageType: 'success',
    loanOptionsValues: { loan_amount_min: 0, loan_amount_max: 0, duration_min: 0, duration_max: 0 },
    errors: {},
  };

  componentDidMount() {
    const { form } = this.state;
    if (this.props.data.id) {
      form.loan_type = this.props.data.loan_type;
      form.profession = this.props.data.profession;
      form.loan_usage = this.props.data.loan_usage;
      form.loan_product = this.props.data.loan_product;
      form.loan_amount = parseFloat(this.props.data.loan_amount || 0.0);
      form.duration = parseFloat(this.props.data.duration || 0.0);
      form.monthly_rate = parseFloat(this.props.data.monthly_rate);
      form.agio = parseFloat(this.props.data.agio);
      form.interest_rate = parseFloat(this.props.data.interest_rate);
      form.loan_options = this.props.data.id;

      form.loan_paid_out = parseFloat(this.props.data.loan_paid_out || 0.0);
      form.agio_amount = parseFloat(this.props.data.agio_amount || 0.0);
      form.rate_amount = parseFloat(this.props.data.rate_amount);
      form.nominal_interest_rate = parseFloat(this.props.data.nominal_interest_rate);

      this.updateCalculations();

      this.loadLoanOptions({
        loan_type: form.loan_type,
        profession: form.profession,
        loan_usage: form.loan_usage,
        loan_product: form.loan_product,
      })
        .then(this.onLoadOptions)
        .catch(this.onLoadOptionsFails);
    }
    if (this.props.toggleNavigation) this.props.toggleNavigation(false);
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.errors !== this.props.errors) {
      this.setState({ errors: nextProps.errors });
      return false;
    }
    return true;
  }

  onLoadOptions = (response) => {
    const { loanOptionsValues, form } = this.state;
    const optionsValues = this.setLoanOptionsValues(loanOptionsValues, response.data.data[0]);

    form.agio = parseFloat(response.data.data[0].agio);
    form.interest_rate = parseFloat(response.data.data[0].interest_rate);
    form.nominal_interest_rate = parseFloat(response.data.data[0].interest_rate);
    form.loan_options = response.data.data[0].id;

    this.setState({
      loanOptionsValues: optionsValues,
      form,
      disableLoanOptions: true,
    });
  };

  onLoadOptionsFails = () => {
    this.setState({
      message: 'Die verfügbaren Darlehensverwendungen können nicht aufgelistet werden',
      open: true,
      messageType: 'error',
    });
  };

  setLoanOptionsValues(options, data) {
    options.agio = parseFloat(data.agio);
    options.duration_max = data.duration_max;
    options.duration_min = data.duration_min;
    options.interest_rate = parseFloat(data.interest_rate);
    options.nominal_interest_rate = parseFloat(data.interest_rate);
    options.loan_amount_max = parseFloat(data.loan_amount_max);
    options.loan_amount_min = parseFloat(data.loan_amount_min);
    return options;
  }

  handleClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false });
  };

  handleSliderChange = (name) => (_event, value) => {
    if (name === 'duration') {
      this.validateLoanPaidOut();
    } else {
      this.validateDuration();
    }
    this.setState({ form: { ...this.state.form, [name]: value } }, () => this.updateCalculations());
  };

  inputUpdateData = (event) => {
    const { name, value } = event.target;
    if (value === undefined) {
      return;
    }
    this.setState({ form: { ...this.state.form, [name]: value } }, () => this.updateCalculations());
  };

  async loadLoanOptions(options) {
    try {
      const response = await request.get(ApiCaptiq.LOAN_OPTIONS_URL, { params: options });
      return response;
    } catch (err) {
      console.error(err);
    }
  }

  loadLoanParams({ loan_type, profession, loan_usage, loan_product }) {
    this.loadLoanOptions({
      loan_type,
      profession,
      loan_usage,
      loan_product,
    })
      .then((response) => {
        if (this.props.updateLoanUsages) this.props.updateLoanUsages(response.data.loan_usages);
        const { loanOptionsValues } = this.state;
        const optionsValues = this.setLoanOptionsValues(loanOptionsValues, response.data.data[0]);

        this.setState(
          {
            loanOptionsValues: optionsValues,
            form: { ...this.state.form, loan_options: response.data.data[0].id },
          },
          () => {
            this.updateConstraints();
          },
        );
      })
      .catch(this.onLoadOptionsFails);
  }

  normalizeValue = (value, min, max) => {
    if (!value) return min;
    let val = parseFloat(value);
    if (min) val = val < min ? min : val;
    if (max) val = val > max ? max : val;
    return val;
  };

  async saveStepData() {
    try {
      const response = await request.patch(
        `${ApiCaptiq.LOAN_APPLICATION_URL}${this.props.data.id}/`,
        this.state.form,
      );
      if (this.props.onDataUpdated) this.props.onDataUpdated(this.state.form);
      if (this.props.initFormSteps) this.props.initFormSteps(response.data);
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  updateCalculations = () => {
    const { duration, interest_rate, agio, nominal_interest_rate, loan_paid_out } = this.state.form;
    const round = (x) => parseFloat(x.toFixed(2));
    const loan_amount = loan_paid_out * (1 + parseFloat(agio) / 100);
    // FORMULA (https://github.com/numpy/numpy-financial)
    //
    // rate: Rate of interest (per period)
    // nper: Number of compounding periods
    // pv : Present value
    //
    // The payment is computed by solving the equation::
    // pv*(1 + rate)**nper +
    // pmt/rate*((1 + rate)**nper - 1) == 0
    const rate = interest_rate / 100 / 12;
    const nper = duration;
    const pv = -loan_amount;
    const monthly_rate = (-pv * rate * (1 + rate) ** nper) / ((1 + rate) ** nper - 1);

    const rate_aux = nominal_interest_rate / 100 / 12;
    const rate_amount = (-pv * rate_aux * (1 + rate_aux) ** nper) / ((1 + rate_aux) ** nper - 1);

    const agio_amount = (loan_paid_out * parseFloat(agio)) / 100;
    this.setState({
      form: {
        ...this.state.form,
        monthly_rate: round(monthly_rate),
        rate_amount: round(rate_amount),
        loan_amount: round(loan_amount),
        agio_amount: round(agio_amount),
      },
    });
  };

  updateConstraints() {
    const { loanOptionsValues } = this.state;
    this.setState(
      {
        form: {
          ...this.state.form,
          agio: loanOptionsValues.agio,
          interest_rate: loanOptionsValues.interest_rate,
          duration: this.state.form.duration || loanOptionsValues.duration_min,
          nominal_interest_rate: loanOptionsValues.interest_rate,
          loan_paid_out: loanOptionsValues.loan_amount_min,
        },
      },
      () => {
        this.updateCalculations();
      },
    );
  }

  updateOptions = (options) => {
    this.setState({ form: { ...this.state.form, ...options } });

    if (options.loan_product) {
      this.loadLoanParams(options);
    }
  };

  updateRealData = (name, value) => {
    this.setState({ form: { ...this.state.form, [name]: value } });
    this.updateCalculations();
  };

  validateData = (name, value) => {
    const { loanOptionsValues } = this.state;
    const min =
      name === 'loan_paid_out' ? loanOptionsValues.loan_amount_min : loanOptionsValues.duration_min;
    const max =
      name === 'loan_paid_out' ? loanOptionsValues.loan_amount_max : loanOptionsValues.duration_max;
    value = this.normalizeValue(value, min, max);
    if (name === 'loan_paid_out') {
      value = Math.round(value / 100) * 100;
    }
    if (name === 'duration') {
      value = Math.round(value);
    }
    this.setState(
      { form: { ...this.state.form, [name]: value }, [name]: value },
      this.updateCalculations,
    );
  };

  validateLoanPaidOut = () => {
    const { loan_paid_out } = this.state.form;
    this.validateData('loan_paid_out', loan_paid_out);
  };

  validateDuration = () => {
    const { duration } = this.state.form;
    const { setLoanApplicationDuration } = this.props.dataContext;
    setLoanApplicationDuration(duration);
    this.validateData('duration', duration);
  };

  render() {
    const { classes, disabledAll } = this.props;
    const { form, disableLoanOptions, errors } = this.state;
    const { sliderColor } = this.context;

    return (
      <>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={this.state.open}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <div>
            <SnackContent
              onClose={this.handleClose}
              variant={this.state.messageType}
              message={this.state.message}
            />
          </div>
        </Snackbar>

        <Grid container spacing={4}>
          <Grid item md={4} xs={12}>
            <LoanOptions
              form={form}
              disabled={disableLoanOptions}
              errors={errors}
              onChange={this.updateOptions}
            />
          </Grid>
          <Grid item md={8} xs={12}>
            <Typography className={classes.titleText}>
              Wählen Sie nun bitte die Höhe des Darlehens und die Anzahl der monatlichen Raten.
            </Typography>
            <div className={classes.resultContainer}>
              <Grid container spacing={4}>
                <Grid item md={3} xs={12}>
                  <NumericField
                    size="small"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    disabled={!this.state.form.loan_product || disabledAll}
                    id="loan-paid-out"
                    name="loan_paid_out"
                    label={
                      <div className={classes.loanAmountLabel}>
                        Wunschdarlehen (Auszahlungsbetrag)
                      </div>
                    }
                    value={this.state.form.loan_paid_out}
                    error={!!this.state.errors.loan_paid_out}
                    helperText={
                      this.state.errors.loan_paid_out ? this.state.errors.loan_paid_out[0] : ''
                    }
                    InputProps={{
                      endAdornment: <InputAdornment position="end">€</InputAdornment>,
                    }}
                    numberFormatProps={{
                      decimalScale: 2,
                      thousandSeparator: '.',
                      decimalSeparator: ',',
                      suffix: '',
                    }}
                    className={classes.numberInput}
                    onChange={this.inputUpdateData}
                    onBlur={this.validateLoanPaidOut}
                  />
                </Grid>
                <Grid item md={9} xs={12}>
                  <Slider
                    disabled={!this.state.form.loan_product || disabledAll}
                    classes={{ root: classes.slider }}
                    style={{ color: sliderColor || null }}
                    value={this.normalizeValue(
                      this.state.form.loan_paid_out,
                      this.state.loanOptionsValues.loan_amount_min,
                      this.state.loanOptionsValues.loan_amount_max,
                    )}
                    min={this.state.loanOptionsValues.loan_amount_min}
                    max={this.state.loanOptionsValues.loan_amount_max}
                    step={100}
                    onChange={this.handleSliderChange('loan_paid_out')}
                    onChangeCommitted={this.validateLoanPaidOut}
                    size="small"
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <InputNumber
                    size="small"
                    disabled={!this.state.form.loan_product || disabledAll}
                    id="duration"
                    name="duration"
                    label="Anzahl der Raten"
                    value={this.state.form.duration}
                    error={!!this.state.errors.duration}
                    className={classes.numberInput}
                    helperText={this.state.errors.duration ? this.state.errors.duration[0] : ''}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">Monate</InputAdornment>,
                    }}
                    onChange={this.inputUpdateData}
                    onBlur={this.validateDuration}
                  />
                </Grid>
                <Grid item md={9} xs={12}>
                  <Slider
                    disabled={!this.state.form.loan_product || disabledAll}
                    classes={{ root: classes.slider }}
                    style={{ color: sliderColor || null }}
                    value={this.normalizeValue(
                      this.state.form.duration,
                      this.state.loanOptionsValues.duration_min,
                      this.state.loanOptionsValues.duration_max,
                    )}
                    min={this.state.loanOptionsValues.duration_min}
                    max={this.state.loanOptionsValues.duration_max}
                    step={1}
                    onChange={this.handleSliderChange('duration')}
                    onChangeCommitted={this.validateDuration}
                    size="small"
                  />
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
              <LoanResume
                classes={classes}
                loanAmount={this.state.form.loan_paid_out}
                monthlyRate={this.state.form.monthly_rate}
                interestRate={this.state.form.interest_rate}
                agio={this.state.form.agio}
              />
              <Divider className={classes.divider} />
              <div className={classes.paragraph}>
                <Typography variant="caption" className={classes.caption}>
                  <sup>1</sup> Die Vermittlungsgebühr beträgt pauschal 3% und wird dem
                  Auszahlungsbetrag hinzugerechnet. Auszahlungsbetrag plus Vermittlungsgebühr
                  ergeben den Darlehensbetrag.
                </Typography>
              </div>
              <Typography variant="caption" className={classes.caption}>
                <sup>2</sup>Die unverbindlichen monatlichen Raten dienen lediglich als Indikation,
                basierend auf einer Durchschnittsverzinsung. Die endgültige monatliche Rate teilen
                wir Ihnen i.d.R. innerhalb von 1 Werktag nach der Beantragung des Darlehens mit. Die
                vorstehend angegebenen Konditionen sind unverbindlich und stehen unter dem Vorbehalt
                des Ergebnisses der Bonitätsprüfung. Für den verbindlichen Zinssatz (und
                entsprechend der sich daraus ergebenden monatlichen Rate) ist insbesondere das
                positive Ergebnis der Bonitätsprüfung maßgeblich. Die verbindlichen Konditionen
                werden im Darlehensvertrag vereinbart.
              </Typography>
            </div>
          </Grid>
        </Grid>
        {this.state.errors.loan_options ? (
          <Typography className={classes.textError}>
            <InfoOutlined style={{ marginRight: 10 }} /> {this.state.errors.loan_options}
          </Typography>
        ) : null}
      </>
    );
  }
}

FirstStep.contextType = AuthContext;

FirstStep.propTypes = {
  data: PropTypes.object,
  onDataUpdated: PropTypes.func,
  classes: PropTypes.object,
  errors: PropTypes.object,
  disabledAll: PropTypes.bool,
  toggleNavigation: PropTypes.func,
  initFormSteps: PropTypes.func,
};

const FirstStepWrapper = memo(
  forwardRef((props, ref) => {
    const dataContext = useDataContext();

    return <FirstStep {...props} dataContext={dataContext} ref={ref} />;
  }),
);

FirstStepWrapper.displayName = 'FirstStepWrapper';

export default withStyles(styles)(FirstStepWrapper);
