import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    height: 200,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #cacaca',
    borderRadius: 5,
    position: 'relative',
  },
  previewContainer: {
    padding: 10,
    height: '100%',
    width: '100%',
    overflow: 'scroll',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
  },
  dialogContainer: {
    padding: '0 !important',
    display: 'flex',
    background: '#ececec',
  },
  dialogActions: {
    background: 'white',
    borderTop: '1px solid rgb(204, 204, 204)',
  },
  listContainer: {
    width: '25%',
    marginTop: 50,
    padding: 30,
    overflowY: 'auto',
    '& div': {
      fontWeight: 'bold',
      color: '#616161',
      cursor: 'pointer',
    },
  },
  richTextContainer: {
    width: '75%',
    marginTop: 40,
    transition: 'all 0.5s',
    overflowY: 'auto',
    '& .tox-tinymce': {
      position: 'unset',
      margin: 30,
    },
  },
  focusRichTextContainter: {
    '& .tox-tinymce': {
      border: `3px solid ${theme.palette.primary.main}`,
    },
  },
  focusRichTextContainterFullscreen: {
    '& .tox-tinymce': {
      border: `3px solid ${theme.palette.primary.main}`,
      height: '100% !important',
      width: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 999999999999,
      margin: 0,
    },
  },
  showHeader: {
    '& .tox-tinymce .tox-editor-container .tox-editor-header': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
    },
    "& button[title='Close fullscreen']": {
      display: 'none',
    },
  },
  showHeaderFullscreen: {
    '& .tox-tinymce .tox-editor-container .tox-editor-header': {
      position: 'unset',
      width: '100%',
    },
    "& button[title='Go fullscreen']": {
      display: 'none',
    },
  },
  hideHeader: {
    '& .tox-tinymce .tox-editor-container .tox-editor-header': {
      display: 'none',
    },
  },
  tabBlock: {
    color: `${theme.palette.primary.main} !important`,
  },
  buttonClass: {
    width: '15%',
  },
  questionName: {
    width: '85%',
  },
  questionNameContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
  },
  closeButton: {
    background: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      borderColor: theme.palette.error.dark,
      boxShadow: 'none',
    },
  },
  loaderContainer: {
    position: 'absolute',
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9,
    background: 'rgba(0,0,0,0.07)',
  },
  fieldsContainer: {
    marginLeft: 20,
  },
  snackbar: {
    bottom: 70,
    right: 10,
    position: 'absolute',
  },
  alert: {
    background: `${theme.palette.primary.main} !important`,
  },
  infoIcon: {
    marginRight: 10,
    color: theme.palette.primary.main,
  },
  changesTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  contentDialogChanges: {
    color: '#4e4f52',
  },
  dialogActionsChanges: {
    justifyContent: 'center',
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  closeButtonDialogChange: {
    position: 'absolute',
    right: 5,
    top: 5,
    padding: 0,
    cursor: 'pointer',
  },
  closeIconButtonDialogChange: {
    fontSize: 30,
  },
  blockTitle: {
    textTransform: 'uppercase',
    cursor: 'inherit !important',
    width: '90%',
  },
  blockContent: {
    marginLeft: 15,
    marginTop: 20,
  },
  inputImageContent: {
    display: 'flex',
    alignItems: 'center',
  },
  iconButtonDeleteInputImage: {
    marginLeft: 10,
  },
  containerPreviewCheck: {
    borderColor: theme.palette.primary.main,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  contentContainer: {
    width: '100%',
  },
  checkIcon: {
    width: 20,
  },
  blockContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  exampleTextButton: {
    padding: 5,
  },
  titleContainerExampleModal: {
    '& h2': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  titleExampleModal: {
    textAlign: 'left',
    fontSize: '1.3rem',
    marginLeft: 20,
    marginRight: 20,
    display: 'flex',
    width: '70%',
  },
  dividerExampleModal: {
    height: 2,
    backgroundColor: `${theme.palette.primary.main}`,
  },
  librayIconExampleModal: {
    width: 70,
    height: 70,
  },
  addExampleModal: {
    width: 240,
  },
  contentExampleModal: {
    padding: '20px 30px',
  },
  dialogExampleModal: {
    maxHeight: 'calc(100% - 120px)',
  },
  paperContainer: {
    minHeight: 'calc(100% - 64px)',
  },
  warningExampleAction: {
    fontSize: 14,
  },
  labelButton: {
    lineHeight: 1.4,
  },
}));

export default useStyles;
