import { useCallback, memo, useMemo, useState, useEffect } from 'react';
import { FastField, useFormikContext, FormikValues } from 'formik';
import _get from 'lodash.get';

import { Autocomplete } from 'formik-mui';
import { Typography, TextField, TextFieldProps } from '@mui/material';

import { shouldFastFieldUpdate } from 'utils/utils';
import { CountryType } from 'utils/commonTypes/types';
import { useCustomerData } from 'context/customer-context';
import Adornment from '../Adornment';
import useStyles from './styles';

export type TypeOptions = 'internationalPrefix' | 'nationality' | 'country';

interface Props {
  name: string;
  type: TypeOptions;
  textFieldProps?: TextFieldProps;
  [propName: string]: any;
}

interface CustomAutocompleteProps {
  field: {
    value: number | string | boolean;
    name: string;
  };
}

const CustomAutocomplete = memo((props: CustomAutocompleteProps) => {
  const classes = useStyles();
  return (
    <Autocomplete {...(props as any)} className={props.field.value ? classes.completeField : ''} />
  );
});

const getOptionLabel = {
  internationalPrefix: (option: CountryType) => `${option.country} (+${option.prefix})`,
  nationality: (option: CountryType) => option.countryNationality || option.nationality,
  country: (option: CountryType) => option.country,
};

const getOptionRender = {
  internationalPrefix: (props: any, option: CountryType) => (
    <Typography data-name={option.prefix} {...props}>
      {option.country} +{option.prefix}
    </Typography>
  ),
  nationality: (props: any, option: CountryType) => (
    <Typography data-name={option.nationality} {...props}>
      {option.countryNationality}
    </Typography>
  ),
  country: (props: any, option: CountryType) => (
    <Typography data-name={option.country} {...props}>
      {option.country}
    </Typography>
  ),
};

const CountrySelector = ({ name, type, textFieldProps = undefined, ...props }: Props) => {
  const classes = useStyles();
  const { touched, errors, values } = useFormikContext<FormikValues>();
  const { countryList } = useCustomerData();
  const [sortedCountryList, setSortedCountryList] = useState<CountryType[]>(countryList);
  const value = useMemo(() => _get(values, name), [values, name]);

  useEffect(() => {
    if (type === 'nationality') {
      setSortedCountryList(
        countryList
          .slice()
          .sort((a: CountryType, b: CountryType) => a.nationality.localeCompare(b.nationality)),
      );
    } else {
      setSortedCountryList(
        countryList
          .slice()
          .sort((a: CountryType, b: CountryType) => a.country.localeCompare(b.country)),
      );
    }
  }, [countryList, type]);

  const handleGetOptionLabel = useCallback(
    (option: any) => (option ? getOptionLabel[type](option) : ''),
    [type],
  );

  const handleGetOptionSelected = useCallback(
    (option: CountryType, paramValue: CountryType) =>
      option && paramValue ? option.code === paramValue.code : null,
    [],
  );

  const handleOnOpenCountryList = useCallback(() => {
    const getOptionScroller = {
      internationalPrefix: '49',
      nationality: 'Deutsch',
      country: 'Deutschland',
    };
    setTimeout(() => {
      const optionEl = document.querySelector(`[data-name="${getOptionScroller[type]}"]`);
      optionEl?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }, 1);
  }, [type]);

  const handleGetOptionRender = useCallback(
    (renderProps: any, option: any) => getOptionRender[type](renderProps, option),
    [type],
  );

  const handleRenderInput = useCallback(
    (params: any) => {
      const { InputProps } = params;
      const prevEndAdornment = InputProps.endAdornment;
      InputProps.endAdornment = (
        <>
          {value && <Adornment classes={classes} />}
          {prevEndAdornment}
        </>
      );

      return (
        <TextField
          {...params}
          {...textFieldProps}
          error={touched[name] && !!errors[name]}
          helperText={errors[name]}
          variant="outlined"
          InputProps={InputProps}
        />
      );
    },
    [errors, touched, name, textFieldProps, value, classes],
  );

  return (
    <FastField
      name={name}
      onOpen={handleOnOpenCountryList}
      component={CustomAutocomplete}
      options={sortedCountryList}
      getOptionLabel={handleGetOptionLabel}
      getOptionSelected={handleGetOptionSelected}
      disableClearable
      renderOption={handleGetOptionRender}
      renderInput={handleRenderInput}
      size="small"
      shouldUpdate={shouldFastFieldUpdate}
      {...props}
    />
  );
};

export default memo(CountrySelector);
