import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';

export default makeStyles((theme: Theme) => ({
  titleYear: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
  },
  alertHGB: {
    marginBottom: 0,
  },
  questionTitle: {
    fontWeight: 500,
    color: '#000',
    marginBottom: 10,
    fontSize: '1.1667rem',
  },
  questionSubTitle: {
    fontWeight: 400,
    color: '#000',
    marginTop: -10,
    marginBottom: 10,
    fontSize: '1rem',
  },
  selectLabel: {
    '&$shrink': {
      display: 'none',
    },
  },
  titleSub: {
    fontWeight: 500,
    color: '#000',
    marginBottom: 15,
    marginTop: 15,
  },
  helperText: {
    fontSize: '1rem',
    color: '#4F5052',
    fontWeight: 200,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
  linkUnderline: {
    color: '#17889B !important',
    textDecoration: 'underline !important',
  },
  table: {
    minWidth: 650,
    '& .MuiTableCell-root': {
      border: '1px solid #e3e3e3',
    },
  },
}));
