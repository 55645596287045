import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { FormikValues, useFormikContext } from 'formik';
import _debounce from 'lodash/debounce';

import { Grid } from '@mui/material';

import { useBlockData } from 'context/block-context';
import { useDataContext } from 'context/data-context';
import DocumentYearForm from '../DocumentYearForm';
import { CURRENT_YEAR } from '../../utils';

const DocumentForm = memo(
  ({
    dataStructure,
    customColors,
    extraInformation,
    blocks,
    isReview,
    isEditing,
  }: {
    dataStructure: any;
    customColors: any;
    extraInformation: any;
    blocks: any[];
    isReview?: boolean;
    isEditing?: boolean;
  }) => {
    const { setStaticFormikValues } = useBlockData();
    const { loanApplication } = useDataContext();
    const currentYear = CURRENT_YEAR[loanApplication?.hgb_version!];
    const { values } = useFormikContext<FormikValues>();

    const canShowNextBlock = (blockId: number) =>
      values[dataStructure[`b${blockId}_field_f1`]?.name] !== 'ja' &&
      values[dataStructure[`b${blockId}_field_f1`]?.name] !== '';
    const canShowNextYear = useCallback(
      (year: number, blockId: number) => Number(blocks[blockId]?.year) >= year,
      [blocks],
    );

    const setStaticValues = useMemo(
      () =>
        _debounce((val) => {
          setStaticFormikValues(val);
        }, 400),
      [setStaticFormikValues],
    );

    useEffect(() => {
      setStaticValues(values);
      return () => {
        setStaticFormikValues({});
      };
    }, [setStaticFormikValues, setStaticValues, values]);

    return (
      <Grid container spacing={4}>
        <DocumentYearForm
          year={currentYear}
          blockId={1}
          formStructure={dataStructure}
          customColors={customColors}
          extraInformation={extraInformation}
          isReview={isReview}
          isEditing={isEditing}
          show
        />
        <DocumentYearForm
          year={currentYear - 1}
          blockId={2}
          formStructure={dataStructure}
          customColors={customColors}
          extraInformation={extraInformation}
          isReview={isReview}
          isEditing={isEditing}
          show={canShowNextYear(extraInformation.foundation, 1) && canShowNextBlock(1)}
        />
        <DocumentYearForm
          year={currentYear - 2}
          blockId={3}
          formStructure={dataStructure}
          customColors={customColors}
          extraInformation={extraInformation}
          isReview={isReview}
          isEditing={isEditing}
          show={
            canShowNextYear(extraInformation.foundation, 2) &&
            canShowNextBlock(1) &&
            canShowNextBlock(2)
          }
        />
        <DocumentYearForm
          year={currentYear - 3}
          blockId={4}
          formStructure={dataStructure}
          customColors={customColors}
          extraInformation={extraInformation}
          isReview={isReview}
          isEditing={isEditing}
          show={
            canShowNextYear(extraInformation.foundation, 3) &&
            canShowNextBlock(1) &&
            canShowNextBlock(2) &&
            canShowNextBlock(3)
          }
        />
      </Grid>
    );
  },
);

DocumentForm.displayName = 'DocumentForm';

export default DocumentForm;
