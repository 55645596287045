import React, { memo, useCallback, useMemo, useState } from 'react';
import { useFormikContext, Field, FormikErrors } from 'formik';
import _get from 'lodash.get';

import { Grid, Typography, FormHelperText, InputAdornment } from '@mui/material';

import { YesOrNo, Level, FastFieldProps } from 'utils/commonTypes/types';
import LabeledGrid from 'components/LabeledGrid';
import PercentageField from 'components/PercentageField';
import { LANGUAGE } from 'constants/language';
import { RefBeneficialOwner, Share, Person, InitialValues } from 'context/customer-context/types';
import {
  getLocaleAllocation,
  addRoleRefs,
  deleteRoleRefs,
  noSideDealsDisplayLabel,
  handleAllocationChangeFunction,
  CLOCK_STATUS,
  getCurrentIconFunction,
  getTotalShareValueWithParent,
} from 'utils/utils';
import { useCustomerData } from 'context/customer-context';
import { getSalutationLabel, SALUTATIONS } from 'constants/salutations';
import { getTitleLabel, TITLES } from 'constants/titles';
import {
  EKLegalFormLabel,
  FreelanceLegalFormLabel,
  GbRLegalForm,
  PartGLegalForm,
  PartGmbBLegalForm,
} from 'constants/legalForms';
import WarningMessage from 'components/WarningMessage';
import { useLazyEffect } from 'hooks/useLazyEffect';
import { useInterval, validateSharesFunction } from 'utils/dataHandlers';
import { ONGOING_TEMPLATE_TAG } from 'constants/templates';
import { useDataContext } from 'context/data-context';
import CustomField from '../CustomField';
import { validateLegalRepresentativeFunction } from './validator';

interface CommonProps {
  refBeneficialOwner: RefBeneficialOwner;
  belongsTo?: string;
  parentCompanyId: number;
  parentCompanyName: string;
  level: Level;
  isEditing?: boolean;
}

export interface PersonFormProps extends CommonProps {
  formPersonShares: Share[];
  isEkOrFreelancer: boolean;
}

export const PersonForm = memo(
  ({
    refBeneficialOwner,
    belongsTo = '',
    parentCompanyName,
    parentCompanyId,
    formPersonShares,
    level,
    isEditing = true,
    isEkOrFreelancer,
  }: PersonFormProps) => {
    const { setFieldValue, handleChange, validateField, values, handleBlur, errors } =
      useFormikContext<InitialValues>();
    const {
      currentLegalForm,
      refsLegalRepresentatives,
      refsLoanApplicants,
      refsOwners,
      setRefsOwners,
      setRefsLegalRepresentatives,
      setRefsLoanApplicants,
      legalForms,
      customerProfileId,
      customerProfileData,
    } = useCustomerData();
    const { loanApplicationUsage } = useDataContext();
    const [loadingAllocation, setLoadingAllocation] = useState(false);
    const [allocationStatus, setAllocationStatus] = useState<string | null>(null);
    const [timerCount, setTimerCount] = useState(0);
    const [showCheck, setShowCheck] = useState(CLOCK_STATUS.STOPPED);

    useInterval(
      () => {
        if (timerCount > 0) {
          setTimerCount(timerCount - 1);
        } else {
          setShowCheck(CLOCK_STATUS.STOPPED);
        }
      },
      showCheck === CLOCK_STATUS.STARTED ? 2000 : null,
    );

    const sharesIndex = useMemo(
      () => formPersonShares?.findIndex((v) => v.parent === parentCompanyId),
      [formPersonShares, parentCompanyId],
    );

    const getCurrentIcon = useCallback(
      () => getCurrentIconFunction(loadingAllocation, allocationStatus),
      [loadingAllocation, allocationStatus],
    );

    const share = formPersonShares[sharesIndex];

    const hasMajorityShares = useMemo(
      () =>
        share?.allocation &&
        getLocaleAllocation(share.allocation, LANGUAGE) > 50 &&
        Number(customerProfileData?.profileVersion.at(-1)) > 2 &&
        loanApplicationUsage === ONGOING_TEMPLATE_TAG,
      [customerProfileData?.profileVersion, loanApplicationUsage, share?.allocation],
    );

    const handleChangeHasOtherCompanies = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (!value) return;
        setFieldValue(`people[${refBeneficialOwner.index}].hasOtherCompanies`, value);
      },
      [setFieldValue, refBeneficialOwner.index],
    );

    useLazyEffect(() => {
      if (
        values.people &&
        values.people[refBeneficialOwner.index] &&
        values.people[refBeneficialOwner.index]?.shares[sharesIndex]?.allocation
      ) {
        validateField(`people[${refBeneficialOwner.index}].shares[${sharesIndex}].allocation`);
      }
      if (
        values.people[refBeneficialOwner.index] &&
        values.people[refBeneficialOwner.index].shares[sharesIndex]
      ) {
        validateField(`people[${refBeneficialOwner.index}].shares[${sharesIndex}].allocation`);
      }
      if (level === 1 && values.people[refBeneficialOwner.index]) {
        validateField(`people[${refBeneficialOwner.index}].isLegalRepresentative`);
        validateField(`people[${refBeneficialOwner.index}].isLoanApplicant`);
      }
    }, [
      refBeneficialOwner,
      sharesIndex,
      validateField,
      share?.sideDeal,
      share?.allocation,
      values.people,
      level,
    ]);

    const handleChangeIsLegalRepresentative = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (!value) return;
        handleChange(event);
        if (value === 'yes') {
          addRoleRefs(
            'LR',
            refBeneficialOwner.index,
            values.people,
            setRefsLegalRepresentatives,
            refsLegalRepresentatives,
          );
        } else {
          const indexLR = [...refsLegalRepresentatives].findIndex(
            (legalRepresentative) => legalRepresentative.id === refBeneficialOwner.id,
          );
          deleteRoleRefs('LR', indexLR, refsLegalRepresentatives, setRefsLegalRepresentatives);
          if (values.people[refBeneficialOwner.index].isLoanApplicant === 'yes') {
            const indexLA = [...refsLoanApplicants].findIndex(
              (loanApplicant) => loanApplicant.id === refBeneficialOwner.id,
            );
            setFieldValue(`people[${refBeneficialOwner.index}].isLoanApplicant`, 'no');
            deleteRoleRefs('LA', indexLA, refsLoanApplicants, setRefsLoanApplicants);
          }
        }
        setFieldValue(`people[${refBeneficialOwner.index}].isLoanApplicant`, 'no', false);
        setTimeout(() => {
          validateField(`people[${refBeneficialOwner.index}].isLegalRepresentative`);
          validateField(`people[${refBeneficialOwner.index}].isLoanApplicant`);
        }, 0);
      },
      [
        handleChange,
        refBeneficialOwner.index,
        setFieldValue,
        refsLegalRepresentatives,
        setRefsLegalRepresentatives,
        refBeneficialOwner.id,
        refsLoanApplicants,
        setRefsLoanApplicants,
        values.people,
        validateField,
      ],
    );

    const validateShareFunctionReusable = useCallback(
      (
        allocation: string,
        sideDealDirect?: YesOrNo,
        isBeneficialOwnerLevelOneDirect?: YesOrNo,
        levelDirect?: Level,
        isOwnerDirect?: boolean,
        totalShares?: number,
      ) =>
        validateSharesFunction(
          allocation,
          values.people[refBeneficialOwner.index].shares[sharesIndex].sideDeal,
          values.company.data,
          values.people[refBeneficialOwner.index],
          currentLegalForm,
          legalForms,
          sideDealDirect,
          isBeneficialOwnerLevelOneDirect,
          levelDirect,
          isOwnerDirect,
          true,
          totalShares,
        ),
      [
        values.people,
        values.company.data,
        refBeneficialOwner.index,
        sharesIndex,
        currentLegalForm,
        legalForms,
      ],
    );

    const handleAllocationChange = useCallback(
      async (allocation: string, sideDeal: YesOrNo | undefined, levelDirect: Level) =>
        handleAllocationChangeFunction(
          customerProfileId,
          allocation,
          sideDeal,
          showCheck,
          share?.id,
          'person',
          refBeneficialOwner.id,
          parentCompanyId,
          setLoadingAllocation,
          setShowCheck,
          setAllocationStatus,
          levelDirect,
        ),
      [share, parentCompanyId, showCheck, refBeneficialOwner.id, customerProfileId],
    );

    const handleBlurAllocation = useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        let currentSideDeal: YesOrNo | undefined =
          values.people[refBeneficialOwner.index].shares[sharesIndex].sideDeal;
        let currentOW: YesOrNo | undefined = values.people[refBeneficialOwner.index].isOwner;
        const { value } = event.target;
        const currentAllocation = getLocaleAllocation(value, LANGUAGE);
        if (currentAllocation < 50.01) {
          setFieldValue(`people[${refBeneficialOwner.index}].hasOtherCompanies`, '');
        }
        const isOwner =
          currentAllocation > 0 && values.people[refBeneficialOwner.index].isOwner === 'no';
        const wasOwner =
          currentAllocation === 0 && values.people[refBeneficialOwner.index].isOwner === 'yes';
        if (currentAllocation >= 50 && level === 1) {
          setFieldValue(
            `people[${refBeneficialOwner.index}].shares[${sharesIndex}].sideDeal`,
            'no',
          );
          currentSideDeal = 'no';
        }
        if (isOwner) {
          addRoleRefs('OW', refBeneficialOwner.index, values.people, setRefsOwners, refsOwners);
          setFieldValue(`people[${refBeneficialOwner.index}].isOwner`, 'yes');
          currentOW = 'yes';
        }
        if (wasOwner) {
          const idx = [...refsOwners].findIndex((owner) => owner.id === refBeneficialOwner.id);
          deleteRoleRefs('OW', idx, refsOwners, setRefsOwners);
          setFieldValue(`people[${refBeneficialOwner.index}].isOwner`, 'no');
          currentOW = 'no';
        }
        handleBlur(event);
        setTimeout(() => {
          if (wasOwner || isOwner) validateField(`people[${refBeneficialOwner.index}].isOwner`);
        }, 0);
        handleBlur(event);
        setTimeout(() => {
          if (wasOwner || isOwner) validateField(`people[${refBeneficialOwner.index}].isOwner`);
          setTimeout(() => {
            const totalState = getTotalShareValueWithParent(
              parentCompanyId,
              values.people,
              values.companies,
            );
            if (
              !validateShareFunctionReusable(
                value,
                currentSideDeal,
                undefined,
                level,
                level === 1 && currentOW === 'yes',
                totalState,
              )
            ) {
              handleAllocationChange(value, currentSideDeal, level);
            }
          }, 0);
        }, 0);
      },
      [
        values.people,
        values.companies,
        parentCompanyId,
        refBeneficialOwner.index,
        refBeneficialOwner.id,
        level,
        handleBlur,
        setFieldValue,
        sharesIndex,
        setRefsOwners,
        refsOwners,
        validateField,
        handleAllocationChange,
        validateShareFunctionReusable,
      ],
    );

    const getBeneficialOwnerValue = useCallback(() => {
      switch (level) {
        case 1:
          return values.people[refBeneficialOwner.index].isBeneficialOwnerLevelOne;
        case 2:
          return values.people[refBeneficialOwner.index].isBeneficialOwnerLevelTwo;
        case 3:
          return values.people[refBeneficialOwner.index].isBeneficialOwnerLevelThree;
        default:
          return '';
      }
    }, [level, refBeneficialOwner.index, values.people]);

    const validateShares = useCallback(
      (allocation: string) => {
        if (share) {
          const totalState = getTotalShareValueWithParent(
            parentCompanyId,
            values.people,
            values.companies,
          );
          return validateShareFunctionReusable(
            allocation,
            undefined,
            getBeneficialOwnerValue(),
            level,
            level === 1 && values.people[refBeneficialOwner.index].isOwner === 'yes',
            totalState,
          );
        }
        return undefined;
      },
      [
        getBeneficialOwnerValue,
        level,
        values.people,
        refBeneficialOwner.index,
        values.companies,
        validateShareFunctionReusable,
        parentCompanyId,
        share,
      ],
    );

    const showNoOptionLRP = useCallback(() => {
      if (
        (currentLegalForm?.label === PartGLegalForm ||
          currentLegalForm?.label === PartGmbBLegalForm) &&
        values.people[refBeneficialOwner.index].isOwner === 'yes'
      )
        return false;
      if (
        currentLegalForm?.label === EKLegalFormLabel ||
        currentLegalForm?.label === FreelanceLegalFormLabel ||
        currentLegalForm?.label === GbRLegalForm
      )
        return false;
      return true;
    }, [currentLegalForm?.label, values.people, refBeneficialOwner.index]);

    const validateLegalRepresentative = useCallback(
      (isLegalRepresentative: YesOrNo) =>
        validateLegalRepresentativeFunction(isLegalRepresentative, currentLegalForm, level),
      [currentLegalForm, level],
    );

    const showLRPError = useCallback(() => {
      if (level === 1) {
        const poepleErrors = errors.people as FormikErrors<Person>[];
        const errorPerson = poepleErrors && poepleErrors[refBeneficialOwner.index];
        return (
          currentLegalForm &&
          (currentLegalForm.label === EKLegalFormLabel ||
            currentLegalForm.label === FreelanceLegalFormLabel) &&
          errorPerson &&
          errorPerson.isLegalRepresentative && (
            <FormHelperText error>
              Aufgrund der ausgewählten Rechtsform {values.company.data.legalForm} muss die
              angegebene Person gesetzlicher Vertreter.
            </FormHelperText>
          )
        );
      }
      return null;
    }, [errors.people, refBeneficialOwner.index, currentLegalForm, level, values.company.data]);

    const handleChangeSideDeals = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const { target } = e;
        setFieldValue(
          `companies[${refBeneficialOwner.index}].shares[${sharesIndex}].sideDeal`,
          target.value,
        );
        setTimeout(() => {
          validateField(`companies[${refBeneficialOwner.index}].shares[${sharesIndex}].sideDeal`);
          setTimeout(() => {
            const totalState = getTotalShareValueWithParent(
              parentCompanyId,
              values.people,
              values.companies,
            );
            if (
              !validateShareFunctionReusable(
                (values.people[refBeneficialOwner.index].shares[sharesIndex]
                  .allocation as string) || '',
                target.value as YesOrNo,
                undefined,
                level,
                level === 1 && values.people[refBeneficialOwner.index].isOwner === 'yes',
                totalState,
              )
            ) {
              handleAllocationChange(
                values.people[refBeneficialOwner.index].shares[sharesIndex].allocation as string,
                target.value as YesOrNo,
                level,
              );
            }
          }, 0);
        }, 0);
      },
      [
        setFieldValue,
        refBeneficialOwner.index,
        validateField,
        values.companies,
        values.people,
        parentCompanyId,
        sharesIndex,
        validateShareFunctionReusable,
        handleAllocationChange,
        level,
      ],
    );

    const getSideDealInput = useCallback(
      () => (
        <CustomField
          name={`people[${refBeneficialOwner.index}].shares[${sharesIndex}].sideDeal`}
          title="Kann das Unternehmen auf vergleichbare Weise Kontrolle ausüben?"
          tooltip="Sollte die Person/das Unternehmen keine oder zu geringe Anteile am Haupt-unternehmen
            halten, kann dieser/dieses ggf.\n durch Sonderabkommen auf vergleichbare Weise
            Kontrolle ausüben und gilt so als wirtschaftlich Berechtigte(r)."
          type="radiogroup"
          disabled={!isEditing || loadingAllocation}
          onChange={handleChangeSideDeals}
        />
      ),
      [refBeneficialOwner.index, sharesIndex, isEditing, loadingAllocation, handleChangeSideDeals],
    );

    const handleChangeOwner = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (!value) return;
        if (value === 'yes') {
          addRoleRefs('OW', refBeneficialOwner.index, values.people, setRefsOwners, refsOwners);
          setFieldValue(`people[${refBeneficialOwner.index}].isOwner`, 'yes');
        } else {
          const idx = [...refsOwners].findIndex((owner) => owner.id === refBeneficialOwner.id);
          deleteRoleRefs('OW', idx, refsOwners, setRefsOwners);
          setFieldValue(`people[${refBeneficialOwner.index}].isOwner`, 'no');
        }
        setTimeout(() => {
          validateField(`people[${refBeneficialOwner.index}].isOwner`);
        }, 0);
      },
      [
        setRefsOwners,
        refBeneficialOwner.id,
        refsOwners,
        refBeneficialOwner.index,
        validateField,
        setFieldValue,
        values.people,
      ],
    );

    const validateOwner = useCallback(
      (isOwner: YesOrNo) => {
        if (
          currentLegalForm &&
          (currentLegalForm.label === GbRLegalForm ||
            currentLegalForm.label === EKLegalFormLabel ||
            currentLegalForm.label === FreelanceLegalFormLabel) &&
          isOwner === 'no'
        )
          return 'Must be OW';
        return undefined;
      },
      [currentLegalForm],
    );

    const showOwnerError = useCallback(() => {
      const errorPerson =
        errors.people &&
        errors.people[refBeneficialOwner.index] &&
        (errors.people[refBeneficialOwner.index] as FormikErrors<Person>);
      return (
        currentLegalForm &&
        currentLegalForm.label === GbRLegalForm &&
        errorPerson &&
        errorPerson.isOwner && (
          <FormHelperText error>
            <>
              Due to the selected legal form{' '}
              {{
                legalForm: values.company.data.legalForm,
              }}
              , the specified person must be an owner.
            </>
          </FormHelperText>
        )
      );
    }, [errors.people, refBeneficialOwner.index, currentLegalForm, values.company.data]);

    const handleUpdateOW = useCallback(
      (nextProps: FastFieldProps, currentProps: FastFieldProps): boolean => {
        const newFormikValue = nextProps.formik.values;
        const currentFormikValue = currentProps.formik.values;
        const newAllocation = newFormikValue.people;
        const currentAllocation = currentFormikValue.people;
        if (
          getLocaleAllocation(
            newAllocation[refBeneficialOwner.index].shares[sharesIndex].allocation,
            LANGUAGE,
          ) !==
          getLocaleAllocation(
            currentAllocation[refBeneficialOwner.index].shares[sharesIndex].allocation,
            LANGUAGE,
          )
        )
          return true;

        return false;
      },
      [refBeneficialOwner.index, sharesIndex],
    );

    const handleUpdateLRP = useCallback(
      (nextProps: FastFieldProps, currentProps: FastFieldProps): boolean => {
        const newFormikValue = nextProps.formik.values;
        const currentFormikValue = currentProps.formik.values;
        const newAllocation = newFormikValue.people;
        const currentAllocation = currentFormikValue.people;
        if (
          newAllocation[refBeneficialOwner.index].isOwner !==
          currentAllocation[refBeneficialOwner.index].isOwner
        )
          return true;
        return false;
      },
      [refBeneficialOwner.index],
    );

    if (sharesIndex === -1) {
      return null;
    }

    return (
      <Grid container item spacing={2}>
        <LabeledGrid label="Wirtschaftlich Berechtigter-ID">
          <Typography>{refBeneficialOwner.beneficialOwnerId}</Typography>
        </LabeledGrid>
        {!!belongsTo && (
          <LabeledGrid label="Zugehörig zu">
            <Typography>{belongsTo}</Typography>
          </LabeledGrid>
        )}
        <CustomField
          data={SALUTATIONS}
          getLabel={getSalutationLabel}
          label="Bitte wählen"
          name={`people[${refBeneficialOwner.index}].salutation`}
          title="Anrede"
          type="select"
          disabled={!isEditing}
          required
        />
        <CustomField
          data={TITLES}
          getLabel={getTitleLabel}
          label="Bitte wählen"
          name={`people[${refBeneficialOwner.index}].title`}
          title="Titel"
          type="select"
          disabled={!isEditing}
        />
        <CustomField
          name={`people[${refBeneficialOwner.index}].firstNames`}
          title="Vorname"
          type="text"
          disabled={!isEditing}
          required
        />
        <CustomField
          name={`people[${refBeneficialOwner.index}].lastName`}
          title="Nachname"
          type="text"
          disabled={!isEditing}
          required
        />
        <CustomField
          name={`people[${refBeneficialOwner.index}].dateOfBirth`}
          title="Geburtsdatum"
          type="date"
          disabled={!isEditing}
          maxDate={new Date()}
          required
        />
        <CustomField
          title="Geburtsland"
          name={`people[${refBeneficialOwner.index}].countryOfBirth`}
          textFieldProps={{ label: 'Bitte wählen' }}
          type="place"
          placetype="country"
          disabled={!isEditing}
          required
        />
        <CustomField
          title="Geburtsort"
          name={`people[${refBeneficialOwner.index}].cityOfBirth`}
          type="text"
          disabled={!isEditing}
          required
        />
        <CustomField
          title="Staatsangehörigkeit"
          name={`people[${refBeneficialOwner.index}].nationality`}
          textFieldProps={{ label: 'Bitte wählen' }}
          type="place"
          placetype="nationality"
          disabled={!isEditing}
          required
        />
        {_get(values, `people[${refBeneficialOwner.index}].taxableInUSA`) === 'yes' && (
          <WarningMessage
            message="Aufgrund Ihrer Angaben zur Steuerpflicht in den USA kann CAPTIQ Ihnen leider keine Finanzierung anbieten. Das Anlegen eines Kundenprofils ist zum Verwaltungszweck jedoch weiterhin möglich."
            severity="error"
          />
        )}
        <CustomField
          name={`people[${refBeneficialOwner.index}].taxableInUSA`}
          title="Steuerpflichtig in den USA?"
          type="radiogroup"
          disabled={!isEditing}
          required
        />
        {showLRPError()}
        <LabeledGrid
          label={`Anteil an ${parentCompanyName} (in %)`}
          tooltip="Sollten Sie keine Anteile besitzen, geben Sie bitte 0,00% an. Mit 0,00%. an. Anteilen kann nicht die Rolle Inhaber gewählt werden"
          required
        >
          {/* Note: this needs to be a Field because its validations depends on the sideDeal field */}
          <Field
            disabled={!isEditing || isEkOrFreelancer || loadingAllocation}
            checkTouched={false}
            validate={validateShares}
            component={PercentageField}
            onBlur={handleBlurAllocation}
            name={`people[${refBeneficialOwner.index}].shares[${sharesIndex}].allocation`}
            variant="outlined"
            size="small"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <span>%</span>
                  {getCurrentIcon()}
                </InputAdornment>
              ),
            }}
          />
        </LabeledGrid>
        {level !== 1 ? (
          getSideDealInput()
        ) : (
          <>
            {values.people[refBeneficialOwner.index]?.shares[sharesIndex]?.allocation &&
              getLocaleAllocation(
                values.people[refBeneficialOwner.index].shares[sharesIndex].allocation,
                LANGUAGE,
              ) <= 50 &&
              !noSideDealsDisplayLabel(currentLegalForm?.label) &&
              getSideDealInput()}
          </>
        )}
        {level === 1 && (
          <>
            <CustomField
              name={`people[${refBeneficialOwner.index}].isOwner`}
              onChange={handleChangeOwner}
              validate={validateOwner}
              title="Inhaber(in)"
              tooltip="Als Inhaber gelten alle Teilhaber am Unternehmen."
              type="radiogroup"
              disabled={!isEditing || loadingAllocation}
              radioShowYes={values.people[refBeneficialOwner.index].isOwner === 'yes'}
              radioShowNo={
                currentLegalForm?.label !== EKLegalFormLabel &&
                currentLegalForm?.label !== FreelanceLegalFormLabel &&
                currentLegalForm?.label !== GbRLegalForm &&
                getLocaleAllocation(
                  values.people[refBeneficialOwner.index].shares[sharesIndex].allocation,
                  LANGUAGE,
                ) === 0 &&
                values.people[refBeneficialOwner.index].isOwner === 'no'
              }
              customUpdateFunction={handleUpdateOW}
              required
            />
            {showOwnerError()}
            <CustomField
              name={`people[${refBeneficialOwner.index}].isLegalRepresentative`}
              onChange={handleChangeIsLegalRepresentative}
              validate={validateLegalRepresentative}
              title="Gesetzliche(r) Vertreter(in)"
              tooltip="Als gesetzliche Vertreter gelten alle Personen, die dazu berechtigt sind, im Namen des
                Unternehmens zu handeln (Bsp. Geschäftsführer)."
              type="radiogroup"
              disabled={!isEditing}
              radioShowNo={showNoOptionLRP()}
              customUpdateFunction={handleUpdateLRP}
              required
            />
            {showLRPError()}
            {hasMajorityShares && level === 1 ? (
              <>
                <CustomField
                  required
                  name={`people[${refBeneficialOwner.index}].hasOtherCompanies`}
                  title="Hält die Person Anteile von mehr als 25% an mind. einer anderen Kapitalgesellschaft (z.B. GmbH) oder ist die Person an mind. einer weiteren Personengesellschaft (z.B. GbR) beteiligt?"
                  type="radiogroup"
                  disabled={!isEditing}
                  onChange={handleChangeHasOtherCompanies}
                />
                {_get(values, `people[${refBeneficialOwner.index}].hasOtherCompanies`) ===
                  'yes' && (
                  <WarningMessage
                    message="Bitte beachten Sie, dass es je nach Finanzierungspartner zur Nachforderung von Dokumenten (z.B. BWA, SuSa, Jahresabschluss) der verflochtenen Unternehmen bzw. Beteiligungen kommen kann. Das CAPTIQ-Team wird Sie nach Einreichung Ihres Antrags im Zuge der Prüfung darüber informieren, ob und welche Dokumente nachgereicht werden müssen."
                    severity="warning"
                  />
                )}{' '}
              </>
            ) : null}
          </>
        )}
      </Grid>
    );
  },
);

PersonForm.displayName = 'PersonForm';
