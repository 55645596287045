import { sharesValidator } from 'components/StaticBlocks/BeneficialOwners/validationSchema';
import { EKLegalFormLabel, FreelanceLegalFormLabel } from 'constants/legalForms';
import { checkLocaleValidAllocation } from 'containers/CustomerProfile/validationSchema';
import { CompanyDataFormValues, Person } from 'context/customer-context/types';
import { LegalFormsType, LegalFormsTypes, YesOrNo } from 'utils/commonTypes/types';
import {
  getCompanyLabel,
  getExceptionsLegalForms,
  getLocaleAllocation,
  noSideDealsDisplayLabel,
} from 'utils/utils';

export const validateOwnerFunction = (
  isOwner: YesOrNo,
  currentLegalForm: LegalFormsType | null,
) => {
  if (
    currentLegalForm &&
    (getExceptionsLegalForms(currentLegalForm.label) ||
      currentLegalForm.label === EKLegalFormLabel ||
      currentLegalForm.label === FreelanceLegalFormLabel) &&
    isOwner === 'no'
  )
    return 'Must be OW';
  return undefined;
};

export const validateBOFunction = (
  isBeneficialOwner: YesOrNo,
  currentLegalForm: LegalFormsType | null,
) => {
  if (
    currentLegalForm &&
    (currentLegalForm.label === EKLegalFormLabel ||
      currentLegalForm.label === FreelanceLegalFormLabel) &&
    (isBeneficialOwner === 'no' || !isBeneficialOwner)
  )
    return 'Must be BO';
  return undefined;
};

export const validateSharesFunction = ({
  allocation,
  sideDeal,
  companyData,
  person,
  currentLegalForm,
  language,
  legalForms,
}: {
  allocation: string;
  sideDeal: YesOrNo | undefined;
  companyData: CompanyDataFormValues;
  person: Person;
  currentLegalForm: LegalFormsType | null;
  language: string;
  legalForms: LegalFormsTypes | null;
}) => {
  const shareError = sharesValidator(
    allocation,
    sideDeal || '',
    1,
    getCompanyLabel(companyData, legalForms),
    person.isBeneficialOwnerLevelOne,
    !noSideDealsDisplayLabel(currentLegalForm?.label),
  );
  if (shareError) return shareError;
  if (
    getLocaleAllocation(allocation, language) <= 25 &&
    person.isBeneficialOwnerLevelOne === 'yes' &&
    noSideDealsDisplayLabel(currentLegalForm?.label)
  )
    return 'Bitte stellen Sie sicher, dass ein Wert zwischen 25,01% und 100% eingetragen wird. Nachkommastellen werden auf 2 Dezimale gerundet.';
  const isOwner = person.isOwner === 'yes';
  const allocationError = checkLocaleValidAllocation(allocation, !isOwner);
  if (!allocationError) {
    return isOwner
      ? `Bitte stellen Sie sicher, dass ein Wert zwischen 0,01% und 100% eingetragen wird. Nachkommastellen werden auf 2 Dezimale gerundet.`
      : `Bitte stellen Sie sicher, dass ein Wert zwischen 0,00% und 100% eingetragen wird. Nachkommastellen werden auf 2 Dezimale gerundet.`;
  }
  return undefined;
};
