import { useCallback, useState, useEffect, useMemo, memo, Children, Dispatch } from 'react';
import { FastField, Field, useFormikContext, FormikValues } from 'formik';
import { useSnackbar } from 'notistack';

import { MenuItem, Typography, Grid, Radio, FormControlLabel } from '@mui/material';
import { TextField, RadioGroup } from 'formik-mui';
import { Add } from '@mui/icons-material';

import BaseModal from 'components/BaseModal';
import * as ApiCaptiq from 'service/ApiCaptiq';
import { request } from 'service/ApiCaptiq';
import { shouldOnlyShowPersons } from 'utils/utils';
import { handleBackendErrors } from 'utils/handleBackendErrors';
import { useCustomerData } from 'context/customer-context';
import { GeneralRole, Level } from 'utils/commonTypes/types';
import { PersonCompanyExistingList } from 'utils/backendTypes';
import { CompanyFormValues } from 'context/customer-context/types';
import {
  serializeExistingCompanyToContext,
  serializeExistingPersonToContext,
} from 'utils/dataHandlers';
import useStyles from '../../styles';
import { ModalType } from '../../types';
import Forms from './forms';
import { getInfoText, selectOwnerOptions, selectBoOptions } from './helpers';

interface OwnerModalProps {
  onClose: () => void;
  open: boolean;
  loading?: boolean;
  modalType: ModalType;
  level?: Level;
  type?: GeneralRole;
  title: string;
  sharesError: boolean;
  setSharesError: Dispatch<boolean>;
  parentCompany?: number;
  secondLevelRefID?: number;
  thirdLevelRefID?: number;
  parentName?: string;
  mainCompanyFormik: CompanyFormValues | null;
  handleCreateOWorBO: (values: FormikValues) => void;
  isEkOrFreelancer: boolean;
}

const OwnerModal = memo(
  ({
    open,
    onClose,
    level = 1,
    type = 'OW',
    loading = false,
    modalType,
    title,
    parentCompany,
    secondLevelRefID = undefined,
    thirdLevelRefID = undefined,
    parentName = undefined,
    sharesError,
    mainCompanyFormik,
    setSharesError,
    handleCreateOWorBO,
    isEkOrFreelancer,
  }: OwnerModalProps) => {
    const classes = useStyles();
    const { values, resetForm, setFieldValue, isValid } = useFormikContext<FormikValues>();
    const [loadingPeopleCompanies, setLoadingPeopleCompanies] = useState(false);
    const [companies, setCompanies] = useState<PersonCompanyExistingList>([]);
    const [persons, setPersons] = useState<PersonCompanyExistingList>([]);
    const { enqueueSnackbar } = useSnackbar();
    const {
      legalForms,
      mainCompany,
      customerProfileId,
      companies: companiesContext,
    } = useCustomerData();
    const [legalFormState, setLegalFormState] = useState(mainCompany?.data.legalForm);

    const showOnlyPerson = useMemo(
      () => (mainCompany ? shouldOnlyShowPersons(legalForms, legalFormState, type) : null),
      [legalForms, mainCompany, type, legalFormState],
    );

    useEffect(() => {
      if (level === 1) {
        setLegalFormState(mainCompany?.data.legalForm);
        return;
      }
      const tempLegalForm = companiesContext.find(
        (company) => company.id === parentCompany,
      )?.legalForm;
      if (tempLegalForm) setLegalFormState(tempLegalForm);
    }, [level, companiesContext, parentCompany, mainCompany?.data.legalForm]);

    useEffect(() => {
      if (!open) return;
      setLoadingPeopleCompanies(true);
      const getCompaniesAndPersons = async () => {
        try {
          const companiesPromise = request.get(
            ApiCaptiq.CUSTOMER_PROFILE_BUSINNESS(customerProfileId),
            {
              params: { exclude_role: modalType === 'owner' ? 'OWC' : 'BOC' },
            },
          );
          const personsPromise = request.get(ApiCaptiq.CUSTOMER_PROFILE_PERSON(customerProfileId), {
            params: { exclude_role: modalType === 'owner' ? 'OWP' : 'BOP' },
          });

          const [newCompanies, newPersons] = await Promise.all([companiesPromise, personsPromise]);

          const existingCompany = serializeExistingCompanyToContext(newCompanies.data);
          const existingPerson = serializeExistingPersonToContext(
            newPersons.data,
            values.peopleMainFormik,
            modalType === 'owner' ? 'OWP' : 'BOP',
          );

          setCompanies(existingCompany);
          setPersons(existingPerson);
        } catch (error: any) {
          handleBackendErrors(error as any, enqueueSnackbar);
          console.error(error);
        } finally {
          setLoadingPeopleCompanies(false);
        }
      };

      getCompaniesAndPersons();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const getMinValSideDeal = useCallback(() => (level === 1 ? 25 : 50), [level]);

    const handleChangeCreateOrOvertake = useCallback(
      (event: any) => {
        const { value } = event.target;
        resetForm();
        setSharesError(false);
        setFieldValue('createOrOvertake', value);
      },
      [resetForm, setFieldValue, setSharesError],
    );

    const handleChangePersonOrCompany = useCallback(
      (event: any) => {
        const { value } = event.target;
        resetForm();
        setSharesError(false);
        setFieldValue('createOrOvertake', values.createOrOvertake);
        setFieldValue('personOrCompany', value);
      },
      [values.createOrOvertake, resetForm, setFieldValue, setSharesError],
    );

    const handleOnSubmit = useCallback(async () => {
      await handleCreateOWorBO(values);
    }, [handleCreateOWorBO, values]);

    return (
      <BaseModal
        open={open}
        onClose={onClose}
        submitButtonOnClick={handleOnSubmit}
        title={title.toUpperCase()}
        subtitle={type === 'OW' ? 'Inhaber(in)' : 'Wirtschaftlich Berechtigte'}
        infoText={getInfoText(values.createOrOvertake)}
        submitButtonDisabled={!isValid || loading}
        submitButtonLabel={
          type === 'OW' ? 'Inhaber(in) hinzufügen' : 'Wirtschaftlich Berechtigte(n) hinzufügen'
        }
        loadingSubmitButton={loading}
        closeOnlyWithButton={loading}
        disableCloseButton={loading}
      >
        {modalType === 'owner' ? (
          // This field have to react when the existing people or companies are loaded
          <Field
            component={TextField}
            name="createOrOvertake"
            onChange={handleChangeCreateOrOvertake}
            variant="outlined"
            size="small"
            label="Bitte wählen"
            fullWidth
            select
            className={classes.selectContainer}
          >
            {loadingPeopleCompanies ? (
              <MenuItem disabled>Laden...</MenuItem>
            ) : (
              Children.toArray(
                selectOwnerOptions(showOnlyPerson as boolean).map((opt) => (
                  <MenuItem key={`${opt.value}-create-generic-owner`} value={opt.value}>
                    <div className={classes.optionItem}>
                      <Add fontSize="inherit" />
                      <Typography variant="body2">{opt.label}</Typography>
                    </div>
                  </MenuItem>
                )),
              )
            )}
          </Field>
        ) : (
          // This field have to react when the existing people or companies are loaded
          <Field
            component={TextField}
            name="createOrOvertake"
            onChange={handleChangeCreateOrOvertake}
            variant="outlined"
            size="small"
            label="Bitte wählen"
            fullWidth
            select
            className={classes.selectContainer}
          >
            {loadingPeopleCompanies ? (
              <MenuItem disabled>Laden...</MenuItem>
            ) : (
              Children.toArray(
                selectBoOptions(showOnlyPerson as boolean).map((opt) => (
                  <MenuItem key={`${opt.value}-create-generic-BO`} value={opt.value}>
                    <div className={classes.optionItem}>
                      <Add fontSize="inherit" />
                      <Typography variant="body2">{opt.label}</Typography>
                    </div>
                  </MenuItem>
                )),
              )
            )}
          </Field>
        )}
        {!!values.createOrOvertake && (
          <>
            <Grid container item spacing={0}>
              <Grid item xs={12}>
                <Typography variant="body2">
                  {values.createOrOvertake === 'overtake' ? (
                    <>Möchten Sie die Dateien einer Person oder eines Unternehmens übernehmen?</>
                  ) : (
                    <>Möchten Sie eine neue Person oder ein Unternehmen erstellen?</>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FastField
                  component={RadioGroup}
                  name="personOrCompany"
                  label="Bitte wählen"
                  onChange={handleChangePersonOrCompany}
                  row
                >
                  <FormControlLabel
                    className={classes.controlLabel}
                    value="person"
                    control={<Radio size="small" color="primary" />}
                    label="Person"
                  />
                  {!showOnlyPerson && (
                    <FormControlLabel
                      className={classes.controlLabel}
                      value="company"
                      control={<Radio size="small" color="primary" />}
                      label="Unternehmen"
                    />
                  )}
                </FastField>
              </Grid>
            </Grid>
            {!!values.personOrCompany && (
              <Forms
                modalType={modalType}
                companies={companies}
                persons={persons}
                parentCompany={parentCompany}
                parentName={parentName}
                getMinValSideDeal={getMinValSideDeal}
                level={level}
                secondLevelRefID={secondLevelRefID}
                thirdLevelRefID={thirdLevelRefID}
                sharesError={sharesError}
                mainCompanyFormik={mainCompanyFormik}
                isEkOrFreelancer={isEkOrFreelancer}
              />
            )}
          </>
        )}
      </BaseModal>
    );
  },
);

export default OwnerModal;
