import { memo } from 'react';

import { Grid } from '@mui/material';

import { CountryType } from 'utils/commonTypes/types';
import { Person } from 'context/customer-context/types';
import CustomField from 'components/StaticBlocks/CustomField';

interface Props {
  personIndex: number;
  isEditing?: boolean;
}

export const getLoanApplicantContactStartValues = (defaultCountry: CountryType | null = null) => ({
  email: '',
  repeatEmail: '',
  prefixMobilePhone: defaultCountry || '',
  mobilePhone: '',
  internationalPrefix: defaultCountry || '',
  phoneNumber: '',
});

export const parseToContact = (person: Person, defaultCountry: CountryType | null = null) => ({
  email: person.email,
  repeatEmail: person.repeatEmail,
  prefixMobilePhone: person.prefixMobilePhone || defaultCountry || '',
  mobilePhone: person.mobilePhone,
  internationalPrefix: person.internationalPrefix || defaultCountry || '',
  phoneNumber: person.phoneNumber,
});

const Contact = memo(({ personIndex, isEditing = true }: Props) => (
  <Grid container item spacing={2}>
    <CustomField
      name={`people[${personIndex}].email`}
      required
      title="E-Mail"
      type="text"
      disabled={!isEditing}
    />
    <CustomField
      name={`people[${personIndex}].repeatEmail`}
      required
      title="E-Mail wiederholen"
      type="text"
      disabled={!isEditing}
    />
    <CustomField
      title="Vorwahl (mobil)"
      name={`people[${personIndex}].prefixMobilePhone`}
      textFieldProps={{ label: 'Bitte wählen' }}
      type="place"
      placetype="internationalPrefix"
      disabled={!isEditing}
      required
    />
    <CustomField
      name={`people[${personIndex}].mobilePhone`}
      required
      title="Telefonnummer (mobil)"
      type="onlyNumbers"
      disabled={!isEditing}
    />
    <CustomField
      title="Vorwahl"
      name={`people[${personIndex}].internationalPrefix`}
      textFieldProps={{ label: 'Bitte wählen' }}
      type="place"
      placetype="internationalPrefix"
      disabled={!isEditing}
    />
    <CustomField
      name={`people[${personIndex}].phoneNumber`}
      title="Telefonnummer"
      type="onlyNumbers"
      disabled={!isEditing}
    />
  </Grid>
));

Contact.displayName = 'LoanApplicantContact';

export default memo(Contact);
