import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  disabledMenuItem: {
    padding: '12px 15px',
    position: 'relative',
    color: '#a6a6a6',
    fontSize: 18,
    '&:active': {
      pointerEvents: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      background: '#f5f5f5',
    },
    '&:hover .alert': {
      pointerEvents: 'none',
      opacity: 0.8,
    },
    '& .alert': {
      textAlign: 'center',
      padding: '2px 10px',
      minWidth: '330px',
      fontSize: '12px',
      display: 'block',
      background: '#3a3a3a',
      color: '#fff',
      borderRadius: 4,
      opacity: 0,
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
      transition: 'all 0.2s',
      left: '50%',
      top: '50%',
      zIndex: 1,
    },
  },
  divider: {
    margin: '30px 0',
  },
}));

export default useStyles;
