import React, { memo, useMemo, useState } from 'react';
import _get from 'lodash/get';
import { Field, FormikValues, useFormikContext } from 'formik';
import Lottie from 'lottie-react-web';
import classNames from 'classnames';

import { Box, IconButton, InputAdornment } from '@mui/material';
import { Edit } from '@mui/icons-material';

import CurrencyField from 'components/CurrencyField';
import CheckAnimation from 'animation/check.json';
import useStyles from './styles';

interface Props {
  name: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  disabled?: boolean;
  showEditButton?: boolean;
  moreInputProps?: any;
  shouldBeLocallyDisabled?: boolean;
  customClassInput?: string;
  customClassesInput?: any;
  showCompleteCheck?: boolean;
  size?: 'small' | 'medium';
  placeholder?: string;
}

const MoneyField = memo(
  ({
    name,
    readOnly,
    onBlur,
    disabled,
    showEditButton,
    moreInputProps = {},
    shouldBeLocallyDisabled = false,
    customClassInput = '',
    customClassesInput = {},
    showCompleteCheck = false,
    size = 'small',
    placeholder = '',
    onChange = undefined,
  }: Props) => {
    const { values } = useFormikContext<FormikValues>();
    const [locallyDisabled, setLocallyDisabled] = useState(shouldBeLocallyDisabled);
    const value = useMemo(() => _get(values, name), [values, name]);
    const styles = useStyles();

    const handleChangeLocallyDisabled = () => {
      setLocallyDisabled((prevState: boolean) => !prevState);
    };

    const inputProps = {
      readOnly,
      'aria-label': 'naked',
      endAdornment: (
        <>
          <InputAdornment position="end" component="span">
            {showEditButton && (
              <IconButton
                classes={{ root: styles.pen }}
                disabled={readOnly}
                aria-label="delete"
                color="primary"
                onClick={handleChangeLocallyDisabled}
                size="large"
              >
                <Edit />
              </IconButton>
            )}
            {showCompleteCheck ? (
              <Lottie
                width={30}
                height={30}
                options={{
                  animationData: CheckAnimation,
                  loop: false,
                }}
                speed={2}
              />
            ) : (
              <Box width={30} />
            )}
          </InputAdornment>
        </>
      ),
      ...moreInputProps,
    };

    return (
      <Field
        classes={customClassesInput}
        className={classNames(
          readOnly
            ? styles.readOnlyField
            : value && value !== undefined && value !== null && styles.completeField,
          customClassInput,
        )}
        component={CurrencyField}
        name={name}
        variant="outlined"
        size={size || 'small'}
        InputProps={inputProps}
        fullWidth
        disabled={disabled || locallyDisabled}
        onChange={onChange}
        onBlur={onBlur}
        InputLabelProps={{ shrink: !!value }}
        data-testid="money-field"
        placeholder={placeholder}
      />
    );
  },
);

export default MoneyField;
