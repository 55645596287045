import { Company, Person, RefBeneficialOwner } from 'context/customer-context/types';
import { getRolId } from './getRolId';

export const getBeneficialOwnerRef = (
  value: Person | Company,
  type: 'person' | 'company',
  index: number,
  children?: RefBeneficialOwner[],
): RefBeneficialOwner => {
  const ref: RefBeneficialOwner = {
    _type: type,
    id: value.id,
    beneficialOwnerId: getRolId(type === 'person' ? 'BOP' : 'BOC', value.correlativeId),
    index,
  };
  ref.children = (type === 'company' && children) || [];

  return ref;
};
