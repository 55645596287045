import { useCallback, useState, useMemo, memo, useEffect } from 'react';
import { useFormikContext, FormikValues } from 'formik';
import classNames from 'classnames';
import _get from 'lodash/get';

import { Typography, Box, Button, Grid, Alert } from '@mui/material';
import { AddCircle, Info } from '@mui/icons-material';

import { useCustomerData } from 'context/customer-context';
import CaptiqReviewBlock from 'components/CaptiqReviewBlock';
import CaptiqTabs from 'components/CaptiqTabs';
import Section from 'components/Section';
import { Errors, validateStaticBlockValues } from 'utils/validateStaticBlocksValues';
import { useLazyEffect } from 'hooks/useLazyEffect';
import { getErrorsOW } from 'utils/validateStaticBlocksValues/getErrorsOW';
import { Scaffold } from 'components/CaptiqReviewBlock/type';
import { LegalForm } from 'utils/commonTypes/types';
import { useDynamicTexts } from 'context/dynamic-texts-context';
import Content from './components/Content';
import CreateModal from './components/CreateModal';
import { OwnerWrapperProps } from './types';
import { validationSchema } from './validationSchema';
import { getGeneralStatus, OwnerScaffold } from '../utils/reviewFunctions';
import useStyles from './styles';

export const TITLE_OW = 'PERSONENBEZOGENE DATEN';

const Owner = memo(
  ({
    block,
    customColors,
    level = 1,
    isReviewing = false,
    isEditing = true,
    subBlocksData = null,
    staticComponentsStatus = [],
    initialCommentsNumber = 0,
  }: OwnerWrapperProps) => {
    const classes = useStyles();
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const {
      setCurrentDeleteModal,
      refsOwners,
      currentLegalForm,
      mainCompany,
      legalForms,
      setCompleteStatus,
      setMissingErrorOW,
      fetchCustomerProfile,
      customerProfileId,
      customerProfileData,
    } = useCustomerData();
    const { companyOrApplicantCompany } = useDynamicTexts();
    const { values, errors } = useFormikContext<FormikValues>();
    const [currentTab, setCurrentTab] = useState(0);
    const [hoverActive, setHoverActive] = useState(false);
    const [isCompleted, setIsCompleted] = useState<boolean>(false);
    const [completedFields, setCompletedFields] = useState<boolean[]>([]);
    const [isSectionEditing, setIsSectionEditing] = useState(false);
    const [commentsNumber, setCommentsNumber] = useState(initialCommentsNumber);

    const maxOW = useMemo(() => {
      if (currentLegalForm) {
        return currentLegalForm.ranges.OW.max;
      }
      return null;
    }, [currentLegalForm]);
    const minOW = useMemo(() => {
      if (currentLegalForm) {
        return currentLegalForm.ranges.OW.min;
      }
      return null;
    }, [currentLegalForm]);

    const getPersonName = useCallback(
      (index: number) =>
        `${_get(values, `people[${index}].firstNames[0]`, '')}. ${_get(
          values,
          `people[${index}].lastName`,
          '',
        )}`,
      [values],
    );

    const getCompanyName = useCallback(
      (index: number) =>
        `${_get(values, `companies[${index}].companyName`, '')} ${
          legalForms
            ? legalForms[_get(values, `companies[${index}].legalForm`, '') as LegalForm]
                ?.short_label
            : _get(values, `companies[${index}].legalForm`, '')
        }`,
      [legalForms, values],
    );

    const OwnerTabs = useMemo(
      () =>
        refsOwners.map((ref, index: number) => {
          const blockPath = `static__unternehmensstruktur__inhaber-${ref._type[0]}${ref.modelId}`;
          const status = staticComponentsStatus.find(
            ({ path }: { path: string }) => path === blockPath,
          );
          return {
            label: (
              <>
                <strong>
                  {block.title}/in {index + 1}:{' '}
                </strong>
                <br />
                {refsOwners[index]._type === 'person'
                  ? getPersonName(refsOwners[index].index)
                  : getCompanyName(refsOwners[index].index)}
              </>
            ),
            slug: block.section_path as any,
            completed: completedFields[index],
            reviewStatus: status?.status as any,
          };
        }),
      [completedFields, refsOwners, block, getPersonName, getCompanyName, staticComponentsStatus],
    );

    useLazyEffect(() => {
      const validate = async () => {
        const [tabsStatuses, errorsYUP] = (await validateStaticBlockValues(
          values,
          refsOwners,
          validationSchema,
          customerProfileData?.profileVersion || 'VERSION_1',
        )) as [boolean[], Errors[]];

        const hasMissingErrors = errorsYUP
          .map((item) => item.errors.some((error) => error.type === 'required'))
          .some((item) => item === true);

        if (hasMissingErrors) {
          setMissingErrorOW(getErrorsOW({ errorsYUP, block, refsOwners }));
        } else {
          setMissingErrorOW({});
        }
        setCompletedFields(tabsStatuses);
        setCompleteStatus('OW', tabsStatuses);
        if (
          (minOW && refsOwners.length < minOW) ||
          (maxOW && refsOwners.length > maxOW) ||
          errors.people ||
          errors.companies
        ) {
          setIsCompleted(false);
        } else {
          const blockStatus = tabsStatuses.every((v) => v);
          setIsCompleted(blockStatus);
        }
      };

      if (values.companies && values.people) validate();
    }, [
      block,
      errors.companies,
      errors.people,
      maxOW,
      minOW,
      refsOwners,
      setCompleteStatus,
      setMissingErrorOW,
      values,
      customerProfileData?.profileVersion,
    ]);

    const handlerOpenCreateModal = useCallback(() => {
      setOpenCreateModal(true);
    }, []);

    const handlerCloseCreateModal = useCallback(() => {
      setOpenCreateModal(false);
    }, []);

    const hoverOnActive = useCallback((active: boolean) => {
      setHoverActive(active);
    }, []);

    const handlerAddNewTab = useCallback(() => {
      handlerOpenCreateModal();
    }, [handlerOpenCreateModal]);

    const handlerOnChangeTab = useCallback((index: number) => {
      setCurrentTab(index);
    }, []);

    const handlerAfterDelete = useCallback(
      (index: number) => () => {
        if (index > 0 && index === currentTab) setCurrentTab(index - 1);
      },
      [currentTab],
    );

    const handlerOnCloseTab = useCallback(
      (index: number) => {
        if (refsOwners[index]._type === 'company') {
          setCurrentDeleteModal({
            type: 'OWNER_COMPANY',
            deleteData: values.companies[refsOwners[index].index],
            afterDelete: handlerAfterDelete(index),
          });
        } else {
          setCurrentDeleteModal({
            type: 'OWNER_PERSON',
            deleteData: values.people[refsOwners[index].index],
            afterDelete: handlerAfterDelete(index),
          });
        }
      },
      [refsOwners, setCurrentDeleteModal, values.companies, values.people, handlerAfterDelete],
    );

    const addComments = useCallback(
      (number: number) => {
        setCommentsNumber((value) => value + number);
      },

      [setCommentsNumber],
    );

    const scaffolds = useMemo(
      () =>
        OwnerScaffold(refsOwners, staticComponentsStatus, TITLE_OW, block, addComments, currentTab),
      [block, refsOwners, currentTab, staticComponentsStatus, addComments],
    );

    useEffect(() => {
      if (customerProfileId) {
        fetchCustomerProfile(customerProfileId, true);
      }
    }, [customerProfileId, fetchCustomerProfile]);

    return (
      <>
        {isReviewing ? (
          <Box mb={1.25}>
            {!!minOW && minOW >= 1 && minOW > refsOwners.length && isEditing && (
              <Grid item xs={12}>
                <Alert icon={<Info />} severity="error">
                  {`Ihre gewählte Rechtsform erfordert mindestens ${minOW} Inhaber/innen.`}
                </Alert>
              </Grid>
            )}
            {!!maxOW && maxOW < refsOwners.length && isEditing && (
              <Grid item xs={12}>
                <Alert icon={<Info />} severity="error">
                  Sie haben mehr Inhaber, als Ihre Rechtsform zulässt
                </Alert>
              </Grid>
            )}
            {refsOwners.length > 0 ? (
              <Section
                title="INHABER"
                staticGeneralStatus={getGeneralStatus(OwnerTabs)}
                customColors={customColors}
                section_path={block.section_path}
                slug={block.slug}
                isReviewBlock
                isSectionEditing={isSectionEditing}
                setIsSectionEditing={setIsSectionEditing}
                commentsNumber={commentsNumber}
                customExtraZIndex={1}
              >
                <Typography className={classes.description}>
                  Als Inhaber gelten alle Anteilseigner Ihres {companyOrApplicantCompany}s
                </Typography>
                <CaptiqTabs
                  tabs={OwnerTabs}
                  maxDuplicates={currentLegalForm?.ranges.OW.max}
                  onCloseTab={handlerOnCloseTab}
                  addTab={handlerAddNewTab}
                  currentTab={currentTab}
                  onChange={handlerOnChangeTab}
                  hoverOnActive={hoverOnActive}
                  customColors={customColors}
                  isEditing={isSectionEditing}
                  isReviewing={isReviewing}
                />
                <div
                  className={classNames(
                    classes.tabsContent,
                    hoverActive && classes.activeBackground,
                    hoverActive && 'tab-content-hovered',
                  )}
                >
                  {refsOwners[currentTab] && (
                    <CaptiqReviewBlock
                      {...subBlocksData}
                      scaffold={scaffolds[currentTab] as unknown as Scaffold}
                      sectionPath={scaffolds[currentTab].section_path}
                      currentTab={currentTab}
                      customColors={customColors}
                    />
                  )}
                </div>
              </Section>
            ) : (
              <Button
                color="primary"
                size="small"
                startIcon={<AddCircle />}
                onClick={handlerOpenCreateModal}
                classes={{ root: classes.button, startIcon: classes.largeButtonIcon }}
              >
                Inhaber hinzufügen
              </Button>
            )}
          </Box>
        ) : (
          <Box mb={1.25}>
            <Section
              title="INHABER"
              customColors={customColors}
              section_path={block.section_path}
              slug={block.slug}
              isCompleted={isCompleted}
              isMandatory
              isMain
              customExtraZIndex={1}
            >
              <>
                {!!minOW && minOW >= 1 && minOW > refsOwners.length && isEditing && (
                  <Grid item xs={12}>
                    <Alert icon={<Info />} severity="error">
                      {`Ihre gewählte Rechtsform erfordert mindestens ${minOW} Inhaber/innen.`}
                    </Alert>
                  </Grid>
                )}
                {!!maxOW && maxOW < refsOwners.length && isEditing && (
                  <Grid item xs={12}>
                    <Alert icon={<Info />} severity="error">
                      Sie haben mehr Inhaber, als Ihre Rechtsform zulässt
                    </Alert>
                  </Grid>
                )}
                {refsOwners.length > 0 ? (
                  <>
                    <Typography className={classes.description}>
                      Als Inhaber gelten alle Anteilseigner Ihres {companyOrApplicantCompany}s
                    </Typography>
                    <CaptiqTabs
                      tabs={OwnerTabs}
                      maxDuplicates={maxOW}
                      onCloseTab={handlerOnCloseTab}
                      addTab={handlerAddNewTab}
                      currentTab={currentTab}
                      onChange={handlerOnChangeTab}
                      hoverOnActive={hoverOnActive}
                      customColors={customColors}
                      isEditing={isEditing}
                      isReviewing={isReviewing}
                    />
                    <div
                      className={classNames(
                        classes.tabsContent,
                        hoverActive && classes.activeBackground,
                        hoverActive && 'tab-content-hovered',
                      )}
                    >
                      {refsOwners[currentTab] && (
                        /* TODO: Also change this component to TS */
                        /* @ts-ignore */
                        <Section
                          title="PERSONENBEZOGENE DATEN"
                          customColors={customColors}
                          section_path={block.section_path}
                          slug={block.slug}
                        >
                          <Box m={1}>
                            <Content
                              key={currentTab}
                              currentTab={currentTab}
                              refOwner={refsOwners[currentTab]}
                              isEditing={isEditing}
                            />
                          </Box>
                        </Section>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    {!!mainCompany && (
                      <Button
                        color="primary"
                        size="small"
                        startIcon={<AddCircle />}
                        onClick={handlerOpenCreateModal}
                        classes={{ root: classes.button, startIcon: classes.largeButtonIcon }}
                      >
                        Inhaber hinzufügen
                      </Button>
                    )}
                  </>
                )}
              </>
            </Section>
          </Box>
        )}
        <CreateModal
          isReviewing={isReviewing}
          modalType="owner"
          type="OW"
          open={openCreateModal}
          onClose={handlerCloseCreateModal}
          level={level}
          isEkOrFreelancer={false}
        />
      </>
    );
  },
);

Owner.displayName = 'Owner';

export default Owner;
