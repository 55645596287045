import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  reviewIcon: {
    width: '1rem',
    height: '1rem',
    marginLeft: '1rem',
    marginRight: 'auto',
    borderRadius: '100px',
  },
  statusSuccess: {
    backgroundColor: '#85DD83',
  },
  statusDeclined: {
    backgroundColor: '#FFABAB',
  },
  statusNotReviewer: {
    backgroundColor: '#FFD571',
  },
}));

export default useStyles;
