import * as Yup from 'yup';

import { LANGUAGE } from 'constants/language';
import { EKLegalForm, FreelanceLegalForm } from 'constants/legalForms';
import { getLocaleAllocation, noSideDealsDisplayKey } from 'utils/utils';
import { checkLocaleValidAllocation } from 'containers/CustomerProfile/validationSchema';
import {
  YesOrNo,
  CreateOrOvertake,
  Level,
  PersonOrCompany,
  LegalForm,
} from 'utils/commonTypes/types';
import { ModalType } from '../../types';

export const regexForValidAllocationEN = /^\d{1,3}(?:\.\d{1,2})?$/;

export const regexForValidAllocationDE = /^\d{1,3}(?:,\d{1,2})?$/;

export const whenOvertake = {
  is: 'overtake',
  then: Yup.object().nullable().required('Dieses Feld ist erforderlich.'),
};

export const whenCreatePerson = {
  is: (first: CreateOrOvertake, second: PersonOrCompany) =>
    first === 'create' && second === 'person',
  then: Yup.string().required('Dieses Feld ist erforderlich.'),
};

export const whenCreateCompany = {
  is: (first: CreateOrOvertake, second: PersonOrCompany) =>
    first === 'create' && second === 'company',
  then: Yup.string().required('Bitte geben Sie den Unternehmensnamen an.'),
};

export const whenCreatePersonIsLegalRepresentative = {
  is: (first: CreateOrOvertake, second: PersonOrCompany, third: YesOrNo) =>
    first === 'create' && second === 'person' && third === 'yes',
  then: Yup.string().required('Dieses Feld ist erforderlich.'),
};

export const whenSideDealMandatoryLevelOW = {
  is: (allocation: string, level: Level) => {
    if (level === 1) return getLocaleAllocation(allocation, LANGUAGE) <= 25;
    return getLocaleAllocation(allocation, LANGUAGE) <= 50;
  },
  then: Yup.string()
    .required('Bitte wählen Sie eine Option')
    .test('test_if_correct_value', '', (value) => value === 'yes'),
};

export const whenSideDealMandatoryLevelBO = {
  is: (allocation: string, level: Level) => {
    const currentAllocation = getLocaleAllocation(allocation, LANGUAGE);
    if (level === 1) return currentAllocation <= 25;
    return currentAllocation <= 50;
  },
  then: Yup.string()
    .required('Bitte wählen Sie eine Option')
    .test('test_if_correct_value', '', (value) => value === 'yes'),
};

export const whenNeedsSideDealOW = {
  is: (
    allocation: string,
    isBeneficialOwner: YesOrNo,
    modalType: ModalType,
    createOrOvertake: CreateOrOvertake,
    personOrCompany: PersonOrCompany,
    currentLegalForm: LegalForm,
  ) => {
    if (createOrOvertake === 'overtake' && personOrCompany === 'person') {
      return false;
    }
    return (
      isBeneficialOwner === 'yes' &&
      !noSideDealsDisplayKey(currentLegalForm) &&
      getLocaleAllocation(allocation, LANGUAGE) <= 50 &&
      modalType === 'owner'
    );
  },
  then: Yup.string()
    .required('Bitte wählen Sie eine Option')
    .when(['allocation', 'level'], whenSideDealMandatoryLevelOW),
};

export const whenNeedsSideDealBO = {
  is: (allocation: string, modalType: ModalType, currentLegalForm: LegalForm) => {
    if (noSideDealsDisplayKey(currentLegalForm)) return false;
    return getLocaleAllocation(allocation, LANGUAGE) <= 50 && modalType === 'beneficialOwner';
  },
  then: Yup.string()
    .required('Bitte wählen Sie eine Option')
    .when(['allocation', 'level'], whenSideDealMandatoryLevelBO),
};

export const whenAllocationGbR = {
  is: (modalType: ModalType, currentLegalForm: LegalForm, isBeneficialOwner: YesOrNo) =>
    noSideDealsDisplayKey(currentLegalForm) && isBeneficialOwner === 'yes' && modalType === 'owner',
  then: Yup.string().test(
    'test_allocation',
    'Bitte stellen Sie sicher, dass ein Wert zwischen 25,01% und 100% eingetragen wird. Nachkommastellen werden auf 2 Dezimale gerundet.',
    (value) => checkLocaleValidAllocation(value, false, 25.01),
  ),
  otherwise: Yup.string().test(
    'test_allocation',
    'Bitte stellen Sie sicher, dass ein Wert zwischen 0,01% und 100% eingetragen wird. Nachkommastellen werden auf 2 Dezimale gerundet.',
    (value) => checkLocaleValidAllocation(value),
  ),
};

export const whenCheckAllocationOwner = {
  is: (modalType: ModalType, currentLegalForm: LegalForm) =>
    currentLegalForm !== EKLegalForm &&
    currentLegalForm !== FreelanceLegalForm &&
    modalType === 'owner',
  then: Yup.string().when(
    ['modalType', 'currentLegalForm', 'isBeneficialOwner'],
    whenAllocationGbR,
  ),
  otherwise: Yup.string().test(
    'test_allocation',
    'Bitte stellen Sie sicher, dass ein Wert zwischen 0,00% und 100% eingetragen wird. Nachkommastellen werden auf 2 Dezimale gerundet.',
    (value) => checkLocaleValidAllocation(value, true),
  ),
};

export const whenCheckAllocationBO = {
  is: (modalType: ModalType, currentLegalForm: LegalForm) => {
    if (noSideDealsDisplayKey(currentLegalForm) && modalType === 'beneficialOwner') return true;
    return false;
  },
  then: Yup.string().test(
    'test_allocation',
    'Bitte stellen Sie sicher, dass ein Wert zwischen 25,01% und 100% eingetragen wird. Nachkommastellen werden auf 2 Dezimale gerundet.',
    (value) => checkLocaleValidAllocation(value, false, 25.01),
  ),
};

export const whenCheckZeroAllocation = {
  is: (allocation: string, allocationMainCompany: string) =>
    getLocaleAllocation(allocation, LANGUAGE) === 0 ||
    getLocaleAllocation(allocationMainCompany, LANGUAGE) === 0,
  then: Yup.string()
    .required('Bitte wählen Sie eine Option')
    .test('test_if_correct_value', '', (value) => value === 'no'),
};

export const whenCreatePersonCheckBO = {
  is: (
    first: CreateOrOvertake,
    allocation: string,
    modalType: ModalType,
    currentLegalForm: LegalForm,
  ) =>
    currentLegalForm !== EKLegalForm &&
    currentLegalForm !== FreelanceLegalForm &&
    first === 'create' &&
    modalType === 'owner' &&
    getLocaleAllocation(allocation, LANGUAGE) >= 0.01,
  then: Yup.string().required('Bitte wählen Sie eine Option'),
};

export const whenCreatePersonCheckOW = {
  is: (
    first: CreateOrOvertake,
    modalType: ModalType,
    allocation: string,
    allocationMainCompany: string,
    level: Level,
    parentAllocation: YesOrNo,
    parentSideDeals: YesOrNo,
    sideDeal: YesOrNo,
  ) => {
    if (level === 1)
      return (
        first === 'create' &&
        modalType === 'beneficialOwner' &&
        getLocaleAllocation(allocation, LANGUAGE) <= 0.0
      );
    const conditionsParentCompany =
      getLocaleAllocation(parentAllocation, LANGUAGE) > 50 ||
      (getLocaleAllocation(parentAllocation, LANGUAGE) <= 50 && parentSideDeals === 'yes');
    const needsMainAllocation =
      conditionsParentCompany &&
      (sideDeal === 'yes' || getLocaleAllocation(allocation, LANGUAGE) > 50);
    return (
      first === 'create' &&
      modalType === 'beneficialOwner' &&
      needsMainAllocation &&
      !!allocationMainCompany &&
      getLocaleAllocation(allocationMainCompany, LANGUAGE) <= 0.0
    );
  },
  then: Yup.string()
    .required('Bitte wählen Sie eine Option')
    .when(['allocation', 'allocationMainCompany'], whenCheckZeroAllocation),
};

export const whenNeedsMainCompany = {
  is: (
    first: CreateOrOvertake,
    modalType: ModalType,
    level: Level,
    parentAllocation: YesOrNo,
    parentSideDeals: YesOrNo,
    sideDeal: YesOrNo,
    allocation: string,
  ) => {
    if (level === 1) return false;
    const conditionsParentCompany =
      getLocaleAllocation(parentAllocation, LANGUAGE) > 50 ||
      (getLocaleAllocation(parentAllocation, LANGUAGE) <= 50 && parentSideDeals === 'yes');
    const needsMainAllocation =
      conditionsParentCompany &&
      (sideDeal === 'yes' || getLocaleAllocation(allocation, LANGUAGE) > 50);
    return first === 'create' && modalType === 'beneficialOwner' && needsMainAllocation;
  },
  then: Yup.string().required('Bitte wählen Sie eine Option'),
};

export const whenLegalRepresentativeIsNeeded = {
  is: (
    first: CreateOrOvertake,
    second: PersonOrCompany,
    level: Level,
    allocationMainCompany: string,
    currentLegalForm: LegalForm,
  ) => {
    if (level === 1)
      return (
        currentLegalForm !== EKLegalForm &&
        currentLegalForm !== FreelanceLegalForm &&
        first === 'create' &&
        second === 'person'
      );
    return first === 'create' && second === 'person' && !!allocationMainCompany;
  },
  then: Yup.string().required(),
};

export const validationSchema = Yup.object().shape({
  level: Yup.number(),
  createOrOvertake: Yup.string().required('Dieses Feld ist erforderlich'),
  personOrCompany: Yup.string().required(),
  personCompanySelection: Yup.object().nullable().when('createOrOvertake', whenOvertake),
  salutation: Yup.string().when(['createOrOvertake', 'personOrCompany'], whenCreatePerson),
  title: Yup.string(),
  firstName: Yup.string().when(['createOrOvertake', 'personOrCompany'], whenCreatePerson),
  lastName: Yup.string().when(['createOrOvertake', 'personOrCompany'], whenCreatePerson),
  isBeneficialOwner: Yup.string().when(
    ['createOrOvertake', 'allocation', 'modalType', 'currentLegalForm'],
    whenCreatePersonCheckBO,
  ),
  isOwner: Yup.string().when(
    [
      'createOrOvertake',
      'modalType',
      'allocation',
      'allocationMainCompany',
      'level',
      'parentAllocation',
      'parentSideDeals',
      'sideDeal',
    ],
    whenCreatePersonCheckOW,
  ),
  isLegalRepresentative: Yup.string().when(
    ['createOrOvertake', 'personOrCompany', 'level', 'allocationMainCompany', 'currentLegalForm'],
    whenLegalRepresentativeIsNeeded,
  ),
  // RE-LA
  /* isLoanApplicant: Yup.string().when(
      ['createOrOvertake', 'personOrCompany', 'isLegalRepresentative'],
      whenCreatePersonIsLegalRepresentative,
    ), */
  companyName: Yup.string().when(['createOrOvertake', 'personOrCompany'], whenCreateCompany),
  legalForm: Yup.string().when(['createOrOvertake', 'personOrCompany'], whenCreateCompany),
  hgb: Yup.string().when(['createOrOvertake', 'personOrCompany'], whenCreateCompany),
  allocation: Yup.string()
    .required('Bitte geben Sie die Anteile ein')
    .when(['modalType', 'currentLegalForm'], whenCheckAllocationOwner)
    .when(['modalType', 'currentLegalForm'], whenCheckAllocationBO),
  sideDeal: Yup.string()
    .when(
      [
        'allocation',
        'isBeneficialOwner',
        'modalType',
        'createOrOvertake',
        'personOrCompany',
        'currentLegalForm',
      ],
      whenNeedsSideDealOW,
    )
    .when(['allocation', 'modalType', 'currentLegalForm'], whenNeedsSideDealBO),
  allocationMainCompany: Yup.string().when(
    [
      'createOrOvertake',
      'modalType',
      'level',
      'parentAllocation',
      'parentSideDeals',
      'sideDeal',
      'allocation',
    ],
    whenNeedsMainCompany,
  ),
});
