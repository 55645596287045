import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  doneTick: {
    animation: 'zoomIn .3s',
    animationTimingFunction: 'cubic-bezier(.01,.07,.21,1)',
    backgroundColor: '#06a72a',
    color: '#fff',
    marginLeft: 10,
  },
  stickyHeader: {
    backgroundColor: 'inherit',
    position: 'sticky',
    top: 0,
    zIndex: 2,
  },
  titleYear: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
  },
  title: {
    fontWeight: 500,
    color: '#000',
    textTransform: 'uppercase',
  },
  alertHGB: {
    marginBottom: 0,
  },
}));
