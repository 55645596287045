import React, { memo, useCallback, useEffect, useState, Children } from 'react';
import { FastField, Field, useFormikContext } from 'formik';

import {
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  TextField as MuiTextField,
  Typography,
  Alert,
} from '@mui/material';
import { RadioGroup, TextField, Autocomplete, AutocompleteRenderInputParams } from 'formik-mui';
import { Info } from '@mui/icons-material';

import LabeledGrid from 'components/LabeledGrid';
import {
  BackendSimplePerson,
  BackendSimpleCompany,
  BackendSimpleExtended,
} from 'utils/backendTypes';
import { useCustomerData } from 'context/customer-context/index';
import { Company, MainCompany } from 'context/customer-context/types';
import PercentageField from 'components/PercentageField';
import {
  getCompanyLabel,
  getLocaleAllocation,
  getWarningMessage,
  legalFormsWithHGB,
  noSideDealsDisplayLabel,
} from 'utils/utils';
import { getSalutationLabel, SALUTATIONS } from 'constants/salutations';
import { getTitleLabel, TITLES } from 'constants/titles';
import { LANGUAGE } from 'constants/language';
import { YesOrNo } from 'utils/commonTypes/types';
import {
  EKLegalFormLabel,
  FreelanceLegalFormLabel,
  GbRLegalForm,
  PartGLegalForm,
  PartGmbBLegalForm,
} from 'constants/legalForms';
import { useDynamicTexts } from 'context/dynamic-texts-context';
import { GenericFormValues, FormProps } from '../../types';
import useStyles from '../../styles';
import WarningSideDeals from './WarningSideDeals';

const CFTrans = ({
  messageBefore,
  messageAfter,
  mainCompany,
  parentName,
}: {
  messageBefore?: string | null;
  messageAfter?: string | null;
  mainCompany: MainCompany | null;
  parentName?: string;
}) => {
  const { legalForms } = useCustomerData();

  return (
    <>
      <span>{messageBefore}</span>{' '}
      {mainCompany ? parentName || getCompanyLabel(mainCompany.data, legalForms) : ''}{' '}
      {messageAfter}
    </>
  );
};

const Forms = memo(
  ({
    modalType,
    companies,
    persons,
    parentCompany,
    parentName,
    getMinValSideDeal,
    level,
    secondLevelRefID = undefined,
    thirdLevelRefID = undefined,
    sharesError = undefined,
    mainCompanyFormik,
    isEkOrFreelancer,
  }: FormProps) => {
    const [parentCompanySideDeals, setParentCompanySideDeals] = useState<YesOrNo | undefined>('');
    const [parentCompanyAllocation, setParentCompanyAllocation] = useState<string | undefined>('');
    const { touched, errors, values, setFieldValue, handleBlur } =
      useFormikContext<GenericFormValues>();
    const {
      legalForms,
      people,
      mainCompany,
      companies: companiesContext,
      currentLegalForm,
      refsBeneficialOwners,
    } = useCustomerData();
    const { companyOrApplicantCompany } = useDynamicTexts();
    const classes = useStyles();

    const getDataFromParentInMainCompany = useCallback(
      (company: Company) => {
        const sharesIndex = company.shares.find((share) => share.parent === mainCompany?.id);
        if (sharesIndex)
          return { sideDeal: sharesIndex.sideDeal, allocation: sharesIndex.allocation };
        return null;
      },
      [mainCompany?.id],
    );

    useEffect(() => {
      if (level !== 1) {
        if (level === 2 && secondLevelRefID !== undefined && secondLevelRefID !== null) {
          const parentIndex = refsBeneficialOwners[secondLevelRefID].index;
          const sharesData = getDataFromParentInMainCompany(companiesContext[parentIndex]);
          if (sharesData) {
            setParentCompanySideDeals(sharesData.sideDeal);
            setParentCompanyAllocation(sharesData.allocation);
            setFieldValue('parentSideDeals', sharesData.sideDeal);
            setFieldValue('parentAllocation', sharesData.allocation);
          }
        }
        // TODO: Check if works with changes from backend
        if (
          level === 3 &&
          secondLevelRefID !== undefined &&
          secondLevelRefID !== null &&
          thirdLevelRefID !== undefined &&
          thirdLevelRefID !== null
        ) {
          const secondLevel = refsBeneficialOwners[secondLevelRefID];
          if (secondLevel && secondLevel.children) {
            const parentIndex = secondLevel.children[thirdLevelRefID].index;
            const sharesData = getDataFromParentInMainCompany(companiesContext[parentIndex]);
            if (sharesData) {
              setParentCompanySideDeals(sharesData.sideDeal);
              setParentCompanyAllocation(sharesData.allocation);
              setFieldValue('parentSideDeals', sharesData.sideDeal);
              setFieldValue('parentAllocation', sharesData.allocation);
            }
          }
        }
      }
      if (isEkOrFreelancer) {
        setFieldValue('allocation', '100', false);
      }
    }, [
      level,
      refsBeneficialOwners,
      secondLevelRefID,
      thirdLevelRefID,
      companiesContext,
      getDataFromParentInMainCompany,
      setFieldValue,
      isEkOrFreelancer,
    ]);

    const handleChangeIsLegalRepresentative = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (currentLegalForm?.label === GbRLegalForm) {
          setFieldValue('isOwner', 'yes');
        }
        setFieldValue('isLegalRepresentative', value);
        // RE-LA
        // setFieldValue('isLoanApplicant', '', false);
      },
      [setFieldValue, currentLegalForm?.label],
    );

    const handleChangeIsOwner = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (currentLegalForm?.label === GbRLegalForm) {
          setFieldValue('isLegalRepresentative', 'yes');
        }
        setFieldValue('isOwner', value);
        // RE-LA
        // setFieldValue('isLoanApplicant', '', false);
      },
      [setFieldValue, currentLegalForm?.label],
    );

    const handleChangeExistingDropdown = useCallback(
      (o: BackendSimpleExtended, value: BackendSimpleExtended) => o && o.id === value.id,
      [],
    );

    const getOptionSelected = useCallback(
      (o: BackendSimplePerson | BackendSimpleCompany) => o?.name,
      [],
    );

    const renderInput = useCallback(
      (params: AutocompleteRenderInputParams) => (
        <MuiTextField
          {...params}
          error={touched.personCompanySelection && !!errors.personCompanySelection}
          helperText={touched.personCompanySelection && errors.personCompanySelection}
          variant="outlined"
        />
      ),
      [touched.personCompanySelection, errors.personCompanySelection],
    );

    const handleChangeLF = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const chosenLegalForm = legalFormsWithHGB.find((legalFormHGB) => legalFormHGB === value);
        if (chosenLegalForm) {
          setFieldValue('hgb', 'yes');
        } else setFieldValue('hgb', '');
        setFieldValue('legalForm', value);
      },
      [setFieldValue],
    );

    const handleBlurAllocation = useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const currentAllocation = getLocaleAllocation(value, LANGUAGE);
        if (
          currentAllocation === 0 &&
          currentLegalForm?.label === GbRLegalForm &&
          modalType === 'owner'
        )
          setFieldValue('isLegalRepresentative', '');
        if (currentAllocation === 0 && modalType === 'beneficialOwner' && level === 1) {
          setFieldValue('isOwner', 'no');
          if (currentLegalForm?.label === GbRLegalForm)
            setFieldValue('isLegalRepresentative', 'no');
        }
        if (
          currentLegalForm?.label === GbRLegalForm &&
          currentAllocation > 0 &&
          modalType === 'owner'
        )
          setFieldValue('isLegalRepresentative', 'yes');
        if (currentAllocation > 0 && modalType === 'beneficialOwner' && level === 1) {
          setFieldValue('isOwner', 'yes');
          if (currentLegalForm?.label === GbRLegalForm)
            setFieldValue('isLegalRepresentative', 'yes');
        }
        if (currentAllocation <= 25 && level === 1) {
          setFieldValue('isBeneficialOwner', '');
          setFieldValue('sideDeal', '');
        }
        if (currentAllocation > 25 && modalType === 'owner')
          setFieldValue('isBeneficialOwner', 'yes');
        if (currentAllocation > 50 && level === 1) {
          setFieldValue('sideDeal', '');
        }
        handleBlur(event);
      },
      [setFieldValue, handleBlur, modalType, level, currentLegalForm?.label],
    );

    const handleBlurAllocationMainCompany = useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const currentAllocation = getLocaleAllocation(value, LANGUAGE);
        if (currentAllocation === 0) {
          setFieldValue('isOwner', 'no');
          if (currentLegalForm?.label === GbRLegalForm)
            setFieldValue('isLegalRepresentative', 'no');
        }
        if (currentAllocation > 0) {
          setFieldValue('isOwner', 'yes');
          if (currentLegalForm?.label === GbRLegalForm)
            setFieldValue('isLegalRepresentative', 'yes');
        }
        handleBlur(event);
      },
      [setFieldValue, handleBlur, currentLegalForm?.label],
    );

    const getIsHGBForm = useCallback(() => {
      const chosenLegalForm = legalFormsWithHGB.find(
        (legalFormHGB) => legalFormHGB === values.legalForm,
      );
      return (
        <LabeledGrid
          label="Wird nach HGB bilanziert (öffentlicher Jahresabschluss, etc.)?"
          required
        >
          <FastField component={RadioGroup} name="hgb" row>
            <FormControlLabel value="yes" control={<Radio color="primary" />} label="Ja" />
            {!chosenLegalForm && (
              <FormControlLabel value="no" control={<Radio color="primary" />} label="Nein" />
            )}
          </FastField>
        </LabeledGrid>
      );
    }, [values.legalForm]);

    const getGroupBy = useCallback(
      (option: BackendSimpleExtended) =>
        option.groupId === 0 ? 'Aus diesem Kundenprofil' : 'Bezogen auf den Kooperationspartner',
      [],
    );

    const clearAllocation = useCallback(() => {
      setFieldValue('allocation', '', false);
      setFieldValue('sideDeal', '', false);
    }, [setFieldValue]);

    const handleChangeCreatedPersonCompany = useCallback(
      (_: any, newValue: BackendSimpleExtended) => {
        setFieldValue('personCompanySelection', newValue);
        if (values.personOrCompany === 'person') {
          if (newValue.groupId === 0) {
            const personInContext = people.find((p) => p.id === newValue.id);
            if (personInContext && personInContext.shares) {
              const { shares } = personInContext;
              const sharesInMainCompany = shares.find((s) => s.parent === parentCompany);
              if (sharesInMainCompany) {
                setFieldValue('allocation', sharesInMainCompany.allocation, false);
                setFieldValue('sideDeal', sharesInMainCompany.sideDeal, false);
              } else {
                clearAllocation();
              }
            } else {
              clearAllocation();
            }
          } else {
            clearAllocation();
          }
        }
        if (values.personOrCompany === 'company') {
          if (newValue.groupId === 0) {
            const companyContext = companiesContext.find((p) => p.id === newValue.id);
            if (companyContext && companyContext.shares) {
              const { shares } = companyContext;
              const sharesInMainCompany = shares.find((s) => s.parent === parentCompany);
              if (sharesInMainCompany) {
                setFieldValue('allocation', sharesInMainCompany.allocation, false);
                setFieldValue('sideDeal', sharesInMainCompany.sideDeal, false);
              } else {
                clearAllocation();
              }
            } else {
              clearAllocation();
            }
          } else {
            clearAllocation();
          }
        }
      },
      [
        setFieldValue,
        values.personOrCompany,
        people,
        parentCompany,
        clearAllocation,
        companiesContext,
      ],
    );

    const handleChangeBO = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (value === 'no') setFieldValue('sideDeal', '');
        setFieldValue('isBeneficialOwner', value);
      },
      [setFieldValue],
    );

    const handleChangeSideDeals = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (value === 'no' && level !== 1) {
          setFieldValue('allocationMainCompany', '');
          setFieldValue('isOwner', '');
          setFieldValue('isLegalRepresentative', '');
        }
        setFieldValue('sideDeal', value);
      },
      [setFieldValue, level],
    );

    const getMinAllocationComparison = useCallback(() => {
      if (modalType === 'owner' || currentLegalForm?.label === GbRLegalForm)
        return getLocaleAllocation(values.allocation, LANGUAGE) > 0;
      return getLocaleAllocation(values.allocation, LANGUAGE) >= 0;
    }, [modalType, values.allocation, currentLegalForm?.label]);

    const getWhenToShowSideDeals = useCallback(() => {
      if (modalType === 'owner')
        return (
          values.isBeneficialOwner === 'yes' &&
          getLocaleAllocation(values.allocation, LANGUAGE) <= 50 &&
          !noSideDealsDisplayLabel(currentLegalForm?.label)
        );
      if (noSideDealsDisplayLabel(currentLegalForm?.label) && modalType === 'beneficialOwner')
        return false;
      return (
        getLocaleAllocation(values.allocation, LANGUAGE) >= 0 &&
        getLocaleAllocation(values.allocation, LANGUAGE) <= 50
      );
    }, [modalType, values.allocation, values.isBeneficialOwner, currentLegalForm?.label]);

    const showWhenIsNotLevelOne = useCallback(() => {
      if (modalType === 'beneficialOwner') {
        if (level !== 1)
          return (
            !!values.allocationMainCompany &&
            getLocaleAllocation(values.allocationMainCompany, LANGUAGE) >= 0
          );
      }
      return true;
    }, [level, values.allocationMainCompany, modalType]);

    const showSharesInLAC = useCallback(() => {
      const conditionsParentCompany =
        getLocaleAllocation(parentCompanyAllocation, LANGUAGE) > 50 ||
        (getLocaleAllocation(parentCompanyAllocation, LANGUAGE) <= 50 &&
          parentCompanySideDeals === 'yes');
      if (
        conditionsParentCompany &&
        (values.sideDeal === 'yes' || getLocaleAllocation(values.allocation, LANGUAGE) > 50)
      )
        return (
          <LabeledGrid
            label={<CFTrans messageBefore="Anteile an" mainCompany={mainCompany} />}
            smLabel={4}
            smChild={8}
            required
          >
            <FastField
              component={PercentageField}
              name="allocationMainCompany"
              variant="outlined"
              size="small"
              onBlur={handleBlurAllocationMainCompany}
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </LabeledGrid>
        );

      return null;
    }, [
      handleBlurAllocationMainCompany,
      mainCompany,
      parentCompanyAllocation,
      parentCompanySideDeals,
      values.sideDeal,
      values.allocation,
    ]);

    const showNoWithLevel = useCallback(() => {
      if (currentLegalForm?.label === GbRLegalForm) return false;
      if (level !== 1) {
        return getLocaleAllocation(values.allocationMainCompany, LANGUAGE) === 0;
      }
      return getLocaleAllocation(values.allocation, LANGUAGE) === 0;
    }, [level, values.allocationMainCompany, values.allocation, currentLegalForm?.label]);

    const showWarningMessageOwnerWithLevel = useCallback(() => {
      if (level !== 1) {
        return getLocaleAllocation(values.allocationMainCompany, LANGUAGE) === 0;
      }
      return getLocaleAllocation(values.allocation, LANGUAGE) === 0;
    }, [level, values.allocationMainCompany, values.allocation]);

    const checkWhenShowSideDeals = useCallback(() => {
      if (modalType === 'beneficialOwner') return true;
      return values.isBeneficialOwner === 'yes' && modalType === 'owner';
    }, [modalType, values.isBeneficialOwner]);

    const checkWhenMinAllocation = useCallback(() => {
      if (modalType === 'beneficialOwner')
        return values.allocation && getLocaleAllocation(values.allocation, LANGUAGE) >= 0;
      return values.allocation && getLocaleAllocation(values.allocation, LANGUAGE) > 0;
    }, [modalType, values.allocation]);

    const showOwnerInCompany = useCallback(() => {
      if (level === 1) return modalType === 'beneficialOwner';
      return modalType === 'beneficialOwner' && values.allocationMainCompany;
    }, [level, modalType, values.allocationMainCompany]);

    const showNoLRP = useCallback(() => {
      if (
        currentLegalForm?.label === PartGmbBLegalForm ||
        currentLegalForm?.label === PartGLegalForm
      )
        return false;
      if (currentLegalForm?.label === GbRLegalForm) {
        if (level !== 1) return getLocaleAllocation(values.allocationMainCompany, LANGUAGE) === 0;
        return getLocaleAllocation(values.allocation, LANGUAGE) === 0;
      }
      return true;
    }, [currentLegalForm?.label, level, values.allocationMainCompany, values.allocation]);

    const showRoleWarningMessage = useCallback(() => {
      // TODO: Improve this code
      if (values.personCompanySelection && values.createOrOvertake === 'overtake' && level === 1) {
        return getWarningMessage(
          values.personOrCompany as 'person' | 'company',
          values.personOrCompany === 'person' ? people : companiesContext,
          values.personCompanySelection,
          values.allocation,
          values.sideDeal,
          mainCompany,
          parentName,
        );
      }
      return null;
    }, [
      level,
      mainCompany,
      people,
      parentName,
      companiesContext,
      values.allocation,
      values.personCompanySelection,
      values.sideDeal,
      values.personOrCompany,
      values.createOrOvertake,
    ]);

    const showWarningMessageOwner = useCallback(
      () =>
        level === 1 &&
        values.allocation &&
        getLocaleAllocation(values.allocation, LANGUAGE) === 0 &&
        modalType === 'owner',
      [level, values.allocation, modalType],
    );

    const showDividerSecondLevel = useCallback(() => {
      const conditionsParentCompany =
        getLocaleAllocation(parentCompanyAllocation, LANGUAGE) > 50 ||
        (getLocaleAllocation(parentCompanyAllocation, LANGUAGE) <= 50 &&
          parentCompanySideDeals === 'yes');
      if (
        conditionsParentCompany &&
        (values.sideDeal === 'yes' || getLocaleAllocation(values.allocation, LANGUAGE) > 50) &&
        level === 2
      )
        return (
          <Grid item xs={12}>
            <Divider className={classes.dividerSecondLevel} />
          </Grid>
        );
      return null;
    }, [
      classes.dividerSecondLevel,
      level,
      parentCompanyAllocation,
      parentCompanySideDeals,
      values.allocation,
      values.sideDeal,
    ]);

    switch (values.createOrOvertake) {
      case 'overtake':
        return (
          <>
            <Grid item xs={12}>
              <Divider className={classes.divider} />
            </Grid>
            {showRoleWarningMessage()}
            {sharesError && (
              <Grid item xs={12}>
                <Alert icon={<Info />} severity="error">
                  Die Anteile zwischen allen beteiligten Eigentümern überschreiten 100%. Dies ist
                  nicht zugelassen. Bitte überprüfen Sie die Zuteilung der Anteile erneut.
                </Alert>
              </Grid>
            )}
            {showWarningMessageOwner() && (
              <Grid item xs={12}>
                <Alert icon={<Info />} severity="error">
                  Sie müssen Anteile am {companyOrApplicantCompany} besitzen, um als Inhaber zu
                  gelten
                </Alert>
              </Grid>
            )}
            {!noSideDealsDisplayLabel(currentLegalForm?.label) && (
              <WarningSideDeals
                allocation={getLocaleAllocation(values.allocation, LANGUAGE)}
                sideDeal={values.sideDeal}
                getMinValSideDeal={getMinValSideDeal()}
              />
            )}
            {modalType === 'beneficialOwner' &&
              noSideDealsDisplayLabel(currentLegalForm?.label) &&
              values.allocation &&
              getLocaleAllocation(values.allocation, LANGUAGE) <= 25 && (
                <Grid item xs={12}>
                  <Alert icon={<Info />} severity="error">
                    Aufgrund der Rechtsform GbR muss ein wirtschaftlich Berechtigter mehr als 25%
                    der Anteile an dem Unternehmen besitzen.
                  </Alert>
                </Grid>
              )}
            {modalType === 'beneficialOwner' &&
              !noSideDealsDisplayLabel(currentLegalForm?.label) && (
                <Grid item xs={12}>
                  <Alert icon={<Info />} severity="info">
                    Sollten Sie keine Anteile besitzen, geben Sie bitte 0,00%.
                  </Alert>
                </Grid>
              )}
            <LabeledGrid label="Daten übernehmen von" smLabel={4} smChild={8} required>
              {/* NOTE: This field is used for person and company */}
              <Field
                component={Autocomplete}
                name="personCompanySelection"
                id="personCompanySelection"
                options={values.personOrCompany === 'person' ? persons : companies}
                getOptionLabel={getOptionSelected}
                getOptionSelected={handleChangeExistingDropdown}
                groupBy={getGroupBy}
                onChange={handleChangeCreatedPersonCompany}
                disableClearable
                renderInput={renderInput}
                size="small"
              />
            </LabeledGrid>
            {values.personOrCompany === 'person' && values.personCompanySelection && (
              <>
                <LabeledGrid customStyle={classes.spacerGrid} label="Vorname(n)">
                  <Typography variant="body2">
                    {values.personCompanySelection.first_name}
                  </Typography>
                </LabeledGrid>
                <LabeledGrid customStyle={classes.spacerGrid} label="Nachname">
                  <Typography variant="body2">{values.personCompanySelection.last_name}</Typography>
                </LabeledGrid>
                {values.personCompanySelection.street && (
                  <LabeledGrid customStyle={classes.spacerGrid} label="Straße">
                    <Typography variant="body2">{values.personCompanySelection.street}</Typography>
                  </LabeledGrid>
                )}
                {values.personCompanySelection.number && (
                  <LabeledGrid customStyle={classes.spacerGrid} label="Hausnummer">
                    <Typography variant="body2">{values.personCompanySelection.number}</Typography>
                  </LabeledGrid>
                )}
                {values.personCompanySelection.plz && (
                  <LabeledGrid customStyle={classes.spacerGrid} label="PLZ">
                    <Typography variant="body2">{values.personCompanySelection.plz}</Typography>
                  </LabeledGrid>
                )}
                {values.personCompanySelection.city && (
                  <LabeledGrid customStyle={classes.spacerGrid} label="Ort">
                    <Typography variant="body2">{values.personCompanySelection.city}</Typography>
                  </LabeledGrid>
                )}
              </>
            )}
            {values.personOrCompany === 'company' && values.personCompanySelection && (
              <>
                <LabeledGrid customStyle={classes.spacerGrid} label="Name des Unternehmens">
                  <Typography variant="body2">{values.personCompanySelection.name}</Typography>
                </LabeledGrid>
                <LabeledGrid customStyle={classes.spacerGrid} label="Rechtsform">
                  <Typography variant="body2">
                    {values.personCompanySelection.shortLabel}
                  </Typography>
                </LabeledGrid>
                {values.personCompanySelection.street && (
                  <LabeledGrid customStyle={classes.spacerGrid} label="Straße">
                    <Typography variant="body2">{values.personCompanySelection.street}</Typography>
                  </LabeledGrid>
                )}
                {values.personCompanySelection.number && (
                  <LabeledGrid customStyle={classes.spacerGrid} label="Hausnummer">
                    <Typography variant="body2">{values.personCompanySelection.number}</Typography>
                  </LabeledGrid>
                )}
                {values.personCompanySelection.plz && (
                  <LabeledGrid customStyle={classes.spacerGrid} label="PLZ">
                    <Typography variant="body2">{values.personCompanySelection.plz}</Typography>
                  </LabeledGrid>
                )}
                {values.personCompanySelection.city && (
                  <LabeledGrid customStyle={classes.spacerGrid} label="Ort">
                    <Typography variant="body2">{values.personCompanySelection.city}</Typography>
                  </LabeledGrid>
                )}
              </>
            )}
            {currentLegalForm?.label !== EKLegalFormLabel &&
              currentLegalForm?.label !== FreelanceLegalFormLabel && (
                <>
                  <LabeledGrid
                    label={
                      <CFTrans
                        messageBefore="Anteile an"
                        mainCompany={mainCompany}
                        parentName={parentName}
                      />
                    }
                    smLabel={4}
                    smChild={8}
                    required
                  >
                    <FastField
                      component={PercentageField}
                      name="allocation"
                      variant="outlined"
                      size="small"
                      onBlur={handleBlurAllocation}
                      fullWidth
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                    />
                  </LabeledGrid>
                  {values.modalType === 'beneficialOwner' &&
                    values.allocation &&
                    getLocaleAllocation(values.allocation, LANGUAGE) <= 50 &&
                    !noSideDealsDisplayLabel(currentLegalForm?.label) && (
                      <LabeledGrid
                        label={
                          <CFTrans
                            messageBefore="Kann auf vergleichbare Weise Kontrolle in"
                            messageAfter="ausüben"
                            mainCompany={mainCompany}
                            parentName={parentName}
                          />
                        }
                        tooltip={`
                Sollte die Person/das Unternehmen keine oder zu geringe Anteile am zugehörigen Unternehmen halten,
                kann dieser/dieses ggf. durch Sonderabkommen auf vergleichbare Weise Kontrolle ausüben und gilt so
                als wirtschaftlich Berechtigte(r).`}
                        required
                      >
                        <FastField component={RadioGroup} name="sideDeal" row>
                          <FormControlLabel
                            value="yes"
                            control={<Radio size="small" color="primary" />}
                            label="Ja"
                          />
                          <FormControlLabel
                            value="no"
                            control={<Radio size="small" color="primary" />}
                            label="Nein"
                          />
                        </FastField>
                      </LabeledGrid>
                    )}
                </>
              )}
          </>
        );
      case 'create':
        if (values.personOrCompany === 'person') {
          return (
            <>
              <Grid item xs={12}>
                <Divider className={classes.divider} />
              </Grid>
              {sharesError && (
                <Grid item xs={12}>
                  <Alert icon={<Info />} severity="error">
                    Die Anteile zwischen allen beteiligten Eigentümern überschreiten 100%. Dies ist
                    nicht zugelassen. Bitte überprüfen Sie die Zuteilung der Anteile erneut.
                  </Alert>
                </Grid>
              )}
              {showWarningMessageOwner() && (
                <Grid item xs={12}>
                  <Alert icon={<Info />} severity="error">
                    Sie müssen Anteile am Unternehmen besitzen, um als Inhaber zu gelten
                  </Alert>
                </Grid>
              )}
              {level !== 1 && (
                <LabeledGrid label="Zugehörig" smLabel={4} smChild={8}>
                  <Typography>{parentName}</Typography>
                </LabeledGrid>
              )}
              <LabeledGrid label="Anrede" smLabel={4} smChild={8} required>
                <FastField
                  component={TextField}
                  name="salutation"
                  label="Bitte wählen"
                  variant="outlined"
                  size="small"
                  fullWidth
                  select
                >
                  {Children.toArray(
                    SALUTATIONS.map((key) => (
                      <MenuItem key={key} value={key}>
                        {getSalutationLabel(key)}
                      </MenuItem>
                    )),
                  )}
                </FastField>
              </LabeledGrid>
              <LabeledGrid label="Titel" smLabel={4} smChild={8} justify="center">
                <FastField
                  component={TextField}
                  select
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Bitte wählen"
                  name="title"
                >
                  {TITLES.map((key) => (
                    <MenuItem key={key} value={key}>
                      {getTitleLabel(key)}
                    </MenuItem>
                  ))}
                </FastField>
              </LabeledGrid>
              <LabeledGrid label="Vorname" smLabel={4} smChild={8} required>
                <FastField
                  component={TextField}
                  name="firstName"
                  variant="outlined"
                  size="small"
                  fullWidth
                  inputProps={{
                    autoComplete: 'none',
                  }}
                />
              </LabeledGrid>
              <LabeledGrid label="Nachname" smLabel={4} smChild={8} required>
                <FastField
                  component={TextField}
                  name="lastName"
                  variant="outlined"
                  size="small"
                  fullWidth
                  inputProps={{
                    autoComplete: 'none',
                  }}
                />
              </LabeledGrid>
              {currentLegalForm?.label !== EKLegalFormLabel &&
                currentLegalForm?.label !== FreelanceLegalFormLabel && (
                  <>
                    {modalType === 'beneficialOwner' &&
                      !noSideDealsDisplayLabel(currentLegalForm?.label) && (
                        <Grid item xs={12}>
                          <Alert icon={<Info />} severity="info">
                            <div>Sollten Sie keine Anteile besitzen, geben Sie bitte 0,00%.</div>
                            <div>Mit 0,00% kann nicht die Rolle Inhaber gewählt werden</div>
                          </Alert>
                        </Grid>
                      )}
                    {modalType === 'beneficialOwner' &&
                      noSideDealsDisplayLabel(currentLegalForm?.label) && (
                        <Grid item xs={12}>
                          <Alert icon={<Info />} severity="info">
                            Aufgrund der Rechtsform GbR können Sie kein wirtschaftlich Berechtigter
                            sein, wenn Sie keine Anteile besitzen.
                          </Alert>
                        </Grid>
                      )}
                    {modalType === 'beneficialOwner' &&
                      checkWhenMinAllocation() &&
                      !noSideDealsDisplayLabel(currentLegalForm?.label) && (
                        <WarningSideDeals
                          allocation={getLocaleAllocation(values.allocation, LANGUAGE)}
                          sideDeal={values.sideDeal}
                          getMinValSideDeal={getMinValSideDeal()}
                        />
                      )}
                    {modalType === 'beneficialOwner' &&
                      noSideDealsDisplayLabel(currentLegalForm?.label) &&
                      values.allocation &&
                      getLocaleAllocation(values.allocation, LANGUAGE) <= 25 && (
                        <Grid item xs={12}>
                          <Alert icon={<Info />} severity="error">
                            Aufgrund der Rechtsform GbR muss ein wirtschaftlich Berechtigter mehr
                            als 25% der Anteile an dem Unternehmen besitzen.
                          </Alert>
                        </Grid>
                      )}
                    <LabeledGrid
                      label={
                        <CFTrans
                          messageBefore="Anteile an"
                          mainCompany={mainCompany}
                          parentName={parentName}
                        />
                      }
                      smLabel={4}
                      smChild={8}
                      required
                    >
                      <FastField
                        disabled={isEkOrFreelancer}
                        component={PercentageField}
                        name="allocation"
                        variant="outlined"
                        size="small"
                        onBlur={handleBlurAllocation}
                        fullWidth
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                      />
                    </LabeledGrid>
                    {values.allocation && getMinAllocationComparison() && (
                      <>
                        {modalType === 'owner' && (
                          <>
                            {checkWhenMinAllocation() &&
                              !noSideDealsDisplayLabel(currentLegalForm?.label) && (
                                <WarningSideDeals
                                  allocation={getLocaleAllocation(values.allocation, LANGUAGE)}
                                  sideDeal={values.sideDeal}
                                  getMinValSideDeal={getMinValSideDeal()}
                                />
                              )}
                            {noSideDealsDisplayLabel(currentLegalForm?.label) &&
                              values.allocation &&
                              getLocaleAllocation(values.allocation, LANGUAGE) <= 25 &&
                              values.isBeneficialOwner === 'yes' && (
                                <Grid item xs={12}>
                                  <Alert icon={<Info />} severity="error">
                                    Aufgrund der Rechtsform GbR muss ein wirtschaftlich Berechtigter
                                    mehr als 25% der Anteile an dem Unternehmen besitzen.
                                  </Alert>
                                </Grid>
                              )}
                            <LabeledGrid
                              label="Wirtschaftlich Berechtigte"
                              tooltip={`
                            Als wirtschaftlich berechtigt gilt, wer mehr als 25 Prozent der Kapitalanteile oder Stimmrechte
                            kontrolliert oder auf vergleichbare Weise Kontrolle ausübt.
                          `}
                            >
                              {/* This field needs to be dependent of the allocation value */}
                              <Field
                                component={RadioGroup}
                                name="isBeneficialOwner"
                                row
                                onChange={handleChangeBO}
                              >
                                <FormControlLabel
                                  value="yes"
                                  control={<Radio size="small" color="primary" />}
                                  label="Ja"
                                />
                                {getLocaleAllocation(values.allocation, LANGUAGE) <= 25 && (
                                  <FormControlLabel
                                    value="no"
                                    control={<Radio size="small" color="primary" />}
                                    label="Nein"
                                  />
                                )}
                              </Field>
                            </LabeledGrid>
                          </>
                        )}
                        {getWhenToShowSideDeals() && (
                          <LabeledGrid
                            label={
                              <CFTrans
                                messageBefore="Kann auf vergleichbare Weise Kontrolle in"
                                messageAfter="ausüben"
                                mainCompany={mainCompany}
                                parentName={parentName}
                              />
                            }
                            tooltip={`Sollte die Person/das Unternehmen keine oder zu geringe Anteile am
                        zugehörigen Unternehmen halten, kann dieser/dieses ggf. durch Sonderabkommen
                        auf vergleichbare Weise Kontrolle ausüben und gilt so als wirtschaftlich Berechtigte(r).`}
                            required
                          >
                            <FastField
                              component={RadioGroup}
                              name="sideDeal"
                              row
                              onChange={handleChangeSideDeals}
                            >
                              <FormControlLabel
                                value="yes"
                                control={<Radio size="small" color="primary" />}
                                label="Ja"
                              />
                              <FormControlLabel
                                value="no"
                                control={<Radio size="small" color="primary" />}
                                label="Nein"
                              />
                            </FastField>
                          </LabeledGrid>
                        )}
                        {showDividerSecondLevel()}
                        {showSharesInLAC()}
                        {showWhenIsNotLevelOne() && modalType !== 'owner' && (
                          <>
                            {showWarningMessageOwnerWithLevel() && values.isOwner === 'yes' && (
                              <Grid item xs={12}>
                                <Alert icon={<Info />} severity="error">
                                  Sie müssen Anteile am Unternehmen besitzen, um als Inhaber zu
                                  gelten
                                </Alert>
                              </Grid>
                            )}
                            <LabeledGrid
                              label={
                                level === 1 ? (
                                  'Inhaber(in)'
                                ) : (
                                  <CFTrans
                                    messageBefore="Inhaber von"
                                    mainCompany={mainCompany}
                                    parentName={`${mainCompanyFormik?.data.companyName} ${mainCompanyFormik?.data.legalForm}`}
                                  />
                                )
                              }
                              tooltip="Als Inhaber gelten alle Teilhaber am Unternehmen."
                            >
                              {/* This field needs to be dependent of the allocation value */}
                              <Field
                                component={RadioGroup}
                                name="isOwner"
                                onChange={handleChangeIsOwner}
                                row
                              >
                                <FormControlLabel
                                  value="yes"
                                  control={<Radio size="small" color="primary" />}
                                  label="Ja"
                                />
                                {showNoWithLevel() && (
                                  <FormControlLabel
                                    value="no"
                                    control={<Radio size="small" color="primary" />}
                                    label="Nein"
                                  />
                                )}
                              </Field>
                            </LabeledGrid>
                          </>
                        )}
                        {showWhenIsNotLevelOne() && (
                          <LabeledGrid
                            label={
                              level === 1 ? (
                                'Gesetzliche(r) Vertreter(in)'
                              ) : (
                                <CFTrans
                                  messageBefore="Gesetzliche(r) Vertreter(in) in"
                                  mainCompany={mainCompany}
                                  parentName={`${mainCompanyFormik?.data.companyName} ${mainCompanyFormik?.data.legalForm}`}
                                />
                              )
                            }
                            tooltip="Als gesetzliche Vertreter gelten alle Personen, die dazu berechtigt sind, im Namen des Unternehmens zu handeln (Bsp. Geschäftsführer)."
                          >
                            <FastField
                              component={RadioGroup}
                              name="isLegalRepresentative"
                              onChange={handleChangeIsLegalRepresentative}
                              row
                            >
                              <FormControlLabel
                                value="yes"
                                control={<Radio size="small" color="primary" />}
                                label="Ja"
                              />
                              {showNoLRP() && (
                                <FormControlLabel
                                  value="no"
                                  control={<Radio size="small" color="primary" />}
                                  label="Nein"
                                />
                              )}
                            </FastField>
                          </LabeledGrid>
                        )}
                      </>
                    )}
                  </>
                )}
              {/* RE-LA */}
              {/* {values.isLegalRepresentative === 'yes' && (
                <LabeledGrid
                  label={('Loan Applicant')}
                  tooltip={
                    <Typography>
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste, molestiae
                      necessitatibus ratione quisquam quasi quia iure quo excepturi obcaecati, velit
                      enim facilis praesentium quibusdam maiores. Soluta harum excepturi optio quae!
                    </Typography>
                  }>
                  <FastField component={RadioGroup} name="isLoanApplicant" row>
                    <FormControlLabel
                      value="yes"
                      control={<Radio size="small" color="primary" />}
                      label={('Yes')}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio size="small" color="primary" />}
                      label={('No')}
                    />
                  </FastField>
                </LabeledGrid>
              )} */}
            </>
          );
        }
        return (
          <>
            <Grid item xs={12}>
              <Divider className={classes.divider} />
            </Grid>
            {sharesError && (
              <Grid item xs={12}>
                <Alert icon={<Info />} severity="error">
                  Die Anteile zwischen allen beteiligten Eigentümern überschreiten 100%. Dies ist
                  nicht zugelassen. Bitte überprüfen Sie die Zuteilung der Anteile erneut.
                </Alert>
              </Grid>
            )}
            {level !== 1 && (
              <LabeledGrid label="Zugehörig" smLabel={4} smChild={8}>
                <Typography>{parentName}</Typography>
              </LabeledGrid>
            )}
            <LabeledGrid label="Name des Unternehmens" smLabel={4} smChild={8} required>
              <FastField
                component={TextField}
                name="companyName"
                variant="outlined"
                size="small"
                fullWidth
              />
            </LabeledGrid>
            <LabeledGrid label="Rechtsform" smLabel={4} smChild={8} required>
              <FastField
                component={TextField}
                name="legalForm"
                label="Bitte wählen"
                variant="outlined"
                size="small"
                onChange={handleChangeLF}
                fullWidth
                select
              >
                {legalForms &&
                  Children.toArray(
                    Object.entries(legalForms).map(([key, data]: any) => (
                      <MenuItem key={key} value={key}>
                        {data.label}
                      </MenuItem>
                    )),
                  )}
              </FastField>
            </LabeledGrid>
            {values.legalForm && getIsHGBForm()}
            {modalType === 'beneficialOwner' && (
              <Grid item xs={12}>
                <Alert icon={<Info />} severity="info">
                  <div>Sollten Sie keine Anteile besitzen, geben Sie bitte 0,00%.</div>
                  <div>Mit 0,00% kann nicht die Rolle Inhaber gewählt werden</div>
                </Alert>
              </Grid>
            )}
            <LabeledGrid
              label={
                <CFTrans
                  messageBefore="Anteile an"
                  mainCompany={mainCompany}
                  parentName={parentName}
                />
              }
              smLabel={4}
              smChild={8}
              required
            >
              <FastField
                component={PercentageField}
                name="allocation"
                variant="outlined"
                size="small"
                onBlur={handleBlurAllocation}
                fullWidth
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
            </LabeledGrid>
            {checkWhenMinAllocation() && (
              <>
                {modalType === 'owner' && (
                  <>
                    {checkWhenMinAllocation() && (
                      <WarningSideDeals
                        allocation={getLocaleAllocation(values.allocation, LANGUAGE)}
                        sideDeal={values.sideDeal}
                        getMinValSideDeal={getMinValSideDeal()}
                      />
                    )}
                    <LabeledGrid
                      label="Wirtschaftlich Berechtigte"
                      tooltip={`Als wirtschaftlich berechtigt gilt, wer mehr als 25 Prozent der Kapitalanteile
                    oder Stimmrechte kontrolliert oder auf vergleichbare Weise Kontrolle ausübt.`}
                    >
                      {/* This field needs to be dependent of the allocation value */}
                      <Field
                        component={RadioGroup}
                        name="isBeneficialOwner"
                        row
                        onChange={handleChangeBO}
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio size="small" color="primary" />}
                          label="Ja"
                        />
                        {getLocaleAllocation(values.allocation, LANGUAGE) <= 25 && (
                          <FormControlLabel
                            value="no"
                            control={<Radio size="small" color="primary" />}
                            label="Nein"
                          />
                        )}
                      </Field>
                    </LabeledGrid>
                  </>
                )}
                {modalType === 'beneficialOwner' && checkWhenMinAllocation() && (
                  <WarningSideDeals
                    allocation={getLocaleAllocation(values.allocation, LANGUAGE)}
                    sideDeal={values.sideDeal}
                    getMinValSideDeal={getMinValSideDeal()}
                  />
                )}
                {checkWhenShowSideDeals() &&
                  getLocaleAllocation(values.allocation, LANGUAGE) <= 50 &&
                  !noSideDealsDisplayLabel(currentLegalForm?.label) && (
                    <LabeledGrid
                      label={
                        <CFTrans
                          messageBefore="Kann auf vergleichbare Weise Kontrolle in"
                          messageAfter="ausüben"
                          mainCompany={mainCompany}
                          parentName={parentName}
                        />
                      }
                      tooltip={`
                          Sollte die Person/das Unternehmen keine oder zu geringe Anteile am zugehörigen Unternehmen halten,
                          kann dieser/dieses ggf. durch Sonderabkommen auf vergleichbare Weise Kontrolle ausüben und gilt so
                          als wirtschaftlich Berechtigte(r).
                        `}
                      required
                    >
                      <FastField component={RadioGroup} name="sideDeal" row>
                        <FormControlLabel
                          value="yes"
                          control={<Radio size="small" color="primary" />}
                          label="Ja"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio size="small" color="primary" />}
                          label="Nein"
                        />
                      </FastField>
                    </LabeledGrid>
                  )}
                {showDividerSecondLevel()}
                {showSharesInLAC()}
                {showOwnerInCompany() && (
                  <>
                    {showWarningMessageOwnerWithLevel() && values.isOwner === 'yes' && (
                      <Grid item xs={12}>
                        <Alert icon={<Info />} severity="error">
                          Sie müssen Anteile am Unternehmen besitzen, um als Inhaber zu gelten
                        </Alert>
                      </Grid>
                    )}
                    <LabeledGrid
                      label={
                        level === 1 ? (
                          'Inhaber(in)'
                        ) : (
                          <CFTrans
                            messageBefore="Inhaber von"
                            mainCompany={mainCompany}
                            parentName={`${mainCompanyFormik?.data.companyName} ${mainCompanyFormik?.data.legalForm}`}
                          />
                        )
                      }
                      tooltip="Als Inhaber gelten alle Teilhaber am Unternehmen."
                    >
                      {/* This field needs to be dependent of the allocation value */}
                      <Field component={RadioGroup} name="isOwner" row>
                        <FormControlLabel
                          value="yes"
                          control={<Radio size="small" color="primary" />}
                          label="Ja"
                        />
                        {showNoWithLevel() && (
                          <FormControlLabel
                            value="no"
                            control={<Radio size="small" color="primary" />}
                            label="Nein"
                          />
                        )}
                      </Field>
                    </LabeledGrid>
                  </>
                )}
              </>
            )}
          </>
        );
      case '':
      default:
        return null;
    }
  },
);

Forms.displayName = 'GenericOwnerForms';

export default Forms;
