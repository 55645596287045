import React, { memo, useCallback, useMemo, useState } from 'react';
import { useFormikContext, Field } from 'formik';

import { Grid, MenuItem, Typography, InputAdornment, Alert } from '@mui/material';
import { Info } from '@mui/icons-material';

import { LegalFormsType, Level, FastFieldProps, YesOrNo } from 'utils/commonTypes/types';
import { useInterval, validateSharesCompanyFunction } from 'utils/dataHandlers';
import LabeledGrid from 'components/LabeledGrid';
import PercentageField from 'components/PercentageField';
import { LANGUAGE } from 'constants/language';
import { Company, InitialValues, RefBeneficialOwner } from 'context/customer-context/types';
import {
  getLocaleAllocation,
  addRoleRefs,
  deleteRoleRefs,
  CLOCK_STATUS,
  getCurrentIconFunction,
  handleAllocationChangeFunction,
  getTotalShareValueWithParent,
} from 'utils/utils';
import { useCustomerData } from 'context/customer-context';
import { useLazyEffect } from 'hooks/useLazyEffect';
import CustomField from '../CustomField';

interface CommonProps {
  refBeneficialOwner: RefBeneficialOwner;
  belongsTo?: string;
  parentCompanyId: number;
  parentCompanyName: string;
  level: Level;
  isEditing?: boolean;
}

export interface CompanyFormProps extends CommonProps {
  formCompany: Company | undefined;
}

export const CompanyForm = memo(
  ({
    refBeneficialOwner,
    level,
    belongsTo = '',
    parentCompanyName,
    parentCompanyId,
    formCompany,
    isEditing = true,
  }: CompanyFormProps) => {
    const [loadingAllocation, setLoadingAllocation] = useState(false);
    const [allocationStatus, setAllocationStatus] = useState<string | null>(null);
    const [timerCount, setTimerCount] = useState(0);
    const [showCheck, setShowCheck] = useState(CLOCK_STATUS.STOPPED);
    const { setFieldValue, validateField, values, handleBlur } = useFormikContext<InitialValues>();
    const { legalForms, refsOwners, setRefsOwners, customerProfileId } = useCustomerData();
    const companyShares = formCompany?.shares;
    const legalFormsArray = useMemo(() => Object.entries(legalForms || {}), [legalForms]) as
      | LegalFormsType[]
      | [];
    const getCurrentIcon = useCallback(
      () => getCurrentIconFunction(loadingAllocation, allocationStatus),
      [loadingAllocation, allocationStatus],
    );
    useInterval(
      () => {
        if (timerCount > 0) {
          setTimerCount(timerCount - 1);
        } else {
          setShowCheck(CLOCK_STATUS.STOPPED);
          setAllocationStatus(null);
        }
      },
      showCheck === CLOCK_STATUS.STARTED ? 2000 : null,
    );

    const sharesIndex = useMemo(() => {
      if (!companyShares) {
        return -1;
      }

      return companyShares.findIndex((v) => v.parent === parentCompanyId);
    }, [companyShares, parentCompanyId]);

    const share = formCompany?.shares?.[sharesIndex];

    const handlerLegalFormsMap = useCallback(
      ([key, data]: [key: string, data: any]) => (
        <MenuItem key={key} value={key}>
          {data.label}
        </MenuItem>
      ),
      [],
    );

    useLazyEffect(() => {
      if (
        sharesIndex !== -1 &&
        values.companies[refBeneficialOwner.index] &&
        values.companies[refBeneficialOwner.index].shares[sharesIndex]
      ) {
        validateField(`companies[${refBeneficialOwner.index}].shares[${sharesIndex}].allocation`);
      }
    }, [
      refBeneficialOwner.index,
      sharesIndex,
      validateField,
      share?.sideDeal,
      share?.allocation,
      values.companies,
    ]);

    const validateShareFunctionReusable = useCallback(
      (
        allocation: string,
        sideDealDirect?: YesOrNo,
        isBeneficialOwnerLevelOneDirect?: YesOrNo,
        levelDirect?: Level,
        isOwnerDirect?: boolean,
        totalShares?: number,
      ) =>
        validateSharesCompanyFunction(
          allocation,
          values.companies[refBeneficialOwner.index].shares[sharesIndex].sideDeal,
          values.company.data,
          values.companies[refBeneficialOwner.index],
          legalForms,
          sideDealDirect,
          isBeneficialOwnerLevelOneDirect,
          levelDirect,
          isOwnerDirect,
          true,
          totalShares,
        ),
      [values.companies, values.company.data, refBeneficialOwner.index, sharesIndex, legalForms],
    );

    const handleAllocationChange = useCallback(
      async (allocation: string, sideDeal: YesOrNo | undefined, levelDirect: Level) =>
        handleAllocationChangeFunction(
          customerProfileId,
          allocation,
          sideDeal,
          showCheck,
          share?.id,
          'company',
          refBeneficialOwner.id,
          parentCompanyId,
          setLoadingAllocation,
          setShowCheck,
          setAllocationStatus,
          levelDirect,
        ),
      [share, parentCompanyId, showCheck, refBeneficialOwner.id, customerProfileId],
    );

    const handleBlurAllocation = useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const currentAllocation = getLocaleAllocation(value, LANGUAGE);
        let currentSideDeal: YesOrNo | undefined =
          values.companies[refBeneficialOwner.index].shares[sharesIndex].sideDeal;
        let currentOW: YesOrNo | undefined = values.companies[refBeneficialOwner.index].isOwner;
        const isOwner =
          currentAllocation > 0 && values.companies[refBeneficialOwner.index].isOwner === 'no';
        const wasOwner =
          currentAllocation === 0 && values.companies[refBeneficialOwner.index].isOwner === 'yes';
        if (currentAllocation >= 50 && level === 1) {
          setFieldValue(
            `companies[${refBeneficialOwner.index}].shares[${sharesIndex}].sideDeal`,
            '',
          );
          currentSideDeal = 'no';
        }
        if (isOwner) {
          addRoleRefs(
            'OW',
            refBeneficialOwner.index,
            values.companies,
            setRefsOwners,
            refsOwners,
            'company',
          );
          setFieldValue(`companies[${refBeneficialOwner.index}].isOwner`, 'yes');
          currentOW = 'yes';
        }
        if (wasOwner) {
          const idx = [...refsOwners].findIndex((owner) => owner.id === refBeneficialOwner.id);
          deleteRoleRefs('OW', idx, refsOwners, setRefsOwners);
          setFieldValue(`companies[${refBeneficialOwner.index}].isOwner`, 'no');
        }
        handleBlur(event);
        setTimeout(() => {
          if (
            !validateShareFunctionReusable(
              value,
              currentSideDeal,
              undefined,
              level,
              level === 1 && currentOW === 'yes',
            )
          ) {
            handleAllocationChange(value, currentSideDeal, level);
          }
        }, 0);
      },
      [
        setFieldValue,
        handleBlur,
        refBeneficialOwner.index,
        sharesIndex,
        level,
        setRefsOwners,
        refBeneficialOwner.id,
        refsOwners,
        values.companies,
        validateShareFunctionReusable,
        handleAllocationChange,
      ],
    );

    const getBeneficialOwnerValue = useCallback(() => {
      switch (level) {
        case 1:
          return values.companies[refBeneficialOwner.index].isBeneficialOwnerLevelOne;
        case 2:
          return values.companies[refBeneficialOwner.index].isBeneficialOwnerLevelTwo;
        case 3:
          return values.companies[refBeneficialOwner.index].isBeneficialOwnerLevelThree;
        default:
          return '';
      }
    }, [level, refBeneficialOwner.index, values.companies]);

    const validateShares = useCallback(
      (allocation: string) => {
        if (share) {
          const totalState = getTotalShareValueWithParent(
            parentCompanyId,
            values.people,
            values.companies,
          );
          return validateShareFunctionReusable(
            allocation,
            undefined,
            getBeneficialOwnerValue(),
            level,
            level === 1 && values.companies[refBeneficialOwner.index].isOwner === 'yes',
            totalState,
          );
        }
        return undefined;
      },
      [
        share,
        getBeneficialOwnerValue,
        level,
        values.companies,
        refBeneficialOwner.index,
        values.people,
        validateShareFunctionReusable,
        parentCompanyId,
      ],
    );

    const handleChangeSideDeals = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const { target } = e;
        setFieldValue(
          `people[${refBeneficialOwner.index}].shares[${sharesIndex}].sideDeal`,
          target.value,
        );
        setTimeout(() => {
          validateField(`people[${refBeneficialOwner.index}].shares[${sharesIndex}].sideDeal`);
          setTimeout(() => {
            const totalState = getTotalShareValueWithParent(
              parentCompanyId,
              values.people,
              values.companies,
            );
            if (
              !validateShareFunctionReusable(
                (values.companies[refBeneficialOwner.index].shares[sharesIndex]
                  .allocation as string) || '',
                target.value as YesOrNo,
                undefined,
                level,
                level === 1 && values.companies[refBeneficialOwner.index].isOwner === 'yes',
                totalState,
              )
            ) {
              handleAllocationChange(
                values.companies[refBeneficialOwner.index].shares[sharesIndex].allocation as string,
                target.value as YesOrNo,
                level,
              );
            }
          }, 0);
        }, 0);
      },
      [
        setFieldValue,
        refBeneficialOwner.index,
        validateField,
        values.people,
        values.companies,
        parentCompanyId,
        sharesIndex,
        validateShareFunctionReusable,
        handleAllocationChange,
        level,
      ],
    );

    const getSideDealInput = useCallback(
      () => (
        <CustomField
          name={`companies[${refBeneficialOwner.index}].shares[${sharesIndex}].sideDeal`}
          title="Kann das Unternehmen auf vergleichbare Weise Kontrolle ausüben?"
          tooltip="Sollte die Person/das Unternehmen keine oder zu geringe Anteile am Haupt-unternehmen
          halten, kann dieser/dieses ggf.\n durch Sonderabkommen auf vergleichbare Weise
          Kontrolle ausüben und gilt so als wirtschaftlich Berechtigte(r)."
          type="radiogroup"
          disabled={!isEditing || loadingAllocation}
          onChange={handleChangeSideDeals}
        />
      ),
      [refBeneficialOwner.index, sharesIndex, isEditing, loadingAllocation, handleChangeSideDeals],
    );

    const handleChangeOwner = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (!value) return;
        if (value === 'yes') {
          addRoleRefs(
            'OW',
            refBeneficialOwner.index,
            values.companies,
            setRefsOwners,
            refsOwners,
            'company',
          );
          setFieldValue(`companies[${refBeneficialOwner.index}].isOwner`, 'yes');
        } else {
          const idx = [...refsOwners].findIndex((owner) => owner.id === refBeneficialOwner.id);
          deleteRoleRefs('OW', idx, refsOwners, setRefsOwners);
          setFieldValue(`companies[${refBeneficialOwner.index}].isOwner`, 'no');
        }
        setTimeout(() => {
          validateField(`companies[${refBeneficialOwner.index}].isOwner`);
        }, 0);
      },
      [
        setRefsOwners,
        refBeneficialOwner.id,
        refsOwners,
        refBeneficialOwner.index,
        validateField,
        setFieldValue,
        values.companies,
      ],
    );

    const handleUpdateOW = useCallback(
      (nextProps: FastFieldProps, currentProps: FastFieldProps): boolean => {
        const newFormikValue = nextProps.formik.values;
        const currentFormikValue = currentProps.formik.values;
        const newAllocation = newFormikValue.companies;
        const currentAllocation = currentFormikValue.companies;
        if (
          getLocaleAllocation(
            newAllocation[refBeneficialOwner.index].shares[sharesIndex].allocation,
            LANGUAGE,
          ) !==
          getLocaleAllocation(
            currentAllocation[refBeneficialOwner.index].shares[sharesIndex].allocation,
            LANGUAGE,
          )
        )
          return true;

        return false;
      },
      [refBeneficialOwner.index, sharesIndex],
    );

    if (!formCompany || sharesIndex === -1) {
      return null;
    }

    return (
      <Grid container item spacing={2}>
        {level === 3 && (
          <Grid item xs={12}>
            <Alert icon={<Info />} severity="error">
              <Typography>
                Aufgrund der Komplexität Ihrer Gesellschafterstruktur, bitten wir Sie darum CAPTIQ
                zu kontaktieren:
              </Typography>
              <Typography>E-Mail: support@captiq.com</Typography>
              <Typography>Telefon: +49 69 98972476</Typography>
            </Alert>
          </Grid>
        )}
        <LabeledGrid label="Wirtschaftlich Berechtigter-ID">
          <Typography>{refBeneficialOwner.beneficialOwnerId}</Typography>
        </LabeledGrid>
        {!!belongsTo && (
          <LabeledGrid label="Zugehörig zu">
            <Typography>{belongsTo}</Typography>
          </LabeledGrid>
        )}
        <CustomField
          name={`companies[${refBeneficialOwner.index}].companyName`}
          title="Name des Unternehmens"
          type="text"
          disabled={!isEditing}
          required
        />
        <CustomField
          data={legalFormsArray}
          label="Bitte wählen"
          name={`companies.[${refBeneficialOwner.index}].legalForm`}
          onMap={handlerLegalFormsMap}
          title="Rechtsform"
          type="select"
          disabled={!isEditing}
          required
        />
        <CustomField
          name={`companies.[${refBeneficialOwner.index}].businessAddressStreet`}
          title="Straße Unternehmensanschrift"
          type="text"
          disabled={!isEditing}
          required
        />
        <CustomField
          name={`companies[${refBeneficialOwner.index}].businessAddressHouseNumber`}
          title="Hausnummer Unternehmensanschrift"
          type="text"
          disabled={!isEditing}
          required
        />
        <CustomField
          name={`companies[${refBeneficialOwner.index}].businessAddressAdditional`}
          title="Adresszusatz Unternehmensanschrift"
          type="text"
          disabled={!isEditing}
        />
        <CustomField
          name={`companies[${refBeneficialOwner.index}].businessAddressPostalCode`}
          title="PLZ Unternehmensanschrift"
          type="onlyNumbers"
          disabled={!isEditing}
          required
        />
        <CustomField
          name={`companies[${refBeneficialOwner.index}].businessAddressCity`}
          title="Ort Unternehmensanschrift"
          type="text"
          disabled={!isEditing}
          required
        />
        <CustomField
          title="Land Unternehmensanschrift"
          name={`companies[${refBeneficialOwner.index}].businessAddressCountry`}
          textFieldProps={{ label: 'Bitte wählen' }}
          type="place"
          placetype="country"
          disabled={!isEditing}
          required
        />
        <LabeledGrid
          label={`Anteil an ${parentCompanyName} (in %)`}
          tooltip="Sollten Sie keine Anteile besitzen, geben Sie bitte 0,00% an. Mit 0,00% Anteilen kann nicht die Rolle Inhaber gewählt werden"
          required
        >
          {/* Note: this needs to be a Field because its validations depends on the sideDeal field */}
          <Field
            disabled={!isEditing || loadingAllocation}
            checkTouched={false}
            validate={validateShares}
            component={PercentageField}
            onBlur={handleBlurAllocation}
            name={`companies[${refBeneficialOwner.index}].shares[${sharesIndex}].allocation`}
            variant="outlined"
            size="small"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <span>%</span>
                  {getCurrentIcon()}
                </InputAdornment>
              ),
            }}
          />
        </LabeledGrid>
        {level !== 1 ? (
          getSideDealInput()
        ) : (
          <>
            {values.companies[refBeneficialOwner.index]?.shares[sharesIndex]?.allocation &&
              getLocaleAllocation(
                values.companies[refBeneficialOwner.index].shares[sharesIndex].allocation,
                LANGUAGE,
              ) <= 50 &&
              getSideDealInput()}
          </>
        )}
        {level === 1 && (
          <CustomField
            name={`companies[${refBeneficialOwner.index}].isOwner`}
            onChange={handleChangeOwner}
            title="Inhaber(in)"
            tooltip="Als Inhaber gelten alle Teilhaber am Unternehmen."
            type="radiogroup"
            disabled={!isEditing || loadingAllocation}
            radioShowYes={values.companies[refBeneficialOwner.index].isOwner === 'yes'}
            radioShowNo={
              getLocaleAllocation(
                values.companies[refBeneficialOwner.index].shares[sharesIndex].allocation,
                LANGUAGE,
              ) === 0 && values.companies[refBeneficialOwner.index].isOwner === 'no'
            }
            customUpdateFunction={handleUpdateOW}
            required
          />
        )}
      </Grid>
    );
  },
);

CompanyForm.displayName = 'CompanyForm';
