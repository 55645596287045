import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  titleText: {
    fontSize: 17,
    fontWeight: 500,
    color: '#000',
  },
  icon: {
    width: 24,
    height: 24,
  },
  innerContainer: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    marginBottom: 10,
  },
  text: {
    fontSize: 16,
    fontWeight: 300,
    color: '#000',
    '& a': {
      color: '#1FB5CF',
    },
  },
});

export default useStyles;
