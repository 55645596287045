import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export default makeStyles<Theme>({
  accordion: {
    '&::before': {
      top: 0,
    },
  },
  margin: {
    marginBottom: 10,
  },
  accordionSummaryRoot: {
    backgroundColor: 'inherit',
    position: 'sticky',
    top: 0,
    zIndex: 2,
  },
  accordionSummaryContent: {
    alignItems: 'center',
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  accordionButton: {
    padding: 0,
    marginRight: 0,
  },
  accordionDetails: {
    flexFlow: 'column',
  },
  statusContainer: {
    display: 'flex',
  },
  title: {
    fontWeight: 500,
    color: '#000',
    textTransform: 'uppercase',
    marginRight: 15,
  },
  doneTick: {
    animation: 'zoomIn .3s',
    animationTimingFunction: 'cubic-bezier(.01,.07,.21,1)',
    backgroundColor: '#06a72a !important',
    color: '#fff !important',
    '& svg': {
      color: '#fff !important',
    },
  },
  subAccordionSummaryRoot: {
    alignItems: 'center',
    backgroundColor: '#eeeeee',
    borderRadius: 4,
    minHeight: 40,
    '&.Mui-expanded': {
      minHeight: 48,
    },
  },
  subAccordionSummaryContent: {
    margin: 0,
    alignItems: 'center',
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  spacer: {
    flex: 1,
  },
  customColorsSection: {
    blockBackgroundColor: '#FFFFFF !important',
    completeBlockColor: '#3A9C2B !important',
  },
  backgroundComplete: {
    backgroundColor: '#3A9C2B !important',
  },
  blockBackgroundColor: {
    backgroundColor: '#FFFFFF !important',
  },
  blockCommentsIcon: {
    padding: '0 10px !important',
    margin: '0 30px 0 10px',
    borderRadius: 100,
    height: '21px',
    minHeight: '21px',
    minWidth: 0,
  },
  iconLeft: {
    marginRight: '10px',
  },
  button: {
    borderRadius: '30px',
    padding: '3px 15px',
  },
  buttonMargin: {
    margin: '9px 0 0 10px',
    borderRadius: '30px',
    padding: '3px 15px',
  },
  blockStatus: {
    fontSize: '12px',
    textTransform: 'uppercase',
    fontWeight: 500,
    height: '22px',
  },
  statusSuccess: {
    backgroundColor: '#85DD83',
    color: '#114310',
  },
  statusDeclined: {
    backgroundColor: '#FFABAB',
    color: '#730000',
  },
  statusPending: {
    backgroundColor: '#E4A2FF',
    color: '#59083D',
  },
  statusNotReviewer: {
    backgroundColor: '#FFD571',
    color: '#363636',
  },
  statusEdit: {
    backgroundColor: '#FCAD6E',
    color: '#321A00',
  },
});
