import { BackendPerson } from 'utils/backendTypes';
import { MainCompany, Person, Share } from 'context/customer-context/types';
import { YesOrNo } from 'utils/commonTypes/types';
import { getLocaleAllocation } from 'utils/utils';
import { LANGUAGE } from 'constants/language';
import { getShare, getCountryOrNull } from './index';

export const getYesOrNo = (val: boolean | null) => {
  if (typeof val === 'boolean') {
    return val ? 'yes' : 'no';
  }
  return '';
};

export function serializePersonToContext(
  person: BackendPerson,
  mainCompany: MainCompany | null,
): Person {
  const isLegitimacy: YesOrNo = getYesOrNo(person.customer_profile_relation.legitimacy);
  const taxableInUSA: YesOrNo = getYesOrNo(person.fatca);
  const isSoleRepresentation: YesOrNo = getYesOrNo(person.is_sole_representation);
  const hasOtherCompanies: YesOrNo = getYesOrNo(person.has_other_companies);

  const shares: Share[] = person.shares.map((p) => getShare(p));

  const serializedPerson: Person = {
    id: person.id,
    personId: person.person_id,
    customerProfileRelationId: person.customer_profile_relation.id,
    correlativeId: person.customer_profile_relation.correlative_id,
    salutation: person.salutation ?? '',
    title: person.title ?? '',
    firstNames: person.first_name,
    lastName: person.last_name,
    dateOfBirth: person.birthday ? new Date(person.birthday) : '',
    countryOfBirth: getCountryOrNull(person.birth_country),
    cityOfBirth: person.birth_city,
    nationality: getCountryOrNull(person.citizenship),
    taxableInUSA,
    isOwner: 'no',
    isBeneficialOwner: 'no',
    isBeneficialOwnerLevelOne: 'no',
    isBeneficialOwnerLevelTwo: 'no',
    isBeneficialOwnerLevelThree: 'no',
    isLegalRepresentative: 'no',
    isLoanApplicant: 'no',
    personalAddressStreet: person.private_address_street,
    personalAddressHouseNumber: person.private_address_house_n,
    personalAddressAdditional: person.private_address_extra,
    personalAddressPostalCode: person.private_address_postcode,
    personalAddressCity: person.private_address_city,
    personalAddressCountry: getCountryOrNull(person.private_address_country),
    isLegitimacy,
    isSoleRepresentation,
    hasOtherCompanies,
    birthName: person.birth_name,
    occupationalGroup: person.occupational_group ?? '',
    familySituation: person.family_status ?? '',
    isContactPerson: person.is_contact_person ? 'yes' : 'no',
    children: person.children
      ? person.children.map((v) => ({
          age: v,
        }))
      : [],
    dependents: person.dependents
      ? person.dependents.map((v) => ({
          age: v[0] !== undefined && v[0] !== null ? Number(v[0]) : '',
          monthlyUpkeep: v[1] !== undefined && v[1] !== null ? Number(v[1]) : '',
        }))
      : [],
    taxOffice: person.tax_revenue_office,
    taxID: person.tax_id,
    taxNumber: person.tax_nr,
    email: person.contact_email,
    repeatEmail: person.contact_email,
    internationalPrefix: getCountryOrNull(person.contact_international_prefix),
    phoneNumber: person.contact_phone,
    prefixMobilePhone: getCountryOrNull(person.contact_prefix_mobile_phone),
    mobilePhone: person.contact_mobile,
    emailContractSigningSentDate: person.email_contract_signing_sent_date
      ? new Date(person.email_contract_signing_sent_date)
      : '',
    isContractSigned: person.contract_signed,
    contractSignedOn: person.contract_signed_on ? new Date(person.contract_signed_on) : '',
    additionalInformation: person.additional_information,
    shares,
  };

  person.customer_profile_relation.roles.forEach((v) => {
    switch (v) {
      case 'LAP':
        serializedPerson.isLoanApplicant = 'yes';
        serializedPerson.isLegalRepresentative = 'yes';
        break;
      case 'LRP':
        serializedPerson.isLegalRepresentative = 'yes';
        break;
      default:
        break;
    }
  });

  shares.forEach(({ allocation, sideDeal, parent }) => {
    if (allocation) {
      const currentAllocation = getLocaleAllocation(allocation, LANGUAGE);
      if (currentAllocation > 0 && mainCompany && parent === mainCompany.id) {
        serializedPerson.isOwner = 'yes';
      }
      if (currentAllocation <= 25 && sideDeal === 'yes') serializedPerson.isBeneficialOwner = 'yes';
      if (currentAllocation > 25) serializedPerson.isBeneficialOwner = 'yes';
    }
  });

  if (serializedPerson.shares.length === 0)
    serializedPerson.shares.push({ allocation: '0', sideDeal: 'no' });

  return serializedPerson;
}
