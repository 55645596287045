import React from 'react';

import CaptiqQuestions from 'components/CaptiqQuestions';
import { RenderStaticComponents, staticBaseComponents } from '../../utils';

interface Props {
  questions: any[];
  block_id: string;
  blockData: any;
  form: any;
  disabled: boolean;
  personIndexTab: number;
  stepOrder: number;
  loanId: string;
  step_id: string;
  upperFormState: any;
  personID: string;
  metaData: any;
  currentTab: number;
  componentProps: any;
  customColors: any;
  setLoanApplication: any;
  loanApplication: any;
  deleteField: (blockSlug: any, field: any) => void;
  handleChange: (
    blockSlug: any,
    _questionSlug: any,
    name: any,
    value: any,
    valueDisplay?: null,
    fieldValidator?: null,
  ) => void;
  renderExtraValidationInfo: (extraValidationInfo: any) => void;
  loanApplicationStatus: string;
  firstStepRef: any;
  isEditing: boolean;
}

const RenderQuestions = ({
  questions,
  block_id,
  blockData,
  form,
  disabled,
  personIndexTab,
  stepOrder,
  loanId,
  step_id,
  upperFormState,
  personID,
  metaData,
  currentTab,
  componentProps,
  customColors,
  setLoanApplication,
  loanApplication,
  deleteField,
  handleChange,
  renderExtraValidationInfo,
  loanApplicationStatus,
  firstStepRef,
  isEditing,
}: Props) => {
  const isStaticComponent = staticBaseComponents.includes(blockData.based_component);
  if (isStaticComponent) {
    return (
      <RenderStaticComponents
        blockData={blockData}
        currentTab={currentTab}
        metaData={componentProps ? { ...metaData, ...componentProps } : metaData}
        personIndexTab={personIndexTab}
        loanApplication={loanApplication}
        setLoanApplication={setLoanApplication}
        edit={isEditing}
        firstStepRef={firstStepRef}
      />
    );
  }

  if (blockData.instances.length > 0 || !blockData.can_be_duplicated) {
    return questions.map((item) => {
      if (
        upperFormState &&
        item.depending_field &&
        item.visibility_value &&
        item.visibility_value !== String(form[blockData.section_path][item.depending_field]) &&
        item.visibility_value !== String(upperFormState[item.depending_field])
      ) {
        return null;
      }
      return (
        <CaptiqQuestions
          blockData={form[blockData.section_path]}
          key={item.section_path}
          loanId={loanId}
          scaffold={item}
          blockSlug={blockData.section_path}
          stepOrder={stepOrder}
          step_id={step_id}
          disabled={disabled}
          block_id={blockData.can_be_duplicated ? block_id : false}
          deleteField={deleteField}
          onChange={handleChange}
          renderExtraValidationInfo={renderExtraValidationInfo}
          questions={questions}
          customColors={customColors}
          personID={personID}
          reviewCancelEdition
          showCopyButton={loanApplicationStatus === 'EDIT' || loanApplicationStatus === 'SUBMITTED'}
          upperFormState={upperFormState}
        />
      );
    });
  }

  return null;
};

export default RenderQuestions;
