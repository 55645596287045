import { createContext, useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import * as ApiCaptiq from 'service/ApiCaptiq';
import { request } from 'service/ApiCaptiq';
import { BalancingFormType } from 'context/customer-context/types';
import { CountryType, LegalFormsTypes } from 'utils/commonTypes/types';
import { generalOnError } from 'utils/queryUtils';
import { AuthContext } from 'context/auth-context';
import { PersistentDataProviderProps } from './types';
import { initialState } from './constants';

const PersistentData = createContext(initialState);

const PersistentProvider = ({ children }: PersistentDataProviderProps) => {
  const [countryList, setCountryList] = useState<CountryType[]>([]);
  const [legalForms, setLegalForms] = useState<LegalFormsTypes | null>(null);
  const [hgbDocuments, setHGBDocuments] = useState<BalancingFormType | null>(null);

  const { isAuthenticated } = useContext(AuthContext);

  const generalQueryOptions = {
    staleTime: Infinity,
    enabled: isAuthenticated(),
    onError: generalOnError,
  };

  const { isLoading: countriesLoading } = useQuery(
    ['countries'],
    () => request.get(ApiCaptiq.CUSTOMER_COUNTRIES),
    {
      ...generalQueryOptions,
      onSuccess: ({ data }) => setCountryList(data),
    },
  );
  const { isLoading: legalFormsLoading } = useQuery(
    ['legal-forms'],
    () => request.get(ApiCaptiq.CUSTOMER_LEGAL_FORMS),
    {
      ...generalQueryOptions,
      onSuccess: ({ data }) => setLegalForms(data),
    },
  );
  const { isLoading: hgbDocumentsLoading } = useQuery(
    ['hgb-documents'],
    () => request.get(ApiCaptiq.LIST_DOCUMENTS_HGB),
    {
      ...generalQueryOptions,
      onSuccess: ({ data }) => setHGBDocuments(data),
    },
  );

  const value = {
    legalForms,
    hgbDocuments,
    countryList,
  };

  return (
    (!isAuthenticated() || (!countriesLoading && !legalFormsLoading && !hgbDocumentsLoading)) && (
      <PersistentData.Provider value={value}>{children}</PersistentData.Provider>
    )
  );
};

export const usePersistentData = () => {
  const context = useContext(PersistentData);

  return context;
};

export default PersistentProvider;
