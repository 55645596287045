import { LoanApplication as LoanApplicationTypes, Question, Step } from 'utils/commonTypes/types';
import { BlockStatus } from 'components/CaptiqTabs/components/ReviewStatusIcon/types';
import {
  BalanceSchufaValuesType,
  BalanceSheetValuesType,
  Block,
  Documents,
  DocumentsBackend,
  RenderFieldBackend,
  RequestUserType,
  StepData,
  staticComponentsStatusType,
} from './types';

export type InitialStateType = {
  blocks: Block;
  stepDataState: Step[] | null;
  stepDataStateReview: StepData | null;
  customerDocuments: Documents[];
  brokerDocuments: Documents[];
  frontingbankDocuments: Documents[];
  registerBlock: (name: string) => void;
  registerQuestion: (blockSlug: string, questionSlug: Question) => void;
  clearBlocks: () => void;
  updateBlockAfterDeletion: (
    blockSlug: string,
    questionSlug: string,
    fieldsList: any,
    questionInstance: any,
  ) => void;
  registerField: (blockSlug: string, questionSlug: string, field: any) => void;
  updateField: (
    blockSlug: string,
    questionSlug: string,
    fieldSlug: string,
    value: any,
    valueDisplay: any,
    fieldValidator: string,
  ) => void;
  loadingPage: boolean;
  removeField: (blockSlug: string, questionSlug: string, field: string) => void;
  saveStepData: any; // TODO it is necessary to migrate ReviewStep
  setSaveStepData: (saveStepData: any) => void; // TODO it is necessary to migrate ReviewStep
  loadingReview: boolean;
  setLoadingReviewPage: (loadingReviewPage: boolean) => void;
  loadingStep: boolean;
  setLoadingStepData: (loadingStepData: boolean) => void;
  fbRequestUser: RequestUserType;
  setFbRequestUser: (fbRequestUserContext: RequestUserType) => void;
  loanApplication: LoanApplicationTypes | null;
  setLoanApplication: (loanApplicationContext: LoanApplicationTypes) => void;
  setStepDataState: (stepDataState: Step[] | null) => void;
  setStepDataStateReview: (stepDataState: StepData | null) => void;
  setListCustomerDocuments: (listCustomerDocuments: DocumentsBackend | RenderFieldBackend) => void;
  setListBrokerDocuments: (listBrokerDocuments: DocumentsBackend | RenderFieldBackend) => void;
  setListFrontingbankDocuments: (
    listFrontingbankDocuments: DocumentsBackend | RenderFieldBackend,
  ) => void;
  removeCustomerDocument: (id: number) => void;
  removeBrokerDocument: (id: number) => void;
  removeFrontingBankDocument: (id: number) => void;
  balanceSheetValues: BalanceSheetValuesType | undefined;
  setBalanceSheetValues: (value: BalanceSheetValuesType | undefined) => void;
  staticComponentsStatus: staticComponentsStatusType[];
  setStaticComponentsStatus: React.Dispatch<React.SetStateAction<staticComponentsStatusType[]>>;
  updateStaticComponentsStatus: (slug: string, newStatus: BlockStatus) => void;
  balanceSchufaValues: BalanceSchufaValuesType | undefined;
  setBalanceSchufaValues: (value: BalanceSchufaValuesType | undefined) => void;
  loanApplicationUsage: string;
  setLoanApplicationUsage: (value: string) => void;
  loanApplicationDuration: number;
  setLoanApplicationDuration: (value: number) => void;
  setBrokerDocuments: (value: Documents[]) => void;
  setCustomerDocuments: (value: Documents[]) => void;
  setFrontingbankDocuments: (value: Documents[]) => void;
  setLoadingPage: (value: boolean) => void;
  modalDeclineAgreementContractSigningPage: boolean;
  setModalDeclineAgreementContractSigningPage: (loadingStepData: boolean) => void;
  modalDeclineLoanContractSigningPage: boolean;
  setModalDeclineLoanContractSigningPage: (loadingStepData: boolean) => void;
  isDeclining: boolean;
  setIsDeclining: (loadingStepData: boolean) => void;
};

export const initialState: InitialStateType = {
  blocks: {},
  stepDataState: null,
  stepDataStateReview: null,
  customerDocuments: [],
  brokerDocuments: [],
  frontingbankDocuments: [],
  loanApplication: null,
  fbRequestUser: {
    Captiq: false,
    CP: false,
  },
  loadingStep: false,
  loadingReview: false,
  loanApplicationUsage: '',
  loanApplicationDuration: 0,
  balanceSheetValues: {},
  staticComponentsStatus: [],
  balanceSchufaValues: {},
  loadingPage: false,
  modalDeclineAgreementContractSigningPage: false,
  modalDeclineLoanContractSigningPage: false,
  isDeclining: false,
  registerBlock: () => {},
  registerQuestion: () => {},
  clearBlocks: () => {},
  updateBlockAfterDeletion: () => {},
  registerField: () => {},
  updateField: () => {},
  removeField: () => {},
  setLoanApplication: () => {},
  setFbRequestUser: () => {},
  saveStepData: () => {},
  setSaveStepData: () => {},
  setLoadingReviewPage: () => {},
  setLoadingStepData: () => {},
  setStepDataState: () => {},
  setStepDataStateReview: () => {},
  setListCustomerDocuments: () => {},
  setListBrokerDocuments: () => {},
  setListFrontingbankDocuments: () => {},
  removeCustomerDocument: () => {},
  removeBrokerDocument: () => {},
  removeFrontingBankDocument: () => {},
  setBalanceSheetValues: () => {},
  setStaticComponentsStatus: () => {},
  updateStaticComponentsStatus: () => {},
  setBalanceSchufaValues: () => {},
  setLoanApplicationUsage: () => {},
  setLoanApplicationDuration: () => {},
  setBrokerDocuments: () => {},
  setCustomerDocuments: () => {},
  setFrontingbankDocuments: () => {},
  setLoadingPage: () => {},
  setModalDeclineAgreementContractSigningPage: () => {},
  setModalDeclineLoanContractSigningPage: () => {},
  setIsDeclining: () => {},
};
