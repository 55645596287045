import {
  SET_COMPANIES,
  SET_PEOPLE,
  SET_MAIN_COMPANY,
  SET_REFS_OWNERS,
  SET_REFS_BENEFICIAL_OWNER,
  SET_CURRENT_LEGAL_FORM,
  SET_REFS_LEGAL_REPRESENTATIVES,
  SET_REFS_LOAN_APPLICANTS,
  SET_CUSTOMER_PROFILE_ID,
  SET_CUSTOMER_PROFILE_DATA,
  SET_STATE,
  Action,
  InitialValues,
} from './constants';

export const reducer = (state: InitialValues, action: Action) => {
  switch (action.type) {
    case SET_MAIN_COMPANY:
      return { ...state, mainCompany: action.mainCompany };
    case SET_PEOPLE:
      return { ...state, people: action.people };
    case SET_COMPANIES:
      return { ...state, companies: action.companies };
    case SET_CURRENT_LEGAL_FORM:
      return { ...state, currentLegalForm: action.currentLegalForm };
    case SET_REFS_OWNERS:
      return { ...state, refsOwners: action.refsOwners };
    case SET_REFS_BENEFICIAL_OWNER:
      return { ...state, refsBeneficialOwners: action.refsBeneficialOwners };
    case SET_REFS_LEGAL_REPRESENTATIVES:
      return { ...state, refsLegalRepresentatives: action.refsLegalRepresentatives };
    case SET_REFS_LOAN_APPLICANTS:
      return { ...state, refsLoanApplicants: action.refsLoanApplicants };
    case SET_CUSTOMER_PROFILE_ID:
      return { ...state, customerProfileId: action.customerProfileId };
    case SET_CUSTOMER_PROFILE_DATA:
      return { ...state, customerProfileData: action.customerProfileData };
    case SET_STATE:
      return { ...state, ...action.state };
    default:
      throw new Error(`Unhandled action type`);
  }
};
