import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  fieldLabel: {
    margin: 'auto 0px',
  },
  label: {
    wordBreak: 'break-word',
  },
  input: {
    '& input, div, span': {
      fontSize: '1rem',
    },
  },
  tooltip: {
    '&  span': {
      margin: '0 0 0 8px',
    },
  },
  typography: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
});

export default useStyles;
