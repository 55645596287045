import { Blocks } from 'utils/commonTypes/types';

type Question = {
  instances: {
    fields: {
      slug: string;
    }[];
  }[];
  section_path: string;
};

type BlockData = {
  instances: {
    questions: Question[];
  }[];
};

export const shouldHideQuestion = (
  hiddenFields?: { loanProcess?: string[] },
  question?: Question,
) => {
  if (!hiddenFields?.loanProcess?.length) {
    return false;
  }
  if (!question) {
    return true;
  }
  const lowerCaseHiddenFields = formatHiddenFields(hiddenFields);
  return !question?.instances?.some((element) =>
    element.fields?.some((field) => !lowerCaseHiddenFields.includes(field.slug)),
  );
};

export const shouldHideBlockData = (
  hiddenFields: { loanProcess?: string[] },
  blockData?: BlockData,
) => {
  if (!hiddenFields.loanProcess?.length) {
    return false;
  }
  const lowerCaseHiddenFields = formatHiddenFields(hiddenFields);

  const showRender = blockData?.instances?.some((instance) =>
    instance?.questions?.some((question) =>
      question?.instances?.some((element) =>
        element.fields?.some((field) => !lowerCaseHiddenFields.includes(field.slug)),
      ),
    ),
  );
  return !showRender;
};

export const removeHiddenQuestion = (
  hiddenFields: { loanProcess?: string[] },
  blockData: Blocks,
) => {
  const newBlock: Blocks = JSON.parse(JSON.stringify(blockData));
  if (hiddenFields.loanProcess?.length) {
    newBlock.instances.forEach((instance, InstanceIndex) => {
      const index = instance?.questions?.findIndex((question) =>
        shouldHideQuestion(hiddenFields, question),
      );

      if (index >= 0) {
        newBlock.instances[InstanceIndex].questions.splice(index, 1);
        if (!newBlock.instances[InstanceIndex].questions.length) {
          newBlock.instances.splice(InstanceIndex, 1);
        }
      }
    });
  }
  return newBlock;
};

export const formatHiddenFields = (hiddenFields: { loanProcess?: string[] }) =>
  (hiddenFields?.loanProcess || []).map((el) => el.toLowerCase());
