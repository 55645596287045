import React, { memo } from 'react';

import { Grid, Alert } from '@mui/material';
import { Info } from '@mui/icons-material';

import { WarningProps } from '../../types';

const WarningSideDeals = memo(({ allocation, sideDeal, getMinValSideDeal }: WarningProps) => (
  <>
    {allocation <= getMinValSideDeal && sideDeal === 'no' && (
      <Grid item xs={12}>
        <Alert icon={<Info />} severity="error">
          Wenn die Anteile weniger als {getMinValSideDeal}% betragen, muss auf eine andere Weise
          Kontrolle ausgeübt werden können, um als wirtschaftlich Berechtigter zu gelten.
        </Alert>
      </Grid>
    )}
  </>
));

export default WarningSideDeals;
