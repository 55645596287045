import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  titleText: {
    marginBottom: 18,
    fontSize: 17,
    fontWeight: 500,
    color: '#000',
  },
  text: {
    fontSize: 16,
    fontWeight: 300,
    color: '#000',
  },
  icon: {
    width: 18,
    height: 18,
  },
  innerContainer: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    marginBottom: 10,
  },
  buttonsContainer: {
    display: 'flex',
    gap: 12,
    width: '100%',
  },
  loading: {
    margin: '0 auto',
  },
});

export default useStyles;
