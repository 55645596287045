import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  formSelect: {
    marginBottom: '10px',
    '&:hover': {
      outline: 'none',
    },
  },
}));

export default useStyles;
