import {
  BackendPersonCompanyExistingList,
  BackendSimpleExtended,
  PersonCompanyExistingList,
} from 'utils/backendTypes';
import { Person, Company } from 'context/customer-context/types';

export const serializeExistingCompanyToContext = (
  existing: BackendPersonCompanyExistingList,
): PersonCompanyExistingList => {
  let existingArray: PersonCompanyExistingList = existing.customer_profile_related.map(
    (c: Person | Company) =>
      ({
        id: c.id,
        name: c.business,
        groupId: 0,
        business: c.business,
        legalForm: c.legal_form,
        shortLabel: c.short_label,
        city: c.business_address_city,
        houseNumber: c.business_address_house_n,
        plz: c.business_address_postcode,
        street: c.business_address_street,
      }) as unknown as BackendSimpleExtended,
  );
  existingArray = [
    ...existingArray,
    ...existing.cooperation_partner_related.map(
      (c: Person | Company) =>
        ({
          id: c.id,
          name: c.business,
          groupId: 1,
          business: c.business,
          legalForm: c.legal_form,
          shortLabel: c.short_label,
          city: c.business_address_city,
          houseNumber: c.business_address_house_n,
          plz: c.business_address_postcode,
          street: c.business_address_street,
        }) as unknown as BackendSimpleExtended,
    ),
  ];
  return existingArray;
};
