import { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';

import * as ApiCaptiq from 'service/ApiCaptiq';
import { request } from 'service/ApiCaptiq';
import Content from 'components/StaticBlocks/Owner/components/Content';
import { PersonForm } from 'components/StaticBlocks/BeneficialOwners/personForm';
import { CompanyForm } from 'components/StaticBlocks/BeneficialOwners/companyForm';
import { AddressForm as AddressBO } from 'components/StaticBlocks/BeneficialOwners/addressForm';
import {
  PersonalData as PersonalDataLA,
  Address as AddressLA,
  Contact,
} from 'components/StaticBlocks/LoanApplicants/components';
import FirstStep from 'components/FirstStep';
import AddressLR from 'components/StaticBlocks/LegalRepresentative/components/Address';
import PersonalDataLR from 'components/StaticBlocks/LegalRepresentative/components/PersonalData';
import { BLOCK_STATUS_EDIT } from 'constants/reviewBlocks';
import { Scaffold } from './type';

export const CaptiqReviewBlockPropTypes = {
  expanded: PropTypes.string,
  onChange: PropTypes.func,
  scaffold: PropTypes.shape({
    can_be_duplicated: PropTypes.bool,
    max_duplicated_number: PropTypes.number,
    order: PropTypes.number,
    questions: PropTypes.array,
    title: PropTypes.string,
    help_text: PropTypes.string,
    personIndex: PropTypes.number,
  }),
  classes: PropTypes.object.isRequired,
  stepOrder: PropTypes.number,
  loanApplication: PropTypes.object,
  loanId: PropTypes.number,
  sectionPath: PropTypes.string,
  onSave: PropTypes.func,
  submitForm: PropTypes.func,
  customColors: PropTypes.any,
  upperFormState: PropTypes.any,
  setActivePanel: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  newComments: PropTypes.array.isRequired,
  deleteField: PropTypes.func,
  isValid: PropTypes.bool,
  hiddenFields: PropTypes.object,
  currentTab: PropTypes.number,
  componentProps: PropTypes.object,
  changeLFContext: PropTypes.object,
  showErrorMessage: PropTypes.func,
  personID: PropTypes.number,
};

export const statusClassNames = {
  APPROVED: 'statusSuccess',
  DECLINED: 'statusDeclined',
  PENDING: 'statusPending',
  NOT_REVIEWED: 'statusNotReviewer',
  EDIT: 'statusEdit',
};

export const staticBaseComponents = [
  'OWNER',
  'BO_PERSON_DATA',
  'BO_ADDRESS',
  'BO_COMPANY_DATA',
  'LR_PERSONAL_DATA',
  'LR_ADDRESS',
  'LA_PERSONAL_DATA',
  'LA_ADDRESS',
  'LA_CONTACT',
  'FIRST_STEP',
];

export const RenderStaticComponents = ({
  blockData,
  currentTab,
  metaData,
  edit,
  personIndexTab,
  firstStepRef,
  loanApplication,
  setLoanApplication,
}: {
  // Any is used because the data comes from a js file
  blockData: any;
  currentTab: number;
  metaData: any;
  edit: boolean;
  personIndexTab: number;
  firstStepRef: any;
  loanApplication: any;
  setLoanApplication: any;
}) => {
  const loadLoanApplication = useCallback(async () => {
    try {
      const { data } = await request.get(`${ApiCaptiq.LOAN_APPLICATION_URL}${loanApplication.id}/`);
      setLoanApplication(data);
    } catch (e) {
      console.error(e);
    }
  }, [loanApplication?.id, setLoanApplication]);

  const getContent = () => {
    switch (blockData.based_component) {
      case 'FIRST_STEP':
        return (
          <Box margin={'32px 0px 0px 32px'} display="flex" justifyContent="center">
            {loanApplication && (
              <FirstStep
                ref={firstStepRef}
                // @ts-ignore
                data={loanApplication}
                disabledAll={!edit}
                onDataUpdated={loadLoanApplication}
              />
            )}
          </Box>
        );
      case 'OWNER':
        return (
          blockData.refsOwners[currentTab] && (
            <Content
              currentTab={currentTab}
              refOwner={blockData.refsOwners[currentTab]}
              isEditing={edit}
            />
          )
        );
      case 'BO_PERSON_DATA':
        return <PersonForm {...metaData} block={blockData} isEditing={edit} isReviewing />;
      case 'BO_ADDRESS':
        return <AddressBO {...metaData} block={blockData} isEditing={edit} isReviewing />;
      case 'BO_COMPANY_DATA':
        return <CompanyForm {...metaData} block={blockData} isEditing={edit} isReviewing />;
      case 'LR_PERSONAL_DATA':
        return (
          <PersonalDataLR
            {...blockData}
            block={blockData}
            isEditing={edit}
            isReviewing
            showDateOfBirthWarning={false}
          />
        );
      case 'LR_ADDRESS':
        return <AddressLR {...blockData} block={blockData} isEditing={edit} isReviewing />;
      case 'LA_PERSONAL_DATA':
        return (
          <PersonalDataLA
            formPerson={blockData.formPerson}
            personIndex={personIndexTab}
            isEditing={edit}
            isStartUpTemplate={blockData.isStartUpTemplate}
            showDateOfBirthWarning={false}
          />
        );
      case 'LA_ADDRESS':
        return <AddressLA personIndex={personIndexTab} isEditing={edit} />;
      case 'LA_CONTACT':
        return <Contact personIndex={personIndexTab} isEditing={edit} />;
      default:
        return null;
    }
  };
  return <>{getContent()}</>;
};

export const getBlockStatus = (
  scaffold: Scaffold,
  registerBlock: (path: string, mandatoryType: string | undefined) => void,
) => {
  const list = scaffold.instances.map((value) => {
    const questions = value.questions ? value.questions : [];
    const { mandatory_type: mandatoryType } =
      questions.find((question) => !!question.mandatory_type) || {};
    registerBlock(value.section_path, mandatoryType);
    return [value.section_path, value.status_display, value.status];
  });
  const blocksPaths = list.reduce((prev: any, curr) => {
    prev[curr[0] as keyof typeof prev] = {};
    return prev;
  }, {});
  const blocksStatus = list.reduce((prev: any, curr) => {
    prev[curr[0]] = { status_display: curr[1], status: curr[2] };
    return prev;
  }, {});

  return { blocksPaths, blocksStatus };
};

export const postSetData = async ({
  loanId,
  block_name,
  form_data,
}: {
  loanId: number;
  block_name: string;
  form_data: any;
}) => {
  try {
    await request.post(`${ApiCaptiq.LOAN_APPLICATION_URL}${loanId}/set_data/${block_name}/`, {
      form_data,
    });
  } catch (e) {
    console.error(e);
  }
};

export const updateExtraData = ({
  extraData,
  response,
  customExtraData,
  path,
  updateStaticComponentsStatus,
}: any) => {
  if (customExtraData && Object.keys(customExtraData).length > 0) {
    Object.keys(customExtraData).forEach((key) => {
      extraData[key].status = response.data.status;
      extraData[key].status_display = response.data.status_display;
    });
  } else {
    extraData[path].status = response.data.status;
    extraData[path].status_display = response.data.status_display;
    updateStaticComponentsStatus(path, response.data.status);
  }
  return extraData;
};

export const handleError =
  ({ showErrorMessage, setLoadingReviewPage }: any) =>
  (error: any) => {
    if (error?.response?.data)
      showErrorMessage(
        error.response.data.error_message ||
          'Es liegt ein Problem auf unserem Server vor. Bitte versuchen Sie es in einer Minute erneut.',
      );
    else {
      console.error(error);
    }
    setLoadingReviewPage(false);
  };

export const saveData =
  ({ state, firstStepRef, updateStateAndStatus, handleUnternehmensdaten, submitForm }: any) =>
  async (block_name: string) => {
    try {
      const { form, stepOrder, loanApplication } = state;

      if (stepOrder === 0 && firstStepRef.current) {
        await firstStepRef.current.saveStepData();
        updateStateAndStatus(block_name, BLOCK_STATUS_EDIT);
      } else if (Object.entries(form[block_name] || {}).length > 0) {
        await postSetData({
          loanId: loanApplication.id,
          block_name,
          form_data: state.form[block_name],
        });
        updateStateAndStatus(block_name, BLOCK_STATUS_EDIT);
        handleUnternehmensdaten(block_name);
      } else {
        submitForm();
        updateStateAndStatus(block_name, BLOCK_STATUS_EDIT);
      }
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

export const saveFinancingDocuments =
  ({ financingDocumentsRef, updateStateAndStatus, state }: any) =>
  async (block_name: string) => {
    const { extraData, loanApplication } = state;

    if (financingDocumentsRef.current) {
      await postSetData({
        block_name,
        form_data: financingDocumentsRef.current.values,
        loanId: loanApplication.id,
      });
      updateStateAndStatus(block_name, BLOCK_STATUS_EDIT, extraData);
    }
  };
