import {
  BLOCK_STATUS_APPROVED,
  BLOCK_STATUS_DECLINED,
  BLOCK_STATUS_EDIT,
  BLOCK_STATUS_NOT_REVIEWED,
  getStatusDisplay,
} from 'constants/reviewBlocks';
import {
  RefBeneficialOwner,
  RefLegalRepresentative,
  RefLoanApplicant,
  RefOwner,
  InitialValues,
} from 'context/customer-context/types';
import { Block, LegalForm, PersonOrCompany, YesOrNo } from 'utils/commonTypes/types';
import { Props } from '../LegalRepresentative/components/form';
import { BlockStatus, StaticComponentStatus } from '../Owner/types';

export const LegalRepresentativeScaffold = (
  refLegalRepresentative: RefLegalRepresentative,
  staticComponentsStatus: StaticComponentStatus[],
  block: Block,
  addComments: (number: number) => void,
  mainProps: Props,
) => {
  const personalDataBlockPath = `static__unternehmensstruktur__gesetzlichevertreter_personenbezogenedaten-${refLegalRepresentative.modelId}`;
  const addressBlockPath = `static__unternehmensstruktur__gesetzlichevertreter_anschrift-${refLegalRepresentative.modelId}`;
  const personalDataStatus = staticComponentsStatus.find(
    ({ path }: { path: string }) => path === personalDataBlockPath,
  );
  const addressStatus = staticComponentsStatus.find(
    ({ path }: { path: string }) => path === addressBlockPath,
  );
  return {
    personalData: {
      ...block,
      ...mainProps,
      section_path: personalDataBlockPath,
      title: 'PERSONENBEZOGENE DATEN',
      based_component: 'LR_PERSONAL_DATA',
      slug: personalDataBlockPath,
      addComments,
      instances: [
        {
          section_path: personalDataBlockPath,
          status: personalDataStatus?.status,
          status_display: getStatusDisplay(personalDataStatus?.status),
        },
      ],
    },
    addressData: {
      ...block,
      ...mainProps,
      section_path: addressBlockPath,
      title: 'ANSCHRIFT',
      based_component: 'LR_ADDRESS',
      slug: addressBlockPath,
      addComments,
      instances: [
        {
          section_path: addressBlockPath,
          status: addressStatus?.status,
          status_display: getStatusDisplay(addressStatus?.status),
        },
      ],
    },
  };
};

export const LoanApplicantScaffold = (
  refLoanApplicant: RefLoanApplicant,
  staticComponentsStatus: StaticComponentStatus[],
  BASE_BLOCK: Block,
) => {
  const personalDataBlockPath = `static__darlehensantragsteller_personenbezogenedaten-${refLoanApplicant.modelId}`;
  const addressBlockPath = `static__darlehensantragsteller_anschrift-${refLoanApplicant.modelId}`;
  const contactBlockPath = `static__darlehensantragsteller_kontakt-${refLoanApplicant.modelId}`;
  const personalDataStatus = staticComponentsStatus.find(
    ({ path }: { path: string }) => path === personalDataBlockPath,
  );
  const addressStatus = staticComponentsStatus.find(
    ({ path }: { path: string }) => path === addressBlockPath,
  );
  const contactStatus = staticComponentsStatus.find(
    ({ path }: { path: string }) => path === contactBlockPath,
  );

  return {
    personalData: {
      ...BASE_BLOCK,
      section_path: personalDataBlockPath,
      title: 'PERSONENBEZOGENE DATEN',
      based_component: 'LA_PERSONAL_DATA',
      slug: personalDataBlockPath,
      instances: [
        {
          section_path: personalDataBlockPath,
          status: personalDataStatus?.status,
          status_display: getStatusDisplay(personalDataStatus?.status),
        },
      ],
    },
    addressData: {
      ...BASE_BLOCK,
      section_path: addressBlockPath,
      title: 'ANSCHRIFT',
      based_component: 'LA_ADDRESS',
      slug: addressBlockPath,
      instances: [
        {
          section_path: addressBlockPath,
          status: addressStatus?.status,
          status_display: getStatusDisplay(addressStatus?.status),
        },
      ],
    },
    contactData: {
      ...BASE_BLOCK,
      section_path: contactBlockPath,
      title: 'KONTAKT',
      based_component: 'LA_CONTACT',
      slug: contactBlockPath,
      instances: [
        {
          section_path: contactBlockPath,
          status: contactStatus?.status,
          status_display: getStatusDisplay(contactStatus?.status),
        },
      ],
    },
  };
};

export const OwnerScaffold = (
  refsOwners: RefOwner[],
  staticComponentsStatus: StaticComponentStatus[],
  TITLE_OW: string,
  block: Block,
  addComments: (number: number) => void,
  currentTab: number,
) =>
  refsOwners.map((ref) => {
    const blockPath = `static__unternehmensstruktur__inhaber-${ref._type[0]}${ref.modelId}`;
    const status = staticComponentsStatus.find(({ path }: { path: string }) => path === blockPath);
    return {
      ...block,
      title: TITLE_OW,
      section_path: blockPath,
      addComments,
      slug: blockPath,
      instances: [
        {
          section_path: blockPath,
          status: status?.status,
          status_display: getStatusDisplay(status?.status),
        },
      ],
      refsOwners,
      personIndex: ref.id,
      currentTab,
    };
  });

export const BeneficialOwnerScaffold = (
  beneficialOwnersInLevel: RefBeneficialOwner[],
  staticComponentsStatus: StaticComponentStatus[],
  block: Block,
  addComments: (number: number) => void,
  values: InitialValues,
) =>
  beneficialOwnersInLevel.map((ref) => {
    const blockPath = `static__unternehmensstruktur__wirtschaftlichberechtigte-${ref._type[0]}${ref.modelId}`;
    const status = staticComponentsStatus.find(({ path }: { path: string }) => path === blockPath);

    const scaffold = {
      personalData: {
        ...block,
        title: 'PERSONENBEZOGENE DATEN',
        section_path: blockPath,
        slug: blockPath,
        based_component: ref._type === 'person' ? 'BO_PERSON_DATA' : 'BO_COMPANY_DATA',
        legalForm: values.company?.data.legalForm as LegalForm,
        valuesPeople: values.people,
        addComments,
        instances: [
          {
            section_path: blockPath,
            status: status?.status,
            status_display: getStatusDisplay(status?.status),
          },
        ],
      },
      addressData: null,
    };
    if (ref._type === 'person') {
      const addressBlockPath = `static__unternehmensstruktur__wirtschaftlichberechtigte_anschrift-${ref.modelId}`;
      const addressStatus = staticComponentsStatus.find(
        ({ path }: { path: string }) => path === addressBlockPath,
      );
      scaffold.addressData = {
        ...block,
        title: 'ANSCHRIFT',
        section_path: addressBlockPath,
        slug: addressBlockPath,
        based_component: 'BO_ADDRESS',
        legalForm: values.company?.data.legalForm as LegalForm,
        valuesPeople: values.people,
        addComments,
        instances: [
          {
            section_path: addressBlockPath,
            status: addressStatus?.status,
            status_display: getStatusDisplay(addressStatus?.status),
          },
        ],
      } as any;
    }
    return scaffold;
  });

export const createRelatedPathsModal = (
  isBeneficialOwner: YesOrNo,
  isOwner: YesOrNo,
  isLegalRepresentative: YesOrNo,
  isLoanApplicant: YesOrNo,
  personOrCompany: PersonOrCompany,
  personOrCompanyId: string,
) => {
  const newRoles: string[] = [];
  if (isOwner === 'yes' && personOrCompany === 'person') newRoles.push('OWP');
  if (isOwner === 'yes' && personOrCompany !== 'person') newRoles.push('OWC');
  if (isBeneficialOwner === 'yes' && personOrCompany === 'person') newRoles.push('BOP');
  if (isBeneficialOwner === 'yes' && personOrCompany !== 'person') newRoles.push('BOC');
  if (isLegalRepresentative === 'yes') newRoles.push('LRP');
  if (isLoanApplicant === 'yes') newRoles.push('LAP');
  const paths: string[] = newRoles.flatMap((role: string) => {
    const AVAILABLE_PATHS: { [key: string]: string | string[] } = {
      OWP: `static__unternehmensstruktur__inhaber-p${personOrCompanyId}`,
      OWC: `static__unternehmensstruktur__inhaber-c${personOrCompanyId}`,
      BOP: [
        `static__unternehmensstruktur__wirtschaftlichberechtigte-p${personOrCompanyId}`,
        `static__unternehmensstruktur__wirtschaftlichberechtigte_anschrift-${personOrCompanyId}`,
      ],
      BOC: `static__unternehmensstruktur__wirtschaftlichberechtigte-c${personOrCompanyId}`,
      LRP: [
        `static__unternehmensstruktur__gesetzlichevertreter_personenbezogenedaten-${personOrCompanyId}`,
        `static__unternehmensstruktur__gesetzlichevertreter_anschrift-${personOrCompanyId}`,
      ],
      LAP: [
        `static__darlehensantragsteller_personenbezogenedaten-${personOrCompanyId}`,
        `static__darlehensantragsteller_anschrift-${personOrCompanyId}`,
        `static__darlehensantragsteller_kontakt-${personOrCompanyId}`,
      ],
    };
    return AVAILABLE_PATHS?.[role as string];
  });

  return paths;
};

export const getResultantStatus = (status: Array<string>) => {
  const statusSet = new Set(status);
  if (![...statusSet].length) return BLOCK_STATUS_NOT_REVIEWED;
  if (statusSet.has(BLOCK_STATUS_DECLINED)) return BLOCK_STATUS_DECLINED;
  if (statusSet.has(BLOCK_STATUS_NOT_REVIEWED) || statusSet.has(BLOCK_STATUS_EDIT))
    return BLOCK_STATUS_NOT_REVIEWED;
  if ([...statusSet].every((statusItem) => statusItem === BLOCK_STATUS_APPROVED))
    return BLOCK_STATUS_APPROVED;
};

export const getGeneralStatus = (tabs: Array<any>) => {
  let status = '';
  const isApproved = tabs.every((item) => item.reviewStatus === 'APPROVED');
  const isDeclined = tabs.every((item) => item.reviewStatus === 'DECLINED');
  const isMixed = tabs.every((item) => !(item.reviewStatus === 'DECLINED'));

  if (isApproved) status = 'APPROVED';
  else if (isDeclined) status = 'DECLINED';
  else if (isMixed) status = 'NOT_REVIEWED';
  else status = 'DECLINED';
  return { status: status as BlockStatus, label: getStatusDisplay(status) };
};

export const getStatusesBO = (
  refs: RefBeneficialOwner[],
  staticComponentsStatus: StaticComponentStatus[],
): { reviewStatus: BlockStatus }[] => {
  let statuses: { reviewStatus: BlockStatus }[] = [];
  refs.forEach((item) => {
    const blockPath = `static__unternehmensstruktur__wirtschaftlichberechtigte-${item._type[0]}${item.modelId}`;
    const status = staticComponentsStatus.find(({ path }: { path: string }) => path === blockPath);
    if (status) statuses.push({ reviewStatus: status.status });
    if (item._type === 'person') {
      const addressPath = `static__unternehmensstruktur__wirtschaftlichberechtigte_anschrift-${item.modelId}`;
      const addressStatus = staticComponentsStatus.find(
        ({ path }: { path: string }) => path === addressPath,
      );
      if (addressStatus) statuses.push({ reviewStatus: addressStatus.status });
    }
    if (item.children && item.children.length > 0) {
      statuses = [...statuses, ...getStatusesBO(item.children, staticComponentsStatus)];
    }
  });
  return statuses;
};
