import { LegalForm } from 'utils/commonTypes/types';

// Related to the legal form key
export const GbRLegalFormKey = 'GbR';
export const PartGLegalFormKey = 'PartG';
export const PartGmbBLegalFormKey = 'PartGmbB';

// Related to the legal form label
export const GbRLegalForm = 'Gesellschaft bürgerlichen Rechts (GbR)';
export const FreelanceLegalForm = 'Fb';
export const FreelanceLegalFormLabel = 'Freiberufler';
export const EKLegalForm = 'eK';
export const EKLegalFormLabel = 'Eingetragener Kaufmann (e.K.)';
export const PartGLegalForm = 'Partnerschaftsgesellschaft (PartG)';
export const PartGmbBLegalForm =
  'Partnerschaftsgesellschaft mit beschränkter Berufshaftung (PartG mbB)';

export const EXCEPTION_A: LegalForm[] = ['eK', 'Fb'];
export const EXCEPTION_C: LegalForm[] = ['PartG', 'PartGmbB'];
