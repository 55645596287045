import { createContext, useContext } from 'react';

import { useDataContext } from 'context/data-context';
import { IDynamicTextsProvider } from './types';
import { initialState } from './constants';
import {
  getApplicantOrSignatory,
  getCompanyOrApplicantCompany,
  getContractSignatoryOrLoanApplicant,
} from './utils';

const DynamicTextsContext = createContext(initialState);

const DynamicTextsProvider = ({ children }: IDynamicTextsProvider) => {
  const { loanApplicationUsage } = useDataContext();
  const contractSignatoryOrLoanApplicant =
    getContractSignatoryOrLoanApplicant(loanApplicationUsage);
  const companyOrApplicantCompany = getCompanyOrApplicantCompany(loanApplicationUsage);
  const applicantOrSignatory = getApplicantOrSignatory(loanApplicationUsage);

  const value = {
    contractSignatoryOrLoanApplicant,
    companyOrApplicantCompany,
    applicantOrSignatory,
  };

  return <DynamicTextsContext.Provider value={value}>{children}</DynamicTextsContext.Provider>;
};

export const useDynamicTexts = () => {
  const context = useContext(DynamicTextsContext);

  return context;
};

export default DynamicTextsProvider;
