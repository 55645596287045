import { NumericFormat } from 'react-number-format';

export function CurrencyFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      fixedDecimalScale
    />
  );
}

export function TelephoneNumberFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      allowLeadingZeros
      onValueChange={(values) => {
        onChange({ target: { value: values.value } });
      }}
      getInputRef={inputRef}
    />
  );
}

export function PercentageFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      decimalScale={2}
      fixedDecimalScale
    />
  );
}
