import { TextField } from '@mui/material';

import useStyles from './CustomSelectStyles';

const CustomSelect = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <TextField
      select
      className={classes.formSelect}
      {...props}
      fullWidth
      variant="outlined"
      margin="dense"
    >
      {children}
    </TextField>
  );
};

export default CustomSelect;
