import makeStyles from '@mui/styles/makeStyles';

export default makeStyles({
  dialogTitle: {
    paddingBottom: 0,
  },
  contentContainer: {
    width: '100%',
  },
  emailContainer: {
    fontSize: 16,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #cacaca',
    borderRadius: 5,
    padding: 10,
    backgroundColor: '#fafafa',
    marginBottom: 20,
  },
  downloadButton: {
    padding: '3px 50px',
    fontSize: 17,
    textTransform: 'unset',
    marginBottom: 10,
  },
  divider: {
    marginBottom: 30,
    marginTop: 30,
  },
  text: {
    fontSize: 17,
    marginBottom: 10,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialogActions: {
    padding: '16px 24px',
    justifyContent: 'flex-start',
  },
  cancelButton: {
    color: 'white',
    background: '#cac9c9',
    padding: '3px 50px',
    fontSize: 17,
    textTransform: 'unset',
    '&:hover': {
      backgroundColor: '#b1b1b1',
    },
  },
});
