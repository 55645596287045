import React from 'react';
import { useFormikContext } from 'formik';

import { useCustomerData } from 'context/customer-context';
import { useBlockData } from 'context/block-context';
import { useChangeLFC } from 'context/changelf-context';
import { useComments } from 'service/useComments';
import useStyles from './styles';
import { Scaffold } from './type';

interface Props {
  scaffold: Scaffold;
  setActivePanel?: (panel: any, expanded: any) => void;
  loanApplication?: any;
  sectionPath: string;
  onChange?: (name: string, value: any) => void;
  deleteField?: (field: string) => void;
  expanded?: string;
  upperFormState?: any;
  customColors?: any;
  loanId?: number;
  stepOrder?: number;
  metaData?: any;
  currentTab?: number;
  componentProps?: any;
  onSave?: (loan_application_status: any, status: string) => void;
  showErrorMessage?: (message: string) => void;
  personID?: number;
}

const withWrapper = (WrappedComponent: any) => (props: Props) => {
  const styles = useStyles();
  const actions = useFormikContext();
  const { hiddenFields, mainCompany } = useCustomerData();
  const { expanded } = useBlockData();
  const changeLFContext = useChangeLFC();
  const loanApplicationId = props.loanApplication?.id;
  const { data: newComments } = useComments(loanApplicationId);

  return (
    <WrappedComponent
      {...props}
      newComments={newComments || []}
      expanded={props.expanded ? props.expanded : expanded}
      submitForm={actions?.submitForm}
      resetForm={actions?.resetForm}
      isValid={actions?.isValid}
      mainCompanyLegalForm={mainCompany?.data.legalForm}
      hiddenFields={hiddenFields || {}}
      changeLFContext={changeLFContext}
      classes={styles}
    />
  );
};

export default withWrapper;
