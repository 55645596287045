import classNames from 'classnames';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import SnackbarContent from '@mui/material/SnackbarContent';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, SnackbarCloseReason } from '@mui/material';

import useStyles from './styles';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

export type VariantSnackContent = keyof typeof variantIcon;

interface Props {
  className?: string;
  message?: React.ReactNode;
  onClose?: (event: React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => void;
  variant: VariantSnackContent;
}

const SnackContent = (props: Props) => {
  const { className, message, onClose, variant, ...other } = props;
  const styles = useStyles();
  const Icon = variantIcon[variant];
  let actionButton;

  if (onClose)
    actionButton = (
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={onClose as () => void}
        size="large"
      >
        <CloseIcon className={styles.icon} />
      </IconButton>
    );

  return (
    <SnackbarContent
      className={classNames(styles[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={styles.message}>
          <Icon className={classNames(styles.icon, styles.iconVariant)} />
          <Box mr={4}>{message}</Box>
        </span>
      }
      classes={{ action: styles.actionButton }}
      action={[actionButton]}
      {...other}
    />
  );
};

export default SnackContent;
