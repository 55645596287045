import moment from 'moment';
import validator from 'validator';
import _get from 'lodash/get';

import { checkValidateDates, validateDates } from 'context/data-context';
import { isFatca } from 'utils/validateStaticBlocksValues/isFatca';
import { getCurrencyValue, hideBlockOnCondition } from 'utils/utils';
import getCountryCode from 'utils/getCountryPrefix';
import { Blocks, CountryType, Instance, QuestionField, Step } from 'utils/commonTypes/types';
import { staticComponentsStatusType } from 'context/data-context/types';
import validatePhoneNumber from 'utils/dataHandlers/validatePhoneNumber';
import { MainCompany } from 'context/customer-context/types';
import { shouldHideBlockData } from 'utils/dataHandlers';
import { LEGAL_FORM_SLUG } from 'constants/defaults';
import { YES, NO } from 'constants/messages';
import { BLOCK_STATUS_APPROVED } from 'constants/reviewBlocks';
import FileButton from './components/FileButton';

export const getValue = (field: QuestionField) => {
  let value;
  switch (field.field_type) {
    case 'CHOICE':
      value = field.choice_value_display ? field.choice_value_display : field.value;
      break;
    case 'DECIMAL':
      if (field.field_mask === 'EUR-CURRENCY') {
        value = getCurrencyValue(field.value as number);
      } else if (field.field_mask === 'PERCENTAGE') {
        value = field.value ? `${(field.value as number).toFixed(2)} %` : '';
      } else {
        value = field.value;
      }
      break;
    case 'FILE':
      value = <FileButton field={field} />;
      break;
    case 'BOOLEAN':
      value = field.value ? YES : NO;
      break;
    case 'DATE': {
      const value_date = moment(field.value as string);
      value = value_date.isValid() ? value_date.format('DD.MM.YYYY') : field.value;
      break;
    }
    default:
      value = field.value;
  }
  return value;
};

export const specialConditions = (
  field: any,
  tempTotalRequiredFields: number,
  tempCompleted: number,
  blockInstance: any,
  countryList: CountryType[],
): [boolean, number, number] => {
  let skip = false;
  if (isFatca(field.slug)) {
    skip = true;
    tempTotalRequiredFields += 1;
    if (field.value === 'False' || field.value === false) tempCompleted += 1;
  }
  if (
    field.is_required &&
    field.field_type === 'DATE' &&
    validateDates.includes(field.field_validator) &&
    !skip
  ) {
    skip = true;
    tempTotalRequiredFields += 1;
    if (checkValidateDates(field.value, field.field_validator)) tempCompleted += 1;
  }
  if (field.field_validator === 'FOUNDATION_DATE_STARTUP' && field.value !== null && !skip) {
    if (!checkValidateDates(field.value, field.field_validator)) {
      skip = true;
      tempTotalRequiredFields += 1;
    }
  }
  if (field.is_required && field.field_validator === 'EMAIL' && !skip) {
    skip = true;
    tempTotalRequiredFields += 1;
    if (field.value && validator.isEmail(field.value)) tempCompleted += 1;
  }
  if (field.is_required && field.field_validator === 'PHONE' && !skip) {
    skip = true;
    tempTotalRequiredFields += 1;
    const internationalPrefixCode: string = _get(
      blockInstance,
      `questions[1].instances[0].fields[0].value`,
    ) as unknown as string;
    const internationalPrefix = getCountryCode(countryList, internationalPrefixCode);
    if (field.value && !validatePhoneNumber(field.value, String(internationalPrefix))[0])
      tempCompleted += 1;
  }
  return [skip, tempTotalRequiredFields, tempCompleted];
};

export const getFilteredComments = (newComments: any, sectionPath: any) => {
  if (newComments && newComments.length > 0) {
    return newComments.filter(
      (comment: any) => comment.path === sectionPath && comment.type === 'IC',
    );
  }
  return [];
};

export const neglectEuroSignOnDecimal = (value: string) => Number(value).toLocaleString('de-DE');

export const countReviewStatus = (
  stepsData: Step[],
  mainCompany: MainCompany | null,
  hiddenFields: { loanProcess?: string[] } = { loanProcess: [] },
  staticComponentsStatus: staticComponentsStatusType[],
) => {
  let totalBlocksToReview = 0;
  let approvedBlocks = 0;
  stepsData.map((step) =>
    step.instances.map((step_instance) =>
      step_instance.blocks.map((block) => {
        if (block.depending_field) {
          if (
            block.depending_field === LEGAL_FORM_SLUG &&
            step.based_component === 'LOAN_APPLICANT'
          ) {
            if (
              hideBlockOnCondition(
                block.depending_field,
                block.visibility_value,
                mainCompany?.data.legalForm as string,
              )
            )
              return;
          }
        }

        if (shouldHideBlockData(hiddenFields, block as any)) {
          return;
        }
        if (block.based_component) return;
        return (
          block.instances &&
          block.instances.forEach((block_instance) => {
            totalBlocksToReview += 1;
            if (block_instance.status === BLOCK_STATUS_APPROVED) approvedBlocks += 1;
          })
        );
      }),
    ),
  );

  staticComponentsStatus.forEach((comp) => {
    totalBlocksToReview += 1;
    if (comp.status === BLOCK_STATUS_APPROVED) approvedBlocks += 1;
  });

  return {
    total: totalBlocksToReview,
    reviewed: approvedBlocks,
    percentCompleted: (100 * approvedBlocks) / totalBlocksToReview,
  };
};

const getFoundation = (step_instance: Instance) => {
  const block = step_instance?.blocks?.find((q) => q.title === 'Zusatzdaten');
  const question = block?.instances[0]?.questions?.find((q) => q.title === 'Gründungsdatum');
  return question
    ? new Date((question?.instances[0]?.fields[0]?.value as string) || '').getFullYear()
    : '';
};

const getFirstDropdownValue = (block: Blocks) => {
  const field = block?.instances[0]?.questions[0]?.instances[0]?.fields[0];
  return field?.slug?.includes('dropdown_by_file') ? field.value : 'ja';
};

export const shouldRenderDependingBlock = (
  isDependingAccounting: boolean,
  newBlock: Blocks,
  step_instance: Instance,
  yearBlock: string,
) => {
  if (!isDependingAccounting) return true;

  const foundation = getFoundation(step_instance);

  const canShowNextYear = Number(yearBlock) >= Number(foundation);

  const isCurrentYear = Number(yearBlock) === Number(new Date().getFullYear());

  const firstDropdown = getFirstDropdownValue(newBlock);

  const canShowNextBlock = firstDropdown !== 'ja';

  if (isCurrentYear) return true;

  return canShowNextYear && canShowNextBlock;
};

export const getYearInHGBlock = (index: number, savedData: any) => {
  // TODO: Use the Year Display to get the block year
  let year = 0;
  Object.keys(savedData).some((k) => {
    if (k.startsWith(`${index}__`) && k.includes('_year_')) {
      year = savedData[k];
      return true;
    }
    return false;
  });
  return Number(year);
};
