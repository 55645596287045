import classNames from 'classnames';
import React, { Children, memo, ReactNode, useCallback, useMemo, useEffect } from 'react';
import Lottie from 'lottie-react-web';

import {
  FormControl,
  InputLabel,
  Select,
  InputAdornment,
  MenuItem,
  TextField,
  Box,
} from '@mui/material';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';

import CheckAnimation from 'animation/check.json';
import Adornment from 'components/Adornment';
import { HGB_ACCORDANCE } from 'constants/defaults';
import { useCustomerData } from 'context/customer-context';
import useStyles from './styles';

interface GenericFieldWrapperProps {
  name: string;
  type: string;
  displayLabel: string;
  /*
    This come from a js file
    TODO: Define when parent component changes to TS
  */
  data: any;
  numColumn: number;
  children: ReactNode;
}

interface Options {
  value: string;
  name: string;
}

interface ChoicesFieldProps {
  /*
    This come from a js file
    TODO: Define when parent component changes to TS
  */
  commonProps: any;
  value: any;
  choice_group: any;
  handleChange: any;
  disabled: boolean;
  inputRef: React.Ref<HTMLInputElement>;
  field_mask: string;
  field_type: string;
  slug?: string;
}

const getOptionRender = {
  countryType: (props: any, option: any) => (
    <Box
      sx={{
        borderRadius: '8px',
        margin: '5px',
        [`&.${autocompleteClasses.option}`]: {
          padding: '8px',
        },
      }}
      component="li"
      data-name={option.value}
      data-testid="autocomplete-option"
      {...props}
    >
      {option.name}
    </Box>
  ),
};

// TODO: move to the inputRender file when translated to TypeScript
export const ChoicesField = memo(
  ({
    commonProps,
    disabled,
    value,
    choice_group,
    handleChange,
    inputRef,
    field_mask,
    field_type,
  }: ChoicesFieldProps) => {
    const classes = useStyles();
    const { setHgbAccordance } = useCustomerData();

    useEffect(() => {
      if (commonProps.name === HGB_ACCORDANCE) {
        setHgbAccordance(value === 'True');
      }
    }, [commonProps.name, setHgbAccordance, value]);

    if (!value) value = '';

    const handleGetOptionLabel = useCallback((option: Options) => option.name, []);

    const handleOnChange = useCallback(
      (_: React.ChangeEvent<{}>, val: Options | null) => {
        const event = { target: { value: val?.value } };
        if (val?.value) handleChange(event);
      },
      [handleChange],
    );

    const handleRenderInput = useCallback(
      (params: any) => {
        const { InputProps } = params;
        const prevEndAdornment = InputProps.endAdornment;
        InputProps.endAdornment = (
          <>
            {value && <Adornment classes={classes} />}
            {prevEndAdornment}
          </>
        );

        return <TextField {...params} variant="outlined" InputProps={InputProps} />;
      },
      [value, classes],
    );

    const getOptionSelected = useCallback(
      (option: Options, paramValue: Options) =>
        option && paramValue ? option.value === paramValue.value : false,
      [],
    );

    const handleGetOptionRender = useCallback(
      (props: any, option: any) => getOptionRender.countryType(props, option),
      [],
    );

    const handleOnOpenCountryList = useCallback(() => {
      const getOptionScroller = {
        value: 'DE',
      };
      setTimeout(() => {
        const optionEl = document.querySelector(`[data-name="${getOptionScroller.value}"]`);
        optionEl?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }, 1);
    }, []);

    const getValue = useMemo(
      () => choice_group.choices.find((item: Options) => item.value === value),
      [choice_group.choices, value],
    );

    // TODO: Improve this to not use the handleChange to keeps the correct values
    if (
      value &&
      choice_group.choices &&
      choice_group.choices.length !== 0 &&
      !choice_group.choices.some((i: Options) => i.value === value)
    ) {
      const event = { target: { value } };
      event.target.value = choice_group.choices[0].value;
      handleChange(event);
      return null;
    }

    return (
      <FormControl variant="outlined" fullWidth={commonProps.fullWidth}>
        {field_mask === 'LONGER' ? (
          field_type === 'CHOICE' ? (
            <Autocomplete
              data-testid="autocomplete-choice"
              options={choice_group.choices}
              getOptionLabel={handleGetOptionLabel}
              renderInput={handleRenderInput}
              value={getValue}
              isOptionEqualToValue={getOptionSelected}
              onChange={handleOnChange}
              renderOption={handleGetOptionRender}
              onOpen={handleOnOpenCountryList}
              disabled={disabled}
            />
          ) : (
            <Autocomplete
              options={choice_group.choices}
              getOptionLabel={handleGetOptionLabel}
              renderInput={handleRenderInput}
              value={getValue}
              isOptionEqualToValue={getOptionSelected}
              onChange={handleOnChange}
            />
          )
        ) : (
          <>
            <InputLabel
              classes={{
                root: classes.selectLabel,
                shrink: classes.shrink,
              }}
              disableAnimation
            >
              {commonProps.placeholder}
            </InputLabel>
            <Select
              inputRef={inputRef}
              disabled={disabled}
              endAdornment={
                value && (
                  <InputAdornment component="div" position="end" className={classes.endAdornment}>
                    <Lottie
                      width={30}
                      height={30}
                      options={{
                        animationData: CheckAnimation,
                        loop: false,
                      }}
                      speed={2}
                    />
                  </InputAdornment>
                )
              }
              className={value && classes.completeField}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              onChange={handleChange}
              value={value}
              sx={{
                '& .MuiSelect-select': {
                  paddingBlock: '8.5px',
                },
              }}
            >
              {choice_group.name === 'Title' ? <MenuItem value="">-</MenuItem> : ''}
              {Children.toArray(
                choice_group.choices?.map((i: any) => (
                  <MenuItem value={i.value}>{i.name}</MenuItem>
                )),
              )}
              {choice_group.choices?.length === 0 ? (
                <MenuItem disabled>Dieses Feld ist leer</MenuItem>
              ) : (
                ''
              )}
            </Select>
          </>
        )}
      </FormControl>
    );
  },
);

export const UploadFieldWrapper = memo(({ children }: { children: ReactNode }) => {
  const classes = useStyles();
  return <div className={classes.uploadFieldContainer}>{children}</div>;
});

export const GenericFieldWrapper = memo(
  ({ displayLabel, type, data, numColumn, children }: GenericFieldWrapperProps) => {
    const classes = useStyles();

    return (
      <>
        {displayLabel ? (
          <div
            className={classNames(
              classes.inputLabel,
              numColumn ? classes.inputLabelVertical : null,
              type === 'RICH_TEXT' ? classes.inputLabelRichText : null,
            )}
          >
            <div>
              {data.label}
              {data.is_required ? '*' : null}
            </div>
          </div>
        ) : null}
        <div
          className={
            displayLabel && !data.full_width
              ? classNames(
                  classes.fieldContainer,
                  type === 'RICH_TEXT' && classes.fieldContainerRichText,
                  numColumn && classes.fieldContainerVertical,
                )
              : classes.fieldContainerFull
          }
        >
          {children}
        </div>
      </>
    );
  },
);
