import React from 'react';

export const replaceWithNode = (word: string, text: string, node: React.ReactNode) => {
  const words = text.split(word);
  if (words.length === 2)
    return (
      <>
        {words[0]}
        <u>{node}</u>
        {words[1]}
      </>
    );

  return text;
};
