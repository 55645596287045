export const yesEmail = (
  <>
    <span>Sehr geehrte/r Frau/Herr ...,</span>
    <br />
    <br />
    <span>
      für die Beantragung eines Darlehens bei der CAPTIQ GmbH werden folgende Dokumente{' '}
      <u>als PDF</u> benötigt:
    </span>
    <ul>
      <li>
        <span>
          Letzte Bilanz inkl. Gewinnermittlung und Kontennachweis <u>nicht älter als 15 Monate</u>
        </span>
      </li>
      <ul>
        <li>
          <span>
            Bitte darauf achten, dass die{' '}
            <b>
              <u>Gewinnermittlung und die Kontennachweise</u>
            </b>{' '}
            beigefügt sind.
          </span>
        </li>
      </ul>
      <li>
        <span>
          Bilanz inkl. Gewinnermittlung und Kontennachweis des <u>Vorjahres</u> der oben
          hochgeladenen Bilanz
        </span>
      </li>
      <ul>
        <li>
          <span>
            Sollte die letzte Bilanz bereits den Vorjahresvergleich beinhalten, ist die separate
            Bilanz des Vorjahres nicht zwingend erforderlich.
          </span>
        </li>
      </ul>
      <li>
        <span>Aktuelle BWA (nicht älter als 3 Monate)</span>
      </li>
      <li>
        <span>
          Aktuelle vollständig eröffnete Summen- und Saldenliste (nicht älter als 3 Monate)
        </span>
      </li>
    </ul>
    <span>Bitte lassen Sie mir die Dokumente als PDF zukommen.</span>
    <br />
    <br />
    <span>Herzlichen Dank.</span>
  </>
);

export const noEmail = (
  <>
    <span>Sehr geehrte/r Frau/Herr ...,</span>
    <br />
    <br />
    <span>
      für die Beantragung eines Darlehens bei der CAPTIQ GmbH werden folgende Dokumente{' '}
      <u>als PDF</u> benötigt:
    </span>
    <ul>
      <li>
        <span>
          Letzte Gewinnermittlung (inkl. Kontennachweis) <u>nicht älter als 15 Monate</u>
        </span>
      </li>
      <ul>
        <li>
          <span>
            Bei den Kontennachweisen bzw. nachrichtlichem Teil bitte <u>zwingend</u> darauf achten,
            dass auch alle <u>sonstigen Konten</u> (Bestandskonten wie z.B. Bankkonten) ausgewiesen
            werden.
          </span>
          <br />
          <span>
            Wenn diese Konten fehlen, kann leider keine Bonitätsprüfung durchgeführt werden.
          </span>
        </li>
      </ul>
      <li>
        <span>
          Gewinnermittlung (inkl. Kontennachweis) des <u>Vorjahres</u> der oben hochgeladenen
          Gewinnermittlung
        </span>
      </li>
      <ul>
        <li>
          <span>
            Sollte die letzte Gewinnermittlung bereits den Vorjahresvergleich beinhalten, ist die
            separate Gewinnermittlung des Vorjahres nicht zwingend erforderlich.
          </span>
        </li>
      </ul>
      <li>
        <span>Aktuelle BWA (nicht älter als 3 Monate)</span>
      </li>
      <li>
        <span>
          Aktuelle vollständig eröffnete Summen- und Saldenliste (nicht älter als 3 Monate)
        </span>
      </li>
    </ul>
    <span>Bitte lassen Sie mir die Dokumente als PDF zukommen.</span>
    <br />
    <br />
    <span>Herzlichen Dank.</span>
  </>
);

export const yesEmailV2 = (
  <>
    <span>Sehr geehrte/r Frau/Herr ...,</span>
    <br />
    <br />
    <span>
      für die Beantragung eines Darlehens bei der CAPTIQ GmbH werden folgende Dokumente{' '}
      <u>als PDF</u> benötigt:
    </span>
    <ul>
      <li>
        <span>
          Vom Steuerberater gestempelte und unterschriebene Bilanz 2023 (inkl. Vorjahresvergleich
          und Gewinnermittlung + Kontennachweise){' '}
          <b>
            <u>oder</u>
          </b>{' '}
          Jahres-BWA und Jahres-Summen- und Saldenliste 2023
        </span>
      </li>
      <ul>
        <li>
          <span>
            Sollten die Finanzkennzahlen für 2023 nur in Form der Jahres-BWA und Jahres-Summen- und
            Saldenliste vorliegen, so wird{' '}
            <b>
              <u>zusätzlich</u>
            </b>{' '}
            die vom Steuerberater gestempelte und unterschriebene Bilanz 2022 (inkl.
            Vorjahresvergleich und Gewinnermittlung + Kontennachweise) benötigt.
          </span>
        </li>
      </ul>
      <li>
        <span>Aktuelle BWA (nicht älter als 5 Monate)</span>
      </li>

      <li>
        <span>
          Aktuelle vollständig eröffnete Summen- und Saldenliste (nicht älter als 5 Monate)
        </span>
      </li>
    </ul>
    <span>Bitte lassen Sie mir die Dokumente als PDF zukommen.</span>
    <br />
    <br />
    <span>Herzlichen Dank.</span>
  </>
);

export const noEmailV2 = (
  <>
    <span>Sehr geehrte/r Frau/Herr ...,</span>
    <br />
    <br />
    <span>
      für die Beantragung eines Darlehens bei der CAPTIQ GmbH werden folgende Dokumente{' '}
      <u>als PDF</u> benötigt:
    </span>
    <ul>
      <li>
        <span>
          Vom Steuerberater gestempelte und unterschriebene Gewinnermittlung 2023 (inkl.
          Vorjahresvergleich und Ausweisung der Sonstigen Konten){' '}
          <b>
            <u>oder</u>
          </b>{' '}
          Jahres-BWA und Jahres-Summen- und Saldenliste 2023
        </span>
      </li>
      <ul>
        <li>
          <span>
            Sollten die Finanzkennzahlen für 2023 nur in Form der Jahres-BWA und Jahres-Summen- und
            Saldenliste vorliegen, so wird{' '}
            <b>
              <u>zusätzlich</u>
            </b>{' '}
            die vom Steuerberater gestempelte und unterschriebene Gewinnermittlung 2022 (inkl.
            Vorjahresvergleich und Kontennachweise) benötigt.
          </span>
        </li>
      </ul>
      <li>
        <span>Aktuelle BWA (nicht älter als 5 Monate)</span>
      </li>
      <li>
        <span>
          Aktuelle vollständig eröffnete Summen- und Saldenliste (nicht älter als 5 Monate)
        </span>
      </li>
    </ul>
    <span>Bitte lassen Sie mir die Dokumente als PDF zukommen.</span>
    <br />
    <br />
    <span>Herzlichen Dank.</span>
  </>
);

export const yesEmailV3 = (
  <>
    <span>Sehr geehrte/r Frau/Herr ...,</span>
    <br />
    <br />
    <span>
      für die Beantragung eines Darlehens bei der CAPTIQ GmbH werden folgende Dokumente{' '}
      <u>als PDF</u> benötigt:
    </span>
    <ul>
      <li>
        <span>
          Vom Steuerberater gestempelte und unterschriebene Bilanz 2023 (inkl. Vorjahresvergleich
          und Gewinnermittlung + Kontennachweise){' '}
          <b>
            <u>oder</u>
          </b>{' '}
          Jahres-BWA und Jahres-Summen- und Saldenliste 2023
        </span>
      </li>
      <ul>
        <li>
          <span>
            Sollten die Finanzkennzahlen für 2023 nur in Form der Jahres-BWA und Jahres-Summen- und
            Saldenliste vorliegen, so wird{' '}
            <b>
              <u>zusätzlich</u>
            </b>{' '}
            die vom Steuerberater gestempelte und unterschriebene Bilanz 2022 (inkl.
            Vorjahresvergleich und Gewinnermittlung + Kontennachweise) benötigt.
          </span>
        </li>
        <li>
          <span>
            Sollte aufgrund des Alters der Unternehmung noch kein Vorjahresvergleich vorhanden sein,
            reicht auch ein Jahresabschluss ohne Vorjahresvergleich.
          </span>
        </li>
      </ul>
      <li>
        <span>Aktuelle BWA (nicht älter als 5 Monate)</span>
      </li>

      <li>
        <span>
          Aktuelle, zur BWA zugehörige vollständig eröffnete Summen- und Saldenliste (nicht älter
          als 5 Monate)
        </span>
      </li>
    </ul>
    <span>Bitte lassen Sie mir die Dokumente als PDF zukommen.</span>
    <br />
    <br />
    <span>Herzlichen Dank.</span>
  </>
);

export const noEmailV3 = (
  <>
    <span>Sehr geehrte/r Frau/Herr ...,</span>
    <br />
    <br />
    <span>
      für die Beantragung eines Darlehens bei der CAPTIQ GmbH werden folgende Dokumente{' '}
      <u>als PDF</u> benötigt:
    </span>
    <ul>
      <li>
        <span>
          Vom Steuerberater gestempelte und unterschriebene Gewinnermittlung 2023 (inkl.
          Vorjahresvergleich und Ausweisung der Sonstigen Konten){' '}
          <b>
            <u>oder</u>
          </b>{' '}
          Jahres-BWA und Jahres-Summen- und Saldenliste 2023
        </span>
      </li>
      <ul>
        <li>
          <span>
            Sollten die Finanzkennzahlen für 2023 nur in Form der Jahres-BWA und Jahres-Summen- und
            Saldenliste vorliegen, so wird{' '}
            <b>
              <u>zusätzlich</u>
            </b>{' '}
            die vom Steuerberater gestempelte und unterschriebene Gewinnermittlung 2022 (inkl.
            Vorjahresvergleich und Kontennachweise) benötigt.
          </span>
        </li>
        <li>
          <span>
            Sollte aufgrund des Alters der Unternehmung noch kein Vorjahresvergleich vorhanden sein,
            reicht auch ein Jahresabschluss ohne Vorjahresvergleich.
          </span>
        </li>
      </ul>
      <li>
        <span>Aktuelle BWA (nicht älter als 5 Monate)</span>
      </li>
      <li>
        <span>
          Aktuelle, zur BWA zugehörige vollständig eröffnete Summen- und Saldenliste (nicht älter
          als 5 Monate)
        </span>
      </li>
    </ul>
    <span>Bitte lassen Sie mir die Dokumente als PDF zukommen.</span>
    <br />
    <br />
    <span>Herzlichen Dank.</span>
  </>
);
