import { FormikValues } from 'formik';
import * as Yup from 'yup';

import { RefArrays, RefContext } from 'context/customer-context/types';
import { IProfileVersion } from 'context/customer-context/constants';
import { isBO } from './isBO';
import { isOW } from './isOW';
import { validateBO } from './validateBO';
import { getValidationErrors, Response } from './getValidationErrors';

export interface Errors {
  position: number;
  level?: number;
  errors: Response[];
}

export const validateStaticBlockValues = async (
  values: FormikValues,
  refs: RefArrays,
  validationSchema: Yup.ObjectSchema<any>,
  profileVersion: IProfileVersion,
) => {
  const options = {
    abortEarly: false,
    context: {
      company: values.company,
      loanApplicationUsage: values.loanApplicationUsage,
      loanApplicationDuration: values.loanApplicationDuration,
      profileVersion,
    },
  };
  const errors: Errors[] = [];
  const result = refs.map(async (ref: RefContext, index: number) => {
    try {
      if (isBO(ref)) {
        return await validateBO({
          ref,
          validationSchema,
          values,
          errors,
          index,
          level: 1,
          profileVersion,
          loanApplicationUsage: values.loanApplicationUsage,
        });
      }
      if (isOW(ref)) {
        if (ref._type === 'person') {
          await validationSchema.validate({ people: [values.people[ref.index]] }, options);
        } else {
          await validationSchema.validate({ companies: [values.companies[ref.index]] }, options);
        }
      } else {
        await validationSchema.validate({ people: [values.people[ref.index]] }, options);
      }
      return true;
    } catch (err: any) {
      errors.push({ position: index, errors: getValidationErrors(err) });
      return false;
    }
  });
  return [await Promise.all(result), errors];
};
