import * as Yup from 'yup';

import { CompanyContactValues } from 'context/customer-context/types';

export const companyContactSchema = () =>
  Yup.object({
    email: Yup.string().email('Bitte geben Sie eine gültige E-Mail-Adresse ein'),
    internationalPrefix: Yup.object().nullable(),
    phoneNumber: Yup.string(),
  });

export const companyContactInitialValues: CompanyContactValues = {
  email: '',
  internationalPrefix: null,
  phoneNumber: '',
};
