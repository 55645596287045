import * as Yup from 'yup';

// Note: this is not necessary because there are no required validations here
export const companyAddressSchema = Yup.object({
  businessAddressStreet: Yup.string(),
  businessAddressHouseNumber: Yup.string(),
  businessAddressAdditional: Yup.string(),
  businessAddressPostalCode: Yup.string(),
  businessAddressCity: Yup.string(),
  businessAddressCountry: Yup.object().nullable(),
});

export const companyAddressInitialValues = {
  businessAddressStreet: '',
  businessAddressHouseNumber: '',
  businessAddressAdditional: '',
  businessAddressPostalCode: '',
  businessAddressCity: '',
  businessAddressCountry: null,
};
