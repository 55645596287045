import { BackendCompany } from 'utils/backendTypes';
import { MainCompany } from 'context/customer-context/types';
import { getCountryOrNull } from './index';

export const getNumberEmployees = (employees: number | string | null) => {
  if (employees === null || employees === '' || employees === undefined) return '';
  return Number(employees);
};

export const serializeMainCompany = (business: BackendCompany): MainCompany => ({
  id: business.id,
  customerProfileRelationId: Number(business.customer_profile_relation.id),
  captiqCompanyID: business.business_id,
  data: {
    companyName: business.business,
    legalForm: business.legal_form,
    organisation: business.cooperation_form,
    hgb: business.hgb_accordance ? 'yes' : 'no',
    founding: business.foundation_date ? new Date(business.foundation_date) : '',
    numberEmployees: getNumberEmployees(business.number_employees),
    taxID: business.tax_id,
    externCompanyID: business.external_id || '',
  },
  address: {
    businessAddressStreet: business.business_address_street,
    businessAddressHouseNumber: business.business_address_house_n,
    businessAddressAdditional: business.business_address_extra,
    businessAddressPostalCode: business.business_address_postcode,
    businessAddressCity: business.business_address_city,
    businessAddressCountry: getCountryOrNull(business.business_address_country),
  },
  contact: {
    email: business.email_business,
    internationalPrefix: getCountryOrNull(business.international_prefix),
    phoneNumber: business.phone_number,
  },
  documentsLegalForm: {
    commercialRegisterNumber: business.commercial_register_number,
    commercialRegister: {
      code: business.commercial_register,
      name: business.commercial_register,
    },
  },
  additionalInformation: business.additional_information,
  status: business.status,
});
