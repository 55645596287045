import makeStyles from '@mui/styles/makeStyles';

export default makeStyles({
  simbol: {
    fontSize: 18,
  },
  copyIcon: {
    marginLeft: '10px',
    borderRadius: 4,
  },
  copyContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 45,
  },
  copyButtonContainer: {
    width: 40,
  },
});
