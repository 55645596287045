export const BLOCK_STATUS_APPROVED = 'APPROVED';
export const BLOCK_STATUS_DECLINED = 'DECLINED';
export const BLOCK_STATUS_EDIT = 'EDIT';
export const BLOCK_STATUS_NOT_REVIEWED = 'NOT_REVIEWED';
export const BLOCK_STATUS_PENDING = 'PENDING';

export const getStatusDisplay = (status: string | undefined) => {
  switch (status) {
    case 'APPROVED':
      return 'Genehmigter Block';
    case 'DECLINED':
      return 'Abgelehnter Block';
    case 'EDIT':
      return 'Block bearbeiten';
    case 'PENDING':
      return 'Ausstehender Block';
    default:
      return 'Nicht geprüfter Block';
  }
};
