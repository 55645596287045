import { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button, CircularProgress, Grid, InputLabel } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import IconExternal from '@mui/icons-material/OpenInNewRounded';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import { Delete } from '@mui/icons-material';

import { DEFAULT_CATEGORY, DEFAULT_TYPE } from '../../constants/defaults';
import { DataContext } from '../../context/data-context';
import * as ApiCaptiq from '../../service/ApiCaptiq';
import { request } from '../../service/ApiCaptiq';
import styles from './styles';

const ALLOWED_FORMATS = ['pdf', 'xlsx'];
const FILE_MAX_SIZE = 41943040;
const FILE_UPLOAD = 'FileUpload';

class UploadField extends Component {
  constructor(props, context) {
    super(props, context);
    this.timer = null;
    this.state = {
      hightlight: false,
      isUploading: false,
      completed: 0,
      error: false,
      newDocument: null,
    };
    this.fileInputRef = createRef();
  }

  onClick = (evt) => {
    evt.stopPropagation();
  };

  onDragLeave = () => {
    this.setState({ hightlight: false });
  };

  onDragOver = (evt) => {
    evt.preventDefault();

    if (this.props.disabled) return;

    this.setState({ hightlight: true });
  };

  onDrop = (event) => {
    event.preventDefault();
    if (this.props.disabled) return;
    const { files } = event.dataTransfer;
    this.handleFiles(files);
  };

  onFilesAdded = (evt) => {
    if (this.props.disabled) return;
    const { files } = evt.target;
    this.handleFiles(files);
  };

  emptyFileInput = (e) => {
    e.target.value = null;
  };

  handleFiles(files) {
    const currentFile = files[0];
    if (
      currentFile?.name &&
      ALLOWED_FORMATS.includes(currentFile.name.split('.').pop().toLowerCase()) &&
      currentFile.size <= FILE_MAX_SIZE
    ) {
      if (this.props.missingDocument) {
        this.setState({ newDocument: currentFile });
      } else {
        this.saveFile(currentFile);
        this.setState({ isUploading: true, error: false });
      }
    } else this.setState({ error: true });
  }

  openFileDialog = () => {
    if (this.props.disabled || (this.props.value && this.props.missingDocument)) return;
    if (this.props?.inputRef?.current) {
      this.props?.inputRef?.current?.click();
      return;
    }
    this.fileInputRef?.current?.click();
  };

  removeFile = (filename) => async (evt) => {
    evt.stopPropagation();
    const { fileId, slug, sectionPath, loanId, onFilesAdded, fileIdNew, fileSource } = this.props;
    const formData = new FormData();
    formData.append('file_name', filename);
    formData.append('slug', slug);
    formData.append('section_path', sectionPath);
    formData.append('loan_application_id', loanId);
    if (fileIdNew || fileId) formData.append('file_id', fileIdNew || fileId);
    formData.append('file_source', fileSource || FILE_UPLOAD);
    try {
      await request.post(`${ApiCaptiq.FILE_UPLOAD_URL}remove_file/`, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      });
      onFilesAdded('');
    } catch (err) {
      console.error(err);
    }
  };

  saveMissingDocument = async () => {
    const { newDocument } = this.state;
    const { setLoadingUpload, setCurrentLoading, index } = this.props;
    if (newDocument) {
      const formData = new FormData();
      formData.append('doc_file', newDocument);
      try {
        setLoadingUpload(true);
        const response = await request.patch(
          `${ApiCaptiq.SOLARIS_MISSING_DOCUMENT}${this.props.missingDocument}/`,
          formData,
          {
            headers: { 'content-type': 'multipart/form-data' },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.floor(
                (progressEvent.loaded * 100) / progressEvent.total,
              );
              this.setState({ completed: percentCompleted });
            },
          },
        );
        setCurrentLoading(index);
        this.props.onFilesAdded(response.data.doc_file, response.data, true);
        this.setState({ isUploading: false });
        this.forceUpdate();
      } catch (e) {
        console.error(e);
        setLoadingUpload(false);
      }
    } else if (this.props.onFilesAdded) this.props.onFilesAdded('');
  };

  async saveFile(file) {
    const {
      linkedDbId,
      slug,
      sectionPath,
      loanId,
      category,
      fileType,
      onFilesAdded,
      personID,
      year,
      legalForm,
      hgbAccordance,
    } = this.props;
    if (file) {
      const formData = new FormData();
      formData.append('datafile', file);
      formData.append('slug', slug);
      formData.append('section_path', sectionPath);
      formData.append('loan_application_id', loanId);
      if (category !== undefined) {
        formData.append('category', category || DEFAULT_CATEGORY);
        formData.append('file_type', fileType || DEFAULT_TYPE);
      }
      if (fileType === 'HGB' || fileType === 'Legal Form') {
        if (legalForm) {
          formData.append('legal_form', legalForm);
        }
        if (hgbAccordance === true || hgbAccordance === false) {
          formData.append('hgb_accordance', hgbAccordance);
        }
      }
      if (year !== undefined) {
        formData.append('year', year);
      }
      if (linkedDbId || personID) formData.append('linked_db_id', linkedDbId || personID);
      if (personID) formData.append('loan_applicant_person', personID);
      try {
        const { data } = await request.post(ApiCaptiq.FILE_UPLOAD_URL, formData, {
          headers: { 'content-type': 'multipart/form-data' },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
            this.setState({ completed: percentCompleted });
          },
        });
        const { datafile, filename, datafile_name, id, file_source } = data;
        onFilesAdded(datafile, filename, datafile_name, id, file_source);
        this.setState({ isUploading: false });
      } catch (e) {
        console.error(e);
      }
    } else if (onFilesAdded) onFilesAdded('');
  }

  renderLabel() {
    const { label, id, labelRef, InputLabelProps } = this.props;
    return (
      label && (
        <Grid item sm={12} md={5}>
          <InputLabel htmlFor={id} ref={labelRef} {...InputLabelProps}>
            {label}
          </InputLabel>
        </Grid>
      )
    );
  }

  renderButtonMissingDocument(
    filename,
    newDocument,
    classes,
    loadingUpload,
    currentLoading,
    index,
  ) {
    return !filename ? (
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={this.saveMissingDocument}
        disabled={!newDocument || loadingUpload}
        classes={{ disabled: this.props.disabledClass }}
      >
        {currentLoading === index && (
          <CircularProgress color="primary" size={24} className={classes.buttonProgress} />
        )}
        {this.props.buttonLabel}
      </Button>
    ) : (
      <Button variant="outlined" className={classes.buttonAnswerSent} size="small" disableRipple>
        Antwort gesendet
      </Button>
    );
  }

  getFileName() {
    const { value, filename } = this.props;
    if (filename) return filename;
    if (value) {
      const filePathArr = value.split('/');
      if (filePathArr.length < 1) return null;
      return filePathArr[filePathArr.length - 1];
    }
    return '';
  }

  render() {
    const {
      classes,
      value,
      customColors,
      disabled,
      size,
      accept,
      missingDocument,
      loadingUpload,
      currentLoading,
      index,
    } = this.props;
    const { isUploading, completed, error, newDocument } = this.state;
    const filename = this.getFileName();

    return (
      <div className={classes.container}>
        <div
          className={classNames(
            classes.dropZone,
            this.state.hightlight && classes.highlight,
            value && classes.uploadFieldWithValue,
          )}
          onDragOver={this.onDragOver}
          onDragLeave={this.onDragLeave}
          onDrop={this.onDrop}
          onClick={this.openFileDialog}
          style={{ cursor: this.props.disabled ? 'default' : 'pointer' }}
        >
          <input
            ref={this.props.inputRef ? this.props.inputRef : this.fileInputRef}
            className={classes.fileInput}
            type="file"
            data-testid="file-input"
            multiple
            onChange={this.onFilesAdded}
            onClick={this.emptyFileInput}
            size={size}
            accept={accept}
          />
          <Grid container justifyContent="center">
            {this.renderLabel()}
            <Grid item sm={12} md={7} className={classes.textUpload}>
              {!filename && !newDocument ? (
                <span className={classes.hint}>
                  Legen Sie Dateien hier ab oder klicken Sie, um sie hochzuladen.
                </span>
              ) : (
                <div className={classes.fileName}>
                  <div className={classes.fileNameContainer}>
                    {filename || newDocument?.name}
                    {!disabled && !missingDocument && (
                      <IconButton
                        className={classes.buttonDelete}
                        size="small"
                        variant="contained"
                        onClick={this.removeFile(filename)}
                      >
                        <Delete className={classes.iconDelete} />
                      </IconButton>
                    )}
                  </div>
                  <Button
                    color="primary"
                    variant="text"
                    size="small"
                    className={classes.fileDownloadButton}
                    href={value ? value.toString() : ''}
                    target="_blank"
                    onClick={this.onClick}
                    style={{ color: customColors.buttonColor }}
                  >
                    <IconExternal className={classes.iconDownload} /> Datei anzeigen
                  </Button>
                </div>
              )}
            </Grid>
          </Grid>
          {isUploading && (
            <LinearProgress variant="determinate" value={completed} className={classes.progress} />
          )}
        </div>
        {error && (
          <div>
            <span className={classes.errorText}>
              Die Dateierweiterung wird nicht unterstützt oder die Datei ist zu groß.
            </span>
          </div>
        )}
        {!!missingDocument &&
          this.renderButtonMissingDocument(
            filename,
            newDocument,
            classes,
            loadingUpload,
            currentLoading,
            index,
          )}
      </div>
    );
  }
}

UploadField.contextType = DataContext;

UploadField.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  onFilesAdded: PropTypes.func,
  filename: PropTypes.string,
  customColors: PropTypes.any,
  size: PropTypes.number,
  accept: PropTypes.string,
  missingDocument: PropTypes.number,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(HTMLInputElement) }),
  ]),
  slug: PropTypes.string,
  sectionPath: PropTypes.string,
  loanId: PropTypes.string,
  category: PropTypes.string,
  year: PropTypes.number,
  fileType: PropTypes.string,
  personID: PropTypes.number,
  buttonLabel: PropTypes.string,
  disabledClass: PropTypes.any,
  setLoadingUpload: PropTypes.func,
  loadingUpload: PropTypes.bool,
  setCurrentLoading: PropTypes.func,
  currentLoading: PropTypes.any,
  linkedDbId: PropTypes.any,
  fileSource: PropTypes.any,
  index: PropTypes.number,
  legalForm: PropTypes.string,
  hgbAccordance: PropTypes.bool,
};

export default withStyles(styles)(UploadField);
