import React from 'react';

import { Typography } from '@mui/material';

import questionIcon from 'Assets/images/question.png';
import useStyles from './styles';

const Support = () => {
  const styles = useStyles();

  return (
    <div>
      <div className={styles.innerContainer}>
        <img src={questionIcon} alt="" className={styles.icon} />
        <Typography className={styles.titleText}>Hilfe benötigt?</Typography>
      </div>
      <Typography className={styles.text}>
        Kontaktieren Sie uns einfach unter{' '}
        <a href="mailto:support@captiq.com">support@captiq.com</a>
      </Typography>
    </div>
  );
};

export default Support;
