import * as Yup from 'yup';

import { LANGUAGE } from 'constants/language';
import {
  Company,
  CompanyContactValues,
  CompanyDataFormValues,
  CompanyDocumentsLegalFormValues,
  CompanyFormValues,
  Person,
} from 'context/customer-context/types';
import { getLocaleAllocation } from 'utils/utils';
import { YesOrNo, Level } from 'utils/commonTypes/types';
import { dateOfBirth } from 'utils/validateStaticBlocksValues/dateOfBirth';
import { countryOrNationality } from 'utils/validateStaticBlocksValues/countryOrNationality';
import { ONGOING_TEMPLATE_TAG } from 'constants/templates';

export interface FormValues {
  beneficialOwners: { company: CompanyFormValues; people: Person[]; companies: Company[] };
}

export const regexForValidAllocationEN = /^\d{1,3}(?:\.\d{1,2})?$/;

export const regexForValidAllocationDE = /^\d{1,3}(?:,\d{1,2})?$/;

const getMinimum = (allowZero: boolean) => (allowZero ? 0.0 : 0.01);

export const checkLocaleValidAllocation = (value: string | undefined, allowZero = false) => {
  if (LANGUAGE === 'en') {
    return (
      !!value &&
      regexForValidAllocationEN.test(`${value}`) &&
      Number(value) >= getMinimum(allowZero) &&
      Number(value) <= 100
    );
  }
  return (
    !!value &&
    regexForValidAllocationDE.test(`${value}`) &&
    Number(value.replace(',', '.')) >= getMinimum(allowZero) &&
    Number(value.replace(',', '.')) <= 100
  );
};

const MIN_ALLOCATION_LVL_1 = 25;
const MIN_ALLOCATION_LVL_2 = 50;

export const sharesValidator = (
  allocation: string,
  sideDeal: YesOrNo,
  level: Level,
  parentCompanyName: string,
  isBeneficialOwner: YesOrNo = '',
  checkSideDeal = true,
) => {
  const currentAllocation = getLocaleAllocation(allocation, LANGUAGE);
  if (currentAllocation < 0) {
    return undefined;
  }
  if (level === 1 && currentAllocation <= MIN_ALLOCATION_LVL_1) {
    if (sideDeal !== 'yes' && isBeneficialOwner === 'yes' && checkSideDeal) {
      return `Anteile oder Stimmäquivalent an ${parentCompanyName} sind nicht über ${MIN_ALLOCATION_LVL_1}%.`;
    }
  }
  if (level === 1 && currentAllocation <= MIN_ALLOCATION_LVL_2) {
    if (sideDeal === '' && isBeneficialOwner === 'yes' && checkSideDeal) {
      return 'Bitte machen Sie noch Angaben zu den vergleichbaren Kontrollrechten.';
    }
  }
  if ((level === 2 || level === 3) && currentAllocation <= MIN_ALLOCATION_LVL_2) {
    if (sideDeal !== 'yes' && checkSideDeal) {
      return `Anteile oder Stimmäquivalent an ${parentCompanyName} sind nicht über ${MIN_ALLOCATION_LVL_2}%.`;
    }
  }
  return undefined;
};

export const companyDataInitialValues: CompanyDataFormValues = {
  companyName: '',
  legalForm: '',
  organisation: '',
  hgb: '',
  founding: '',
  numberEmployees: '',
  taxID: '',
  externCompanyID: '',
};

export const companyAddressSchema = Yup.object({
  businessAddressStreet: Yup.string(),
  businessAddressHouseNumber: Yup.string(),
  businessAddressAdditional: Yup.string(),
  businessAddressPostalCode: Yup.string(),
  businessAddressCity: Yup.string(),
  businessAddressCountry: Yup.object().nullable(),
});
export const companyAddressInitialValues = {
  businessAddressStreet: '',
  businessAddressHouseNumber: '',
  businessAddressAdditional: '',
  businessAddressPostalCode: '',
  businessAddressCity: '',
  businessAddressCountry: null,
};

export const companyContactSchema = () =>
  Yup.object({
    email: Yup.string().email('Please enter a valid email'),
    internationalPrefix: Yup.object().nullable(),
    phoneNumber: Yup.string(),
  });
export const companyContactInitialValues: CompanyContactValues = {
  email: '',
  internationalPrefix: null,
  phoneNumber: '',
};

export const companyDocumentsLegalFormSchema = Yup.object().shape({
  commercialRegister: Yup.object().nullable().shape({
    code: Yup.string(),
    name: Yup.string(),
  }),
  commercialRegisterNumber: Yup.string(),
});
export const companyDocumentsLegalFormInitialValues: CompanyDocumentsLegalFormValues = {
  commercialRegister: null,
  commercialRegisterNumber: '',
};

export const validationSchema = Yup.object().shape({
  people: Yup.array().of(
    Yup.lazy((value) =>
      value
        ? Yup.object({
            salutation: Yup.string().required('Bitte wählen Sie eine Anrede').label('Anrede'),
            firstNames: Yup.string()
              .required('Bitte geben Sie Ihren Vornamen ein')
              .label('Vorname(n)'),
            lastName: Yup.string()
              .required('Bitte geben Sie Ihren Nachnamen ein')
              .label('Nachname'),
            title: Yup.string().nullable().label('Titel'),
            dateOfBirth,
            countryOfBirth: countryOrNationality('Geburtsland'),
            additionalInformation: Yup.string().max(
              1000,
              'Sie haben eine maximale Anzahl von 1000 Zeichen',
            ),
            cityOfBirth: Yup.string().required().label('Geburtsort'),
            nationality: countryOrNationality('Staatsangehörigkeit'),
            taxableInUSA: Yup.string()
              .oneOf(['no'])
              .required()
              .label('Steuerpflichtig in den USA?'),
            isLegalRepresentative: Yup.string().required().label('Gesetzliche(r) Vertreter(in)'),
            isLoanApplicant: Yup.string().required(),
            shares: Yup.array().of(
              Yup.object({
                allocation: Yup.string(),
                sideDeal: Yup.string(),
              }),
            ),
            personalAddressStreet: Yup.string().required().label('Straße Privatanschrift'),
            personalAddressHouseNumber: Yup.string().required().label('Hausnummer Privatanschrift'),
            personalAddressAdditional: Yup.string().label('Adresszusatz Privatanschrift'),
            personalAddressPostalCode: Yup.string()
              .min(
                4,
                'Die PLZ in Deutschland sind 5-stellig und in Österreich und in der Schweiz 4-stellig.',
              )
              .max(
                5,
                'Die PLZ in Deutschland sind 5-stellig und in Österreich und in der Schweiz 4-stellig.',
              )
              .required()
              .label('PLZ Privatanschrift'),
            personalAddressCity: Yup.string().required().label('Ort Privatanschrift'),
            personalAddressCountry: countryOrNationality('Wohnsitzland Privatanschrift'),
            hasOtherCompanies: Yup.string()
              .label(
                'Hält die Person Anteile von mehr als 25% an mind. einer anderen Kapitalgesellschaft (z.B. GmbH) oder ist die Person an mind. einer weiteren Personengesellschaft (z.B. GbR) beteiligt?',
              )
              .test('required', 'Bitte wählen Sie eine Option', (val: any, context: any) => {
                const { profileVersion, loanApplicationUsage } = context.options.context;
                const { shares } = context.parent;

                if (
                  Number(profileVersion.at(-1)) < 3 ||
                  loanApplicationUsage !== ONGOING_TEMPLATE_TAG ||
                  (shares &&
                    shares.length > 0 &&
                    !!shares[0].allocation &&
                    getLocaleAllocation(shares[0].allocation || '0', LANGUAGE) < 50.01)
                ) {
                  return true;
                }

                const condition = !!val;

                return condition;
              }),
          })
        : Yup.object({}).nullable(),
    ) as unknown as Yup.AnySchema,
  ),
  companies: Yup.array().of(
    Yup.lazy((value) =>
      value
        ? Yup.object({
            companyName: Yup.string()
              .required('Bitte geben Sie den Unternehmensname ein')
              .label('Name des Unternehmens'),
            legalForm: Yup.string()
              .required('Bitte wählen Sie eine Rechtsform')
              .label('Rechtsform'),
            businessAddressStreet: Yup.string().required().label('Straße Unternehmensanschrift'),
            businessAddressHouseNumber: Yup.string()
              .required()
              .label('Hausnummer Unternehmensanschrift'),
            businessAddressAdditional: Yup.string().label('Adresszusatz Unternehmensanschrift'),
            businessAddressPostalCode: Yup.string()
              .max(5, 'Max. 5 Zeichen erlaubt.')
              .required()
              .label('PLZ Unternehmensanschrift'),
            businessAddressCity: Yup.string().required().label('Ort Unternehmensanschrift'),
            businessAddressCountry: countryOrNationality('Land Unternehmensanschrift'),
            shares: Yup.array().of(
              Yup.object({
                allocation: Yup.string().required(),
                sideDeal: Yup.string().nullable(false),
              }),
            ),
            isOwner: Yup.string(),
            isBeneficialOwnerLevelOne: Yup.string(),
            isBeneficialOwnerLevelTwo: Yup.string(),
            isBeneficialOwnerLevelThree: Yup.string(),
            isBeneficialOwner: Yup.string(),
          })
        : Yup.object({}).nullable(),
    ) as unknown as Yup.AnySchema,
  ),
});
