import React, { useEffect, useState, useRef, useCallback, memo } from 'react';
import resolvePath from 'object-resolve-path';
import moment from 'moment';
import $ from 'jquery';
import { compareDesc } from 'date-fns';

import { TextField, InputAdornment } from '@mui/material';
import { CalendarTodayRounded } from '@mui/icons-material';

import 'jquery-datetimepicker/build/jquery.datetimepicker.full';
import 'jquery-datetimepicker/jquery.datetimepicker.css';

export type ValueType = string | Date;

interface Props {
  inputRefProp: React.RefObject<HTMLInputElement>;
  options?: object;
  datepicker?: boolean;
  timepicker?: boolean;
  placeholder?: string;
  value?: ValueType;
  defaultValue?: ValueType;
  value_format?: string | string[];
  displayDateFormat?: string;
  displayTimeFormat?: string;
  displayDateTimeFormat?: string;
  scrollInput?: boolean;
  onChange?: (e: ValueType) => void;
  onBlur?: (e: ValueType) => void;
  onBlurTyped?: (e: ValueType) => void;
  customInput?: React.FunctionComponentElement<any>;
  locale?: string;
  maxDate?: string;
  earlierDate?: boolean;
  readonly?: boolean;
}

const ISO_DATE_FORMAT = 'YYYY-MM-DD';
const ISO_TIME_FORMAT = 'HH:mm';
const ISO_DATETIME_FORMAT = `${ISO_DATE_FORMAT}T${ISO_TIME_FORMAT}`;

const DateTimePicker = ({
  inputRefProp,
  options = undefined,
  datepicker = true,
  timepicker = true,
  placeholder = '',
  defaultValue = '',
  value: propValue = '',
  displayDateFormat = 'DD.MM.YYYY',
  displayTimeFormat = 'HH=mm',
  displayDateTimeFormat = 'DD.MM.YYYY HH=mm',
  value_format = undefined,
  onChange = undefined,
  onBlur = undefined,
  onBlurTyped = undefined,
  scrollInput = false,
  customInput = undefined,
  locale = 'de',
  maxDate = undefined,
  earlierDate = false,
  readonly,
}: Props) => {
  const [value, setValue] = useState<ValueType>('');
  const [isInvalid, setIsInvalid] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selected, setSelected] = useState(false);
  const inputRef = useRef(null);

  const getDisplayFormat = useCallback(() => {
    if (datepicker && timepicker) {
      return displayDateTimeFormat;
    }
    if (datepicker) {
      return displayDateFormat;
    }
    if (timepicker) {
      return displayTimeFormat;
    }
    return displayDateTimeFormat;
  }, [displayDateFormat, displayTimeFormat, displayDateTimeFormat, datepicker, timepicker]);

  const getValueFormat = useCallback(() => {
    if (value_format) {
      return value_format;
    }
    if (datepicker && timepicker) {
      return ISO_DATETIME_FORMAT;
    }
    if (datepicker) {
      return ISO_DATE_FORMAT;
    }
    if (timepicker) {
      return ISO_TIME_FORMAT;
    }
    return ISO_DATETIME_FORMAT;
  }, [datepicker, timepicker, value_format]);

  const setFormatter = useCallback(() => {
    $.datetimepicker.setDateFormatter({
      parseDate(date: Date, _format: string) {
        const d = moment.utc(date, _format);
        return d.isValid() ? d.toDate() : false;
      },

      formatDate(date: Date, _format: string) {
        return moment.utc(date).format(_format);
      },
    });
  }, []);

  const getValue = useCallback(
    (newValue: ValueType = '') => {
      if (newValue) {
        return moment.utc(newValue, getValueFormat()).format(getDisplayFormat());
      }
      return moment.utc(value, getValueFormat()).format(getDisplayFormat());
    },
    [getDisplayFormat, getValueFormat, value],
  );

  const onChangeHandler = useCallback(
    (newValue: string) => {
      setSelected(true);
      let currenIsInvalid = false;
      if (newValue) {
        try {
          const momentValue = moment.utc(newValue, true);
          if (!momentValue.isValid()) {
            currenIsInvalid = true;
          } else {
            const newInputValue = moment.utc(newValue).format(getDisplayFormat());
            setInputValue(newInputValue || '');
            if (onChange && newInputValue) {
              onChange(moment.utc(newInputValue, getValueFormat()).toDate());
            }
          }
        } catch (e) {
          currenIsInvalid = true;
        }
      } else {
        setInputValue(newValue);
        if (onChange && newValue) {
          onChange(moment.utc(newValue, getValueFormat()).toDate());
        }
      }
      setIsInvalid(currenIsInvalid);
    },
    [getDisplayFormat, getValueFormat, onChange],
  );

  const setComputedValue = useCallback(
    (momentValue: ValueType) => {
      const newValue = momentValue && moment.utc(momentValue, getValueFormat()).toDate();
      setInputValue(momentValue ? getValue(momentValue) : inputValue);
      setValue(newValue);
      const $input = $(inputRefProp.current);
      if ($input) {
        $input.datetimepicker('setOptions', { value: propValue });
      }
    },
    [getValue, getValueFormat, inputValue, inputRefProp, propValue],
  );

  const initPlugin = useCallback(() => {
    if (inputRefProp && inputRefProp.current) {
      let $input = $(inputRefProp.current);
      const inputCurrentValue = value || defaultValue;
      const defaultOptions = {
        formatTime: displayTimeFormat,
        formatDate: displayDateFormat,
        dayOfWeekStart: 1,
      };
      const pickerOptions = {
        allowDates: [],
        ...defaultOptions,
        ...options,
      };
      const allowDateTimes = resolvePath(pickerOptions, 'allowDateTimes');
      if (allowDateTimes) {
        pickerOptions.allowDates = allowDateTimes.map((dt: moment.MomentInput) =>
          moment.utc(dt, ISO_DATETIME_FORMAT).format(pickerOptions.formatDate),
        );
      }
      setFormatter();
      setInputValue('');
      $input.datetimepicker('destroy');
      $input = $input.datetimepicker({
        ...pickerOptions,
        format: getDisplayFormat(),
        datepicker,
        timepicker,
        onChangeDateTime: onChangeHandler,
        value: inputCurrentValue,
        scrollInput,
        maxDate,
        lazyInit: true,
      });
    }
    // This function has to run only once since it initialize the datetimepicker plugin
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    $.datetimepicker.setLocale(locale);
    initPlugin();
  }, [initPlugin, locale]);

  useEffect(() => {
    if (propValue) {
      if (propValue !== value) {
        if (earlierDate) {
          setComputedValue(new Date());
        } else {
          setComputedValue(propValue);
        }
      }
    } else {
      setInputValue('');
      setValue('');
    }
    // This function should run whenever the propValue changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propValue]);

  const onBlurHandler = useCallback(
    (newValue: ValueType) => {
      if (onBlurTyped && !selected) {
        if (maxDate) {
          const compareDates = compareDesc(
            newValue as Date,
            moment(new Date(maxDate as string).getUTCDate(), getValueFormat()).toDate(),
          );
          if (!newValue || compareDates === 0 || compareDates === 1) onBlurTyped(newValue);
          else onBlurTyped(value);
        } else onBlurTyped(newValue);
      }
      if (onBlur) onBlur(newValue);
    },
    [onBlurTyped, onBlur, selected, getValueFormat, maxDate, value],
  );

  const onBlurInput = useCallback(() => {
    if (isInvalid) {
      setInputValue('');
      setValue('');
      onBlurHandler('');
    } else {
      if (!inputValue) {
        setInputValue('');
        setValue('');
        onBlurHandler('');
        return;
      }
      const newValue = inputValue && moment.utc(inputValue, getValueFormat()).toDate();
      setComputedValue(inputValue);
      onBlurHandler(newValue);
    }
  }, [getValueFormat, inputValue, isInvalid, onBlurHandler, setComputedValue]);

  const onChangeHandlerInput = useCallback(
    (newValue: string) => {
      const re = /^[0-9]{0,2}\.?[0-9]{0,2}\.?[0-9]{0,4}$/;
      let currentIsInvalid = false;
      setSelected(false);
      const validValue = re.test(newValue);
      if (validValue) setInputValue(newValue);
      if (newValue && validValue) {
        try {
          const momentValue = moment.utc(newValue, getDisplayFormat());
          if (!momentValue.isValid()) {
            currentIsInvalid = true;
          }
        } catch (e) {
          currentIsInvalid = true;
        }
      } else {
        currentIsInvalid = true;
      }
      setIsInvalid(currentIsInvalid);
    },
    [getDisplayFormat],
  );

  const onChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeHandlerInput(e.target.value);
    },
    [onChangeHandlerInput],
  );

  const renderInput = useCallback(() => {
    if (customInput) {
      return React.cloneElement(customInput, {
        inputRef,
        placeholder,
        value: inputValue,
        onChange: onChangeInput,
        onBlur: onBlurInput,
      });
    }
    const inputEl = <input type="text" />;
    return React.cloneElement(inputEl, {
      inputRef,
      placeholder,
      value: inputValue,
      onChange: onChangeInput,
      onBlur: onBlurInput,
    });
  }, [customInput, inputValue, onBlurInput, onChangeInput, placeholder]);

  if (readonly) {
    return (
      <TextField
        size="small"
        ref={inputRef}
        margin="dense"
        fullWidth
        disabled={readonly}
        value={inputValue}
        InputProps={{
          autoComplete: 'none',
          placeholder: 'tt.mm.jjjj',
          readOnly: readonly,
          endAdornment: (
            <InputAdornment position="start">
              <CalendarTodayRounded style={{ fontSize: 18 }} />
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
    );
  }

  return (
    <div data-testid="datetime-input" className="datetimepicker">
      {renderInput()}
    </div>
  );
};

export default memo(DateTimePicker);
