import { BackendNewShare } from 'utils/backendTypes';
import { Share } from 'context/customer-context/types';
import { returnBooleanForBackend } from './index';

export const serializerShareToBackend = (share: Share): BackendNewShare => ({
  id: share.id || undefined,
  allocation: Number(share.allocation),
  parent: share.parent || undefined,
  side_deal:
    share.sideDeal !== undefined ? returnBooleanForBackend(share.sideDeal, true) : undefined,
});
