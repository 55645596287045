import React, { memo, useMemo, useState } from 'react';
import _get from 'lodash/get';
import { Field, FormikValues, useFormikContext } from 'formik';
import classNames from 'classnames';
import Lottie from 'lottie-react-web';

import { IconButton, InputAdornment, MenuItem } from '@mui/material';
import { TextField } from 'formik-mui';
import { Edit } from '@mui/icons-material';

import CheckAnimation from 'animation/check.json';
import useStyles from './styles';

interface Props {
  name: string;
  readOnly?: boolean;
  options?: string[][];
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  moreInputProps?: any;
  showEditButton?: boolean;
  shouldBeLocallyDisabled?: boolean;
  customClassInput?: string;
  customClassesInput?: any;
  showLabelEmpty?: boolean;
  showCompleteCheck?: boolean;
  isCurrency?: boolean;
  size?: 'small' | 'medium';
  placeholder?: string;
  customLabel?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const StringField = memo(
  ({
    name,
    readOnly,
    options,
    onBlur,
    showEditButton,
    moreInputProps = {},
    shouldBeLocallyDisabled = false,
    customClassInput = '',
    customClassesInput = {},
    showCompleteCheck = false,
    showLabelEmpty = false,
    size = 'small',
    isCurrency = false,
    placeholder = '',
    customLabel = '',
    onChange = undefined,
  }: Props) => {
    const { values } = useFormikContext<FormikValues>();
    const [locallyDisabled, setLocallyDisabled] = useState(shouldBeLocallyDisabled);
    const value = useMemo(() => _get(values, name), [values, name]);
    const styles = useStyles();

    const handleChangeLocallyDisabled = () => {
      setLocallyDisabled((prevState: boolean) => !prevState);
    };

    const inputProps = {
      readOnly,
      endAdornment: (
        <>
          <InputAdornment component="div" position="end" className={styles.endAdornment}>
            {isCurrency && <span>€</span>}
            {showEditButton && (
              <IconButton
                classes={{ root: styles.pen }}
                disabled={readOnly}
                aria-label="delete"
                color="primary"
                onClick={handleChangeLocallyDisabled}
                size="large"
              >
                <Edit />
              </IconButton>
            )}
            {showCompleteCheck && (
              <Lottie
                width={30}
                height={30}
                options={{
                  animationData: CheckAnimation,
                  loop: false,
                }}
                speed={2}
              />
            )}
          </InputAdornment>
        </>
      ),
      ...moreInputProps,
    };
    const isSelect = !!options;

    return (
      <Field
        classes={customClassesInput}
        className={classNames(
          readOnly ? styles.readOnlyField : value && styles.completeField,
          customClassInput,
        )}
        component={TextField}
        name={name}
        variant="outlined"
        size={size || 'small'}
        InputProps={inputProps}
        fullWidth
        disabled={locallyDisabled}
        label={isSelect && !showLabelEmpty ? customLabel || 'Please choose' : undefined}
        select={isSelect}
        onBlur={onBlur}
        InputLabelProps={{ shrink: !!value }}
        data-testid="string-field"
        placeholder={placeholder}
        onChange={onChange}
      >
        {options?.map((option) => (
          <MenuItem key={option[1]} value={option[0]} disabled={!!option?.[2]}>
            {option[1]}
          </MenuItem>
        ))}
      </Field>
    );
  },
);

export default StringField;
