import { Block } from 'utils/commonTypes/types';
import { Response } from './getValidationErrors';

interface Props {
  errorsYUP: Response[];
  block: Block;
  isEqual: boolean;
}

export const getErrorsLA = ({ block, isEqual, errorsYUP }: Props) => ({
  title: block.title,
  type: 'normalBlock',
  section_path: block.section_path,
  warnings: errorsYUP
    ?.filter((error) => (isEqual ? error.type === 'required' : error.type !== 'required'))
    .map((error) => ({
      label: isEqual ? error.label : `${error.label}: ${error.message}`,
      slug: `${error.label}la`,
    })),
});
