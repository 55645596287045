import * as Yup from 'yup';

import { LANGUAGE } from 'constants/language';
import { regexForValidNumber } from 'utils/utils';
import { Company, CompanyFormValues, Person } from 'context/customer-context/types';
import {
  companyDataInitialValues,
  companyDataSchema,
} from '../Company/components/FormBlocks/Data/validations';
import {
  companyAddressInitialValues,
  companyAddressSchema,
} from '../Company/components/FormBlocks/Address/validations';
import {
  companyContactInitialValues,
  companyContactSchema,
} from '../Company/components/FormBlocks/Contact/validations';
import {
  companyDocumentsLegalFormInitialValues,
  companyDocumentsLegalFormSchema,
} from '../Company/components/FormBlocks/DocumentsLegalForm/validations';

export type FormValues = {
  jurisdiction: number | '';
  company: CompanyFormValues;
  people: Person[];
  companies: Company[];
};

export const regexForValidAllocationEN = /^\d{1,3}(?:\.\d{1,2})?$/;

export const regexForValidAllocationDE = /^\d{1,3}(?:,\d{1,2})?$/;

const getMinimum = (allowZero: boolean) => (allowZero ? 0.0 : 0.01);

export const checkLocaleValidAllocation = (
  value: string | undefined,
  allowZero = false,
  customMin: number | undefined = undefined,
) => {
  const realMinimum = customMin ?? getMinimum(allowZero);
  if (LANGUAGE === 'en') {
    return (
      !!value &&
      regexForValidAllocationEN.test(`${value}`) &&
      Number(value) >= realMinimum &&
      Number(value) <= 100
    );
  }
  return (
    !!value &&
    regexForValidAllocationDE.test(`${value}`) &&
    Number(value.replace(',', '.')) >= realMinimum &&
    Number(value.replace(',', '.')) <= 100
  );
};

export const validationSchema = (withMainCompany: boolean) =>
  Yup.object({
    jurisdiction: Yup.string().required('Bitte wählen Sie die Zuständigkeit'),
    company: withMainCompany
      ? Yup.object({
          data: companyDataSchema(),
          address: companyAddressSchema,
          contact: companyContactSchema(),
          documentsLegalForm: companyDocumentsLegalFormSchema,
          additionalInformation: Yup.string().max(
            1000,
            'Sie haben eine maximale Anzahl von 1000 Zeichen',
          ),
        })
      : Yup.object(),
    // people and companies are used in Beneficial Owners, Owners, Legal Representatives and Loan Applicants
    people: Yup.array().of(
      Yup.object({
        salutation: Yup.string().required('Bitte wählen Sie eine Anrede'),
        firstNames: Yup.string().required('Bitte geben Sie Ihren Vornamen ein'),
        lastName: Yup.string().required('Bitte geben Sie Ihren Nachnamen ein'),
        email: Yup.string().email('Bitte geben Sie eine gültige E-Mail-Adresse ein'),
        isLegalRepresentative: Yup.string(),
        isBeneficialOwner: Yup.string(),
        isLoanApplicant: Yup.string(),
        isOwner: Yup.string(),
        repeatEmail: Yup.string().when('email', {
          is: (val: string) => val && val.length > 0,
          then: Yup.string().oneOf(
            [Yup.ref('email')],
            'Die Mailadresse stimmt leider nicht mit der oben angegebenen überein. Bitte überprüfen Sie diese nochmals.',
          ),
        }),
        additionalInformation: Yup.string().max(
          1000,
          'Sie haben eine maximale Anzahl von 1000 Zeichen',
        ),
        shares: Yup.array().of(
          Yup.object({
            allocation: Yup.string(),
            sideDeal: Yup.string(),
          }),
        ),
        dependents: Yup.array().of(
          Yup.object({
            age: Yup.number()
              .moreThan(0, 'Das Alter kann keine negative Zahl sein')
              .typeError('Bitte geben Sie eine gültige Zahl ein')
              .nullable()
              .transform((v, o) => (o === '' ? null : v)),
            monthlyUpkeep: Yup.number()
              .positive('The monthly upkeep cannot be a negative number')
              .nullable()
              .transform((v, o) => (o === '' ? null : v))
              .typeError('Bitte geben Sie eine gültige Zahl ein')
              .test(
                'test_currency',
                'Stelle sicher, dass es insgesamt nicht mehr als 6 Ziffern lang ist"',
                (value) => {
                  if (value === undefined || value === null) return true;
                  return regexForValidNumber.test(`${value}`);
                },
              ),
          }),
        ),
        children: Yup.array().of(
          Yup.object({
            age: Yup.number()
              .moreThan(0, 'Das Alter kann keine negative Zahl sein')
              .typeError('Bitte geben Sie eine gültige Zahl ein'),
          }),
        ),
      }),
    ),
    companies: Yup.array().of(
      Yup.object({
        companyName: Yup.string().required('Bitte geben Sie den Unternehmensname ein'),
        legalForm: Yup.string().required('Bitte wählen Sie eine Rechtsform'),
        shares: Yup.array().of(
          Yup.object({
            allocation: Yup.string(),
            sideDeal: Yup.string(),
          }),
        ),
        isOwner: Yup.string(),
        isBeneficialOwnerLevelOne: Yup.string(),
        isBeneficialOwnerLevelTwo: Yup.string(),
        isBeneficialOwnerLevelThree: Yup.string(),
        isBeneficialOwner: Yup.string(),
      }),
    ),
  });

export const initialValues: FormValues = {
  jurisdiction: '',
  company: {
    id: null,
    data: companyDataInitialValues,
    address: companyAddressInitialValues,
    contact: companyContactInitialValues,
    documentsLegalForm: companyDocumentsLegalFormInitialValues,
    additionalInformation: '',
  },
  people: [],
  companies: [],
};
