import { LoanApplication } from 'utils/commonTypes/types';

export const serializeLoanApplicationDetails = (loanApplication: LoanApplication) => ({
  status: loanApplication.status,
  statusDisplay: loanApplication.status_display,
  loanID: loanApplication.loan_id,
  loanTaker: loanApplication.loan_taker,
  loanApplicationID: loanApplication.id,
  cooperationPartner: loanApplication.cooperation_partner,
  duration: loanApplication.duration,
  finalDuration: loanApplication.final_duration ?? null,
  loanAmount: loanApplication.final_loan_amount
    ? Number(loanApplication.final_loan_amount)
    : Number(loanApplication.loan_amount),
  loanUsage: loanApplication.loan_usage_display,
  professionName: loanApplication.profession_name,
  finalNominalInterest: loanApplication.final_nominal_interest_rate,
  refinancingChannelInterestRate: loanApplication.refinancing_channel_interest_rate,
});
