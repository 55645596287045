import { useCallback, memo } from 'react';

import { TextField as MuiTextField } from '@mui/material';
import { fieldToTextField, TextFieldProps } from 'formik-mui';

import useStyles from './styles';

const OnlyNumbers = (props: TextFieldProps) => {
  const {
    form: { setFieldValue },
    field: { name },
    onChange: customOnChange,
  } = props;
  const classes = useStyles();

  const onChange = useCallback(
    (event: any) => {
      const { value } = event.target;
      if (/^\+?\d*$/.test(value)) {
        setFieldValue(name, value);
        if (typeof customOnChange === 'function') {
          customOnChange(event);
        }
      }
    },
    [customOnChange, setFieldValue, name],
  );
  return (
    <MuiTextField
      {...fieldToTextField(props)}
      onChange={onChange}
      className={
        props.className ? props.className : props.field.value ? classes.completeField : undefined
      }
    />
  );
};

export default memo(OnlyNumbers);
