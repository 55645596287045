import { InitialStateType } from './types';

export const initialState: InitialStateType = {
  editorValues: {},
  current: null,
  open: false,
  props: {},
  addBlock: () => {},
  handleOpen: () => {},
  handleClose: () => {},
};
