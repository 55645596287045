import { memo } from 'react';
import Lottie from 'lottie-react-web';

import { InputAdornment } from '@mui/material';
import { EuroSymbol } from '@mui/icons-material';

import CheckAnimation from 'animation/check.json';
import useStyles from './styles';

type TypeOptions = 'percent' | string;

interface Props {
  classes?: any;
  type?: TypeOptions;
  isSelect?: boolean;
}

const Adornment = memo(({ type, classes, isSelect }: Props) => {
  const styles = useStyles();

  switch (type) {
    case 'percent':
      return <InputAdornment position="end">%</InputAdornment>;
    case 'money':
      return (
        <InputAdornment position="end">
          <EuroSymbol className={styles.simbol} />
        </InputAdornment>
      );
    default:
      return (
        <InputAdornment position="end" className={isSelect && classes.endAdornment}>
          <Lottie
            width={30}
            height={30}
            options={{
              animationData: CheckAnimation,
              loop: false,
            }}
            speed={2}
          />
        </InputAdornment>
      );
  }
});

export default Adornment;
