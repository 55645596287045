import { useCallback, memo, useEffect, useState } from 'react';
import resolvePath from 'object-resolve-path';

import { TextField as MuiTextField } from '@mui/material';
import { fieldToTextField, TextFieldProps } from 'formik-mui';

import { LANGUAGE } from 'constants/language';
import useStyles from './styles';

const countDecimals = (value: number) => {
  if (Math.floor(value) === value) return 0;
  return value.toString().split('.')[1]?.length || 0;
};

interface Props {
  checkTouched?: boolean;
  moreDecimals?: boolean;
}

const PercentageField = (props: TextFieldProps & Props) => {
  // TODO: Manage translation
  // @ts-ignore
  const localeDecimal = LANGUAGE === 'en' ? '.' : ',';
  const classes = useStyles();
  const {
    form: { setFieldValue, errors, touched, handleBlur },
    field: { name, value: initValue },
    onBlur,
    onChange: customOnChange,
    checkTouched = true,
    moreDecimals,
  } = props;
  const [focus, setFocus] = useState(false);
  const textFieldProp = { ...props };
  const amountDecimals = moreDecimals ? 3 : 2;
  delete textFieldProp.checkTouched;

  const onChange = useCallback(
    (event: any) => {
      const { value } = event.target;
      if (LANGUAGE === 'en' && /^(\d*\.?\d*?)$/.test(value)) {
        setFieldValue(name, value);
        if (customOnChange) customOnChange(event);
        // TODO: Manage translation
      } else if (LANGUAGE === 'de' && /^(\d*,?\d*?)$/.test(value)) {
        setFieldValue(name, value);
        if (customOnChange) customOnChange(event);
      }
    },
    [customOnChange, setFieldValue, name],
  );

  const handleOnBlur = useCallback(
    (event: any) => {
      if (onBlur) onBlur(event);
      else handleBlur(event);
      const { value } = event.target;
      if (value) {
        const currentDecimals = countDecimals(
          Number(localeDecimal === ',' ? value.replace(',', '.') : value),
        );
        if (currentDecimals <= amountDecimals) {
          if (localeDecimal === '.')
            setFieldValue(name, String(parseFloat(value).toFixed(amountDecimals)));
          else if (localeDecimal === ',') {
            setFieldValue(
              name,
              String(parseFloat(value.replace(',', '.')).toFixed(amountDecimals)).replace('.', ','),
            );
          }
        }
      }
      setFocus(false);
    },
    [onBlur, localeDecimal, setFieldValue, name, handleBlur, amountDecimals],
  );

  const onFocus = useCallback(() => {
    setFocus(true);
  }, []);

  const checkErrors = useCallback(() => {
    if (checkTouched) {
      return resolvePath(touched, name) && !!resolvePath(errors, name);
    }
    return !!resolvePath(errors, name);
  }, [checkTouched, errors, name, touched]);

  const checkHelperText = useCallback(() => {
    if (checkTouched) {
      return resolvePath(touched, name) && resolvePath(errors, name);
    }
    return resolvePath(errors, name);
  }, [checkTouched, errors, name, touched]);

  useEffect(() => {
    // This will format the number only 1 time when component after component mounted or value was change but not by event. The purpose is trying to format the number to German locale if the value received from backend is from En locale
    if (initValue && !focus) {
      const currentDecimals = countDecimals(
        Number(localeDecimal === ',' ? initValue.replace(',', '.') : initValue),
      );
      const numberValue = parseFloat(initValue.replace(',', '.')).toFixed(amountDecimals);
      if (currentDecimals <= amountDecimals && !Number.isNaN(numberValue)) {
        const newValue =
          localeDecimal === ',' ? String(numberValue).replace('.', ',') : String(numberValue);
        if (newValue !== initValue) {
          setFieldValue(name, newValue);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initValue, focus]);

  return (
    <MuiTextField
      {...fieldToTextField(textFieldProp)}
      onChange={onChange}
      onBlur={handleOnBlur}
      onFocus={onFocus}
      error={checkErrors()}
      helperText={checkHelperText()}
      className={
        props.className ? props.className : props.field.value ? classes.completeField : undefined
      }
    />
  );
};

export default memo(PercentageField);
