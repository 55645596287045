import { BackendShare } from 'utils/backendTypes';
import { Share } from 'context/customer-context/types';
import { returnYesNoForFormik } from './index';

export const getShare = (share: BackendShare): Share => ({
  id: share.id,
  holdsShares: share.allocation === null ? 'no' : 'yes',
  allocation: share.allocation === null ? '' : share.allocation,
  sideDeal: returnYesNoForFormik(share.side_deal),
  parent: share.parent,
});
