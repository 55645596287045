const MIN_LENGTH_PHONE_NUMBER = 7;
const MAX_LENGTH_PHONE_NUMBER = 15;
const CHECK_ONLY_NUMBERS = /^\d+$/;
const ONLY_NUMBERS = /[^Z0-9]/g;

const validatePhoneNumber = (
  value: string,
  internationalPrefix: string | undefined | null = undefined,
) => {
  if (!CHECK_ONLY_NUMBERS.test(value))
    return [true, 'Die Telefonnummer darf nur aus Ziffern bestehen.'];
  const max = internationalPrefix
    ? MAX_LENGTH_PHONE_NUMBER - internationalPrefix.replace(ONLY_NUMBERS, '').length
    : MAX_LENGTH_PHONE_NUMBER;
  if (value.length < MIN_LENGTH_PHONE_NUMBER)
    return [true, 'Die Telefonnummer muss mindestens 7 Ziffern haben.'];
  if (value.length > max) return [true, `Die Telefonnummer darf maximal ${max} Ziffern haben.`];
  return [false, ''];
};

export default validatePhoneNumber;
