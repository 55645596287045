import React, { memo } from 'react';

import { Grid, Typography, Alert } from '@mui/material';
import { Info } from '@mui/icons-material';

interface Props {
  type: 'BO' | 'OW';
  entityType: 'person' | 'company';
  parentName?: string;
}

const DeletingOWBOMessage = ({ type, entityType, parentName = '' }: Props) => (
  <Grid item xs={12}>
    <Alert icon={<Info />} severity="warning">
      {type === 'BO' ? (
        <Typography>
          {entityType === 'person' ? 'Die ausgewählte Person' : 'Das ausgewählte Unternehmen'} wurde
          bereits als wirtschaftlichen Berechtigten angelegt. Durch die Änderung kann{' '}
          {entityType === 'person' ? 'die ausgewählte Person' : 'das ausgewählte Unternehmen'} kein
          wirtschaftlicher Berechtigter mehr sein und wird von der Rolle entfernt.
        </Typography>
      ) : (
        <Typography>
          {entityType === 'person' ? 'Die ausgewählte Person' : 'Das ausgewählte Unternehmen'} wurde
          bereits als Inhaber angelegt. Durch die Angabe von 0% Anteilen an das Unternehmens kann{' '}
          {entityType === 'person' ? 'die ausgewählte Person' : 'das ausgewählte Unternehmen'} kein
          Inhaber mehr sein und wird somit als Inhaber von {parentName} entfernt.
        </Typography>
      )}
    </Alert>
  </Grid>
);
export default memo(DeletingOWBOMessage);
