import React, { useMemo, useState } from 'react';
import resolvePath from 'object-resolve-path';
import { Field, FormikValues, useFormikContext } from 'formik';

import { IconButton, InputAdornment } from '@mui/material';
import { Edit } from '@mui/icons-material';

import OnlyNumbersField from 'components/OnlyNumbersField';
import useStyles from './styles';

interface Props {
  name: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  moreInputProps?: any;
  showEditButton?: boolean;
  shouldBeLocallyDisabled?: boolean;
}

const NumberField = ({
  name,
  readOnly,
  onChange,
  onBlur,
  showEditButton,
  moreInputProps = {},
  shouldBeLocallyDisabled = false,
}: Props) => {
  const { values } = useFormikContext<FormikValues>();
  const [locallyDisabled, setLocallyDisabled] = useState(shouldBeLocallyDisabled);
  const value = useMemo(() => resolvePath(values, name), [values, name]);
  const styles = useStyles();

  const handleChangeLocallyDisabled = () => {
    setLocallyDisabled((prevState: boolean) => !prevState);
  };

  const inputProps = {
    readOnly,
    endAdornment: (
      <>
        {showEditButton && (
          <InputAdornment position="end" component="span">
            <IconButton
              classes={{ root: styles.pen }}
              disabled={readOnly}
              aria-label="delete"
              color="primary"
              onClick={handleChangeLocallyDisabled}
              size="large"
            >
              <Edit />
            </IconButton>
          </InputAdornment>
        )}
      </>
    ),
    ...moreInputProps,
  };

  return (
    <Field
      className={readOnly ? styles.readOnlyField : value && styles.completeField}
      component={OnlyNumbersField}
      name={name}
      variant="outlined"
      size="small"
      fullWidth
      InputProps={inputProps}
      onChange={onChange}
      onBlur={onBlur}
      disabled={locallyDisabled}
      data-testid="number-field"
    />
  );
};

export default NumberField;
