import { CountryType } from 'utils/commonTypes/types';

export const serializeNationalities = (countryList: Array<CountryType>) => {
  const copyCountryList = [...countryList]
    .slice()
    .sort((a: CountryType, b: CountryType) => a.nationality.localeCompare(b.nationality));
  const newCountryList = [...copyCountryList];
  for (let i = 1; i < newCountryList.length; i += 1) {
    const previous = copyCountryList[i - 1].nationality;
    const current = copyCountryList[i].nationality;
    newCountryList[i - 1].countryNationality = copyCountryList[i - 1].nationality;
    newCountryList[i].countryNationality = copyCountryList[i].nationality;
    if (current === previous) {
      newCountryList[i].countryNationality =
        `${copyCountryList[i].nationality} (${copyCountryList[i].country})`;
      newCountryList[i - 1].countryNationality = `${copyCountryList[i - 1].nationality} (${
        copyCountryList[i - 1].country
      })`;
    }
  }
  return newCountryList
    .slice()
    .sort((a: CountryType, b: CountryType) => a.code.localeCompare(b.code));
};
