import { green, amber } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    flexWrap: 'unset',
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
    color: '#fff',
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    flexFlow: 'row',
  },
  actionButton: {
    position: 'absolute',
    top: 2,
    right: 10,
  },
}));
