import { memo, useCallback, useState } from 'react';
import { saveAs } from 'file-saver';
import classNames from 'classnames';

import { Box, Button, CircularProgress, Typography, Alert } from '@mui/material';
import Info from '@mui/icons-material/Info';

import * as ApiCaptiq from 'service/ApiCaptiq';
import { request } from 'service/ApiCaptiq';
import EmailTemplateModal from 'components/CaptiqBlock/EmailTemplateModal';
import useStyles from './styles';

const InfoBoxHGB = memo(({ hgb }: { hgb: boolean }) => {
  const classes = useStyles();

  const [openModal, setOpenModal] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);

  const handleDownloadAllFiles = useCallback(
    async (e: any) => {
      e.preventDefault();
      try {
        if (!loadingDownload) {
          setLoadingDownload(true);
          const { data } = await request.get(ApiCaptiq.DOWNLOAD_ALL_HGB_DOCUMENTS, {
            params: { hgb: hgb ? 'True' : 'False' },
            responseType: 'blob',
          });
          saveAs(data, 'CAPTIQ Musterdokumente.zip');
          setLoadingDownload(false);
        }
      } catch (error) {
        console.error(error);
        setLoadingDownload(false);
      }
    },
    [hgb, loadingDownload],
  );

  return (
    <>
      <Alert icon={<Info />} severity="info">
        <Box>
          <Typography className={classNames(classes.text, classes.mainText)}>
            <strong>WICHTIGER HINWEIS:</strong> Bitte achten Sie bei den nachfolgenden Dokumenten
            genauestens auf die jeweilige Beschreibung des Upload-Felds. Entsprechen die Dokumente
            nicht den Anforderungen, können wir leider{' '}
            <span className={classes.linkUnderline}>keine</span> Bonitätsprüfung durchführen.
            <br />
            Weiterhin bitten wir Sie darum, <u>keine passwortgeschützten Dateien</u> hochzuladen.
          </Typography>
          <Typography className={classes.text}>
            Sie möchten die Dokumente schnell und einfach vom Steuerberater anfordern?
          </Typography>
          <Typography className={classes.text}>
            Hier finden Sie eine vorgefertigte E-Mail-Vorlage:
          </Typography>
        </Box>
        <Box mt={1}>
          <Button
            onClick={() => setOpenModal(true)}
            variant="contained"
            color="primary"
            className={classes.openDialog}
          >
            E-Mail-Vorlage nutzen
            {loadingDownload && (
              <CircularProgress color="primary" size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </Box>
      </Alert>
      <EmailTemplateModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        hgbYes={hgb}
        handleDownloadAllFiles={handleDownloadAllFiles}
        loadingDownload={loadingDownload}
        version={2}
      />
    </>
  );
});

InfoBoxHGB.displayName = 'InfoBoxHGB';

export default InfoBoxHGB;
