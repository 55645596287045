import { LANGUAGE } from 'constants/language';
import { EKLegalFormLabel, FreelanceLegalFormLabel } from 'constants/legalForms';
import { checkLocaleValidAllocation } from 'containers/CustomerProfile/validationSchema';
import { Company, Person, Share } from 'context/customer-context/types';
import { LegalFormsType, Level, YesOrNo } from 'utils/commonTypes/types';
import { getLocaleAllocation, noSideDealsDisplayLabel } from 'utils/utils';
import { sharesValidator } from './validationSchema';

export const validateLegalRepresentativeFunction = (
  isLegalRepresentative: YesOrNo,
  currentLegalForm: LegalFormsType | null,
  level: Level,
) => {
  if (
    level === 1 &&
    currentLegalForm &&
    (currentLegalForm.label === EKLegalFormLabel ||
      currentLegalForm.label === FreelanceLegalFormLabel) &&
    isLegalRepresentative === 'no'
  )
    return 'Must be LRP';
  return undefined;
};

export const validateSharesFunction = (
  allocation: string,
  share: Share,
  isBeneficialOwner: YesOrNo,
  parentCompanyName: string,
  level: Level,
  beneficialOwner: Person | Company,
  currentLegalForm: LegalFormsType | null,
) => {
  const shareError = sharesValidator(
    allocation,
    share?.sideDeal as YesOrNo,
    level,
    parentCompanyName,
    isBeneficialOwner,
    !noSideDealsDisplayLabel(currentLegalForm?.label),
  );
  if (shareError) return shareError;
  if (
    getLocaleAllocation(allocation, LANGUAGE) <= 25 &&
    beneficialOwner.isBeneficialOwnerLevelOne === 'yes' &&
    noSideDealsDisplayLabel(currentLegalForm?.label)
  )
    return 'Bitte stellen Sie sicher, dass ein Wert zwischen 25,01% und 100% eingetragen wird. Nachkommastellen werden auf 2 Dezimale gerundet.';
  const isOwner = beneficialOwner.isOwner === 'yes';
  const allocationError = checkLocaleValidAllocation(allocation, !isOwner);
  if (!allocationError) {
    return isOwner
      ? 'Bitte stellen Sie sicher, dass ein Wert zwischen 0,01% und 100% eingetragen wird. Nachkommastellen werden auf 2 Dezimale gerundet.'
      : 'Bitte stellen Sie sicher, dass ein Wert zwischen 0,00% und 100% eingetragen wird. Nachkommastellen werden auf 2 Dezimale gerundet.';
  }
  return undefined;
};
