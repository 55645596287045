import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  iconLeft: {
    marginRight: '10px',
  },
  buttonWrapper: {
    margin: '9px 0 0 10px',
    position: 'relative',
  },
  button: {
    borderRadius: '30px',
    padding: '3px 15px',
  },
  buttonMargin: {
    margin: '9px 0 0 10px',
    borderRadius: '30px',
    padding: '3px 15px',
  },
  title: {
    fontWeight: 500,
    color: '#000',
    textTransform: 'uppercase',
  },
  helpText: {
    flexGrow: 1,
  },
  blockStatus: {
    fontSize: '12px',
    textTransform: 'uppercase',
    fontWeight: 500,
    height: '22px',
  },
  statusSuccess: {
    backgroundColor: '#85DD83',
    color: '#114310',
  },
  statusDeclined: {
    backgroundColor: '#FFABAB',
    color: '#730000',
  },
  statusPending: {
    backgroundColor: '#E4A2FF',
    color: '#59083D',
  },
  statusNotReviewer: {
    backgroundColor: '#FFD571',
    color: '#363636',
  },
  statusEdit: {
    backgroundColor: '#FCAD6E',
    color: '#321A00',
  },
  blockCommentsIcon: {
    padding: '0 10px !important',
    margin: '0 30px 0 10px',
    borderRadius: 100,
    height: '21px',
    minHeight: '21px',
    minWidth: 0,
  },
  blockCommentsLabel: {
    height: 22,
  },
  buttonContainer: {
    float: 'right',
    margin: '30px 2px 2px',
  },
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: 2,
  },
  menu: {
    bottom: -50,
    right: 165,
    zIndex: 1,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  fabProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: -5,
    left: -5,
    zIndex: 1,
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    background: 'white',
    zIndex: 2,
  },
}));
