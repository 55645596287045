export default (theme) => ({
  dropZone: {
    minHeight: '50px',
    margin: '10px 0',
    padding: '10px',
    border: '2px dashed rgb(214, 213, 213)',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    fontSize: '16px',
    justifyContent: 'space-between',
    width: '100%',
    transition: 'all 0.3s',
    '&:hover': {
      borderColor: '#999',
    },
  },
  label: {
    fontFamily: theme.typography.fontFamily,
  },
  fileName: {
    fontFamily: theme.typography.fontFamily,
    textAlign: 'center',
  },
  textUpload: {
    textAlign: 'center',
  },
  hint: {
    fontFamily: theme.typography.fontFamily,
    color: '#888',
    fontSize: 16,
  },
  highlight: {
    border: '2px dashed',
    borderColor: theme.palette.primary.main,
  },
  icon: {
    opacity: 0.3,
    height: '64px',
    width: '64px',
  },
  fileInput: {
    display: 'none',
  },
  progress: {
    width: '100%',
    maxWidth: 500,
    marginTop: 10,
  },
  fileDownloadButton: {
    marginTop: '5px',
    fontSize: '13px',
  },
  uploadFieldWithValue: {
    borderColor: theme.palette.primary.main,
  },
  fileNameContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fileNameDelete: {
    marginLeft: 10,
  },
  buttonDelete: {
    marginLeft: 5,
    marginRight: 5,
  },
  iconDelete: {
    fontSize: 22,
    color: '#C8C8C8',
    '&:hover': {
      color: '#b70000',
    },
  },
  iconDownload: {
    marginRight: 5,
    fontSize: '14px',
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 14,
  },
  container: {
    flex: 1,
  },
  buttonAnswerSent: {
    backgroundColor: '#fff',
    borderColor: '#6DA618',
    color: '#6DA618',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});
