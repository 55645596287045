import { IComment } from 'utils/commonTypes/types';

export const INTERNAL_COMMENT = 'IC';

export const EXTERNAL_COMMENT = 'EC';

export const NO_PREDEFINED_COMMENTS_MSG: IComment = {
  title: '<Keine Kommentare gefunden>',
  type: 'IC',
  comment: '',
  id: 1,
};
