import axios from 'axios';

const API_VERSION = '/api/v1/';
const BASE_URL = '/de/';

export const EXPORT_FILE = `${BASE_URL}admin/financing/loanapplication/`;

export const CUSTOMER_REGISTER = `${API_VERSION}customers/customer/sign-up/`;
export const CUSTOMER_LEGAL_FORMS = `${API_VERSION}customers/legal-forms/`;
export const LIST_DOCUMENTS_HGB = `${API_VERSION}customers/customer-profile/list-documents-hgb/`;
export const CUSTOMER_COUNTRIES = `${API_VERSION}customers/countries/`;
export const CUSTOMER_JURISDICTION = `${API_VERSION}customers/jurisdiction/`;
export const CUSTOMER_PROFILE = (profileId) =>
  `${API_VERSION}customers/customer-profile/${profileId}/`;
export const CUSTOMER_PROFILE_BUSINNESS_ID = (profileId, idBusiness) =>
  `${API_VERSION}customers/customer-profile/${profileId}/business/${idBusiness}/`;
export const CUSTOMER_PROFILE_BUSINNESS = (profileId) =>
  `${API_VERSION}customers/customer-profile/${profileId}/business/`;
export const CUSTOMER_PROFILE_PERSON = (profileId) =>
  `${API_VERSION}customers/customer-profile/${profileId}/person/`;
export const CUSTOMER_PROFILE_PERSON_ID = (profileId, idPerson) =>
  `${API_VERSION}customers/customer-profile/${profileId}/person/${idPerson}/`;
export const CUSTOMER_PROFILE_SHARES = (profileId) =>
  `${API_VERSION}customers/customer-profile/${profileId}/shares/`;
export const HIDDEN_FIELDS = (profileId) =>
  `${API_VERSION}customers/customer-profile/${profileId}/hidden-fields/`;
export const CUSTOMER_PROFILE_SHOW_HISTORY = (customerId) =>
  `${API_VERSION}customers/customer-profile/${customerId}/show-terms-and-conditions-history/`;

export const LOAN_SECTION_URL = (section, id) =>
  `${API_VERSION}financing-settings/${section.toLowerCase()}/${id || ''}`;
export const HISTORY_LOAN_PROCESS = (id) =>
  `${BASE_URL}admin/financing_settings/processversion/${id}/history/`;
export const PROCESS_VERSION = `${API_VERSION}financing-settings/process-version`;
export const GET_TEMPLATE_FILE = `${API_VERSION}financing-settings/loan-usage/get-template-file/`;
export const LOAN_OPTIONS_URL = `${API_VERSION}financing-settings/loan-options/filter-options/`;
export const GET_LOAN_OPTIONS_URL = `${API_VERSION}financing-settings/loan-options/`;
export const STEP_URL = `${API_VERSION}financing-settings/step/`;
export const FIELD_URL = `${API_VERSION}financing-settings/field/`;
export const BLOCK_URL = `${API_VERSION}financing-settings/block/`;
export const QUESTION_URL = `${API_VERSION}financing-settings/question/`;
export const LOAN_APPLICATION_URL = `${API_VERSION}financing/loan-application/`;
export const ANADIBANK_COMPLIANCE_QUESTIONS = `${API_VERSION}anadibank/compliance-questions/`;
export const ANADIBANK_REQUEST_LOGS = (loanId) =>
  `${API_VERSION}anadibank/request-logs/?loan_application=${loanId}`;
export const ANADIBANK_CANCEL_WEBID = (loanId) =>
  `${API_VERSION}anadibank/identification/${loanId}/cancel-identification/`;
export const FRONTING_BANK_IDENTIFICATION = (frontingBank) =>
  `${API_VERSION}${frontingBank}/identification/`;
export const ANADI_BANK_TEST = `${API_VERSION}anadibank/debug/webid-test-data-response/`;
export const SOLARIS_URL = `${API_VERSION}solarisbank/identification/`;
export const SOLARIS_COMPLIANCE_QUESTION = `${API_VERSION}solarisbank/compliance-question/`;
export const SOLARIS_MISSING_DOCUMENT = `${API_VERSION}solarisbank/missing-document/`;
export const SOLARIS_ACCOUNT_BALANCE_URL = `${API_VERSION}solarisbank/accounts/`;
export const BANK_ACCOUNTS_URL = `${API_VERSION}bank-accounts/`;
export const LOAN_APPLICATION_COMMENT_URL = `${API_VERSION}financing/loan-application-comment/`;
export const FILE_UPLOAD_URL = `${API_VERSION}financing/file-upload/`;
export const FILE_COMMENT_URL = `${API_VERSION}financing/file-comment/`;
export const ADD_INPUT_DATA_URL = `${API_VERSION}financing/loan-application-input/`;
export const LOAN_APPLICATION_REQUEST = `${API_VERSION}financing/loan-application-request/`;
export const SAVE_STEP_OR_BLOCK = `${API_VERSION}financing-settings/process-version/save-as-template/`;
export const COPY_STEP_OR_BLOCK_PROCESS_VERSION = `${API_VERSION}financing-settings/process-version/save-template/`;
export const LIST_TEMPLATES = `${API_VERSION}financing-settings/process-version/template-list/`;
export const WEBHOOKS_URL = `${API_VERSION}solarisbank/notification/`;
export const DOCUSIGN_WEBHOOKS_URL = (loanId) =>
  `${API_VERSION}docusign/webhook/${loanId}/notification-details/`;

export const GET_PROFILE = `${API_VERSION}accounts/auth/profile`;
export const CHECK_EMAIL_URL = `${API_VERSION}accounts/auth/check-email`;
export const LOGIN_URL = `${API_VERSION}accounts/auth/login/`;
export const LOGOUT_URL = `${API_VERSION}accounts/auth/logout/`;
export const RECOVER_PASSWORD_URL = `${API_VERSION}accounts/auth/recover_password/`;
export const RESET_PASSWORD_URL = `${API_VERSION}accounts/auth/reset_password/`;
export const REGISTER_COOPERATION_PARTNER = `${API_VERSION}accounts/cooperation-partner/`;
export const REGISTER_TIPSTER_PARTNER = `${API_VERSION}partners/tipster-partner/`;
export const PASSWORD_CHANGE_URL = `${API_VERSION}accounts/auth/force-password-change/`;
export const REGISTER_TERMS_AND_CONDITIONS_URL = `${API_VERSION}accounts/auth/list-register-terms-conditions/`;
export const DOWNLOAD_ALL_HGB_DOCUMENTS = `${API_VERSION}customers/customer-profile/download-documents-hgb/`;

export const SEND_DOCUSIGN_EMAIL = `${API_VERSION}docusign/envelope/`;

export const BCA_LOGIN_URL = `${API_VERSION}bca/login/`;

export const PREDEFINED_COMMENTS_URL = `${API_VERSION}financing-settings/predefined-comments`;
export const GET_BIC_BANK_NAME = (iban) =>
  `${API_VERSION}financing-settings/validator/${iban}/iban/`;

export const TIPSTER_PARTNER_EMPLOYEE_URL = `${API_VERSION}partners/tipster-employee/`;
export const TIPSTER_PARTNER_TEMPLATE_SETTINGS_URL = `${API_VERSION}partners/template-settings/`;
export const TIPSTER_PARTNER_TOKEN_URL = (partner, employee) =>
  `${API_VERSION}partners/tipster-employee/tipster-token/${partner}/${
    employee && employee !== 'undefined' ? `?employee=${employee}` : ''
  }`;

export const SAVE_ANSWER_URL = (loanId) =>
  `${API_VERSION}financing/loan-application/${loanId}/save-answer/`;
export const LOAN_APPLICATION_REMINDERS = (loanId) =>
  `${API_VERSION}financing/loan-application/${loanId}/reminders/`;
export const LOAN_APPLICATION_FILES = (loanId) =>
  `${API_VERSION}financing/loan-application/${loanId}/get_loan_file_comments/`;
export const TRANSFER_AGREEMENT_FILES = (loanId) =>
  `${API_VERSION}financing/loan-application/${loanId}/get_transfer_agreement/`;
export const BUSINESS_PLAN_EXPORTER = (loanId) =>
  `${API_VERSION}financing/business-plan/${loanId}/export/`;
export const LOAN_APPLICATION_RLA_INPUTS = (loanId) =>
  `${API_VERSION}financing/loan-application/${loanId}/rla-documents/`;
export const FILE_DELETE_URL = (loanInputId) =>
  `${API_VERSION}financing/file-upload/${loanInputId}/`;
export const LOAN_APPLICATION_CHANGE_STATUS_RLA = (loanId) =>
  `${API_VERSION}financing/loan-application/${loanId}/change_status/`;
export const INPUT_DATA_URL = (loanInputId) =>
  `${API_VERSION}financing/loan-application-input/${loanInputId}/`;
export const LOAN_APPLICATION_INFO = (loanId) =>
  `${API_VERSION}financing/loan-application/${loanId}/`;
export const SEND_OWN_ACCOUNT_EMAIL = (loanId) =>
  `${API_VERSION}financing/loan-application/${loanId}/lap-acting-on-his-own/`;
export const SEND_TERMS_AND_CONDITIONS_EMAIL = (loanId) =>
  `${API_VERSION}financing/loan-application/${loanId}/lap-new-terms-conditions/`;

export const LOAN_APPLICATION_HISTORIES = (loanId) =>
  `${API_VERSION}financing/loan-application/${loanId}/histories/`;

export const SAVE_SHARES = (shareID, profileId) =>
  `${API_VERSION}customers/customer-profile/${profileId}/shares/${shareID}/`;
export const GENERATE_CONTRACT = (loanId, offerId) =>
  `${API_VERSION}docusign/contract/${loanId}/generate-contract/${offerId}/`;
export const GET_APPLICATION_CHANNELS = (loanId, view) =>
  `${API_VERSION}financing/loan-application-offer/?loan_application=${loanId}${
    view ? `&view=${view}` : ''
  }`;
export const GENERATE_TRANSFER_AGREEMENT = (loanId, offerId) =>
  `${API_VERSION}docusign/contract/${loanId}/generate-transfer-agreement/${offerId}/`;
export const UPDATE_APPLICATION_CHANNEL = (loanId, offerId) =>
  `${API_VERSION}financing/loan-application-offer/${offerId}/?loan_application=${loanId}`;
export const CHANGE_STATUS_LOAN_OFFER = (offerId) =>
  `${API_VERSION}financing/loan-application-offer/${offerId}/change-status/`;
export const UPLOAD_LOAN_OFFER_FILE = `${API_VERSION}financing/loan-offer-file-upload/`;
export const LOAN_OFFER_FILE = (id) => `${API_VERSION}financing/loan-offer-file-upload/${id}/`;

export const LOAN_APPLICATION_OFFER_URL = (loanId) =>
  `${API_VERSION}financing/loan-application-offer/?loan_application=${loanId}`;
export const LOAN_APPLICATION_OFFER_CHANGE_STATUS = (loanOferId) =>
  `${API_VERSION}financing/loan-application-offer/${loanOferId}/change_status`;
export const UPLOAD_SIGN_UP_SCREENSHOT = (customerId) =>
  `${API_VERSION}customers/customer/${customerId}/upload-sign-up/`;
export const UPLOAD_SIGN_UP_SCREENSHOT_B2B = (id) =>
  `${API_VERSION}accounts/cooperation-partner/${id}/upload-sign-up/`;
export const UPLOAD_SIGN_UP_SCREENSHOT_TIPSTER = (id) =>
  `${API_VERSION}partners/tipster-partner/${id}/upload-sign-up/`;
export const GET_FINANCING_DOCUMENTS = (loanId, legalForm, hgbStatus) =>
  `${API_VERSION}financing/loan-application/${loanId}/got_data_corporate_documents/${legalForm}/${hgbStatus}/`;
export const UPLOAD_CONFIRMATION_SCREENSHOT = (loanId) =>
  `${API_VERSION}financing/loan-application/${loanId}/upload-confirmation-submitted-loan/`;

export const STATUS_STARTING = 'STARTING';
export const STATUS_IN_PROGRESS = 'IN_PROGRESS';
export const STATUS_PENDING = 'PENDING';
export const STATUS_PRE_WAITING = 'PRE_WAITING';
export const STATUS_SUBMITTED = 'SUBMITTED';
export const STATUS_DECLINED = 'DECLINED';
export const STATUS_EDIT = 'EDIT';
export const STATUS_RLA = 'RLA1';
export const STATUS_RLA2 = 'RLA2';
export const STATUS_CONFIRMATION_PENDING = 'CONFIRMATION_PENDING';
export const STATUS_CONFIRMATION_RECEIVED = 'CONFIRMATION_RECEIVED';
export const STATUS_CONTRACT_SIGNING_PREPARATION = 'CONTRACT_SIGNING_PREPARATION';
export const STATUS_CONTRACT_SEND = 'CONTRACT_SEND';
export const STATUS_CONTRACT_SIGNING_RECEIVED = 'CONTRACT_SIGNING_RECEIVED';
export const STATUS_API_TRANSFER = 'API_TRANSFER';
export const STATUS_LOAN_ACTIVE = 'LOAN_ACTIVE';
export const STATUS_PAID_OUT = 'PAID_OUT';
export const STATUS_IDENTIFICATION_RECEIVED = 'IDENTIFICATION_RECEIVED';
export const STATUS_IDENTIFICATION_PENDING = 'IDENTIFICATION_PENDING';
export const STATUS_IDENTIFICATION_SUCCESSFULL = 'IDENTIFICATION_SUCCESSFULL';
export const STATUS_IDENTIFICATION_UNSUCCESSFULL = 'IDENTIFICATION_UNSUCCESSFULL';
export const STATUS_BANK_CHECK_1 = 'BANK_CHECK_1';
export const STATUS_BANK_REQUEST_AVAILABLE = 'BANK_REQUEST_AVAILABLE';
export const STATUS_BANK_REQUEST_PENDING = 'BANK_REQUEST_PENDING';
export const STATUS_BANK_REQUEST_RESPONSE = 'BANK_REQUEST_RESPONSE';
export const STATUS_BANK_CHECK_2 = 'BANK_CHECK_2';
export const STATUS_CHECK_SUCCESSFULL = 'CHECK_SUCCESSFULL';
export const STATUS_CHECK_SUCCESSFUL_2 = 'CHECK_SUCCESSFUL_2';
export const STATUS_BANK_CANCELLED = 'BANK_CANCELLED';
export const STATUS_PAYMENT_PENDING = 'PAYMENT_PENDING';
export const STATUS_REPAID = 'LOAN_REPAID';
export const STATUS_FAILED = 'FAILED';
export const SOLARISBANK = 'Solarisbank';

export const getToken = () => localStorage.getItem('token') || '';

const removeUserData = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refresh');
  localStorage.removeItem('user');
  localStorage.removeItem('role');
  localStorage.removeItem('userWarned');
};

export const logout = () => {
  removeUserData();
  window.location.href = `/loan-application/logout`;
};

const handler429 = () => {
  removeUserData();
  window.location.href = `/de/429-error/`;
};

const client = () => {
  // TODO: this could be set one App/index.tsx as default options
  const defaultOptions = {
    headers: {
      'Accept-Language': 'de-de',
    },
  };

  axios.interceptors.request.use((req) => {
    const token = getToken();
    if (token) {
      req.headers.Authorization = `Bearer ${token}`;
    }
    return req;
  });

  axios.interceptors.response.use(
    (response) => {
      if (response.headers['content-type']) {
        if (response.headers['content-type'].includes('json')) {
          return response;
        }
        if (response.headers['content-type'].includes('pdf')) {
          return response;
        }
        if (
          response.headers['content-type'].includes(
            'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          )
        ) {
          return response;
        }
        if (response.headers['content-type'].includes('application/x-zip-compressed')) {
          return response;
        }
        window.location.href = `/loan-application/auth`;
      }
      return response;
    },
    (error) => {
      if (error.response && error.response.status) {
        const token = getToken();
        if ((error.response.status === 401 || error.response.status === 403) && token) {
          logout();
        }
        if (error.response.status === 429) {
          if (process.env.NODE_ENV === 'production') handler429();
        }
      }
      return Promise.reject(error);
    },
  );

  return {
    get: (url, options = {}) => axios.get(url, { ...defaultOptions, ...options }),
    post: (url, data, options = {}) => axios.post(url, data, { ...defaultOptions, ...options }),
    put: (url, data, options = {}) => axios.put(url, data, { ...defaultOptions, ...options }),
    patch: (url, data, options = {}) => axios.patch(url, data, { ...defaultOptions, ...options }),
    delete: (url, options = {}) => axios.delete(url, { ...defaultOptions, ...options }),
    options: (url, options = {}) => axios.options(url, { ...defaultOptions, ...options }),
  };
};

export const request = client();
