import moment from 'moment';

import {
  ONGOING_TEMPLATE_TAG,
  PROCESS_COMPANY_TEMPLATE_TAG,
  STARTUP_TEMPLATE_TAG,
  TAKEOVER_PERSON_TEMPLATE_TAG,
} from 'constants/templates';
import { MainCompany } from 'context/customer-context/types';
import WarningMessage from 'components/WarningMessage';
import { LegalForm } from 'utils/commonTypes/types';
import { getContractSignatoryOrLoanApplicant } from 'context/dynamic-texts-context/utils';

const showMaxAgeWarning = ({
  ageCustomer,
  legalForm,
  loanApplicationDuration,
  loanApplicationUsage,
}: {
  ageCustomer: number;
  legalForm: '' | LegalForm | undefined;
  loanApplicationDuration: number;
  loanApplicationUsage: string;
}) => {
  const totalAge = ageCustomer + loanApplicationDuration / 12;

  if (loanApplicationUsage === STARTUP_TEMPLATE_TAG) {
    const maxAllowedAge = 60;
    if (ageCustomer >= maxAllowedAge + 1) {
      const message = `Wir bitten um Verständnis, dass wir nur Darlehen an Gründer bis ${maxAllowedAge} Jahren vergeben können.`;

      return <WarningMessage message={message} severity="error" />;
    }
  } else {
    const maxAllowedAge = 65;
    const legalFormsForOldAgeWarning = ['eK', 'Fb', 'GbR', 'PartG', 'PartGmbB'];

    if (legalFormsForOldAgeWarning.includes(legalForm as string)) {
      if (totalAge >= maxAllowedAge + 1) {
        let message = '';
        if (legalForm === 'eK' || legalForm === 'Fb') {
          message = `Der gesetzliche Vertreter darf zum Zeitpunkt des Endes der Darlehenslaufzeit nicht älter als ${maxAllowedAge} Jahre sein. Bitte passen Sie die Darlehenslaufzeit entsprechend in Schritt 1 an, um den Antrag einreichen zu können.`;
        } else {
          message = `Mindestens einer der gesetzlichen Vertreter darf zum Zeitpunkt des Endes der Darlehenslaufzeit nicht älter als ${maxAllowedAge} Jahre sein. Bitte passen Sie die Darlehenslaufzeit entsprechend in Schritt 1 an, um den Antrag einreichen zu können.`;
        }

        return <WarningMessage message={message} severity="error" />;
      }
    }
  }

  return null;
};

const showMinAgeWarning = ({
  ageCustomer,
  legalForm,
  firstName,
  lastName,
  loanApplicationUsage,
  isStartUpTemplate,
}: {
  ageCustomer: number;
  legalForm: '' | LegalForm | undefined;
  firstName: string;
  lastName: string;
  loanApplicationUsage: string;
  isStartUpTemplate: boolean;
}) => {
  if (
    [STARTUP_TEMPLATE_TAG, TAKEOVER_PERSON_TEMPLATE_TAG, PROCESS_COMPANY_TEMPLATE_TAG].includes(
      loanApplicationUsage,
    ) ||
    (loanApplicationUsage === ONGOING_TEMPLATE_TAG && (legalForm === 'eK' || legalForm === 'Fb'))
  ) {
    const minAllowedAge = 20;
    let message = '';
    if (ageCustomer < minAllowedAge) {
      message =
        loanApplicationUsage === TAKEOVER_PERSON_TEMPLATE_TAG
          ? 'Wir bitten um Verständnis, dass wir Darlehen zur Übernahme nur an Personen ab 20 Jahren vergeben können.'
          : legalForm &&
              (legalForm === 'eK' || legalForm === 'Fb') &&
              !isStartUpTemplate &&
              loanApplicationUsage !== PROCESS_COMPANY_TEMPLATE_TAG
            ? 'Wir bitten um Verständnis, dass wir Darlehen an e.K. und Freiberufler erst ab 20 Jahren vergeben können.'
            : 'Wir bitten um Verständnis, dass wir Darlehen an Gründer erst ab 20 Jahren vergeben können.';
      return <WarningMessage message={message} severity="error" />;
    }
  }

  const contractSignatoryOrLoanApplicant =
    getContractSignatoryOrLoanApplicant(loanApplicationUsage);

  const minAllowedAge = 18;
  if (ageCustomer < minAllowedAge) {
    const message = `Der/Die ${contractSignatoryOrLoanApplicant}/in ${firstName} ${lastName} muss volljährig sein, um ein CAPTIQ Darlehen beantragen zu können.`;
    return <WarningMessage message={message} severity="error" />;
  }

  return null;
};

export const showAgeWarningMessage = ({
  birthDate,
  firstName,
  lastName,
  loanApplicationUsage,
  mainCompany,
  loanApplicationDuration,
  isStartUpTemplate,
}: {
  birthDate: Date | string;
  firstName: string;
  lastName: string;
  mainCompany: MainCompany | null;
  loanApplicationUsage: string;
  loanApplicationDuration: number;
  isStartUpTemplate: boolean;
}) => {
  const ageCustomer = moment().diff(new Date(birthDate), 'years', true);

  const minAgeWarning = showMinAgeWarning({
    ageCustomer,
    legalForm: mainCompany?.data.legalForm,
    firstName,
    lastName,
    loanApplicationUsage,
    isStartUpTemplate,
  });

  if (minAgeWarning) return minAgeWarning;

  const maxAgeWarning = showMaxAgeWarning({
    ageCustomer,
    legalForm: mainCompany?.data.legalForm,
    loanApplicationDuration,
    loanApplicationUsage,
  });

  if (maxAgeWarning) return maxAgeWarning;

  return null;
};
