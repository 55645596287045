import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  buttonMargin: {
    margin: '9px 0 0 10px',
    borderRadius: '30px',
    padding: '3px 15px',
  },
  iconLeft: {
    marginRight: '10px',
  },
  buttonWrapper: {
    margin: '9px 0 0 10px',
    position: 'relative',
  },
  button: {
    borderRadius: '30px',
    padding: '3px 15px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  fabProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: -5,
    left: -5,
    zIndex: 1,
  },
}));
