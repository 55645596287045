import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import styles from './styles';

function getCurrencyValue(value) {
  return Number(value).toLocaleString('de-DE', {
    style: 'currency',
    currency: 'EUR',
  });
}

function LoanResume(props) {
  const { classes, loanAmount, monthlyRate, interestRate, agio } = props;

  return (
    <Grid container spacing={4} className={classes.calculationBox}>
      <Grid item md={4} className={classes.innerContainer}>
        <Typography variant="subtitle1">
          Darlehensbetrag
          <br />
          inkl. Vermittlungsgebühr<sup>1</sup>
        </Typography>
        <Typography className={classes.textAmount}>
          {getCurrencyValue(parseInt(loanAmount) * (1 + agio / 100))}
        </Typography>
      </Grid>
      <Grid item md={4} className={classes.innerContainer}>
        <Typography variant="subtitle1">
          Unverbindliche
          <br />
          monatliche Raten<sup>2</sup>
        </Typography>
        <Typography className={classes.textAmount}>{getCurrencyValue(monthlyRate)}</Typography>
      </Grid>
      <Grid item md={4} className={classNames(classes.innerContainer, classes.displayNone)}>
        <Typography variant="subtitle1">
          Unverbindlicher
          <br />
          Zinssatz
        </Typography>
        <Typography className={classes.textAmount}>
          {interestRate ? interestRate.toFixed(2).replace('.', ',') : '0,00'}
          <span className={classes.adornment}>% p.a.</span>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(LoanResume);
