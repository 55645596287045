export const SALUTATIONS = ['ms', 'mr'] as const;

export type Salutation = (typeof SALUTATIONS)[number];

export const getSalutationLabel = (salutation: Salutation | '') => {
  switch (salutation) {
    case 'ms':
      return 'Frau';
    case 'mr':
      return 'Herr';
    default:
      return '';
  }
};
