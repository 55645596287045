export const TITLES = ['', 'dr.', 'prof.', 'prof. dr.', 'other'] as const;

export type Title = (typeof TITLES)[number];

export const getTitleLabel = (title: Title | '') => {
  switch (title) {
    case '':
      return '-';
    case 'dr.':
      return 'Dr.';
    case 'prof.':
      return 'Prof.';
    case 'prof. dr.':
      return 'Prof. Dr.';
    case 'other':
      return 'Sonstiges';
    default:
      return '';
  }
};
