import classNames from 'classnames';

import { IReviewStatusIconProps } from './types';
import useStyles from './styles';

const ReviewStatusIcon = ({ status = 'NOT_REVIEWED' }: IReviewStatusIconProps) => {
  const classes = useStyles();

  const statusClasses = {
    APPROVED: classes.statusSuccess,
    DECLINED: classes.statusDeclined,
    NOT_REVIEWED: classes.statusNotReviewer,
  };

  return <div className={classNames(classes.reviewIcon, statusClasses[status])} />;
};

export default ReviewStatusIcon;
