import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useReducer,
  useRef,
} from 'react';
import _findIndex from 'lodash/findIndex';
import _isEmpty from 'lodash.isempty';
import * as Yup from 'yup';
import { useQuery } from '@tanstack/react-query';

import { AuthContext } from 'context/auth-context';
import { BOData } from 'containers/CustomerProfile/types';
import { Block, CountryType, LegalForm, LegalFormsType } from 'utils/commonTypes/types';
import * as ApiCaptiq from 'service/ApiCaptiq';
import { request } from 'service/ApiCaptiq';
import { serializeCustomerProfileToContext, serializeNationalities } from 'utils/dataHandlers';
import { useLazyEffect } from 'hooks/useLazyEffect';
import {
  BlockValidated,
  LATabCompletionStateType,
} from 'components/StaticBlocks/LoanApplicants/types';
import { validationSchema as ownerValidationSchema } from 'components/StaticBlocks/Owner/validationSchema';
import { validationSchema as legalRepresentativesValidationSchema } from 'components/StaticBlocks/LegalRepresentative/validationSchema';
import { validationSchema as beneficaialOwnersValidationSchema } from 'components/StaticBlocks/BeneficialOwners/validationSchema';
import { Response } from 'utils/validateStaticBlocksValues/getValidationErrors';

import { Errors, validateStaticBlockValues } from 'utils/validateStaticBlocksValues';
import { getErrorsOW } from 'utils/validateStaticBlocksValues/getErrorsOW';
import { getErrorsBO } from 'utils/validateStaticBlocksValues/getErrorsBO';
import { getErrorsLR } from 'utils/validateStaticBlocksValues/getErrorsLR';
import { getErrorsLA } from 'utils/validateStaticBlocksValues/getErrorsLA';
import { useDataContext } from 'context/data-context';
import { usePersistentData } from 'context/persistent-context';
import { generalOnError } from 'utils/queryUtils';
import { useDynamicTexts } from 'context/dynamic-texts-context';
import {
  initialState,
  CustomerProfileIdType,
  SET_COMPANIES,
  SET_PEOPLE,
  SET_REFS_OWNERS,
  SET_REFS_BENEFICIAL_OWNER,
  SET_CURRENT_LEGAL_FORM,
  SET_REFS_LEGAL_REPRESENTATIVES,
  SET_REFS_LOAN_APPLICANTS,
  SET_STATE,
  initialValues as cpInitValues,
  UpdateInitialValues,
  InitialValues,
} from './constants';
import {
  Company,
  Options,
  Person,
  RefBeneficialOwner,
  RefLegalRepresentative,
  RefLoanApplicant,
  RefOwner,
} from './types';
import { reducer } from './reducer';
import { people as peopleValidation, companies as companyValidations } from './validations';
import { validateBirthDate, validateLAStaticValues } from './utils';

export const CustomerData = createContext(initialState);

const CustomerDataProvider = ({ children }: { children: React.ReactNode }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const { loanApplication, loanApplicationUsage, loanApplicationDuration } = useDataContext();
  const { applicantOrSignatory } = useDynamicTexts();
  const hasBeenCalled = useRef(false);
  const [state, dispatch] = useReducer(reducer, cpInitValues);
  const {
    mainCompany,
    people,
    companies,
    currentLegalForm,
    refsOwners,
    refsBeneficialOwners,
    refsLegalRepresentatives,
    refsLoanApplicants,
    customerProfileId,
    customerProfileData,
  } = state;
  const [options, setOptions] = useState<Options>({
    countryList: [],
    legalForms: null,
    balancingForms: {},
  });
  const [initialValues, setInitialValues] = useState<InitialValues | {}>({});
  const [validationSchema, setValidationSchema] = useState<Yup.SchemaOf<InitialValues> | null>(
    null,
  );
  // Owner
  const [initialValuesMain, setInitialValuesMain] = useState<InitialValues | {}>({});

  // Owner
  const [validationSchemaOwner, setValidationSchemaOwner] =
    useState<Yup.SchemaOf<InitialValues> | null>(null);
  // Beneficial Owner
  const [validationSchemaBeneficialOwner, setValidationSchemaBeneficialOwner] =
    useState<Yup.SchemaOf<InitialValues> | null>(null);
  // Legal Representative
  const [validationSchemaLegalRepresentative, setValidationSchemaLegalRepresentative] =
    useState<Yup.SchemaOf<InitialValues> | null>(null);
  // Loan Applicant
  const [validationSchemaLoanApplicant, setValidationSchemaLoanApplicant] =
    useState<Yup.SchemaOf<InitialValues> | null>(null);

  const [hgbAccordance, setHgbAccordance] = useState<boolean>(false);
  const [legalForm, setLegalForm] = useState<LegalForm | null>(null);
  const [completedStaticFieldsOW, setCompletedStaticFieldsOW] = useState<boolean[]>([]);
  const [completedStaticFieldsBO, setCompletedStaticFieldsBO] = useState<boolean[]>([]);
  const [completedStaticFieldsLR, setCompletedStaticFieldsLR] = useState<boolean[]>([]);
  const [completedStaticFieldsLA, setCompletedStaticFieldsLA] = useState<boolean[]>([]);
  const [missingErrorOW, setMissingErrorOW] = useState<Block | {}>({});
  const [missingErrorLR, setMissingErrorLR] = useState<Block | {}>({});
  const [missingErrorBO, setMissingErrorBO] = useState<Block | {}>({});
  const [missingErrorLA, setMissingErrorLA] = useState<Block | {}>({});
  const [wrongErrorLR, setWrongErrorLR] = useState<Block | {}>({});
  const [wrongErrorLA, setWrongErrorLA] = useState<Block | {}>({});
  const [wrongErrorBO, setWrongErrorBO] = useState<Block | {}>({});
  const [currentDeleteModal, setCurrentDeleteModal] = useState(null);
  const [boDeleteModalStatus, setDeleteModalStatusBO] = useState(false);
  const [deleteData, setDeleteData] = useState<Company | null>(null);
  const [boData, setBOData] = useState<BOData | null>(null);
  const [firstDataLoad, setFirstDataLoad] = useState<boolean>(false);
  const [bulkUpdateStatus, setBulkUpdateStatus] = useState(false);
  const [sonstigeDisplay, setSonstigeDisplay] = useState(true);
  const hiddenFieldsQuery = useQuery(
    ['hiddenFields'],
    () => request.get(ApiCaptiq.HIDDEN_FIELDS(customerProfileId)),
    {
      staleTime: Infinity,
      enabled: isAuthenticated() && !!customerProfileId,
      onError: generalOnError,
      onSuccess: ({ data }) => {
        const loanProcessHiddenFields = (data as { legal_form: string[] })?.legal_form ?? [];
        // TODO: Update this array with the response from the backend when that task is done
        const hgbDocumentsFieldsFields: string[] = [];
        setHiddenFields({
          loanProcess: [...loanProcessHiddenFields, ...hgbDocumentsFieldsFields],
        });
      },
    },
  );
  const [hiddenFields, setHiddenFields] = useState<{
    loanProcess: string[];
  }>({ loanProcess: [] });

  const { countryList, legalForms, hgbDocuments } = usePersistentData();

  const validateLAValues = useCallback(async () => {
    const tabsStatuses = await validateLAStaticValues(
      { ...initialValues, loanApplicationUsage, loanApplicationDuration } as InitialValues,
      refsLoanApplicants,
      customerProfileData?.profileVersion || 'VERSION_1',
    );

    return tabsStatuses;
  }, [
    customerProfileData?.profileVersion,
    initialValues,
    loanApplicationDuration,
    loanApplicationUsage,
    refsLoanApplicants,
  ]);

  const setCompleteStatus = useCallback(
    (component: string, fields: boolean[]) => {
      switch (component) {
        case 'OW':
          setCompletedStaticFieldsOW(fields);
          break;
        case 'BO':
          setCompletedStaticFieldsBO(fields);
          break;
        case 'LR':
          setCompletedStaticFieldsLR(fields);
          break;
        case 'LA':
          setCompletedStaticFieldsLA(fields);
          break;
        default:
          break;
      }
    },
    // No dependencies to avoid a infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (!_isEmpty(initialValuesMain)) {
      setInitialValues((prevInitialValues) => ({
        ...prevInitialValues,
        ...initialValuesMain,
      }));
    }
  }, [initialValuesMain]);

  useEffect(() => {
    setValidationSchema(
      Yup.object().shape({
        people: peopleValidation,
        companies: companyValidations,
        ...validationSchemaBeneficialOwner?.fields,
        ...validationSchemaOwner?.fields,
        ...validationSchemaLegalRepresentative?.fields,
        ...validationSchemaLoanApplicant?.fields,
      }) as Yup.SchemaOf<InitialValues>,
    );
  }, [
    validationSchemaBeneficialOwner,
    validationSchemaOwner,
    validationSchemaLegalRepresentative,
    validationSchemaLoanApplicant,
  ]);

  const validateOwners = useCallback(async () => {
    const [tabsStatuses, errorsYUP] = (await validateStaticBlockValues(
      initialValues,
      refsOwners,
      ownerValidationSchema,
      customerProfileData?.profileVersion || 'VERSION_1',
    )) as [boolean[], Errors[]];
    setCompleteStatus('OW', tabsStatuses as boolean[]);
    const block = {
      title: 'Inhaber',
      section_path: 'unternehmensstruktur__inhaber',
    };
    const hasMissingErrors = errorsYUP
      .map((item) => item.errors.some((error) => error.type === 'required'))
      .some((item) => item === true);

    if (hasMissingErrors) {
      setMissingErrorOW(getErrorsOW({ errorsYUP, block, refsOwners }));
    } else {
      setMissingErrorOW({});
    }
  }, [customerProfileData?.profileVersion, initialValues, refsOwners, setCompleteStatus]);

  const validateBOs = useCallback(async () => {
    const [tabsStatuses, errorsYUP] = (await validateStaticBlockValues(
      initialValues,
      refsBeneficialOwners,
      beneficaialOwnersValidationSchema,
      customerProfileData?.profileVersion || 'VERSION_1',
    )) as [boolean[], Errors[]];
    setCompleteStatus('BO', tabsStatuses);
    const block = {
      title: 'Wirtschaftlich Berechtigte',
      section_path: 'unternehmensstruktur__wirtschaftlichberechtigte',
    };
    const hasMissingErrors = errorsYUP
      .map((item) => item.errors.some((error) => error.type === 'required'))
      .some((item) => item === true);
    const hasWrongErrors = errorsYUP
      .map((item) => item.errors.some((error) => error.type !== 'required'))
      .some((item) => item === true);

    if (hasMissingErrors) {
      setMissingErrorBO(getErrorsBO({ errorsYUP, refsBeneficialOwners, block, isEqual: true }));
    } else {
      setMissingErrorBO({});
    }
    if (hasWrongErrors) {
      setWrongErrorBO(getErrorsBO({ errorsYUP, refsBeneficialOwners, block, isEqual: false }));
    } else {
      setWrongErrorBO({});
    }
  }, [customerProfileData?.profileVersion, initialValues, refsBeneficialOwners, setCompleteStatus]);

  const validateLRP = useCallback(async () => {
    const [tempTabsStatuses, tempErrorsYUP] = (await validateStaticBlockValues(
      initialValues,
      refsLegalRepresentatives,
      legalRepresentativesValidationSchema,
      customerProfileData?.profileVersion || 'VERSION_1',
    )) as [boolean[], Errors[]];

    const [tabsStatuses, errorsYUP] = validateBirthDate(
      tempTabsStatuses,
      tempErrorsYUP,
      refsLegalRepresentatives,
    ) as [boolean[], Errors[]];

    setCompleteStatus('LR', tabsStatuses as boolean[]);
    const block = {
      title: 'Gesetzliche Vertreter',
      section_path: 'unternehmensstruktur__gesetzlichevertreter',
    };
    const hasMissingErrors = errorsYUP
      .map((item) => item.errors.some((error) => error.type === 'required'))
      .some((item) => item === true);

    const hasWrongErrors = errorsYUP
      .map((item) => item.errors.some((error) => error.type !== 'required'))
      .some((item) => item === true);

    if (hasMissingErrors) {
      setMissingErrorLR(getErrorsLR({ errorsYUP, block, isEqual: true, refsLegalRepresentatives }));
    } else {
      setMissingErrorLR({});
    }
    if (hasWrongErrors) {
      setWrongErrorLR(getErrorsLR({ errorsYUP, block, isEqual: false, refsLegalRepresentatives }));
    } else {
      setWrongErrorLR({});
    }
  }, [
    customerProfileData?.profileVersion,
    initialValues,
    refsLegalRepresentatives,
    setCompleteStatus,
  ]);

  const validateLAP = useCallback(async () => {
    const tabsStatuses = await validateLAValues();
    let completedStatus: boolean[] = [];
    tabsStatuses.forEach((val: LATabCompletionStateType) => {
      completedStatus = completedStatus.concat(
        Object.values(val).map((item: BlockValidated) => item.complited),
      );
    });
    setCompleteStatus('LA', completedStatus);
    const existError = completedStatus.some((item) => item === false);
    const personalDataErrors: Response[][] = [];
    const addressErrors: Response[][] = [];
    const contactErrors: Response[][] = [];

    let personalDataHasMissingErrors = false;
    let personalDataHasWrongErrors = false;
    let addressHasMissingErrors = false;
    let addressHasWrongErrors = false;
    let contactHasMissingErrors = false;
    let contactHasWrongErrors = false;

    const getErrors = (isEqual: boolean) =>
      refsLoanApplicants.map((_, index) => {
        const blocks = [];
        let personalDataErrorsFormated;
        let addressErrorsFormated;
        let contactErrorsFormated;
        if (personalDataErrors?.[index]?.length) {
          if (isEqual ? personalDataHasMissingErrors : personalDataHasWrongErrors) {
            personalDataErrorsFormated = getErrorsLA({
              errorsYUP: personalDataErrors[index],
              block: {
                title: 'Personenbezogene Daten',
                section_path: 'darlehensantragsteller_personenbezogenedaten',
              },
              isEqual,
            });
          }
        }
        if (addressErrors?.[index]?.length) {
          if (isEqual ? addressHasMissingErrors : addressHasWrongErrors) {
            addressErrorsFormated = getErrorsLA({
              errorsYUP: addressErrors[index],
              block: {
                title: 'Anschrift',
                section_path: 'darlehensantragsteller_anschrift',
              },
              isEqual,
            });
          }
        }
        if (contactErrors?.[index]?.length) {
          if (isEqual ? contactHasMissingErrors : contactHasWrongErrors) {
            contactErrorsFormated = getErrorsLA({
              errorsYUP: contactErrors[index],
              block: {
                title: 'Kontakt',
                section_path: 'darlehensantragsteller_kontakt',
              },
              isEqual,
            });
          }
        }
        if (personalDataErrorsFormated) blocks.push(personalDataErrorsFormated);
        if (addressErrorsFormated) blocks.push(addressErrorsFormated);
        if (contactErrorsFormated) blocks.push(contactErrorsFormated);
        return {
          title: `${applicantOrSignatory}${refsLoanApplicants.length !== 1 ? ` ${index + 1}` : ''}`,
          type: 'withInnerBlocks',
          section_path: 'darlehensantragsteller',
          blocks,
        };
      });

    if (existError) {
      tabsStatuses.forEach((val, index) =>
        Object.entries(val).forEach(([label, item]) => {
          if (!item.complited) {
            if (label === 'personalData') {
              personalDataErrors[index] = item.errors;
              personalDataHasMissingErrors = personalDataErrors[index].some(
                (error) => error.type === 'required',
              );
              personalDataHasWrongErrors = personalDataErrors[index].some(
                (error) => error.type !== 'required',
              );
            }
            if (label === 'address') {
              addressErrors[index] = item.errors;
              addressHasMissingErrors = addressErrors[index].some(
                (error) => error.type === 'required',
              );
              addressHasWrongErrors = addressErrors[index].some(
                (error) => error.type !== 'required',
              );
            }
            if (label === 'contact') {
              contactErrors[index] = item.errors;
              contactHasMissingErrors = contactErrors[index].some(
                (error) => error.type === 'required',
              );
              contactHasWrongErrors = contactErrors[index].some(
                (error) => error.type !== 'required',
              );
            }
          }
        }),
      );
      if (personalDataHasMissingErrors || addressHasMissingErrors || contactHasMissingErrors) {
        setMissingErrorLA(getErrors(true));
      } else {
        setMissingErrorLA({});
      }
      if (personalDataHasWrongErrors || addressHasWrongErrors || contactHasWrongErrors) {
        setWrongErrorLA(getErrors(false));
      } else {
        setWrongErrorLA({});
      }
    } else {
      setMissingErrorLA({});
      setWrongErrorLA({});
    }
  }, [applicantOrSignatory, refsLoanApplicants, setCompleteStatus, validateLAValues]);

  const runValidations = useCallback(async () => {
    await Promise.all([validateOwners(), validateBOs(), validateLAP(), validateLRP()]);
  }, [validateBOs, validateLAP, validateLRP, validateOwners]);

  const setPeople = useCallback((value: Person[]) => {
    dispatch({
      type: SET_PEOPLE,
      people: value,
    });
  }, []);

  const setCompanies = useCallback((value: Company[]) => {
    dispatch({
      type: SET_COMPANIES,
      companies: value,
    });
  }, []);

  const setCurrentLegalForm = useCallback((value: LegalFormsType) => {
    dispatch({
      type: SET_CURRENT_LEGAL_FORM,
      currentLegalForm: value,
    });
  }, []);

  const setRefsOwners = useCallback((value: RefOwner[]) => {
    dispatch({
      type: SET_REFS_OWNERS,
      refsOwners: value,
    });
  }, []);

  const setRefsBeneficialOwners = useCallback((value: RefBeneficialOwner[]) => {
    dispatch({
      type: SET_REFS_BENEFICIAL_OWNER,
      refsBeneficialOwners: value,
    });
  }, []);

  const setRefsLegalRepresentatives = useCallback((value: RefLegalRepresentative[]) => {
    dispatch({
      type: SET_REFS_LEGAL_REPRESENTATIVES,
      refsLegalRepresentatives: value,
    });
  }, []);

  const setRefsLoanApplicants = useCallback((value: RefLoanApplicant[]) => {
    dispatch({
      type: SET_REFS_LOAN_APPLICANTS,
      refsLoanApplicants: value,
    });
  }, []);

  const initialHasBeenCalled = useCallback(() => {
    hasBeenCalled.current = false;
  }, []);

  const setBODeleteModalStatus = (
    statusValue: boolean,
    deleteDataValue: Company | null,
    boDataValue: BOData | null,
  ) => {
    setDeleteModalStatusBO(statusValue);
    setDeleteData(deleteDataValue);
    setBOData(boDataValue);
  };

  const fetchCustomerProfile = useCallback(
    async (customerID: CustomerProfileIdType, refresh?: boolean): Promise<void> => {
      try {
        if ((customerID && !hasBeenCalled.current) || refresh) {
          hasBeenCalled.current = true;
          const { data } = await request.get(ApiCaptiq.CUSTOMER_PROFILE(customerID));
          const {
            companies: newCompanies,
            mainCompany: newMainCompany,
            people: newPeople,
            refsBeneficialOwners: newRefsBeneficialOwners,
            refsLegalRepresentatives: newRefsLegalRepresentatives,
            refsOwners: newRefsOwners,
            refsLoanApplicants: newRefsLoanApplicants,
            customerProfileData: newCustomerProfileData,
          } = serializeCustomerProfileToContext(data);
          const newState: UpdateInitialValues = {
            people: newPeople,
            companies: newCompanies,
            mainCompany: newMainCompany,
            refsLegalRepresentatives: newRefsLegalRepresentatives,
            refsBeneficialOwners: newRefsBeneficialOwners,
            refsOwners: newRefsOwners,
            refsLoanApplicants: newRefsLoanApplicants,
            customerProfileData: newCustomerProfileData,
            customerProfileId: customerID,
          };
          if (newMainCompany && newMainCompany.data.legalForm && options.legalForms) {
            newState.currentLegalForm = options.legalForms[newMainCompany.data.legalForm];
            setLegalForm(newMainCompany.data.legalForm);
            setHgbAccordance(newMainCompany.data.hgb === 'yes');
          }

          dispatch({ type: SET_STATE, state: { ...newState } });
          const newFormikValues = {
            jurisdiction: newCustomerProfileData?.jurisdiction,
            company: newMainCompany,
            people: [...newPeople],
            companies: [...newCompanies],
          };
          setInitialValuesMain({ ...newFormikValues });
          setFirstDataLoad(true);
        }
      } catch (e) {
        console.error(e);
      }
    },
    [options.legalForms],
  );

  useLazyEffect(() => {
    if ((initialValues as InitialValues).companies && (initialValues as InitialValues).people) {
      runValidations();
    }
  }, [initialValues, runValidations]);

  useEffect(() => {
    const fetchInitialData = async () => {
      const newCountryList = countryList;
      const germanIndex = _findIndex(
        newCountryList ?? [],
        (country: CountryType) => country.code === 'DE',
      );

      if (germanIndex !== -1) {
        [newCountryList[0], newCountryList[germanIndex]] = [
          newCountryList[germanIndex],
          newCountryList[0],
        ];
      }

      if (customerProfileData?.customerProfileType === 'B2C') {
        if (newCountryList.length > 0 && legalForms && hgbDocuments)
          setOptions({
            countryList: serializeNationalities(newCountryList),
            legalForms,
            balancingForms: hgbDocuments,
          });
      } else if (newCountryList.length > 0 && legalForms && hgbDocuments) {
        setOptions({
          countryList: serializeNationalities(newCountryList),
          legalForms,
          balancingForms: hgbDocuments,
        });
      }

      if (legalForms && mainCompany?.data.legalForm) {
        setCurrentLegalForm(legalForms[mainCompany.data.legalForm]);
        setLegalForm(mainCompany.data.legalForm);
        setHgbAccordance(mainCompany.data.hgb === 'yes');
      }
    };

    if (isAuthenticated() && !options.legalForms) {
      fetchInitialData();
    }
  }, [
    mainCompany,
    isAuthenticated,
    options.legalForms,
    setCurrentLegalForm,
    countryList,
    legalForms,
    hgbDocuments,
    loanApplication,
    customerProfileData?.customerProfileType,
    customerProfileData,
  ]);

  return (
    <CustomerData.Provider
      value={{
        firstDataLoad,
        boData,
        boDeleteModalStatus,
        companies,
        countryList: options.countryList,
        currentDeleteModal,
        currentLegalForm,
        setCurrentLegalForm,
        customerProfileId,
        customerProfileData,
        deleteData,
        legalForms: options.legalForms,
        balancingForms: options.balancingForms,
        mainCompany,
        people,
        refsBeneficialOwners,
        refsLegalRepresentatives,
        refsLoanApplicants,
        refsOwners,
        fetchCustomerProfile,
        bulkUpdateStatus,
        sonstigeDisplay,
        hiddenFields,
        setCompanies,
        setCurrentDeleteModal,
        setBODeleteModalStatus,
        setPeople,
        setRefsBeneficialOwners,
        setRefsLegalRepresentatives,
        setRefsLoanApplicants,
        setRefsOwners,
        initialHasBeenCalled,
        setBulkUpdateStatus,
        setSonstigeDisplay,
        legalForm,
        setLegalForm,
        hgbAccordance,
        setHgbAccordance,
        initialValues,
        validationSchema,
        completedStaticFieldsOW,
        completedStaticFieldsBO,
        completedStaticFieldsLR,
        completedStaticFieldsLA,
        missingErrorOW,
        missingErrorLR,
        missingErrorBO,
        missingErrorLA,
        wrongErrorLR,
        wrongErrorLA,
        wrongErrorBO,
        setCompleteStatus,
        setInitialValues,
        setInitialValuesMain,
        setValidationSchemaLegalRepresentative,
        setValidationSchemaOwner,
        setValidationSchemaBeneficialOwner,
        setValidationSchemaLoanApplicant,
        setValidationSchema,
        setMissingErrorOW,
        setMissingErrorLR,
        setMissingErrorBO,
        setMissingErrorLA,
        setWrongErrorLR,
        setWrongErrorLA,
        setWrongErrorBO,
        runValidations,
        hiddenFieldsQuery,
      }}
    >
      {children}
    </CustomerData.Provider>
  );
};

export const useCustomerData = () => {
  const context = useContext(CustomerData);
  if (context === undefined) {
    throw new Error('useCustomerData must be used within a CountProvider');
  }
  return context;
};

export default CustomerDataProvider;
