import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
  completeField: {
    '& fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  endAdornment: {
    marginRight: 4,
    marginLeft: 10,
  },
}));

export default useStyles;
