import React, { Suspense } from 'react';

const App = React.lazy(() => import('./main'));

export default function Index() {
  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        <App />
      </Suspense>
    </div>
  );
}
