import React, { ChangeEvent, memo, useCallback } from 'react';
import { NumericFormat } from 'react-number-format';

interface Props {
  name?: string;
  inputRef?: React.Ref<HTMLInputElement> | undefined;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const NumberFormatGeneric = (props: Props) => {
  const { inputRef, onChange, ...other } = props;

  const buildEvent = useCallback((value: string, name: string) => {
    const newEvent = { target: { value, name } };
    return newEvent as ChangeEvent<HTMLInputElement>;
  }, []);

  const handlerValueChange = useCallback(
    (values: any) => {
      if (onChange) {
        onChange(buildEvent(values.value, props.name as string));
      }
    },
    [buildEvent, onChange, props.name],
  );

  const handlerAllowedValue = useCallback((values: any) => {
    const { floatValue } = values;
    let floatValue0;
    if (floatValue) floatValue0 = parseFloat(`${floatValue}`);
    return !floatValue0 || (floatValue0 >= 0 && floatValue0 <= 9999999999);
  }, []);

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={handlerValueChange}
      fixedDecimalScale
      valueIsNumericString
      allowNegative={false}
      isAllowed={handlerAllowedValue}
    />
  );
};
export default memo(NumberFormatGeneric);
