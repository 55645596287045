import moment from 'moment';
import PropTypes from 'prop-types';

// TODO: replace any type when the component change to typescript
export const valueFactory = (data: any) => {
  let value = data.value || data.default || '';
  if (data.field_type === 'INTEGER') {
    value = data.value === 0 ? data.value : data.value || data.default || '';
  }
  if (data.field_type === 'DECIMAL') {
    value = data.value;
  }
  if (data.field_type === 'BOOLEAN') {
    value = value === true;
  }
  if (data.field_type === 'DATE') {
    value = value.length > 0 ? moment(value, 'YYYY-MM-DD').toDate() : null;
  }
  return value;
};

export const CaptiqFieldPropTypes = {
  onChange: PropTypes.func,
  deleteField: PropTypes.func,
  type: PropTypes.oneOf([
    'CHOICE',
    'INTEGER',
    'DECIMAL',
    'CHAR',
    'FILE',
    'BOOLEAN',
    'TEXT',
    'DATE',
    'CALCULATED',
    'RICH_TEXT',
  ]),
  data: PropTypes.shape({
    type: PropTypes.oneOf([
      'CHOICE',
      'INTEGER',
      'DECIMAL',
      'CHAR',
      'FILE',
      'BOOLEAN',
      'TEXT',
      'DATE',
      'RICH_TEXT',
    ]),
    slug: PropTypes.string.isRequired,
    label: PropTypes.string,
    choice_group: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    cutom_choice_group: PropTypes.object,
    default: PropTypes.string,
    depending_field: PropTypes.string,
    description: PropTypes.string,
    field_type: PropTypes.string,
    is_required: PropTypes.bool,
    order: PropTypes.number,
  }),
  blockSlug: PropTypes.string,
  questionSlug: PropTypes.string,
  stepOrder: PropTypes.number,
  disabled: PropTypes.bool,
  customColors: PropTypes.any,
  personID: PropTypes.number,
};
