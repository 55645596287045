import { BackendCompany } from 'utils/backendTypes';
import { Company, MainCompany, Share } from 'context/customer-context/types';
import { getLocaleAllocation } from 'utils/utils';
import { LANGUAGE } from 'constants/language';
import { getCountryOrNull, returnYesNoForFormik, getShare } from './index';

export const serializeCompanyToContext = (
  company: BackendCompany,
  mainCompany: MainCompany | null,
): Company => {
  const shares: Share[] = company.shares.map((v) => getShare(v));

  const serializedCompany: Company = {
    id: company.id,
    businessId: company.business_id,
    customerProfileRelationId: Number(company.customer_profile_relation.id),
    correlativeId: company.customer_profile_relation.correlative_id,
    companyName: company.business,
    legalForm: company.legal_form,
    shortLabel: company.short_label,
    businessAddressStreet: company.business_address_street,
    businessAddressHouseNumber: company.business_address_house_n,
    businessAddressAdditional: company.business_address_extra,
    businessAddressPostalCode: company.business_address_postcode,
    businessAddressCity: company.business_address_city,
    hgb: company.hgb_accordance ? returnYesNoForFormik(company.hgb_accordance) : '',
    businessAddressCountry: getCountryOrNull(company.business_address_country),
    isMainCompany: '',
    isBeneficialOwner: 'no',
    isBeneficialOwnerLevelOne: 'no',
    isBeneficialOwnerLevelTwo: 'no',
    isBeneficialOwnerLevelThree: 'no',
    isOwner: 'no',
    shares,
  };

  shares.forEach(({ allocation, sideDeal, parent }) => {
    if (allocation) {
      const currentAllocation = getLocaleAllocation(allocation, LANGUAGE);
      if (currentAllocation > 0 && mainCompany && parent === mainCompany.id) {
        serializedCompany.isOwner = 'yes';
      }
      if (currentAllocation <= 25 && sideDeal === 'yes')
        serializedCompany.isBeneficialOwner = 'yes';
      if (currentAllocation > 25) serializedCompany.isBeneficialOwner = 'yes';
    }
  });

  return serializedCompany;
};
