import React, { memo } from 'react';

import { Grid } from '@mui/material';

import { CountryType } from 'utils/commonTypes/types';
import { Person } from 'context/customer-context/types';
import CustomField from 'components/StaticBlocks/CustomField';

interface Props {
  personIndex: number;
  isEditing?: boolean;
}

export const getLoanApplicantAddressStartValues = (defaultCountry: CountryType | null = null) => ({
  personalAddressStreet: '',
  personalAddressHouseNumber: '',
  personalAddressAdditional: '',
  personalAddressPostalCode: '',
  personalAddressCity: '',
  personalAddressCountry: defaultCountry || '',
});

export const parseToAddress = (person: Person, defaultCountry: CountryType | null = null) => ({
  personalAddressStreet: person.personalAddressStreet,
  personalAddressHouseNumber: person.personalAddressHouseNumber,
  personalAddressAdditional: person.personalAddressAdditional,
  personalAddressPostalCode: person.personalAddressPostalCode,
  personalAddressCity: person.personalAddressCity,
  personalAddressCountry: person.personalAddressCountry || defaultCountry || '',
});

const Address = memo(({ personIndex, isEditing = false }: Props) => (
  <Grid container item spacing={2}>
    <CustomField
      name={`people[${personIndex}].personalAddressStreet`}
      title="Straße Privatanschrift"
      type="text"
      disabled={!isEditing}
      required
    />
    <CustomField
      name={`people[${personIndex}].personalAddressHouseNumber`}
      title="Hausnummer Privatanschrift"
      type="text"
      disabled={!isEditing}
      required
    />
    <CustomField
      name={`people[${personIndex}].personalAddressAdditional`}
      title="Adresszusatz Privatanschrift"
      type="text"
      disabled={!isEditing}
    />
    <CustomField
      name={`people[${personIndex}].personalAddressPostalCode`}
      title="PLZ Privatanschrift"
      type="onlyNumbers"
      disabled={!isEditing}
      required
    />
    <CustomField
      name={`people[${personIndex}].personalAddressCity`}
      title="Ort Privatanschrift"
      type="text"
      disabled={!isEditing}
      required
    />
    <CustomField
      title="Wohnsitzland Privatanschrift"
      name={`people[${personIndex}].personalAddressCountry`}
      textFieldProps={{ label: 'Bitte wählen' }}
      type="place"
      placetype="country"
      disabled={!isEditing}
      required
    />
  </Grid>
));

Address.displayName = 'LoanApplicantAddress';

export default Address;
