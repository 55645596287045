import { CreateOrOvertake } from 'utils/commonTypes/types';

export const getInfoText = (option: CreateOrOvertake) => {
  switch (option) {
    case 'overtake':
      return 'Bitte wählen Sie eine Person aus.\nDie Daten der gewählten Person werden übernommen. Bitte überprüfen Sie diese und ergänzen Sie sie gegebenenfalls.';
    case 'create':
      return 'Bitte wählen Sie, ob sie eine Person oder ein Unternehmen erstellen möchten und füllen Sie alle unten stehenden Felder vollständig aus.';
    case '':
    default:
      return 'Bitte wählen Sie, ob Sie die Daten einer bestehenden Person übernehmen oder eine neue Person anlegen möchten.';
  }
};

export const selectOwnerOptions = (showOnlyPerson: boolean) => {
  if (showOnlyPerson)
    return [
      {
        value: 'overtake',
        label: 'Daten einer bestehenden Person übernehmen',
      },
      {
        value: 'create',
        label: 'Neue Person hinzufügen als Inhaber',
      },
    ];
  return [
    {
      value: 'overtake',
      label: 'Daten einer bestehenden Person/eines Unternehmens übernehmen',
    },
    {
      value: 'create',
      label: 'Neue Person/Unternehmen hinzufügen als Inhaber',
    },
  ];
};

export const selectBoOptions = (showOnlyPerson: boolean) => {
  if (showOnlyPerson)
    return [
      {
        value: 'overtake',
        label: 'Daten einer bestehenden Person übernehmen',
      },
      {
        value: 'create',
        label: 'Neue Person hinzufügen als wirtschaftlichen Berechtigten',
      },
    ];
  return [
    {
      value: 'overtake',
      label: 'Daten einer bestehenden Person/eines Unternehmens übernehmen',
    },
    {
      value: 'create',
      label: 'Neue Person/Unternehmen hinzufügen als wirtschaftlichen Berechtigten',
    },
  ];
};
