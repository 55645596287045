export const OCCUPATIONAL_GROUPS = [
  'pharmacist',
  'architect',
  'doctor',
  'consulting_eng',
  'notary',
  'psychotherapist',
  'lawyer',
  'tax_consultant',
  'vet',
  'sworn_auditor',
  'auditor',
  'dentist',
] as const;

export type OccupationalGroup = (typeof OCCUPATIONAL_GROUPS)[number];

export function getOccupationalGroupLabel(occupationalGroup: OccupationalGroup) {
  switch (occupationalGroup) {
    case 'pharmacist':
      return 'Apotheker/in';
    case 'architect':
      return 'Architekt/in';
    case 'doctor':
      return 'Arzt/Ärztin';
    case 'consulting_eng':
      return 'Beratende/r Ingeneur/in';
    case 'notary':
      return 'Notar/in';
    case 'psychotherapist':
      return 'Psychotherapeut/in';
    case 'lawyer':
      return 'Rechtsanwalt/Rechtsanwältin';
    case 'tax_consultant':
      return 'Steuerberater/in';
    case 'vet':
      return 'Tierarzt/Tierärztin';
    case 'sworn_auditor':
      return 'Vereidigte/r Buchprüfer/in';
    case 'auditor':
      return 'Wirtschaftsprüfer/in';
    case 'dentist':
      return 'Zahnarzt/Zahnärztin';
    default:
      return '';
  }
}
