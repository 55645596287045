import { memo } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Alert,
} from '@mui/material';
import { Info } from '@mui/icons-material';

import useStyles from './styles';

interface BaseModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  subtitle?: string | null;
  infoText?: string;
  submitButtonLabel: string;
  submitButtonDisabled?: boolean;
  submitButtonOnClick?: () => Promise<void>;
  loadingSubmitButton?: boolean;
  closeOnlyWithButton?: boolean;
  disableCloseButton?: boolean;
  withWarning?: boolean;
  children: React.ReactNode;
}

const BaseModal = ({
  open,
  onClose,
  title,
  subtitle = null,
  children,
  infoText = '',
  submitButtonLabel,
  submitButtonDisabled = false,
  submitButtonOnClick = async () => {},
  loadingSubmitButton = false,
  closeOnlyWithButton = false,
  disableCloseButton = false,
  withWarning = false,
}: BaseModalProps) => {
  const classes = useStyles();

  return (
    <Dialog onClose={onClose} open={open} fullWidth disableEscapeKeyDown={closeOnlyWithButton}>
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="h5">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          {withWarning && (
            <Grid item xs={12}>
              <Alert icon={<Info />} severity="error">
                Warnung
              </Alert>
            </Grid>
          )}
          {subtitle && (
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.dialogSubtitleText}>
                {subtitle}
              </Typography>
            </Grid>
          )}
          {!!infoText && (
            <Grid item xs={12}>
              <Box display="flex">
                <Info className={classes.infoIcon} />
                <Typography className={classes.infoText} variant="body2">
                  {infoText}
                </Typography>
              </Box>
            </Grid>
          )}
          {children}
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          disabled={submitButtonDisabled}
          onClick={submitButtonOnClick}
        >
          {submitButtonLabel}
          {loadingSubmitButton && (
            <CircularProgress color="primary" size={24} className={classes.buttonProgress} />
          )}
        </Button>
        <Button
          onClick={onClose}
          variant="contained"
          size="large"
          className={classes.cancelButton}
          disabled={disableCloseButton}
        >
          Abbrechen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(BaseModal);
