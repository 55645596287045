import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  commentBlockSmall: {
    fontSize: '14px',
    overflow: 'hidden',
    lineClamp: 2,
    display: '-webkit-box',
    boxOrient: 'vertical',
    maxHeight: '34px',
  },
  commentBlockPrivate: {
    backgroundColor: '#ffffd8',
  },
  predefinedComments: {
    maxWidth: '350px',
    overflow: 'auto',
    maxHeight: '400px',
    fontFamily: theme.typography.fontFamily,
  },
}));

export default useStyles;
