import * as Yup from 'yup';

import { countryObject } from 'components/StaticBlocks/Owner/validationSchema';
import {
  whenInternationalPrefix,
  whenPrefixMobilePhone,
} from 'components/StaticBlocks/LoanApplicants/validationSchema';
import checkIsNumber from 'utils/dataHandlers/checkIsNumber';

export const people = Yup.array().of(
  Yup.object({
    isLegitimacy: Yup.string().nullable(),
    salutation: Yup.string().nullable(),
    firstNames: Yup.string().required('Bitte geben Sie Ihren Vornamen ein'),
    lastName: Yup.string().required('Bitte geben Sie Ihren Nachnamen ein'),
    email: Yup.string().email('Bitte geben Sie eine gültige E-Mail-Adresse ein'),
    repeatEmail: Yup.string().when('email', {
      is: (val: string) => val && val.length > 0,
      then: Yup.string().oneOf(
        [Yup.ref('email')],
        'Die Mailadresse stimmt leider nicht mit der oben angegebenen überein. Bitte überprüfen Sie diese nochmals.',
      ),
    }),
    additionalInformation: Yup.string().max(
      1000,
      'Sie haben eine maximale Anzahl von 1000 Zeichen',
    ),
    internationalPrefix: Yup.object(countryObject).nullable(),
    phoneNumber: Yup.string()
      .test(
        'test_is_number',
        'Die Telefonnummer darf nur aus Ziffern bestehen.',
        (value, context) => checkIsNumber(value, context),
      )
      .min(7, 'Die Telefonnummer muss mindestens 7 Ziffern haben.')
      .when('internationalPrefix', whenInternationalPrefix),
    prefixMobilePhone: Yup.object(countryObject).nullable(),
    mobilePhone: Yup.string()
      .test(
        'test_is_number',
        'Die Telefonnummer darf nur aus Ziffern bestehen.',
        (value, context) => checkIsNumber(value, context),
      )
      .min(7, 'Die Telefonnummer muss mindestens 7 Ziffern haben.')
      .when('prefixMobilePhone', whenPrefixMobilePhone),
    isLoanApplicant: Yup.string(),
    isLegalRepresentative: Yup.string(),
    isOwner: Yup.string(),
    isBeneficialOwnerLevelOne: Yup.string(),
    shares: Yup.array().of(
      Yup.object({
        allocation: Yup.string(),
        sideDeal: Yup.string(),
      }),
    ),
    personalAddressPostalCode: Yup.string()
      .min(
        4,
        'Die PLZ in Deutschland sind 5-stellig und in Österreich und in der Schweiz 4-stellig.',
      )
      .max(
        5,
        'Die PLZ in Deutschland sind 5-stellig und in Österreich und in der Schweiz 4-stellig.',
      ),
    isSoleRepresentation: Yup.string()
      .nullable()
      .label('Ist die Person einzelvertretungsberechtigt?'),
    /* CRP-2726 Undisplay not mandatory fields
    dependents: Yup.array().of(
      Yup.object({
        age: Yup.number()
          .moreThan(-1, 'Das Alter kann keine negative Zahl sein')
          .typeError('Bitte geben Sie eine gültige Zahl ein')
          .nullable()
          .transform((v, o) => (o === '' ? null : v)),
        monthlyUpkeep: Yup.number()
          .moreThan(-1, 'Der monatliche Unterhalt kann kein negativer Betrag sein')
          .nullable()
          .transform((v, o) => (o === '' ? null : v))
          .typeError('Bitte geben Sie eine gültige Zahl ein')
          .test(
            'test_currency',
            'Stelle sicher, dass es insgesamt nicht mehr als 6 Ziffern lang ist',
            (value) => {
              if (value === undefined || value === null) return true;
              return regexForValidNumber.test(`${value}`);
            },
          ),
      }),
    ),
    children: Yup.array().of(
      Yup.object({
        age: Yup.number()
          .moreThan(-1, 'Das Alter kann keine negative Zahl sein')
          .typeError('Bitte geben Sie eine gültige Zahl ein'),
      }),
    ), */
  }),
);

export const companies = Yup.array().of(
  Yup.object({
    companyName: Yup.string().required('Bitte geben Sie den Unternehmensname ein'),
    legalForm: Yup.string().required('Bitte wählen Sie eine Rechtsform'),
    shares: Yup.array().of(
      Yup.object({
        allocation: Yup.string(),
        sideDeal: Yup.string(),
      }),
    ),
    isOwner: Yup.string(),
    isBeneficialOwnerLevelOne: Yup.string(),
    isBeneficialOwnerLevelTwo: Yup.string(),
    isBeneficialOwnerLevelThree: Yup.string(),
    isBeneficialOwner: Yup.string(),
  }),
);
