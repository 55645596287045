import classNames from 'classnames';
import Lottie from 'lottie-react-web';

import useStyles from './styles';
import CheckAnimation from '../../animation/check.json';
import { useEditorData } from '../../context/editor-context';

function PreviewField({ value, blockSlug, slug, innerRef }) {
  const classes = useStyles();
  const { handleOpen } = useEditorData();

  const createMarkup = () => ({ __html: value });

  const customHandleOpen = () => {
    handleOpen(blockSlug, slug);
  };

  return (
    <div
      ref={innerRef}
      className={classNames(classes.container, value && classes.containerPreviewCheck)}
      onClick={customHandleOpen}
      data-testid="preview-field"
    >
      <div className={classes.previewContainer}>
        <div className={classes.contentContainer} dangerouslySetInnerHTML={createMarkup()} />
        {value && (
          <div className={classes.iconContainer}>
            <Lottie
              width={30}
              height={30}
              options={{
                animationData: CheckAnimation,
                loop: false,
              }}
              speed={2}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default PreviewField;
