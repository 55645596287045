import { Company, Person, RefBeneficialOwner } from 'context/customer-context/types';
import { getRolId } from './index';

interface TemporalRef {
  parents: number[];
  ref: RefBeneficialOwner;
}

const getTemporalRefs = (
  entities: (Person | Company)[],
  mainCompanyId: number,
  type: 'person' | 'company',
): TemporalRef[] =>
  entities.reduce<TemporalRef[]>((filteredRefs, v, index) => {
    if (v.isBeneficialOwner !== 'yes') {
      return filteredRefs;
    }
    // Don't include the main company inside the parents array
    const parents = v.shares.reduce<number[]>((filtered, option) => {
      if (option.parent !== mainCompanyId) {
        filtered.push(Number(option.parent));
      }
      return filtered;
    }, []);
    const tmpRef: TemporalRef = {
      parents,
      ref: {
        _type: type,
        id: v.id,
        index,
        beneficialOwnerId: getRolId(type === 'person' ? 'BOP' : 'BOC', v.correlativeId),
        modelId: type === 'person' ? v.personId : v.businessId,
      },
    };
    if (type === 'company') {
      tmpRef.ref.children = [];
    }

    filteredRefs.push(tmpRef);
    return filteredRefs;
  }, []);

export const getBeneficialOwnerRefs = (
  people: Person[],
  companies: Company[],
  mainCompanyId: number,
): RefBeneficialOwner[] => {
  const peopleTemporalRefs = getTemporalRefs(people, mainCompanyId, 'person');
  const companyTemporalRefs = getTemporalRefs(companies, mainCompanyId, 'company');

  peopleTemporalRefs.forEach((temporalRef) => {
    temporalRef.parents.forEach((parentId) => {
      const parentCompany = companyTemporalRefs.find(
        (companyTemporal) => companyTemporal.ref.id === parentId,
      );
      if (!parentCompany) {
        throw new Error(
          'Something went wrong building company-child structure for beneficial Owners',
        );
      }
      parentCompany.ref.children?.push(temporalRef.ref);
    });
  });

  companyTemporalRefs.forEach((temporalRef) => {
    temporalRef.parents.forEach((parentId) => {
      const parentCompany = companyTemporalRefs.find(
        (companyTemporal) => companyTemporal.ref.id === parentId,
      );
      if (!parentCompany) {
        throw new Error(
          'Something went wrong building company-child structure for beneficial Owners',
        );
      }
      parentCompany.ref.children?.push(temporalRef.ref);
    });
  });

  const refs: RefBeneficialOwner[] = peopleTemporalRefs.reduce<RefBeneficialOwner[]>(
    (filtered, option) => {
      if (!option.parents.length) {
        filtered.push(option.ref);
      }
      return filtered;
    },
    [],
  );

  refs.push(
    ...companyTemporalRefs.reduce<RefBeneficialOwner[]>((filtered, option) => {
      if (!option.parents.length) {
        filtered.push(option.ref);
      }
      return filtered;
    }, []),
  );

  return refs;
};
