import React from 'react';
import classNames from 'classnames';

import {
  Accordion,
  AccordionSummary,
  Typography,
  Chip,
  Button,
  AccordionDetails,
  Grid,
} from '@mui/material';
import { ExpandMore, CommentRounded } from '@mui/icons-material';

import { staticBaseComponents, statusClassNames } from 'components/CaptiqReviewBlock/utils';
import { shouldHideBlockData } from 'utils/dataHandlers';
import { useDataContext } from 'context/data-context';
import { hideBlockOnCondition } from 'utils/utils';
import { BLOCK_STATUS_APPROVED, BLOCK_STATUS_DECLINED } from 'constants/reviewBlocks';
import RenderQuestions from '../RenderQuestions';
import Actions from '../Actions';
import useStyles from './styles';

interface Props {
  value: any;
  index: number;
  expanded: string;
  hiddenFields: { loanProcess?: string[] };
  upperFormState: any;
  loanId: string;
  loanApplication: any;
  customColors: any;
  isValid: boolean;
  stepOrder: number;
  step_id: string;
  personID: string;
  metaData: any;
  currentTab: number;
  componentProps: any;
  comments: any;
  extraData: any;
  blockData: any;
  blockKey: string;
  waitingAction: string;
  extraValidationInfo: any;
  form: any;
  disabled: boolean;
  personIndexTab: number;
  setActivePanel: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  openComments: () => void;
  startEdition: () => void;
  cancelEdit: () => void;
  saveData: (block_name: any) => Promise<void>;
  changeStatus: (sectionPath: string, status: string) => void;
  firstStepRef: React.RefObject<HTMLDivElement>;
  actionsRef: React.RefObject<any>;
  renderExtraValidationInfo: (extraValidationInfo: any) => React.ReactNode;
  handleChange: (event: React.ChangeEvent<any>) => void;
  deleteField: (sectionPath: string, field: string) => void;
}

const Block = ({
  value,
  index,
  expanded,
  hiddenFields,
  upperFormState,
  loanId,
  loanApplication,
  customColors,
  isValid,
  stepOrder,
  step_id,
  personID,
  metaData,
  currentTab,
  componentProps,
  comments,
  extraData,
  blockData,
  blockKey,
  waitingAction,
  extraValidationInfo,
  form,
  disabled,
  personIndexTab,
  setActivePanel,
  openComments,
  startEdition,
  cancelEdit,
  saveData,
  changeStatus,
  firstStepRef,
  actionsRef,
  renderExtraValidationInfo,
  handleChange,
  deleteField,
}: Props) => {
  const styles = useStyles();
  const context = useDataContext();
  const isStaticComponent = staticBaseComponents.includes(blockData.based_component);

  if (!extraData[value.section_path]) {
    return null;
  }
  const { status, status_display } = extraData[value.section_path];
  const shouldHide = isStaticComponent
    ? false
    : shouldHideBlockData(hiddenFields, blockData) ||
      hideBlockOnCondition(
        blockData?.depending_field,
        blockData?.visibility_value,
        upperFormState && blockData?.depending_field
          ? upperFormState[blockData?.depending_field]
          : null,
      );

  if (
    shouldHide ||
    (blockData.can_be_duplicated && blockData.slug.startsWith('dokumente_pro_aus_hgb'))
  ) {
    return null;
  }

  return (
    <Accordion
      expanded={stepOrder === 0 ? true : expanded === value.section_path}
      key={index}
      onChange={setActivePanel(value.section_path)}
    >
      <AccordionSummary expandIcon={<ExpandMore />} className={styles.stickyHeader}>
        <Typography className={styles.title}>
          {blockData.title} {index !== 0 ? ` - ${index}` : ''}
        </Typography>
        <div className={styles.helpText} />
        <Chip
          className={classNames(
            styles.blockStatus,
            styles[
              statusClassNames[status as keyof typeof statusClassNames] as keyof typeof styles
            ],
          )}
          label={status_display}
        />
        <Button
          aria-describedby="pop"
          size="small"
          color="primary"
          variant="contained"
          className={styles.blockCommentsIcon}
          onClick={openComments}
          classes={{ root: styles.blockCommentsLabel }}
        >
          <CommentRounded style={{ fontSize: '16px', marginRight: '6px' }} />
          {comments.length}
        </Button>
      </AccordionSummary>
      <AccordionDetails key={`block_${blockKey}`}>
        <Grid container spacing={4} justifyContent="center">
          <RenderQuestions
            questions={value.questions}
            block_id={String(index + 1)}
            blockData={blockData}
            form={form}
            disabled={disabled}
            personIndexTab={personIndexTab}
            stepOrder={stepOrder}
            loanId={loanId}
            step_id={step_id}
            upperFormState={upperFormState}
            personID={personID}
            metaData={metaData}
            currentTab={currentTab}
            componentProps={componentProps}
            customColors={customColors}
            setLoanApplication={context.setLoanApplication}
            loanApplication={context.loanApplication}
            deleteField={deleteField}
            handleChange={handleChange}
            renderExtraValidationInfo={renderExtraValidationInfo}
            loanApplicationStatus={loanApplication?.status}
            firstStepRef={firstStepRef}
            isEditing={actionsRef.current?.isEditing}
          />
          <Grid item xs={12}>
            {extraValidationInfo}
          </Grid>
        </Grid>
      </AccordionDetails>
      <Actions
        ref={actionsRef}
        isStaticComponent={isStaticComponent}
        isValid={isValid}
        status={status}
        waitingAction={waitingAction}
        onStartEdition={startEdition}
        onCancel={cancelEdit}
        onSave={() => saveData(value.section_path)}
        onApproveBlock={() => changeStatus(value.section_path, BLOCK_STATUS_APPROVED)}
        onDeclineBlock={() => changeStatus(value.section_path, BLOCK_STATUS_DECLINED)}
      />
    </Accordion>
  );
};

export default Block;
