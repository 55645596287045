import { memo } from 'react';

import useStyles from './styles';

interface Props {
  link: string;
  text: string;
}

const SimpleLink = memo(({ link, text }: Props) => {
  const styles = useStyles();

  return (
    <a href={link} target="_blank" className={styles.linkUnderline} rel="noreferrer">
      {text}
    </a>
  );
});

export default SimpleLink;
