import classNames from 'classnames';

import { List, ListItem, Popover } from '@mui/material';

import { IPredefinedCommentsProps } from './types';
import useStyles from './styles';

const PredefinedComments = ({
  open = false,
  element,
  onChoose,
  onClose,
  comments = [],
}: IPredefinedCommentsProps) => {
  const styles = useStyles();

  return (
    <Popover
      id="pop"
      open={open}
      anchorEl={element}
      onClose={() => onClose(false)}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <List className={styles.predefinedComments}>
        {comments.map((comment, index) => (
          <ListItem
            className={classNames(
              styles.commentBlockSmall,
              comment.type === 'IC' ? styles.commentBlockPrivate : '',
            )}
            button
            key={index}
            onClick={() => onChoose(comment)}
          >
            <span>{comment.title}</span>
          </ListItem>
        ))}
      </List>
    </Popover>
  );
};

export default PredefinedComments;
