import { InitialStateType } from './types';

export const initialState: InitialStateType = {
  errors: [],
  customErrors: [],
  showErrorModal: false,
  showReviewApprovalModal: false,
  showIcon: false,
  currentSection: 0,
  currentStep: 0,
  fieldViewed: {},
  reminders: [],
  validateReminder: true,
  redirectView: 'view',
  extraNavigation: null,
  setErrors: () => {},
  setCustomErrors: () => {},
  setShowErrorModal: () => {},
  setShowReviewApprovalModal: () => {},
  setShowIcon: () => {},
  setCurrentSection: () => {},
  setCurrentStep: () => {},
  setFieldViewed: () => {},
  handleReset: () => {},
  setReminders: () => {},
  setValidateReminder: () => {},
  setRedirectView: () => {},
  setExtraNavigation: () => {},
};
