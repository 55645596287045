import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import styles from './styles';

function LoanTypesInstructions(props) {
  const { classes } = props;

  return (
    <>
      <Typography className={classes.titleText}>
        Konfigurieren Sie das Darlehen individuell nach Ihren Bedürfnissen.
      </Typography>
      <Typography className={classes.stepHelpText}>
        Sollte Ihr persönlicher Darlehens-Verwendungszweck nicht zur Auswahl stehen, wählen Sie
        bitte "Sonstiges".
      </Typography>
    </>
  );
}
export default withStyles(styles)(LoanTypesInstructions);
