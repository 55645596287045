import {
  getCompanyLabel,
  getLocaleAllocation,
  noSideDealsDisplayLabel,
  sharesValidator,
} from 'utils/utils';
import { checkLocaleValidAllocation } from 'containers/CustomerProfile/validationSchema';
import { LegalFormsType, LegalFormsTypes, Level, YesOrNo } from 'utils/commonTypes/types';
import { Person, Company, CompanyDataFormValues } from 'context/customer-context/types';

export const validateSharesFunction = (
  allocation: string,
  sideDeal: YesOrNo | undefined,
  companyData: CompanyDataFormValues,
  person: Person,
  currentLegalForm: LegalFormsType | null,
  legalForms: LegalFormsTypes | null,
  sideDealDirect?: YesOrNo,
  isBeneficialOwnerLevelOneDirect?: YesOrNo,
  level?: Level,
  isOwnerDirect?: boolean,
  checkTotalShares?: boolean,
  totalShares?: number,
) => {
  if (!!checkTotalShares && !!totalShares) {
    if (totalShares > 100) {
      return 'Die Anzahl der Aktien zwischen allen Eigentümern darf 100% nicht überschreiten. Bitte überprüfen Sie Ihre neue Zuteilung.';
    }
  }
  const currentSideDeal = sideDealDirect || sideDeal || '';
  const currentBeneficialOwnerLevelOne =
    isBeneficialOwnerLevelOneDirect || person.isBeneficialOwnerLevelOne;
  const shareError = sharesValidator(
    allocation,
    currentSideDeal,
    level || 1,
    getCompanyLabel(companyData, legalForms),
    currentBeneficialOwnerLevelOne,
    !noSideDealsDisplayLabel(currentLegalForm?.label),
  );
  if (shareError) return shareError;
  if (
    getLocaleAllocation(allocation, 'de') <= 25 &&
    currentBeneficialOwnerLevelOne === 'yes' &&
    noSideDealsDisplayLabel(currentLegalForm?.label)
  )
    return 'Bitte stellen Sie sicher, dass ein Wert zwischen 25,01% und 100% eingetragen wird. Nachkommastellen werden auf 2 Dezimale gerundet.';
  const isOwner = isOwnerDirect !== undefined ? isOwnerDirect : person.isOwner === 'yes';
  const allocationError = checkLocaleValidAllocation(allocation, !isOwner);
  if (!allocationError) {
    return isOwner
      ? 'Bitte stellen Sie sicher, dass ein Wert zwischen 0,01% und 100% eingetragen wird. Nachkommastellen werden auf 2 Dezimale gerundet.'
      : 'Bitte stellen Sie sicher, dass ein Wert zwischen 0,00% und 100% eingetragen wird. Nachkommastellen werden auf 2 Dezimale gerundet.';
  }
  return undefined;
};

export const validateSharesCompanyFunction = (
  allocation: string,
  sideDeal: YesOrNo | undefined,
  companyData: CompanyDataFormValues,
  company: Person | Company,
  legalForms: LegalFormsTypes | null,
  sideDealDirect?: YesOrNo,
  isBeneficialOwnerLevelOneDirect?: YesOrNo,
  level?: Level,
  isOwnerDirect?: boolean,
  checkTotalShares?: boolean,
  totalShares?: number,
) => {
  if (!!checkTotalShares && !!totalShares) {
    if (totalShares > 100) {
      return 'Die Anzahl der Aktien zwischen allen Eigentümern darf 100% nicht überschreiten. Bitte überprüfen Sie Ihre neue Zuteilung.';
    }
  }
  const currentSideDeal = sideDealDirect || sideDeal || '';
  const currentBeneficialOwnerLevelOne =
    isBeneficialOwnerLevelOneDirect || company.isBeneficialOwnerLevelOne;
  const shareError = sharesValidator(
    allocation,
    currentSideDeal,
    level || 1,
    getCompanyLabel(companyData, legalForms),
    currentBeneficialOwnerLevelOne,
  );
  if (shareError) return shareError;
  const isOwner = isOwnerDirect !== undefined ? isOwnerDirect : company.isOwner === 'yes';
  const allocationError = checkLocaleValidAllocation(allocation, !isOwner);
  if (!allocationError) {
    return isOwner
      ? 'Bitte stellen Sie sicher, dass ein Wert zwischen 0,01% und 100% eingetragen wird. Nachkommastellen werden auf 2 Dezimale gerundet.'
      : 'Bitte stellen Sie sicher, dass ein Wert zwischen 0,00% und 100% eingetragen wird. Nachkommastellen werden auf 2 Dezimale gerundet.';
  }
  return undefined;
};
