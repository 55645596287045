import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Grid, Typography } from '@mui/material';

import CaptiqTooltip from '../CaptiqTooltip';
import useStyles from './styles';

const LabeledGrid = ({
  label,
  children,
  justify = 'flex-start',
  alignItems = 'flex-start',
  xs = 12,
  smLabel = 4,
  smChild = 6,
  smExtra = 2,
  spacing = 1,
  required = false,
  tooltip,
  positionTooltip = 'top',
  titleTooltip = undefined,
  customClassesTooltip = undefined,
  customClassLabel = '',
  customClassChild = '',
  customStyle,
  extraComponent,
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      item
      spacing={spacing}
      justifyContent={justify}
      alignItems={alignItems}
      className={customStyle}
    >
      {label && (
        <Grid item xs={xs} sm={smLabel} className={classes.fieldLabel}>
          <div className={classes.typography}>
            <Typography
              className={
                customClassLabel === undefined || customClassLabel === ''
                  ? classes.label
                  : customClassLabel
              }
              variant="body2"
            >
              {label}: {required && ' *'}
            </Typography>
            {tooltip && (
              <div className={classes.tooltip}>
                <CaptiqTooltip
                  content={tooltip}
                  position={positionTooltip}
                  title={titleTooltip}
                  customClasses={customClassesTooltip}
                />
              </div>
            )}
          </div>
        </Grid>
      )}
      {children && (
        <>
          <Grid item xs={xs} sm={smChild} className={classNames(classes.input, customClassChild)}>
            {children}
          </Grid>
          {extraComponent && (
            <Grid item xs={xs} sm={smExtra} className={classes.input}>
              {extraComponent}
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

LabeledGrid.propTypes = {
  tooltip: PropTypes.any,
  customStyle: PropTypes.any,
  extraComponent: PropTypes.any,
  customClassLabel: PropTypes.any,
};

LabeledGrid.defaultProps = {
  tooltip: undefined,
  customStyle: undefined,
  extraComponent: undefined,
  customClassLabel: undefined,
};

export default memo(LabeledGrid);
