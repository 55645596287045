import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
  downloadButton: {
    padding: '0px 4px',
    textTransform: 'capitalize',
  },
  downloadButtonIcon: {
    marginRight: 4,
  },
  paragraph: {
    margin: 0,
  },
}));

export default useStyles;
