import React, { useRef } from 'react';
import classNames from 'classnames';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Chip,
  Button,
} from '@mui/material';
import { ExpandMore, CommentRounded } from '@mui/icons-material';

import { BLOCK_STATUS_APPROVED, BLOCK_STATUS_DECLINED } from 'constants/reviewBlocks';
import useStyles from './styles';
import Actions from '../Actions';
import FinancingDocuments from '../../../CaptiqBlock/FinancingDocuments';
import { statusClassNames } from '../../utils';

interface Props {
  blockData: any;
  extraData: {
    [key: string]: any;
  };
  comments: any;
  waitingAction: string;
  upperFormState: any;
  expanded: any;
  classes: any;
  loanId: any;
  customColors: any;
  isValid: any;
  setActivePanel: (index: any) => (event: any, expanded: any) => void;
  openComments: (event: any) => void;
  startEdition: () => void;
  cancelEdit: () => void;
  saveFinancingDocuments: (block_name: any) => Promise<void>;
  changeStatus: (path: any, status: any, customExtraData: any) => void;
  financingDocumentsRef: any;
}

const RenderFinancingDocuments = ({
  blockData,
  extraData,
  comments,
  waitingAction,
  upperFormState,
  expanded,
  loanId,
  customColors,
  isValid,
  setActivePanel,
  openComments,
  startEdition,
  cancelEdit,
  saveFinancingDocuments,
  changeStatus,
  financingDocumentsRef,
}: Props) => {
  const styles = useStyles();
  const actionsRef = useRef<{ isEditing: boolean }>(null);

  if (blockData.can_be_duplicated && blockData.slug.startsWith('dokumente_pro_aus_hgb')) {
    const hgb = upperFormState.hgb_bilanziert_grunddaten === 'True';
    const foundation = new Date(
      upperFormState?.grundung || upperFormState?.grundungtakeover,
    ).getFullYear();
    const legalForm = upperFormState.rechtsform_unternehmens;

    const { status, status_display } = Object.values(extraData).reduce(
      (acc, curr) => {
        if (curr.status === 'NOT_REVIEWED') {
          acc.status = curr.status;
          acc.status_display = curr.status_display;
          return acc;
        }

        if (curr.status === 'DECLINED') {
          acc.status = curr.status;
          acc.status_display = curr.status_display;
          return acc;
        }

        if (curr.status === 'EDIT') {
          acc.status = curr.status;
          acc.status_display = curr.status_display;
          return acc;
        }

        return acc;
      },
      { status: 'APPROVED', status_display: 'Genehmigter Block' },
    );

    return (
      <Accordion
        expanded={expanded === 'dokumente_pro_aus_hgb'}
        key={'dokumente_pro_aus_hgb'}
        onChange={setActivePanel('dokumente_pro_aus_hgb')}
      >
        <AccordionSummary expandIcon={<ExpandMore />} className={styles.stickyHeader}>
          <Typography className={styles.title}>{blockData.title}</Typography>
          <div className={styles.helpText} />
          <Chip
            className={classNames(
              styles.blockStatus,
              styles[
                statusClassNames[status as keyof typeof statusClassNames] as keyof typeof styles
              ],
            )}
            label={status_display}
          />
          <Button
            aria-describedby="pop"
            size="small"
            color="primary"
            variant="contained"
            className={styles.blockCommentsIcon}
            onClick={openComments}
            classes={{ root: styles.blockCommentsLabel }}
          >
            <CommentRounded style={{ fontSize: '16px', marginRight: '6px' }} />
            {comments.length}
          </Button>
        </AccordionSummary>
        <AccordionDetails>
          <FinancingDocuments
            customColors={customColors}
            expanded={expanded}
            setActivePanel={setActivePanel}
            extraInformation={{ hgb, loanId, foundation, legalForm }}
            isReview
            isEditing={actionsRef.current?.isEditing}
            formRef={financingDocumentsRef}
          />
        </AccordionDetails>
        <Actions
          ref={actionsRef}
          isStaticComponent={false}
          isValid={isValid}
          status={status}
          waitingAction={waitingAction}
          onStartEdition={startEdition}
          onCancel={cancelEdit}
          onSave={() => saveFinancingDocuments('unternehmensdaten__dokumente_pro_aus_hgb')}
          onApproveBlock={() =>
            changeStatus(
              'unternehmensdaten__dokumente_pro_aus_hgb',
              BLOCK_STATUS_APPROVED,
              extraData,
            )
          }
          onDeclineBlock={() =>
            changeStatus(
              'unternehmensdaten__dokumente_pro_aus_hgb',
              BLOCK_STATUS_DECLINED,
              extraData,
            )
          }
        />
      </Accordion>
    );
  }

  return null;
};

export default RenderFinancingDocuments;
