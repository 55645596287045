import React, { memo, useCallback } from 'react';

import { Box } from '@mui/material';
import { Info } from '@mui/icons-material';

import useStyles from './styles';

interface HelperProps {
  link: string;
  name: string;
  questionSlug: string;
}

const HelperTextFieldDocument = memo(({ link, questionSlug }: HelperProps) => {
  const classes = useStyles();

  const renderHelperText = useCallback(() => {
    // TODO(CRP-2722): Change the use of the questionSlug to a more dynamic implementation with backend
    switch (true) {
      case questionSlug.startsWith('q_letzte_gew_ein_hgb') ||
        questionSlug.startsWith('q_letzte_bilanz_hgb_f_'):
        if (questionSlug.includes('3775')) {
          return (
            <>
              Sollte die oben hochgeladene Gewinnermittlung bereits den Vorjahresgleich beinhalten,
              laden Sie hier bitte dieselbe Datei erneut hoch.
            </>
          );
        }
        return (
          <>
            Bei den Kontennachweisen bzw. nachrichtlichem Teil bitte <u>zwingend</u> darauf achten,
            dass auch alle <u>sonstigen Konten</u> (Bestandskonten wie z.B. Bankkonten) ausgewiesen
            werden. Siehe bitte Seite 8 von der Muster-Gewinnermittlung. <br /> Eine
            Muster-Gewinnermittlung finden Sie{' '}
            <a href={link} target="_blank" rel="noreferrer" className={classes.link}>
              hier.
            </a>
          </>
        );
      case questionSlug.startsWith('q_letzte_bilanz_hgb'):
        if (questionSlug.includes('3775')) {
          return (
            <>
              Sollte die oben hochgeladene Bilanz bereits den Vorjahresgleich beinhalten, laden Sie
              hier bitte dieselbe Datei erneut hoch.
            </>
          );
        }
        return (
          <>
            Bitte achten Sie darauf, dass die Gewinnermittlung und der Kontennachweis beigefügt
            sind. <br /> Eine Muster-Bilanz finden Sie{' '}
            <a href={link} target="_blank" rel="noreferrer" className={classes.link}>
              hier.
            </a>
          </>
        );
      case questionSlug.startsWith('q_dazugehorige_anlage_hgb'):
        return (
          <>
            Eine Muster-Anlage EÜR finden Sie{' '}
            <a href={link} target="_blank" rel="noreferrer" className={classes.link}>
              hier.
            </a>
            <br />
            Ihre Datei sollte dem Musterdokument entsprechen.
          </>
        );
      case questionSlug.startsWith('q_aktuelle_bwa_hgb'):
        return (
          <>
            Eine Muster-BWA finden Sie{' '}
            <a href={link} target="_blank" rel="noreferrer" className={classes.link}>
              hier.
            </a>
          </>
        );
      case questionSlug.startsWith('q_dazugehorige_summen_hgb'):
        return (
          <>
            Eine Muster-SuSa finden Sie{' '}
            <a href={link} target="_blank" rel="noreferrer" className={classes.link}>
              hier.
            </a>
          </>
        );
      default:
        return null;
    }
  }, [questionSlug, classes, link]);

  return (
    renderHelperText() && (
      <Box display="flex" alignItems="center" marginTop={1}>
        <Box width={20} height={20} marginRight={0.6} alignSelf="flex-start" color="primary.main">
          <Info />
        </Box>
        <p className={classes.paragraph}>{renderHelperText()}</p>
      </Box>
    )
  );
});

export default HelperTextFieldDocument;
