import {
  DEFAULT_BLOCK_BACKGROUND_COLOR,
  DEFAULT_TAB_BACKGROUND_COLOR,
  DEFAULT_MAIN_COLOR,
  DEFAULT_BACKGROUND_COLOR,
  DEFAULT_LANGUAGE,
  DEFAULT_COMPLETE_COLOR,
} from 'constants/design';
import footerDefaultImage from 'images/footer.png';
import logoDefaultImage from 'images/logo.png';
import faviconDefaultImage from 'images/favicon.png';
import { AuthContextI } from './types';

export const initialValues: AuthContextI = {
  login: () => {},
  logout: () => {},
  isAuthenticated: () => false,
  getUser: () => null,
  getRole: () => null,
  getTipsterData: () => {},
  setTipsterData: () => {},
  persistData: () => {},
  clearPersistData: () => {},
  getPersistData: () => ({}),
  blocksStatus: [],
  addRequiredField: () => {},
  mainLogo: logoDefaultImage,
  mainColor: DEFAULT_MAIN_COLOR,
  backgroundColor: DEFAULT_BACKGROUND_COLOR,
  initialLanguage: DEFAULT_LANGUAGE,
  isTipster: false,
  favicon: faviconDefaultImage,
  blockBackgroundColor: DEFAULT_BLOCK_BACKGROUND_COLOR,
  tabBackgroundColor: DEFAULT_TAB_BACKGROUND_COLOR,
  stepColor: DEFAULT_MAIN_COLOR,
  checkboxColor: DEFAULT_MAIN_COLOR,
  buttonColor: DEFAULT_MAIN_COLOR,
  getCustomColors: () => ({
    blockBackgroundColor: DEFAULT_BLOCK_BACKGROUND_COLOR,
    buttonColor: DEFAULT_MAIN_COLOR,
    tabBackgroundColor: DEFAULT_TAB_BACKGROUND_COLOR,
    stepColor: DEFAULT_MAIN_COLOR,
    completeBlockColor: DEFAULT_COMPLETE_COLOR,
  }),
  selectionColor: DEFAULT_MAIN_COLOR,
  completeBlockColor: DEFAULT_COMPLETE_COLOR,
  footerImage: footerDefaultImage,
  sliderColor: null,
  fontFamily: 'Barlow',
  customFontFamily: null,
  setMainLogo: () => {},
  setMainColor: () => {},
  setBackgroundColor: () => {},
  setInitialLanguage: () => {},
  setIsTipster: () => {},
  setFavicon: () => {},
  setBlockBackgroundColor: () => {},
  setTabBackgroundColor: () => {},
  setStepColor: () => {},
  setCheckboxColor: () => {},
  setButtonColor: () => {},
  setSelectionColor: () => {},
  setCompleteBlockColor: () => {},
  setFooterImage: () => {},
  setSliderColor: () => {},
  setFontFamily: () => {},
  setCustomFontFamily: () => {},
  loadingUser: false,
  isCustomerProfileActivated: async () => false,
  getRefreshToken: () => null,
  isViewMode: false,
  setIsViewMode: () => {},
};
