import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  completeField: {
    '& fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default useStyles;
