export const underlineWord = (word: string, text: string) => {
  const words = text.split(word);
  if (words.length === 2)
    return (
      <>
        {words[0]}
        <u>{word}</u>
        {words[1]}
      </>
    );

  return text;
};
