import React, { memo } from 'react';

import CircularProgress from '@mui/material/CircularProgress';

const LoadingIndicator = memo(() => (
  <div style={{ justifyContent: 'center', padding: '100px 0', display: 'flex' }}>
    <CircularProgress size={30} />
  </div>
));

LoadingIndicator.displayName = 'LoadingIndicator';

export default LoadingIndicator;
