import { Person } from 'context/customer-context/types';
import {
  BackendPersonCompanyExistingList,
  BackendSimpleExtended,
  BackendSimplePerson,
  PersonCompanyExistingList,
} from 'utils/backendTypes';
import { CustomerProfileRole } from 'utils/commonTypes/types';

export const serializeExistingPersonToContext = (
  existing: BackendPersonCompanyExistingList | null,
  peopleMainFormik: Person[],
  role: CustomerProfileRole,
  onlyLocalPersons: boolean = false,
): PersonCompanyExistingList => {
  const existingArray: PersonCompanyExistingList = [];
  let property: 'isOwner' | 'isBeneficialOwner' | 'isLegalRepresentative' | 'isLoanApplicant' =
    'isOwner';
  switch (role) {
    case 'OWP':
      property = 'isOwner';
      break;
    case 'BOP':
      property = 'isBeneficialOwner';
      break;
    case 'LRP':
      property = 'isLegalRepresentative';
      break;
    case 'LAP':
      property = 'isLoanApplicant';
      break;
    default:
      throw new Error(`Unhandled action type`);
  }

  if (onlyLocalPersons) {
    peopleMainFormik.forEach((person) => {
      if (person[property] !== 'yes' && person.isLegalRepresentative === 'yes') {
        existingArray.push({
          id: person.id,
          name: `${person.lastName} ${person.firstNames}`,
          groupId: 0,
          last_name: person.lastName,
          first_name: person.firstNames,
        } as BackendSimpleExtended);
      }
    });
  } else if (existing) {
    // For is used to be able to skip the element when it already exists.
    for (let index = 0; index < existing.customer_profile_related.length; index++) {
      const {
        id,
        last_name,
        first_name,
        private_address_city,
        private_address_house_n,
        private_address_postcode,
        private_address_street,
      } = existing.customer_profile_related[index] as BackendSimplePerson;
      // Check in the context of the formik main if the person already has that role.
      // Since you probably already have a role on the frontend side and it is not reflected in the backend.
      const exists = peopleMainFormik.find(
        (person) => person.id === id && person[property] === 'yes',
      );
      // When the person already has the role, the return is skipped
      // eslint-disable-next-line no-continue
      if (exists) continue;

      existingArray.push({
        id,
        name: `${last_name} ${first_name}`,
        groupId: 0,
        last_name,
        first_name,
        city: private_address_city,
        houseNumber: private_address_house_n,
        plz: private_address_postcode,
        street: private_address_street,
      } as BackendSimpleExtended);
    }

    existingArray.push(
      ...existing.cooperation_partner_related.map(
        (p: BackendSimplePerson) =>
          ({
            id: p.id,
            name: `${p.last_name} ${p.first_name}`,
            groupId: 1,
            last_name: p.last_name,
            first_name: p.first_name,
            city: p.private_address_city,
            houseNumber: p.private_address_house_n,
            plz: p.private_address_postcode,
            street: p.private_address_street,
          }) as BackendSimpleExtended,
      ),
    );
  }
  return existingArray;
};
