import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
// This file will not exist at startup, it will be generated when the yarn build, yarn start, yarn test or yarn watch commands are executed.
// eslint-disable-next-line import/no-unresolved
import generatedGitInfo from './generatedGitInfo.json';

import App from './containers/App';
import AppProviders from './context';
import './Assets/fonts/Barlow/Barlow-Regular.ttf';
import './Assets/fonts/Barlow/Barlow-Medium.ttf';
import './Assets/fonts/Barlow/Barlow-SemiBold.ttf';
import './index.css';

const release = generatedGitInfo.gitCommitHash;
const container = document.getElementById('root');
const root = createRoot(container);

if (process.env.REACT_APP_REPORT_ENDPOINT) {
  Sentry.init({
    integrations: [new BrowserTracing()],
    environment: process.env.NODE_ENV,
    dsn: process.env.REACT_APP_REPORT_ENDPOINT,
    release,
    tracesSampleRate: 1.0,
    sendDefaultPii: true,
  });
}
root.render(
  <AppProviders>
    <BrowserRouter basename="/loan-application">
      <App />
    </BrowserRouter>
    <ReactQueryDevtools initialIsOpen={false} />
  </AppProviders>,
);
