import { createRef, useState, useEffect, useCallback } from 'react';
import Lottie from 'lottie-react-web';

import Button from '@mui/material/Button';
import { AddRounded, ChevronLeftRounded, ChevronRightRounded, Delete } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Fab from '@mui/material/Fab';
import CircularProgress from '@mui/material/CircularProgress';

import CheckAnimation from '../../animation/check-white.json';
import WarningDeleteOwner from './components/WarningDeleteOwner';
import ReviewStatusIcon from './components/ReviewStatusIcon';
import { ICaptiqTabsProps, ITab } from './types';
import useStyles from './styles';

const CaptiqTabs = (props: ICaptiqTabsProps) => {
  const {
    maxDuplicates,
    currentTab,
    tabs,
    onChange,
    onCloseTab,
    addTab,
    isAdding,
    isEditing = true,
    isReviewing = false,
    customColors,
    hideDelete = false,
    hoverOnActive,
    beforeDelete,
  } = props;
  const styles = useStyles(props);
  const [nextTab, setNextTab] = useState(0);
  const [showScroll, setShowScroll] = useState(false);
  const [warningDelete, setWarningDelete] = useState(false);
  const [indexWarning, setIndexWarning] = useState<number | null>(null);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const refList = createRef<HTMLUListElement>();
  const refContent = createRef<HTMLDivElement>();

  const updateDimensions = useCallback(() => {
    if (refList.current && refContent.current) {
      const overflow = refContent.current.offsetWidth - 40 < refList.current?.scrollWidth;
      setShowScroll(overflow);
    }
  }, [refContent, refList]);

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    updateDimensions();

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, [updateDimensions]);

  useEffect(() => {
    if (tabs.length > 0) {
      updateDimensions();
    }
  }, [tabs, updateDimensions]);

  useEffect(() => {
    setNextTab(currentTab);
    if (refList.current) refList.current.scrollLeft = currentTab * 170;
  }, [currentTab, refList]);

  const getZIndex = (index: number) => {
    if (currentTab === index) return tabs.length;
    if (currentTab > index) {
      return index;
    }
    if (currentTab < index) {
      return tabs.length - (index - currentTab);
    }
  };

  const addNew = async () => {
    if (addTab) {
      await addTab();
      updateDimensions();
    }
  };

  const changeTab = (index: number) => {
    if (index < tabs.length && index >= 0) {
      setNextTab(index);
      if (onChange) onChange(index);
    }
  };

  const closeTab = async () => {
    setLoadingDelete(true);
    if (beforeDelete) await beforeDelete();
    if (onCloseTab && indexWarning !== null) {
      await onCloseTab(indexWarning);
      updateDimensions();
      setWarningDelete(false);
      setIndexWarning(null);
      setLoadingDelete(false);
    }
  };

  const openWarning = (event: any, index: number) => {
    event.stopPropagation();
    setWarningDelete(true);
    setIndexWarning(index);
  };

  const mapTabs = (item: ITab, index: number) =>
    item && (
      <li
        id={`tab_${index}`}
        key={index}
        className={currentTab === index ? 'active-tab' : 'no-active-tab'}
        style={{
          zIndex: getZIndex(index),
        }}
        onClick={() => {
          if (!isAdding) changeTab(index);
        }}
        onMouseEnter={() => {
          if (!isAdding) hoverOnActive(currentTab === index);
        }}
        onMouseLeave={() => {
          if (!isAdding) hoverOnActive(false);
        }}
      >
        <span className="tabLabel">{item.label}</span>
        {isReviewing && !isEditing && <ReviewStatusIcon status={item.reviewStatus} />}
        {nextTab !== currentTab && nextTab === index ? (
          <CircularProgress size={20} style={{ minWidth: 20, marginLeft: 5, marginRight: 5 }} />
        ) : !hideDelete && onCloseTab && isEditing ? (
          <IconButton
            style={{
              marginLeft: 5,
              marginRight: 5,
              transition: !item.completed ? 'all 0.4s' : 'none',
            }}
            size="small"
            onClick={(event) => openWarning(event, index)}
            data-testid="delete-tab"
          >
            <Delete style={{ fontSize: 22, color: '#b70000' }} />
          </IconButton>
        ) : null}
        {item.completed && isEditing ? (
          <div className="tab-status">
            <Lottie
              width={22}
              height={22}
              options={{
                animationData: CheckAnimation,
                loop: false,
              }}
              speed={2}
            />
          </div>
        ) : null}
      </li>
    );

  const scrollDown = () => {
    if (refList.current) refList.current.scrollLeft -= 50;
  };

  const scrollUp = () => {
    if (refList.current) refList.current.scrollLeft += 50;
  };

  return (
    <div ref={refContent} className={styles.tabContent}>
      {showScroll ? (
        <Button className={styles.scrollButton} onClick={scrollDown}>
          <ChevronLeftRounded />
        </Button>
      ) : null}
      <ul ref={refList} className={`${styles.tabList} ${!showScroll ? styles.noScroll : null}`}>
        {tabs.map((item, index) => mapTabs(item, index))}
        {isEditing && (
          <li>
            {(!maxDuplicates || maxDuplicates > tabs.length) && (
              <Fab
                className={styles.fabButton}
                size="small"
                color="primary"
                style={{ backgroundColor: customColors.buttonColor }}
                onClick={addNew}
                disabled={isAdding}
                data-testid="add-new-tab"
              >
                {isAdding ? <CircularProgress size={20} color="inherit" /> : <AddRounded />}
              </Fab>
            )}
          </li>
        )}
      </ul>
      {showScroll ? (
        <Button className={styles.scrollButton} onClick={scrollUp}>
          <ChevronRightRounded />
        </Button>
      ) : null}
      <WarningDeleteOwner
        open={warningDelete}
        handleCloseModal={() => setWarningDelete(false)}
        handleCloseDeleteOwnerModal={closeTab}
        loadingDelete={loadingDelete}
        isLegaRepresentative={
          tabs && tabs.length > 0 && tabs[0]?.slug?.includes('gesetzlichevertreter')
        }
        isLoanApplicant={
          tabs && tabs.length > 0 && tabs[0]?.slug?.includes('darlehensantragsteller')
        }
      />
    </div>
  );
};

export default CaptiqTabs;
