import React, { useContext, memo, useCallback } from 'react';
import classNames from 'classnames';

import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Chip,
  Button,
  Box,
} from '@mui/material';
import { ExpandMore, DoneAllRounded, CommentRounded, Edit, Check } from '@mui/icons-material';

import { DataContext } from 'context/data-context';
import { useBlockData } from 'context/block-context';
import { CustomColors } from 'utils/commonTypes/types';
import { BlockStatus } from 'components/CaptiqTabs/components/ReviewStatusIcon/types';
import useStyles from './styles';

interface Props {
  title: string;
  children: React.ReactNode;
  customColors: CustomColors;
  customTitle?: string;
  customDetails?: string;
  slug?: string | undefined;
  section_path?: string;
  staticGeneralStatus?: { status: BlockStatus; label: string };
  customSummary?: string;
  isCompleted?: boolean;
  isMandatory?: boolean;
  isMain?: boolean;
  isReviewBlock?: boolean;
  commentsNumber?: number;
  isSectionEditing?: boolean;
  setIsSectionEditing?: React.Dispatch<React.SetStateAction<boolean>>;
  customClasses?: { incompleteTag: string };
  withMargin?: boolean;
  customExtraZIndex?: number;
}

const STATUS_CLASS_NAMES = {
  APPROVED: 'statusSuccess',
  DECLINED: 'statusDeclined',
  PENDING: 'statusPending',
  NOT_REVIEWED: 'statusNotReviewer',
  EDIT: 'statusEdit',
};

export const GeneralStatusChip = memo(
  ({ staticGeneralStatus }: { staticGeneralStatus: { status: BlockStatus; label: string } }) => {
    const classes = useStyles();
    return (
      <div className={classes.statusContainer}>
        <Box flexGrow={1} />
        <Chip
          className={classNames(
            classes.blockStatus,
            classes[STATUS_CLASS_NAMES[staticGeneralStatus.status]],
          )}
          label={staticGeneralStatus.label}
        />
      </div>
    );
  },
);

export const GeneralStatusActions = memo(
  ({
    isSectionEditing,
    setIsSectionEditing,
  }: {
    isSectionEditing: boolean;
    setIsSectionEditing: React.Dispatch<React.SetStateAction<boolean>>;
  }) => {
    const classes = useStyles();
    const startEdition = () => {
      setIsSectionEditing(true);
    };

    const endEdition = () => {
      setIsSectionEditing(false);
    };
    return (
      <AccordionActions>
        {isSectionEditing ? (
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={endEdition}
            className={classes.button}
          >
            <Check className={classes.iconLeft} /> Änderungen übernehmen
          </Button>
        ) : (
          <Button size="small" onClick={startEdition} className={classes.buttonMargin}>
            <Edit className={classes.iconLeft} /> Abschnitt ändern
          </Button>
        )}
      </AccordionActions>
    );
  },
);

const Section = memo(
  ({
    title,
    children,
    customColors,
    customTitle,
    customDetails,
    section_path,
    staticGeneralStatus,
    slug,
    customSummary,
    isCompleted,
    isMandatory,
    isMain,
    isReviewBlock = false,
    commentsNumber = 0,
    withMargin = false,
    /* TODO: Uncoment when the backend fix is applyed CRP-3438
    isSectionEditing = false,
    setIsSectionEditing = () => {}, */
    customClasses,
    customExtraZIndex,
  }: Props) => {
    const classes = useStyles();
    const { blocks } = useContext(DataContext);
    const { setExpanded, expanded } = useBlockData();
    const defaultZIndex = 900;

    const expand = expanded === section_path;

    const getBlockObject = useCallback((path: any) => blocks[path] || {}, [blocks]);

    const handleOnClick = useCallback(() => {
      if (isMain && section_path) {
        setExpanded(expand ? '' : section_path);
      }
    }, [expand, isMain, section_path, setExpanded]);

    /*  TODO: Uncoment when the backend fix is applyed CRP-3438
     const startEdition = () => {
      setIsSectionEditing(true);
    };

    const endEdition = () => {
      setIsSectionEditing(false);
    }; */

    return (
      <Accordion
        className={classNames(classes.accordion, withMargin && classes.margin)}
        style={{
          backgroundColor: customColors.blockBackgroundColor,
        }}
        expanded={isMain ? expand : undefined}
      >
        <AccordionSummary
          onClick={handleOnClick}
          style={{
            backgroundColor:
              customColors.summaryBackgroundColor || customColors.blockBackgroundColor,
            zIndex: customExtraZIndex ? defaultZIndex + customExtraZIndex : defaultZIndex,
          }}
          expandIcon={<ExpandMore />}
          classes={{
            root: customSummary || classes.accordionSummaryRoot,
            content: classes.accordionSummaryContent,
            expandIconWrapper: classes.accordionButton,
          }}
        >
          <Typography className={customTitle || classes.title}>{title}</Typography>

          {getBlockObject(section_path).isCompleted ? (
            <Chip
              className={classNames(classes.doneTick, classes.backgroundComplete)}
              icon={<DoneAllRounded fontSize="inherit" />}
              label="KOMPLETT"
              size="small"
            />
          ) : (
            getBlockObject(section_path).isRequired &&
            slug !== 'businessplan' && (
              <Chip label="ERFORDERLICH" size="small" className={customClasses?.incompleteTag} />
            )
          )}
          {isCompleted !== undefined && isMandatory ? (
            !isCompleted ? (
              <Chip label="ERFORDERLICH" size="small" className={customClasses?.incompleteTag} />
            ) : (
              <Chip
                className={classNames(classes.doneTick, classes.backgroundComplete)}
                icon={<DoneAllRounded fontSize="inherit" />}
                label="KOMPLETT"
                size="small"
              />
            )
          ) : null}
          {isReviewBlock && (
            <>
              <Box flexGrow={1} />
              {staticGeneralStatus && (
                <Chip
                  className={classNames(
                    classes.blockStatus,
                    classes[STATUS_CLASS_NAMES[staticGeneralStatus.status]],
                  )}
                  label={staticGeneralStatus.label}
                />
              )}
              <Button
                aria-describedby="pop"
                size="small"
                color="primary"
                variant="contained"
                className={classes.blockCommentsIcon}
              >
                <CommentRounded style={{ fontSize: '16px', marginRight: '6px' }} />
                {commentsNumber}
              </Button>
            </>
          )}
        </AccordionSummary>
        <AccordionDetails className={customDetails || classes.accordionDetails}>
          {isMain ? (expand ? children : null) : children}
        </AccordionDetails>
        {/* TODO: Uncoment when the backend fix is applyed CRP-3438
        {isReviewBlock && (
          <AccordionActions>
            {isSectionEditing ? (
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={endEdition}
                className={classes.button}
              >
                <Check className={classes.iconLeft} /> Änderungen übernehmen
              </Button>
            ) : (
              <Button size="small" onClick={startEdition} className={classes.buttonMargin}>
                <Edit className={classes.iconLeft} /> Abschnitt ändern
              </Button>
            )}
          </AccordionActions>
        )} */}
      </Accordion>
    );
  },
);

export default Section;
