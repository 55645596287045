import { memo, useCallback } from 'react';
import moment from 'moment';

import { IconButton } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import { QuestionField } from 'utils/commonTypes/types';
import useStyles from './styles';

interface Props {
  value?: string | number;
  type?: string;
}

export const showCopyButton = (field: QuestionField) => {
  if (field?.slug?.includes('land_anschrift')) return true;

  switch (field?.field_type) {
    case 'TEXT':
    case 'CHAR':
    case 'DATE':
    case 'INTEGER':
    case 'DECIMAL':
      return true;
    default:
      return false;
  }
};

export const ContainedCopyButton = memo(
  ({ value, field }: { value?: string | number; field: QuestionField }) => {
    const classes = useStyles();

    let dateVal = value;
    if (value && field?.field_type === 'DATE') {
      dateVal = moment(value).format('DD.MM.YYYY');
    }

    return (
      <div className={classes.copyContainer}>
        {showCopyButton(field) && dateVal ? (
          <div className={classes.copyButtonContainer}>
            <CopyButton value={dateVal} />
          </div>
        ) : null}
      </div>
    );
  },
);

const CopyButton = memo(({ value, type }: Props) => {
  const classes = useStyles();

  const copyToClipboard = useCallback(() => {
    let text = value;
    if (typeof value === 'number') {
      text = value.toString();
    }

    if (type && type === 'date') {
      text = moment(value).format('DD.MM.YYYY');
    }

    navigator.clipboard.writeText(text as string);
  }, [type, value]);

  return (
    <IconButton edge="end" size="small" className={classes.copyIcon} onClick={copyToClipboard}>
      <FileCopyIcon fontSize="small" />
    </IconButton>
  );
});

export default CopyButton;
