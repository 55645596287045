import React, { memo, useEffect } from 'react';
import { evaluate } from 'mathjs';
import classNames from 'classnames';

import { Grid, Box } from '@mui/material';

import { ChoiceGroupType } from 'utils/commonTypes/types';
import CaptiqField from 'components/CaptiqField';
import { useCustomerData } from 'context/customer-context';
import { useAuth } from 'context/auth-context';
import { isAdmin } from 'utils/isAdmin';
import { GET_BIC_BANK_NAME, request } from 'service/ApiCaptiq';
import { useIbanStatus } from 'context/iban-context';
import { useDataContext } from 'context/data-context';
import WarningMessage from 'components/WarningMessage';
import { getMapFieldMessage } from '../../utils';
import { IFieldProps } from './types';
import useStyles from './styles';

const Field = memo(
  ({
    data,
    index,
    questionData,
    blockData,
    step_id,
    block_id,
    handleChange,
    blockSlug,
    handleOnBlur,
    handleDeleteField,
    form,
    renderSepaData,
    stepOrder,
    disabled,
    questions,
    loanId,
    title,
    blockHelper,
    customColors,
    personID,
    reviewCancelEdition,
    showCopyButton,
    upperFormState,
    validateIban,
    ibanTimestamp,
  }: IFieldProps) => {
    const customerContext = useCustomerData();
    const authContext = useAuth();
    const { setIbanValidation, ibanValidation } = useIbanStatus();
    const context = useDataContext();
    const styles = useStyles();

    const initialIbanValidation = async (iban: string) => {
      if (iban && iban !== '')
        try {
          iban = String(iban).replace(/\s/g, '');
          const response = await request.get(GET_BIC_BANK_NAME(iban));
          setIbanValidation(response.data.is_valid);
          const sepaData = response.data.sepa_data ?? {};
          renderSepaData(sepaData, response.data.last_api_update);
        } catch (error) {
          console.error(error);
        }
    };

    useEffect(() => {
      if (data.field_validator === 'IBAN') {
        initialIbanValidation(
          context.blocks[blockSlug]?.questions[`q_${data.slug}`]?.fields[data.slug]?.value || '',
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const replaceAll = (str: string, mapObj: { [x: string]: any }) => {
      const re = new RegExp(Object.keys(mapObj).join('|'), 'gi');
      return str.replace(re, (matched) => mapObj[matched.toLowerCase()]);
    };

    const calculateValue = (formula: string, question_id: any) => {
      const dummy: any = {};
      formula
        .split(' ')
        .filter((item) => item.length > 1)
        .map((item) => {
          item = item.toLowerCase();
          const item_slug = `${step_id ? `${step_id}__` : ''}${block_id ? `${block_id}__` : ''}${
            question_id ? `${question_id}__` : ''
          }${item.toLowerCase()}`;
          dummy[item] = blockData[item_slug] ? blockData[item_slug] : 0;
          return {
            slug: item_slug,
            value: blockData[item_slug] ? blockData[item_slug] : 0,
          };
        });
      return evaluate(replaceAll(formula, dummy));
    };

    let fieldValue = data.value;
    const hiddenFields = (customerContext.hiddenFields?.loanProcess || []).map((item: string) =>
      item.toLowerCase(),
    );
    if (hiddenFields.includes(data.slug)) {
      return null;
    }
    const question_id = questionData.can_be_duplicated ? index + 1 : false;

    if (data.choice_rules) {
      if (blockData[data.depending_field] && data.choice_rules[blockData[data.depending_field]]) {
        const choiceName = data.choice_rules[blockData[data.depending_field]];
        const getRule = (data.choice_group as any).find(
          (rule: ChoiceGroupType) => rule.name === choiceName,
        );
        data.custom_choice_group = getRule;
      } else {
        return null;
      }
    } else if (
      data.depending_field &&
      data.visibility_value !== String(blockData[data.depending_field])
    ) {
      return null;
    }

    let type = data.field_type;
    if (data.formula !== '') {
      if (data.formula.includes('sum') && !data.slug.includes('nettovermogen')) {
        if (data.formula.includes('kredit_rate') || data.formula.includes('kredit_rate_v2')) {
          fieldValue = Object.keys(upperFormState).reduce((sum, v) => {
            if (v.includes('__kredit_rate') || v.includes('__kredit_rate_v2')) {
              return sum + upperFormState[v];
            }
            return sum;
          }, 0);
        } else fieldValue = 0;
      } else fieldValue = calculateValue(data.formula, question_id);
      if (form[data.slug] !== fieldValue) {
        handleChange(blockSlug, questionData.slug, data.slug, fieldValue);
      }
      type = 'CALCULATED';
    }

    // Note: If the CaptiqField component logic is needed, move this condition at that level
    const role = authContext.getRole();
    if (data.is_admin_only && !isAdmin(role)) {
      return null;
    }

    const { warningMessage, maxDate, severity } = getMapFieldMessage(
      data.slug,
      data.field_validator,
      blockSlug,
      questionData.slug,
      context.blocks,
    );

    return (
      <React.Fragment key={data.slug}>
        <div>
          {(data.slug.includes('__kredit_rate') || data.slug.includes('__kredit_rate_v2')) &&
          (context.blocks[blockSlug]?.questions[questionData.slug]?.fields[data.slug]
            ?.value as unknown as number) === 0 &&
          context.blocks[blockSlug]?.questions[questionData.slug]?.fields[
            `${data.slug[0]}__dieses_darlehen`
          ]?.value === 'no' ? (
            <Box mt={2} width="100%">
              <WarningMessage
                message={
                  'Sie haben 0€ als Höhe der jährlichen Raten angegeben und gleichzeitig gewählt, dass es sich nicht um ein endfälliges Darlehen handelt. Bitte geben Sie eine kurze Erläuterung hierzu im Feld des Darlehenszwecks an.'
                }
                severity={'warning'}
              />
            </Box>
          ) : null}
        </div>
        {warningMessage && (
          <Box mb={2} width="100%">
            <WarningMessage message={warningMessage} severity={severity} />
          </Box>
        )}
        {data.field_validator === 'IBAN' && !ibanValidation && (
          <Box mb={2} width="100%">
            <WarningMessage message="Bitte geben Sie eine gültige IBAN ein." severity="error" />
          </Box>
        )}
        <Grid
          className={classNames(
            styles.fieldContainer,
            type === 'RICH_TEXT' ? styles.fieldContainerRichText : null,
          )}
          item
          xs={questionData.layout === 'H' ? 4 : 12}
        >
          <CaptiqField
            stepOrder={stepOrder}
            type={type}
            disabled={disabled}
            calculationValue={fieldValue}
            blockSlug={blockSlug}
            questionSlug={questionData.slug}
            data={data}
            onChange={handleChange}
            onBlur={handleOnBlur}
            deleteField={handleDeleteField}
            questions={questions}
            loanId={loanId}
            title={title}
            blockHelper={blockHelper}
            numColumn={questionData.num_column}
            customColors={customColors}
            maxDate={maxDate}
            personID={personID}
            isIBAN={data.field_validator === 'IBAN'}
            validateIban={validateIban}
            reviewCancelEdition={reviewCancelEdition}
            showCopyButton={showCopyButton}
            upperFormState={upperFormState}
          />
        </Grid>
        {ibanTimestamp && (
          <small>
            <i>{ibanTimestamp}</i>
          </small>
        )}
      </React.Fragment>
    );
  },
);

Field.displayName = 'Field';

export default Field;
