import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  mainText: {
    marginBottom: 20,
  },
  text: {
    fontSize: 17,
  },
  linkUnderline: {
    color: '#17889B',
    textDecoration: 'underline',
  },
  openDialog: {
    padding: '3px 50px',
    fontSize: 17,
    textTransform: 'unset',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default useStyles;
