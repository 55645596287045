import * as Yup from 'yup';

import { countryOrNationality } from 'utils/validateStaticBlocksValues/countryOrNationality';
import { getLocaleAllocation } from 'utils/utils';
import { LANGUAGE } from 'constants/language';
import { ONGOING_TEMPLATE_TAG } from 'constants/templates';
import { whenDateOfBirth } from '../LoanApplicants/validationSchema';

export const validationSchema = Yup.object().shape({
  people: Yup.array().of(
    Yup.lazy((value) =>
      value
        ? Yup.object({
            salutation: Yup.string().required('Bitte wählen Sie eine Anrede').label('Anrede'),
            firstNames: Yup.string()
              .required('Bitte geben Sie Ihren Vornamen ein')
              .label('Vorname'),
            lastName: Yup.string()
              .required('Bitte geben Sie Ihren Nachnamen ein')
              .label('Nachname'),
            title: Yup.string().nullable().label('Titel'),
            dateOfBirth: Yup.string().when([], whenDateOfBirth),
            countryOfBirth: countryOrNationality('Geburtsland'),
            cityOfBirth: Yup.string().required().label('Geburtsort'),
            nationality: countryOrNationality('Staatsangehörigkeit'),
            taxableInUSA: Yup.string()
              .oneOf(['no'])
              .required()
              .label('Steuerpflichtig in den USA?'),
            isOwner: Yup.string().required().label('Inhaber(in)'),
            isBeneficialOwner: Yup.string().label('Wirtschaftlich Berechtigte'),
            isLoanApplicant: Yup.string().required().label('Darlehensantragsteller(in)'),
            personalAddressStreet: Yup.string().required().label('Straße Privatanschrift'),
            personalAddressHouseNumber: Yup.string().required().label('Hausnummer Privatanschrift'),
            personalAddressAdditional: Yup.string().label('Adresszusatz Privatanschrift'),
            personalAddressPostalCode: Yup.string()
              .min(
                4,
                'Die PLZ in Deutschland sind 5-stellig und in Österreich und in der Schweiz 4-stellig.',
              )
              .max(
                5,
                'Die PLZ in Deutschland sind 5-stellig und in Österreich und in der Schweiz 4-stellig.',
              )
              .required()
              .label('PLZ Privatanschrift'),
            personalAddressCity: Yup.string().required().label('Ort Privatanschrift'),
            personalAddressCountry: countryOrNationality('Wohnsitzland Privatanschrift'),
            hasOtherCompanies: Yup.string()
              .label(
                'Hält die Person Anteile von mehr als 25% an mind. einer anderen Kapitalgesellschaft (z.B. GmbH) oder ist die Person an mind. einer weiteren Personengesellschaft (z.B. GbR) beteiligt?',
              )
              .test('required', 'Bitte wählen Sie eine Option', (val: any, context: any) => {
                const { profileVersion, loanApplicationUsage } = context.options.context;
                const { shares } = context.parent;

                if (
                  Number(profileVersion.at(-1)) < 3 ||
                  loanApplicationUsage !== ONGOING_TEMPLATE_TAG ||
                  (shares &&
                    shares.length > 0 &&
                    !!shares[0].allocation &&
                    getLocaleAllocation(shares[0].allocation || '0', LANGUAGE) < 50.01)
                ) {
                  return true;
                }

                const condition = !!val;

                return condition;
              }),
          })
        : Yup.object({}).nullable(),
    ) as unknown as Yup.AnySchema,
  ),
});
