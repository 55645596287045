import { createContext, useContext, useState } from 'react';

import { BlockDataProviderProps } from './types';
import { initialState } from './constants';

const IbanStatus = createContext(initialState);

const IbanStatusProvider = ({ children }: BlockDataProviderProps) => {
  const [ibanStatus, setIbanStatus] = useState<boolean>(true);
  const [ibanValidation, setIbanValidation] = useState<boolean>(true);

  const value = {
    ibanStatus,
    setIbanStatus,
    ibanValidation,
    setIbanValidation,
  };

  return <IbanStatus.Provider value={value}>{children}</IbanStatus.Provider>;
};

export const useIbanStatus = () => {
  const context = useContext(IbanStatus);

  return context;
};

export default IbanStatusProvider;
