import React, { ChangeEvent, useCallback, memo } from 'react';

import { TextField as MuiTextField, TextFieldProps } from '@mui/material';
import { NumericFormatProps } from 'react-number-format';

import NumberFormatGeneric from './NumberFormatGeneric';

type NumericFieldProps = TextFieldProps & {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  numberFormatProps?: NumericFormatProps;
  presets?: 'money' | 'percentage';
};

// We can add or edit more presets
const MONY_PRESETS = {
  thousandSeparator: '.',
  decimalSeparator: ',',
  decimalScale: 2,
  suffix: '€',
};
const PERCENTAGE_PRESETS = {
  decimalScale: 2,
  suffix: '%',
};

const NumericField = (props: NumericFieldProps) => {
  const { numberFormatProps, presets, onChange: origianlOnchange, ...other } = props;

  const onChange = useCallback(
    (event: any) => {
      const { value } = event.target;
      if (/^(\d*\.?\d*?)$/.test(value) && origianlOnchange) {
        origianlOnchange(event);
      }
    },
    [origianlOnchange],
  );

  const getProps = useCallback(() => {
    switch (presets) {
      case 'money':
        return MONY_PRESETS;
      case 'percentage':
        return PERCENTAGE_PRESETS;
      default:
        return numberFormatProps;
    }
  }, [numberFormatProps, presets]);

  return (
    <MuiTextField
      {...other}
      InputProps={{
        inputComponent: NumberFormatGeneric,
        ...other.InputProps,
        inputProps: getProps() as any,
      }}
      onChange={onChange}
    />
  );
};

export default memo(NumericField);
