import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { Tooltip } from '@mui/material';

export const HtmlTooltip = withStyles(() => ({
  tooltip: { background: 'transparent' },
  popper: { pointerEvents: 'auto' },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  tooltip: {
    position: 'relative',
    background: theme.palette.primary.main,
    borderRadius: '7px',
    minWidth: 200,
    padding: 25,
    color: '#fff',
    opacity: 1,
    boxShadow: '2px 10px 15px rgba(0, 0, 0, 0.2)',
    '& h4': {
      margin: '0 0 10px 0',
      fontSize: 18,
    },
    '& p': {
      margin: '0',
      fontSize: 14,
      color: '#fff',
    },
  },
  tooltipContainer: {
    position: 'relative',
    width: '100%',
    paddingTop: '4px',
  },
  helpTextIcon: {
    marginLeft: '15px',
    marginBottom: '7px',
  },
  helpTextContainer: {
    cursor: 'pointer',
  },
  customWidth: {
    maxWidth: 500,
  },
  tooltipTitle: {
    color: '#134A54',
    fontWeight: 500,
    marginTop: '5px !important',
  },
  closeButton: {
    position: 'absolute',
    right: 5,
    top: 5,
    padding: 0,
    color: 'white',
    cursor: 'pointer',
  },
  closeButtonIcon: {
    fontSize: '21px !important',
  },
  helpIcon: {
    width: 20,
    height: 18,
  },
  downloadIcon: {
    width: 20,
    height: 30,
  },
  editIcon: {
    width: 20,
    height: 36,
  },
  deleteIcon: {
    width: 20,
    height: 36,
  },
  imageIcon: {
    width: 20,
    height: 36,
  },
}));

export default useStyles;
