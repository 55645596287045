import { Person, RefLegalRepresentative } from 'context/customer-context/types';
import { getRolId } from './getRolId';

export const getLegalRepresentativeRef = (
  value: Person,
  index: number,
): RefLegalRepresentative => ({
  id: value.id,
  legalRepresentativeId: getRolId('LRP', value.correlativeId),
  index,
});
