import React, { createRef, Component } from 'react';
import validator from 'validator';
import Lottie from 'lottie-react-web';
import _get from 'lodash/get';

import { Chip, InputAdornment, TextField } from '@mui/material';
import { CalendarTodayRounded } from '@mui/icons-material';
import Typography from '@mui/material/Typography';

import { ALLOWED_KEYS } from '../../constants/phoneField';
import getCountryCode from '../../utils/getCountryPrefix';
import CheckAnimation from '../../animation/check.json';
import { CurrencyFormatCustom, PercentageFormatCustom } from './CustomNumberFormat';
import validatePhoneNumber from '../../utils/dataHandlers/validatePhoneNumber';
import DateTimePicker from '../DateTimePicker/components/DateTimePicker';
import { isValueValid } from '../../utils/utils';
import { ChoicesField } from './wrappers';

export default class InputRender extends Component {
  static getCalculated(data, value, className) {
    let displayReadOnly = '';
    if (data.field_validator === 'BIC' || data.field_validator === 'BANK_NAME')
      displayReadOnly = value || 'Wird automatisch generiert';

    if (data.field_type === 'CHOICE') {
      displayReadOnly =
        data?.choice_group?.choices?.find((item) => item.value === value)?.name || '';
    }

    return !displayReadOnly ? (
      <Typography key={data.slug} className={className}>
        {data.field_type === 'INTEGER' || data.field_type === 'DECIMAL'
          ? Number(value).toLocaleString('de-DE', {
              style: 'currency',
              currency: 'EUR',
            })
          : value}
      </Typography>
    ) : (
      <Typography key={data.slug}>{displayReadOnly}</Typography>
    );
  }

  static getCharField(
    data,
    value,
    inputProps,
    commonProps,
    handleChange,
    classes,
    hasValue,
    checkOnBlur,
    disabled,
    ref,
    isIBAN,
    validateIban,
    upperFormState,
    countryList,
  ) {
    const charInputType = 'text';
    let handleKeyDown = () => {};

    if (
      data &&
      data.field_mask &&
      data.field_mask === 'NUMBERS-ONLY' &&
      data.slug !== 'geplante_plz'
    ) {
      handleKeyDown = (event) => {
        const { key } = event;

        if (ALLOWED_KEYS.includes(key)) {
          return;
        }

        event.preventDefault();
      };
    }

    if (isIBAN) {
      inputProps.inputProps = {
        ...(inputProps.inputProps || {}),
        style: { textTransform: 'uppercase ' },
      };
    }

    const error = InputRender.validateValue(data, value, upperFormState, countryList);

    const ibanError = InputRender.validateIBAN(value, validateIban, isIBAN);

    return (
      <TextField
        inputRef={ref}
        className={value && !error.hasError ? classes.completeField : null}
        {...commonProps}
        error={error.hasError}
        helperText={error.hasError ? error.errorMessage : null}
        value={value}
        onChange={handleChange}
        type={charInputType}
        onBlur={checkOnBlur}
        disabled={disabled}
        onKeyDown={handleKeyDown}
        InputProps={{
          ...inputProps,
          endAdornment: value && hasValue && !error.hasError && !ibanError && (
            <InputAdornment>
              <Lottie
                width={30}
                height={30}
                options={{
                  animationData: CheckAnimation,
                  loop: false,
                }}
                speed={2}
              />
            </InputAdornment>
          ),
        }}
      />
    );
  }

  static getChoice(data, value, inputProps, commonProps, handleChange, disabled, ref) {
    const choice_group = data.custom_choice_group ? data.custom_choice_group : data.choice_group;
    if (choice_group) {
      return (
        <ChoicesField
          value={value}
          commonProps={commonProps}
          handleChange={handleChange}
          disabled={disabled}
          choice_group={choice_group}
          inputRef={ref}
          field_mask={data.field_mask}
          field_type={data.field_type}
        />
      );
    }
    return (
      <Chip
        label="Error creating choice field"
        color="primary"
        style={{ backgroundColor: '#ff0000' }}
      />
    );
  }

  static getDatePicker(
    data,
    value,
    disabled,
    handleDateChange,
    classes,
    maxDate,
    ref,
    customEndAdorment = null,
  ) {
    this.myRef = createRef();

    return (
      <DateTimePicker
        maxDate={maxDate}
        key={data.slug}
        name={data.slug}
        timepicker={false}
        value={value}
        format="DD.MM.YYYY"
        value_format={['DD.MM.YYYY', 'DD.MM.YY, DDMMYYYY']}
        onChange={handleDateChange}
        onBlurTyped={handleDateChange}
        inputRefProp={this.myRef}
        locale="de"
        customInput={
          <TextField
            size="small"
            style={{ pointerEvents: disabled && !customEndAdorment ? 'none' : 'auto' }}
            ref={this.myRef}
            disabled={disabled}
            className={value && classes.completeField}
            margin="dense"
            fullWidth
            label={data.placeholder || ''}
            InputProps={{
              inputRef: ref,
              autoComplete: 'off',
              placeholder: 'tt.mm.jjjj',
              startAdornment: (
                <InputAdornment position="start">
                  <CalendarTodayRounded style={{ fontSize: 18 }} />
                </InputAdornment>
              ),
              endAdornment: value && (
                <>
                  {customEndAdorment !== undefined ? (
                    customEndAdorment
                  ) : (
                    <InputAdornment>
                      <Lottie
                        width={30}
                        height={30}
                        options={{
                          animationData: CheckAnimation,
                          loop: false,
                        }}
                        speed={2}
                      />
                    </InputAdornment>
                  )}
                </>
              ),
            }}
            variant="outlined"
          />
        }
      />
    );
  }

  static getDecimalInput(
    data,
    value,
    disabled,
    inputProps,
    commonProps,
    handleChange,
    handleCurrencyChange,
    classes,
    hasValue,
    checkOnBlur,
    ref,
  ) {
    let onChange = handleChange;
    let inputType = 'number';
    this.MONEY_DIGITS = 16;
    if (!inputProps.inputProps.maxLength) {
      inputProps.inputProps.maxLength = this.MONEY_DIGITS;
    }
    if (data.max) {
      inputProps.inputProps.max = data.max;
      inputProps.inputProps.min = data.min;
    }
    if (data.field_mask === 'EUR-CURRENCY') {
      inputProps.endAdornment = (
        <InputAdornment position="end">
          <span>€</span>
          {isValueValid(value) && hasValue && (
            <Lottie
              width={30}
              height={30}
              options={{
                animationData: CheckAnimation,
                loop: false,
              }}
              speed={2}
            />
          )}
        </InputAdornment>
      );
      inputProps.inputComponent = CurrencyFormatCustom;
      inputType = 'text';
      onChange = handleCurrencyChange;
    } else if (data.field_mask === 'PERCENTAGE') {
      inputType = 'text';
      inputProps.endAdornment = (
        <InputAdornment position="end">
          <span>%</span>
          {isValueValid(value) && hasValue && (
            <Lottie
              width={30}
              height={30}
              options={{
                animationData: CheckAnimation,
                loop: false,
              }}
              speed={2}
            />
          )}
        </InputAdornment>
      );
      inputProps.inputComponent = PercentageFormatCustom;
    }
    return (
      <TextField
        inputRef={ref}
        onBlur={checkOnBlur}
        className={isValueValid(value) && classes.completeField}
        {...commonProps}
        placeholder={
          typeof value === 'number' && Number(value) === 0 ? '0,00' : commonProps.placeholder
        }
        value={value}
        InputProps={inputProps}
        onChange={onChange}
        type={inputType}
        disabled={disabled}
      />
    );
  }

  static validateIBAN(iban, validateFunction, isIBAN) {
    if (!isIBAN) return false;
    return isIBAN && !validateFunction(iban);
  }

  static validateValue(data, value, upperFormState, countryList) {
    const result = {
      hasError: false,
      errorMessage: '',
    };
    if (value && data && data.field_validator && data.field_validator === 'EMAIL') {
      result.hasError = !validator.isEmail(value);
      result.errorMessage = 'Bitte geben Sie eine gültige E-Mail-Adresse ein.';
    }
    if (value && data && data.field_validator && data.field_validator === 'ZIP') {
      result.hasError = value.length < 4 || value.length > 5;
      result.errorMessage =
        'Die PLZ in Deutschland sind 5-stellig und in Österreich und in der Schweiz 4-stellig.';
    }
    if (value && data && data.field_validator && data.field_validator === 'PHONE') {
      const internationalPrefixCode = _get(upperFormState, `internationales_prafix`);
      const internationalPrefix = getCountryCode(countryList, internationalPrefixCode);
      const [hasError, errorMessage] = validatePhoneNumber(value, String(internationalPrefix));
      result.hasError = hasError;
      result.errorMessage = errorMessage;
    }

    return result;
  }
}
