import { useContext } from 'react';

import { Checkbox, CheckboxProps } from '@mui/material';

import { AuthContext } from 'context/auth-context';

interface ICaptiqCheckboxProps extends Omit<CheckboxProps, 'ref' | 'onClick'> {
  onClick?: (event: any) => void;
  animate?: boolean;
}

const CaptiqCheckbox = (props: ICaptiqCheckboxProps) => {
  const context = useContext(AuthContext);
  return (
    <Checkbox
      inputProps={{ 'data-testid': 'checkbox-input' } as any}
      {...props}
      style={{ color: context.checkboxColor }}
    />
  );
};

export default CaptiqCheckbox;
