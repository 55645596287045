import * as Yup from 'yup';

import { CompanyDataFormValues } from 'context/customer-context/types';

export const companyDataSchema = () =>
  Yup.object({
    companyName: Yup.string().required('Bitte geben Sie den Unternehmensname ein'),
    legalForm: Yup.string().required('Bitte wählen Sie eine Rechtsform'),
    organisation: Yup.string(),
    hgb: Yup.string().required('Bitte wählen Sie eine Kooperations-/Organisationsform'),
    founding: Yup.date(),
    numberEmployees: Yup.number()
      .typeError('Bitte geben Sie eine gültige Zahl ein')
      .test(
        'more-equal-zero',
        'Die Anzahl der Mitarbeiter darf keine negative Anzahl sein',
        (value) => {
          if (value) {
            if (value >= 0) return true;
            return false;
          }
          return true;
        },
      )
      .test('is-decimal', 'Bitte geben Sie keine Dezimalzahlen ein', (value) => {
        if (value) {
          return !String(value).includes(',');
        }
        return true;
      }),
    taxID: Yup.string(),
  });

export const companyDataInitialValues: CompanyDataFormValues = {
  companyName: '',
  legalForm: '',
  organisation: '',
  hgb: '',
  founding: '',
  numberEmployees: '',
  taxID: '',
  externCompanyID: '',
};
