import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
  inputLabel: {
    marginBottom: '-8px',
    marginTop: 15,
    display: 'flex',
    fontSize: '15px',
    fontWeight: 500,
    width: '49%',
    minWidth: '49%',
    maxWidth: '49%',
    marginRight: 15,
    alignItems: 'center',
  },
  inputLabelVertical: {
    justifyContent: 'space-between',
  },
  inputLabelRichText: {
    width: '100%',
    minWidth: '100%',
    maxWidth: '100%',
  },
  fieldContainer: {
    width: '49%',
    minWidth: '49%',
    maxWidth: '49%',
    display: 'flex',
    marginTop: 15,
  },
  fieldContainerVertical: {
    justifyContent: 'space-between',
  },
  fieldContainerFull: {
    display: 'flex',
    width: '100%',
  },
  fieldContainerRichText: {
    width: '100%',
    minWidth: '100%',
    maxWidth: '100%',
  },
  uploadFieldContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
  },
  endAdornment: {
    marginRight: 25,
    marginLeft: 20,
  },
  selectLabel: {
    transform: 'translate(14px, 8px) scale(1)',
    '&$shrink': {
      display: 'none',
    },
  },
  shrink: {},
  completeField: {
    '& fieldset': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
}));

export default useStyles;
