import { InitialStateType } from './types';

export const initialState: InitialStateType = {
  wantedLF: '',
  openChangeLF: false,
  wantedLFEvent: null,
  handleClose: () => {},
  manualSubmit: false,
  handleAccept: () => {},
  setManualSubmit: () => {},
  setWantedLF: () => {},
  setWantedLFEvent: () => {},
  setOpenChangeLF: () => {},
  setHandleAccept: () => {},
  handleFinalAccept: () => {},
};
