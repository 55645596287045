import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import * as ApiCaptiq from 'service/ApiCaptiq';
import { request } from 'service/ApiCaptiq';

export const useFinancingDocuments = (
  loanId: string,
  legalForm: string,
  hgbStatus: string,
  props?: UseQueryOptions<any>,
) =>
  useQuery<any>({
    queryKey: ['financingDocuments', legalForm, hgbStatus, loanId],
    queryFn: () =>
      request
        .get(ApiCaptiq.GET_FINANCING_DOCUMENTS(loanId, legalForm, hgbStatus ? 'True' : 'False'))
        .then((res) => res.data),
    staleTime: Infinity,
    ...props,
  });
