import { memo } from 'react';

import { Grid, Typography, Alert, AlertColor } from '@mui/material';
import { Info } from '@mui/icons-material';

import useStyles from './styles';

interface Props {
  message: string;
  severity?: AlertColor;
}

const WarningMessage = memo(({ message, severity = 'warning' }: Props) => {
  const classes = useStyles();

  return (
    <Grid item className={classes.container}>
      <Alert icon={<Info color={severity} />} severity={severity}>
        <Typography>{message}</Typography>
      </Alert>
    </Grid>
  );
});

WarningMessage.displayName = 'WarningMessage';

export default WarningMessage;
