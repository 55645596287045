import { BackendPartialBusinessForUpdate } from 'utils/backendTypes';
import { CompanyFormValues } from 'context/customer-context/types';
import { LegalForm } from 'utils/commonTypes/types';

export const getNumberEmployees = (employees: number | string | null) => {
  if (employees === null || employees === '' || employees === undefined) return null;
  return Number(employees);
};

export const serializeMainCompanyToBackend = (
  mainCompany: CompanyFormValues,
): BackendPartialBusinessForUpdate => ({
  id: mainCompany.id as number,
  business: mainCompany.data.companyName,
  business_address_street: mainCompany.address.businessAddressStreet,
  business_address_house_n: mainCompany.address.businessAddressHouseNumber,
  business_address_extra: mainCompany.address.businessAddressAdditional,
  business_address_postcode: mainCompany.address.businessAddressPostalCode,
  business_address_city: mainCompany.address.businessAddressCity,
  business_address_country: mainCompany.address.businessAddressCountry?.code || '',
  international_prefix: mainCompany.contact.internationalPrefix?.code || '',
  phone_number: mainCompany.contact.phoneNumber,
  email_business: mainCompany.contact.email,
  legal_form: mainCompany.data.legalForm as LegalForm,
  cooperation_form: mainCompany.data.organisation,
  commercial_register: mainCompany.documentsLegalForm.commercialRegister?.code,
  commercial_register_number: mainCompany.documentsLegalForm.commercialRegisterNumber,
  number_employees: getNumberEmployees(mainCompany.data.numberEmployees),
  foundation_date: mainCompany.data.founding
    ? mainCompany.data.founding.toISOString().substring(0, 10)
    : null,
  tax_id: mainCompany.data.taxID,
  additional_information: mainCompany.additionalInformation,
  hgb_accordance: mainCompany.data.hgb === 'yes',
  customer_profile_relation: {
    roles: ['LAC'],
  },
  external_id: mainCompany.data.externCompanyID,
});
