import React, { memo } from 'react';

import { Grid, Box } from '@mui/material';

import Section from 'components/Section';
import Wrapper from 'components/Wrapper';
import { RefLegalRepresentative } from 'context/customer-context/types';
import { Block, CustomColors } from 'utils/commonTypes/types';
import CustomField from 'components/StaticBlocks/CustomField';

export interface AddressProps {
  refLegalRepresentative: RefLegalRepresentative;
  block: Block;
  customColors: CustomColors;
  isEditing?: boolean;
  isReviewing?: boolean;
}

const Address = memo(
  ({
    refLegalRepresentative,
    block,
    customColors,
    isEditing = true,
    isReviewing = false,
  }: AddressProps) => (
    <Grid item xs={12}>
      <Wrapper
        condition={!isReviewing}
        wrapper={(children: JSX.Element) => (
          <Section
            title="ANSCHRIFT"
            customColors={customColors}
            section_path={block.section_path}
            slug={block.slug}
          >
            {children}
          </Section>
        )}
      >
        <Box m={1}>
          <Grid container spacing={2}>
            <CustomField
              name={`people[${refLegalRepresentative.index}].personalAddressStreet`}
              title="Straße Privatanschrift"
              type="text"
              disabled={!isEditing}
              required
            />
            <CustomField
              name={`people[${refLegalRepresentative.index}].personalAddressHouseNumber`}
              title="Hausnummer Privatanschrift"
              type="text"
              disabled={!isEditing}
              required
            />
            <CustomField
              name={`people[${refLegalRepresentative.index}].personalAddressAdditional`}
              title="Adresszusatz Privatanschrift"
              type="text"
              disabled={!isEditing}
            />
            <CustomField
              name={`people[${refLegalRepresentative.index}].personalAddressPostalCode`}
              title="PLZ Privatanschrift"
              type="onlyNumbers"
              disabled={!isEditing}
              required
            />
            <CustomField
              name={`people[${refLegalRepresentative.index}].personalAddressCity`}
              title="Ort Privatanschrift"
              type="text"
              disabled={!isEditing}
              required
            />
            <CustomField
              title="Wohnsitzland Privatanschrift"
              name={`people[${refLegalRepresentative.index}].personalAddressCountry`}
              textFieldProps={{ label: 'Bitte wählen' }}
              type="place"
              placetype="country"
              disabled={!isEditing}
              required
            />
          </Grid>
        </Box>
      </Wrapper>
    </Grid>
  ),
);

export default Address;
