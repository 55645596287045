import { RadioButtonsValues } from 'utils/commonTypes/types';

export const returnBooleanForBackend = (
  value: RadioButtonsValues | undefined,
  considerNull: boolean = false,
) => {
  switch (value) {
    case 'yes':
      return true;
    case 'no':
      return false;
    case '':
    case null:
    case undefined:
      return considerNull ? null : false;
    default:
      throw new Error('This function only supports "yes", "no", "" or null');
  }
};
