import { Company, Person, RefOwner } from 'context/customer-context/types';
import { getRolId } from './getRolId';

export const getOwnerRef = (
  value: Person | Company,
  type: 'person' | 'company',
  index: number,
): RefOwner => ({
  _type: type,
  id: value.id,
  ownerId: getRolId(type === 'person' ? 'OWP' : 'OWC', value.correlativeId),
  index,
});
