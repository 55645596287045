import { Field } from 'context/data-context/types';

export function findAField(
  data: any,
  condition: (value: Field) => boolean,
  depth: number = 0,
): Field | undefined {
  try {
    if (condition(data)) return data;
    if (typeof data !== 'object' || depth > 20) return undefined;
    const nodes = Array.isArray(data) ? [...data] : (Object.values(data) as []);
    for (const node of nodes) {
      const result = findAField(node, condition, depth + 1);
      if (result) return result;
    }
  } catch (err) {
    return undefined;
  }
}

export function isAfieldExisted(
  data: any,
  condition: (value: Field) => boolean,
  depth: number = 0,
): boolean {
  try {
    if (condition(data)) return true;
    if (typeof data !== 'object' || depth > 20) return false;
    const nodes = Array.isArray(data) ? [...data] : (Object.values(data) as []);
    for (const node of nodes) {
      const result = findAField(node, condition, depth + 1);
      if (result) return !!result;
    }
    return false;
  } catch (err) {
    return false;
  }
}
