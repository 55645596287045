export default (theme) => ({
  calculatedField: {
    fontSize: 23,
    backgroundColor: '#efefef',
    padding: 5,
    marginTop: 10,
    borderRadius: 8,
    textAlign: 'center',
  },
  multiline: {
    minHeight: '90px',
  },
  selectLabel: {
    '&$shrink': {
      display: 'none',
    },
  },
  shrink: {},
  labelTooltip: {
    display: 'block',
  },
  readOnlyContainer: {
    fontSize: 23,
    padding: 5,
    marginTop: 10,
    borderRadius: 8,
    textAlign: 'center',
  },
  completeField: {
    '& fieldset': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  textReadOnly: {
    fontSize: 14,
  },
  checkIcon: {
    width: 20,
  },
  marginCheckIcon: {
    marginLeft: 10,
  },
  checkIconUploadField: {
    width: 20,
    marginLeft: 15,
  },
  customTooltipWidth: {
    maxWidth: '250px !important',
  },
  checkBoxField: {
    alignSelf: 'flex-start',
    paddingTop: '10px',
  },
  checkBoxFieldHtml: {
    alignSelf: 'flex-start',
    paddingTop: 0,
  },
});
