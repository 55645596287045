interface Props {
  children: JSX.Element;
  condition: boolean;
  wrapper: (children: JSX.Element) => JSX.Element;
}

const Wrapper = ({ children, condition, wrapper }: Props) =>
  condition ? wrapper(children) : children;

export default Wrapper;
