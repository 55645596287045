import { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { MenuItem, Divider } from '@mui/material';

import { AuthContext } from '../../context/auth-context';
import LoanTypesInstructions from './LoanTypesInstructions';
import useStyles from './LoanOptionsStyles';
import CustomSelect from './CustomSelect';
import { request } from '../../service/ApiCaptiq';
import * as ApiCaptiq from '../../service/ApiCaptiq';
import TemplateFile from './components/TemplateFile';
import Support from './components/Support';

const LoanOptions = ({ disabled, onChange, form, errors }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const [loanTypes, setLoanTypes] = useState([]);
  const [professions, setProfessions] = useState();
  const [loanUsages, setLoanUsages] = useState();
  const [options, setOptions] = useState({});
  const classes = useStyles();
  const [selectedLoanUsageName, setSelectedLoanUsageName] = useState(null);

  useEffect(() => {
    async function getLoanTypes() {
      const { loan_types } = await getOptions();
      setLoanTypes(loan_types);
      if (loan_types[0]) await changeLoanType(loan_types[0].id);
    }

    if (form.loan_product) {
      loadFormOptions();
    } else {
      getLoanTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadFormOptions = async () => {
    const params = {
      loan_types: form.loan_types,
      loan_product: form.loan_product,
      loan_usage: form.loan_usage,
      professions: form.professions,
    };
    const { loan_types, loan_usages, professions: newProfessions } = await getOptions(params);
    setLoanTypes(loan_types);
    const loanUsagesTmp = loan_usages || [];
    setLoanUsages([...loanUsagesTmp]);
    setProfessions(newProfessions);
  };

  const getOptions = async (optionParams) => {
    try {
      const { data } = await request.get(ApiCaptiq.LOAN_OPTIONS_URL, { params: optionParams });
      return data;
    } catch (e) {
      console.error(e);
      return {
        loan_products: [],
        loan_types: [],
        loan_usages: [],
        professions: [],
        data: [],
      };
    }
  };

  const changeLoanType = async (loanTypeID) => {
    const { professions: newProfessions } = await getOptions({ loan_type: loanTypeID });
    setProfessions(newProfessions);
    updateOptions({ loan_product: '', loan_usage: '', professions: '', loan_type: loanTypeID });
  };

  const changeProfession = async (professionID) => {
    const { loan_usages } = await getOptions({ profession: professionID });
    setLoanUsages([...loan_usages]);
    updateOptions({ loan_product: '', loan_usage: '', profession: professionID });
  };

  const changeLoanUsage = async (loanUsageID) => {
    const { loan_products } = await getOptions({ loan_usage: loanUsageID });
    const selectedLoanUsage = loanUsages.find((usage) => usage.id === loanUsageID);
    setSelectedLoanUsageName(selectedLoanUsage ? selectedLoanUsage.name : '');
    updateOptions({
      loan_product: loan_products[0].id,
      loan_usage: loanUsageID,
      loanUsageSelected: loanUsages.find((n) => n.id === loanUsageID),
    });
  };

  const updateOptions = (newOptions) => {
    const opt = { ...options, ...newOptions };
    setOptions(opt);
    onChange(opt);
  };

  useEffect(() => {
    if (
      form.loan_usage >= 0 &&
      !selectedLoanUsageName &&
      loanUsages?.length > 0 &&
      isAuthenticated()
    ) {
      const selectedLoanUsage = loanUsages.find((usage) => usage.id === form.loan_usage);
      setSelectedLoanUsageName(selectedLoanUsage ? selectedLoanUsage.name : '');
    }
  }, [form.loan_usage, isAuthenticated, loanUsages, selectedLoanUsageName]);

  return (
    <>
      <LoanTypesInstructions />
      {loanTypes.filter((n) => n.is_active).length > 1 && (
        <CustomSelect
          disabled={disabled}
          onChange={({ target }) => changeLoanType(target.value)}
          name="loan_type"
          label="Darlehensart"
          value={form.loan_type}
        >
          {loanTypes &&
            loanTypes.map((item) =>
              item.is_active ? (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ) : (
                <li key={item.id} className={classes.disabledMenuItem}>
                  <span className="alert">Auswahl für das Kundenprofil nicht möglich.</span>{' '}
                  {item.name}
                </li>
              ),
            )}
        </CustomSelect>
      )}
      <CustomSelect
        size="small"
        disabled={disabled}
        onChange={({ target }) => changeProfession(target.value)}
        name="profession"
        label="Ich bin..."
        value={professions ? form.profession : ''}
        error={!!errors.profession}
        helperText={errors.profession ? errors.profession : ''}
      >
        {professions ? (
          professions.map((item, index) =>
            item.is_active ? (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            ) : (
              <li key={index} className={classes.disabledMenuItem}>
                <span className="alert">Dieser Beruf kann aktuell noch nicht gewählt werden.</span>{' '}
                {item.name}
              </li>
            ),
          )
        ) : (
          <MenuItem disabled>Daten abrufen</MenuItem>
        )}
      </CustomSelect>
      <CustomSelect
        size="small"
        disabled={disabled}
        onChange={({ target }) => changeLoanUsage(target.value)}
        name="loan_usage"
        label="Ich benötige ein Darlehen für..."
        value={loanUsages ? form.loan_usage : ''}
        error={!!errors.loan_usage}
        helperText={errors.loan_usage ? errors.loan_usage : ''}
      >
        {loanUsages ? (
          loanUsages.map((item, index) =>
            item && item.is_active ? (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            ) : (
              <li key={index} className={classes.disabledMenuItem}>
                <span className="alert">Auswahl für das Kundenprofil nicht möglich.</span>{' '}
                {item?.name}
              </li>
            ),
          )
        ) : (
          <MenuItem disabled>Bitte füllen Sie erst das obere Feld aus</MenuItem>
        )}
      </CustomSelect>
      {selectedLoanUsageName && isAuthenticated() ? (
        <>
          <Divider className={classes.divider} />
          <TemplateFile loanUsageId={form.loan_usage} loanUsageName={selectedLoanUsageName} />{' '}
        </>
      ) : null}
      {isAuthenticated() ? (
        <>
          <Divider className={classes.divider} />
          <Support />
        </>
      ) : null}
    </>
  );
};

LoanOptions.defaultProps = {
  disabled: false,
  form: {},
  errors: {},
  onChange: () => {},
};

LoanOptions.propTypes = {
  disabled: PropTypes.bool,
  form: PropTypes.object,
  onChange: PropTypes.func,
  errors: PropTypes.object,
};

export default LoanOptions;
