import * as Yup from 'yup';

export const dateOfBirth = Yup.lazy((value) => {
  const label = 'Geburtsdatum';
  switch (typeof value) {
    case 'object':
      return Yup.date().required().label(label);
    case 'string':
      return Yup.string().required().label(label);
    default:
      return Yup.mixed().required().label(label);
  }
});
