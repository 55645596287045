import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  tabContainer: {
    borderBottom: '1px solid #ccc',
    overflow: 'hidden',
    height: '20px',
  },
  tabContent: {
    display: 'flex',
    minHeight: '70px',
    flexWrap: 'nowrap',
  },
  scrollButton: {
    margin: '5px 7px 5px 7px',
    maxWidth: 25,
    minWidth: 0,
    zIndex: 999999,
    backgroundColor: '#efefef',
    color: 'black',
    '&:hover': {
      backgroundColor: '#bfbfbf',
    },
  },
  noScroll: {
    margin: '0px 25px !important',
  },
  tabList: ({ isEditing }: any): any => {
    const stylesForTabs = {
      transition: 'background-color 0.4s',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      minWidth: '240px',
      padding: '10px 35px',
      color: 'rgba(0, 0, 0, 0.6§)',
      position: 'relative',
      height: '100%',
      minHeight: 64,
      border: '1px solid #eaeaeacc',
      borderBottom: 'none',
      borderRadius: '15px 15px 0px 0px',
      marginLeft: '-15px',
      marginRight: '-15px',
      backgroundColor: (props: any) => props?.customColors?.tabBackgroundColor || '#ffffff',
      boxShadow: '0px 0px 0px 0px #eaeaeacc, 0px 6px 4px 3px #eaeaeacc',
      '& .tabLabel': {
        width: 122,
      },
      '& .tab-status': {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 5,
        transition: 'all 0.4s',
        opacity: 1,
      },
      '& button': {
        opacity: 0,
      },
      '&.active-tab': {
        color: 'black',
        border: 'none',
        position: 'relative',
        backgroundColor: '#efefef',
        borderRadius: '15px 15px 0px 0px',
        marginRight: '-15px',
        marginLeft: '-15px',
        boxShadow: '0px 0px 0px 0px #b9b9b9cc, 0px 6px 4px 2px #b9b9b9cc',
        '&:hover': {
          backgroundColor: '#bfbfbf',
          '& #tab-content': {
            backgroundColor: '#bfbfbf',
          },
        },
      },
      '&:hover': {
        backgroundColor: '#bfbfbf',
        '& button': {
          opacity: 1,
        },
        '& .tab-status': {
          opacity: 0,
          display: 'none',
        },
      },
    };

    const styles: any = {
      flexGrow: 1,
      listStyle: 'none',
      padding: '0 20px',
      paddingTop: 10,
      margin: '0px',
      display: 'flex',
      alignItems: 'center',
      background: 'white',
      minHeight: '70px',
      overflowX: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    };

    if (!isEditing) {
      styles['& li'] = stylesForTabs;
      return styles;
    }

    styles['& li:last-child'] = {
      minWidth: 58,
      marginLeft: 40,
    };
    styles['& li:not(:last-child)'] = stylesForTabs;
    return styles;
  },
  fabButton: {
    boxShadow:
      '0px 0px 0px 0px rgba(0,0,0,0.2), 3px 2px 3px 0px rgba(0,0,0,0.14), 0px 0px 2px 0px rgba(0,0,0,0.12)',
  },
}));

export default useStyles;
