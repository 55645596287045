export const FAMILY_STATUSES = [
  'other',
  'unknown',
  'divorced',
  'married',
  'widowed',
  'single',
  'registered_civil_partnership',
] as const;

export type FamilyStatus = (typeof FAMILY_STATUSES)[number];

export function getFamilyStatusLabel(familyStatus: FamilyStatus) {
  switch (familyStatus) {
    case 'unknown':
      return 'Unbekannt';
    case 'registered_civil_partnership':
      return 'Eingetragene Lebenspartnerschaft';
    case 'divorced':
      return 'Geschieden';
    case 'single':
      return 'Ledig';
    case 'married':
      return 'Verheiratet';
    case 'widowed':
      return 'Verwitwet';
    case 'other':
      return 'Sonstige';
    default:
      return '';
  }
}
