import { memo, useRef } from 'react';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material';
import { GetApp } from '@mui/icons-material';

import { useDataContext } from 'context/data-context';
import { yesEmail, noEmail, noEmailV2, yesEmailV2, yesEmailV3, noEmailV3 } from './constants';
import useStyles from './styles';

interface BaseModalProps {
  open: boolean;
  onClose: () => void;
  hgbYes: boolean;
  loadingDownload: boolean;
  handleDownloadAllFiles: (e: any) => Promise<void>;
  version?: number;
}

const EmailTemplateModal = ({
  open,
  hgbYes,
  loadingDownload,
  handleDownloadAllFiles,
  onClose,
  version = 1,
}: BaseModalProps) => {
  const { loanApplication } = useDataContext();
  const classes = useStyles();
  const editorRef = useRef<any>();

  const copyHTML = async () => {
    const text = document.getElementById('tax-advisor-email-template')?.innerHTML || '';
    const copyListener = (e: ClipboardEvent) => {
      e.clipboardData?.setData('text/html', text);
      e.clipboardData?.setData('text/plain', text);
      e.preventDefault();
    };
    try {
      if (window.ClipboardItem) {
        const clipboardItem = new ClipboardItem({
          'text/plain': Promise.resolve(new Blob([text], { type: 'text/plain' })),
          'text/html': Promise.resolve(new Blob([text], { type: 'text/html' })),
        });
        await navigator.clipboard.write([clipboardItem]);
      } else {
        // TODO: update this functionality when a better well supported option is available
        document.addEventListener('copy', copyListener);
        document.execCommand('copy');
        document.removeEventListener('copy', copyListener);
      }
    } catch (error) {
      console.error(error);
      console.error('Error while coping rich text to clipboard');
    }
  };
  return (
    <Dialog onClose={onClose} open={open} fullWidth>
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="h6">
          E-MAIL-VORLAGE ZUR ANFORDERUNG DER DOKUMENTE VOM STEUERBERATER
        </Typography>
      </DialogTitle>
      <DialogContent>
        <div ref={editorRef} className={classes.emailContainer}>
          <div id="tax-advisor-email-template" className={classes.contentContainer}>
            {version === 1
              ? hgbYes
                ? yesEmail
                : noEmail
              : loanApplication?.hgb_version === '2023-01'
                ? hgbYes
                  ? yesEmailV2
                  : noEmailV2
                : hgbYes
                  ? yesEmailV3
                  : noEmailV3}
          </div>
        </div>
        <Button
          onClick={copyHTML}
          variant="contained"
          color="primary"
          className={classes.downloadButton}
        >
          Text kopieren
        </Button>
        <Divider className={classes.divider} />
        <Typography className={classes.text}>
          Im Folgenden können Sie die Musterdokumente für all die von Ihrem Steuerberater benötigten
          Unternehmensdokumente als komprimierten Ordner für den{' '}
          <strong>Anhang Ihrer E- Mail herunterladen:</strong>
        </Typography>
        <Button
          disabled={loadingDownload}
          onClick={handleDownloadAllFiles}
          variant="contained"
          color="primary"
          startIcon={<GetApp />}
          className={classes.downloadButton}
        >
          Alle Musterdokumente herunterladen
          {loadingDownload && (
            <CircularProgress color="primary" size={24} className={classes.buttonProgress} />
          )}
        </Button>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={onClose} variant="contained" className={classes.cancelButton}>
          Abbrechen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(EmailTemplateModal);
